import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import SearchInput from '@/components/UI/SearchInput';
import { GameCategoryNav } from './GameCategoryNav';
import { forceCheck } from 'react-lazyload';
import styled from 'styled-components';
import { GamesSearch } from './GamesSearch';
import { useLocation } from 'react-use';
import { envIsMexico } from '@/constants/constants';

const GamesHeaderMexico = lazy(() => import('./GamesHeaderMexico'));

const StyledGamesHero = styled.section`
  background: ${props => props.theme.colors.blue2};
  padding: 60px 40px 60px;
  position: relative;
  z-index: 1;
  overflow: hidden;

  @media (min-width: ${props => props.theme.breakpoints.small}) {
    padding: 100px 20px 100px;
  }
`;

function Games(): JSX.Element {
  const intl = useIntl();
  const location = useLocation();
  const [searchValue, setSearchValue] = useState('');

  const updateSearch = (event: any) => {
    setSearchValue(event.target.value.substr(0, 20));

    setTimeout(() => {
      forceCheck();
    }, 10);
  };

  const resetSearch = () => {
    setSearchValue('');
  };

  // This effect resets the search on every location change - that happens when the category changes
  useEffect(() => {
    resetSearch();
  }, [location]);

  return (
    <div>
      <Helmet>
        <title>LuckyDays - {intl.formatMessage({ id: 'header.games' })}</title>
      </Helmet>
      <Suspense fallback={<div />}>
        {envIsMexico() ? (
          <GamesHeaderMexico />
        ) : (
          <StyledGamesHero>
            <SearchInput
              id="games-page-search-input"
              name="gameSearch"
              placeholder={intl.formatMessage({ id: 'games.searchPlaceholder' })}
              onChange={event => updateSearch(event)}
              onClose={() => resetSearch()}
              value={searchValue}
            />
          </StyledGamesHero>
        )}
      </Suspense>
      <GameCategoryNav />
      {searchValue && searchValue.length > 0 ? (
        <GamesSearch searchValue={searchValue} />
      ) : (
        <Outlet />
      )}
    </div>
  );
}

export default Games;
