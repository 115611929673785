import Field, { FieldProps } from '../field/Field';
import React, { useState } from 'react';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';

interface PasswordFieldProps extends Omit<FieldProps, 'type'> {}

const PasswordField = (props: PasswordFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Field
      type={showPassword ? 'text' : 'password'}
      endIcon={showPassword ? <EyeSlashIcon /> : <EyeIcon />}
      endIconAction={() => setShowPassword(!showPassword)}
      {...props}
    />
  );
};

export default PasswordField;
