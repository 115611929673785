import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { selectAuthToken } from '@/store/selectors/authSelectors';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { isRegulatedLocale } from '@/shared/utility';
import { getAmlVerificationStatus } from '@/store/actions/amlActions';

const useAmlVerificationStatusLoader = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectAuthToken);
  const locale = useSelector(selectLocale);

  useEffect(() => {
    if (!token || !isRegulatedLocale(locale)) {
      return;
    }

    dispatch(getAmlVerificationStatus());
  }, [token, locale]);
};

export default useAmlVerificationStatusLoader;
