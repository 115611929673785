import React from 'react';
import { selectGamesLoading, selectLastPlayedGames } from '@/store/selectors/gamesSelectors';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import LobbySection from '@/pages/games/lobby/lobby-sections/LobbySection';
import { LastPlayedCategory } from '@/models/game-category.type';

const LastPlayedGames = (): JSX.Element | null => {
  const intl = useIntl();
  const games = useSelector(selectLastPlayedGames);
  const loading = useSelector(selectGamesLoading);

  return (
    <LobbySection
      header={intl.formatMessage({ id: `games.categories.lastPlayed` })}
      subheader={intl.formatMessage({ id: `games.categories.lastPlayed.info` })}
      games={games}
      loading={loading}
      category={LastPlayedCategory.LastPlayed}
      maxItems={6}
    />
  );
};

export default LastPlayedGames;
