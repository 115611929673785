import parse from 'html-react-parser';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import PageHeader from '../Layout/PageHeader';
import TermsAndConditionsStyles from '../styles/TermsAndConditionsStyles';
import Spinner from '../UI/Spinner';
import { useTermsAndConditionsLoader } from '@/hooks/useTermsAndConditionsLoader';

function TermsAndConditions(): JSX.Element {
  const intl = useIntl();
  const termsAndConditions = useTermsAndConditionsLoader();

  return (
    <>
      <Helmet>
        <title>LuckyDays - {intl.formatMessage({ id: 'termsAndConditions.header' })}</title>
      </Helmet>
      <PageHeader>
        <h1>{intl.formatMessage({ id: 'termsAndConditions.header' })}</h1>
        <p>{!!termsAndConditions && termsAndConditions.last_updated}</p>
      </PageHeader>
      <TermsAndConditionsStyles>
        <div className="content">
          {!!termsAndConditions ? (
            parse(termsAndConditions.content.toString())
          ) : (
            <Spinner dark absolute />
          )}
        </div>
      </TermsAndConditionsStyles>
    </>
  );
}

export default TermsAndConditions;
