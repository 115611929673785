import { ArrowRightStartOnRectangleIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { Outlet } from 'react-router-dom';
import { AbsoluteLink } from '@/components/AbsoluteLink';
import PageHeader from '@/components/Layout/PageHeader';
import SettingsNav from './SettingsNav';
import { envIsCanada } from '@/constants/constants';
import Regily from '@/components/Regily/Regily';

const SettingsHeader = (): JSX.Element => {
  const intl = useIntl();

  return (
    <>
      <div className="relative min-h-screen">
        <Helmet>
          <title>LuckyDays - {intl.formatMessage({ id: 'settings.header' })}</title>
        </Helmet>
        <PageHeader>
          <h1>{intl.formatMessage({ id: 'settings.header' })}</h1>
          <p>{intl.formatMessage({ id: 'settings.subHeader' })}</p>
        </PageHeader>
        <AbsoluteLink to="/logout">
          <div className="absolute top-20 right-8 hidden h-10 w-10 items-center justify-center rounded-full bg-white/20 text-white transition hover:bg-white/30 hover:shadow-lg md:flex">
            <ArrowRightStartOnRectangleIcon
              id="e2e_logoutButton"
              className="relative -right-[1px] h-5 w-5"
            />
          </div>
        </AbsoluteLink>
        <SettingsNav />
        <div className="content">
          <Outlet />
        </div>
      </div>
      {envIsCanada() && <Regily />}
    </>
  );
};

export default SettingsHeader;
