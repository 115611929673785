import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export function useRebate() {
  const [showRebate, setShowRebate] = useState(false);
  const [redirectFromRebate, setRedirectFromRebate] = useState(false);
  const { user, tags } = useSelector(state => state.user);
  const locale = useSelector(state => state.common.locale);

  useEffect(() => {
    if (!user || !tags || tags.length === 0) {
      setShowRebate(false);
      return;
    }

    const isExcludedFromRebate = tags.some(tag => tag.Name === 'Bonus Restricted');

    if (!isExcludedFromRebate && locale === 'th') {
      setShowRebate(true);
    }

    if (isExcludedFromRebate || locale !== 'th') {
      setRedirectFromRebate(true);
    }
  }, [tags, locale, user]);

  return { showRebate, redirectFromRebate };
}
