import React from 'react';
import GameListHeader from '@/pages/games/GameListHeader';
import { GamesCategories } from '@/models/game-category.type';
import { GameCardVM } from '@/models/vm/game-card-vm.model';
import EmptyGamesPlaceholder from '@/pages/games/EmptyGamesPlaceholder';
import GamesInfiniteGrid from '@/pages/games/GamesInfiniteGrid';

type CategoryPageContentProps = {
  header: string;
  subheader?: string;
  category: GamesCategories;
  games: GameCardVM[];
  loading: boolean;
};

const CategoryPageContent = ({
  header,
  subheader,
  category,
  games,
  loading,
}: CategoryPageContentProps): JSX.Element => {
  return (
    <section className="py-16">
      <GameListHeader
        header={header}
        subheader={subheader}
        games={games}
        count={games.length}
        gamesLoading={loading}
      />
      {games && games.length > 0 ? (
        <GamesInfiniteGrid games={games} category={category} gamesLoading={loading} />
      ) : (
        <EmptyGamesPlaceholder category={category} />
      )}
    </section>
  );
};

export default CategoryPageContent;
