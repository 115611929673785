import { SagaIterator } from 'redux-saga';
import { all, AllEffect, call, ForkEffect, put, takeEvery } from 'redux-saga/effects';
import {
  ConsentsActionTypes,
  getConsentsError,
  getConsentsSuccess,
} from '../actions/consentsActions';
import ApiService from '../../shared/apiService';

export function* getConsentsSaga(): SagaIterator {
  try {
    const response = yield call(ApiService.getConsents);
    yield put(getConsentsSuccess(response.data));
  } catch (error) {
    yield put(getConsentsError());
  }
}

export function* watchConsents(): Generator<AllEffect<ForkEffect<never>>, void> {
  yield all([takeEvery(ConsentsActionTypes.GetConsents, getConsentsSaga)]);
}
