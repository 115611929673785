import React, { ReactNode, useEffect, useRef, useState } from 'react';

import StepperProgress from './StepperProgress';
import usePreviousValue from '@/hooks/usePreviousValue';
import { InjectedStepProps, StepProps } from './Step';

interface StepperProps {
  activeStep: number;
  children: ReactNode;
}

const Stepper = ({ activeStep, children }: StepperProps) => {
  const previousStep = usePreviousValue(activeStep);
  const stepperContainerRef = useRef<HTMLDivElement | null>(null);
  const [translation, setTranslation] = useState(0);

  const stepsCount = React.Children.count(children);

  const propsInjectedChildren = React.Children.map(children, (child, index) => {
    if (React.isValidElement(child)) {
      const step = index + 1;
      return React.cloneElement(child as React.ReactElement<{ inject: InjectedStepProps }>, {
        inject: {
          isActiveStep: activeStep === step,
        },
      });
    }
  });

  const stepIcons =
    React.Children.toArray(children).map(child => {
      if (React.isValidElement(child)) {
        const stepProps = child.props as StepProps;
        return stepProps.stepIcon ?? null;
      }
      return null;
    }) || [];

  const getTranslation = () => {
    if (!previousStep) return 0; // Initial mount

    const direction = activeStep > previousStep ? -1 : 1; // -1 for forward, 1 for backward
    return translation + (100 / stepsCount) * direction;
  };

  useEffect(() => {
    // Define translation
    const computedTranslation = getTranslation();
    setTranslation(computedTranslation);
    // Scroll to the offsetted top of the stepper on every step change
    if (stepperContainerRef && stepperContainerRef.current) {
      window.scrollTo({ top: stepperContainerRef.current.offsetTop - 84 });
    }
  }, [activeStep]);

  return (
    <div ref={stepperContainerRef} className="w-full overflow-x-clip flex flex-col">
      <StepperProgress count={stepsCount} activeStep={activeStep} stepIcons={stepIcons} />
      <div
        style={{
          width: `${stepsCount * 100}%`,
          transform: `translateX(${translation}%)`,
        }}
        className="flex transition-transform duration-150 ease-out"
      >
        {propsInjectedChildren}
      </div>
    </div>
  );
};

export default Stepper;
