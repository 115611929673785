import React from 'react';

import { BoltIcon } from '@heroicons/react/24/solid';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { useSelector } from 'react-redux';
import { selectLocale } from '@/store/selectors/commonSelectors';

const RegistrationUsp = () => {
  const locale = useSelector(selectLocale);

  return (
    <div className="flex items-center justify-between bg-blue-blueMedium py-3 px-4 rounded-t-lg sm:py-4">
      <span
        className={classNames('text-white text-sm max-w-[240px] sm:!max-w-fit', {
          '!max-w-[260px]': locale === 'ca-fr',
        })}
      >
        <FormattedMessage
          id="registration.ontario.usp"
          values={{
            b: str => <span className="font-bold">{str}</span>,
          }}
        />
      </span>
      <BoltIcon className="w-5 h-auto text-white/70" />
    </div>
  );
};

export default RegistrationUsp;
