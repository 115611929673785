import { useEffect, useState } from 'react';
import { useCookieConsentStore } from '@/store/cookieConsentStore';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import { selectLocale } from '@/store/selectors/commonSelectors';
import {
  selectUserEmail,
  selectUserCurrency,
  selectUserId,
  selectDepositCount,
} from '@/store/selectors/userSelectors';
import { selectUserBtag } from '@/store/selectors/combined.selectors';
import { sha256Hash } from '@/shared/crypto-utils';
import { logException } from '@/utils/logger';
import { GTagEvents } from '@/lib/gTagManager';
import { APP_VERSION, envIsMexico } from '@/constants/constants';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

function gtag() {
  window.dataLayer = window.dataLayer || [];
  // @ts-ignore
  dataLayer.push(arguments);
}

const defaultGTMConsents = (): void => {
  // @ts-ignore
  gtag('consent', 'default', {
    ad_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    analytics_storage: 'denied',
  });
};

const updateGTMConsents = (granted: boolean): void => {
  const consent = granted ? 'granted' : 'denied';
  // @ts-ignore
  gtag('consent', 'update', {
    ad_storage: consent,
    ad_user_data: consent,
    ad_personalization: consent,
    analytics_storage: consent,
  });
  // Important! This is sending an event to GTM called consent_update, and we use it to trigger certain tags
  if (granted) {
    // @ts-ignore
    gtag('event', 'consents_granted');
  }
};

// we've decided to split the GTM container for Xexico
const tagManagerId = (): string => {
  if (envIsMexico()) {
    return 'GTM-NS5HLST3';
  }
  return 'GTM-WPM3ZX3';
};

const useTagManager = (): void => {
  const performanceCookiesAccepted = useCookieConsentStore(state => state.performance);
  const locale = useSelector(selectLocale);
  const userBtag = useSelector(selectUserBtag);
  const userId = useSelector(selectUserId);
  const email = useSelector(selectUserEmail);
  const currency = useSelector(selectUserCurrency);
  const depositCount = useSelector(selectDepositCount);
  const [consentsGranted, setConsentsGranted] = useState(false);

  useEffect(() => {
    defaultGTMConsents();
    if (APP_VERSION) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        appVersion: APP_VERSION,
      });
    }
  }, []);

  useEffect(() => {
    if (!locale) {
      return;
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      market: locale,
    });

    // We don't want GA events triggering when running the app for Cypress purposes
    // @ts-ignore
    if (!window.Cypress) {
      TagManager.initialize({
        gtmId: tagManagerId(),
      });
    }
  }, [locale]);

  useEffect(() => {
    // for Thailand we want to grant consent by default
    if (locale === 'th' || locale === 'mx') {
      setConsentsGranted(true);
      return;
    }

    // for other locales we don't want to grant consent mode if user doesn't accept performance cookies
    setConsentsGranted(performanceCookiesAccepted);
  }, [performanceCookiesAccepted, locale]);

  useEffect(() => {
    // Important: we've went a few back and forths in how to do this
    // currently our users can't change their consent settings from granted -> denied
    // so we only want to update GTM with the new consents if they are granted
    // because we have the consents as denied by default
    if (consentsGranted) {
      updateGTMConsents(true);
    }
  }, [consentsGranted]);

  useEffect(() => {
    // Set btag only once per session
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      btag: userBtag,
    });
  }, [userBtag]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    if (userId) {
      window.dataLayer.push({
        event: GTagEvents.user_id_set,
        userId,
      });
    } else {
      window.dataLayer.push({
        event: GTagEvents.user_id_unset,
        userId: undefined,
      });
    }
  }, [userId]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];

    if (!email) {
      window.dataLayer.push({
        email: undefined,
      });
      return;
    }

    sha256Hash(email)
      .then(hashedEmail => {
        window.dataLayer.push({
          email: hashedEmail,
        });
      })
      .catch(e => logException(e));
  }, [email]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      currency,
    });
  }, [currency]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      depositCount,
    });
  }, [depositCount]);
};

export default useTagManager;
