import React from 'react';
import Spinner from '../UI/Spinner';

export function BtnOneLineRed({
  onClickHandler,
  isLoading,
  text,
  disabled = false,
}: {
  onClickHandler: () => void;
  isLoading: boolean;
  text: string;
  disabled?: boolean;
}): JSX.Element {
  return (
    <button
      type="button"
      className="relative min-h-[64px] w-full rounded-full bg-red-500 p-3 text-white transition hover:bg-red-600 disabled:cursor-not-allowed disabled:opacity-50"
      onClick={onClickHandler}
      disabled={isLoading || disabled}
    >
      {isLoading && (
        <Spinner absolute={true} width={20} height={20} borderwidth={3} color="white" />
      )}
      <span className={`text-lg font-bold leading-6 ${isLoading ? 'opacity-0' : ''}`}>{text}</span>
    </button>
  );
}
