import styled from 'styled-components';

export const StyledPaginatedTable = styled.div`
  margin-top: 1rem;

  .paginated-table {
    background: white;
    box-shadow:
      0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);

    tr th {
      background: ${props => props.theme.colors.black};
      color: white;
    }

    tr:nth-child(even) {
      background: ${props => props.theme.colors.grey10};
    }

    .paginated-table-controls {
      button.active {
        background: ${props => props.theme.colors.blue1};
      }
    }
  }
`;
