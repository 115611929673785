import React, { useState } from 'react';

import { GiftIcon } from '@heroicons/react/24/solid';

import Stepper from '@/components/stepper/Stepper';
import Step from '@/components/stepper/Step';
import BasicInfoForm from './BasicInfoForm';
import AddressInfoForm from './AddressInfoForm';
import WorkInfoForm from './WorkInfoForm';
import ConsentsForm from './ConsentsForm';
import RegistrationUsp from './RegistrationUsp';
import RegistrationCustomerSupport from './RegistrationCustomerSupport';
import { CDN_BASE, CDN_IMAGES_PATH } from '@/constants/constants';

const DESKTOP_BG = `${CDN_BASE}${CDN_IMAGES_PATH}/common/ont-reg-desktop.jpg`;
const DESKTOP_SMALL_BG = `${CDN_BASE}${CDN_IMAGES_PATH}/common/ont-reg-desktop-small.jpg`;
const TABLET_BG = `${CDN_BASE}${CDN_IMAGES_PATH}/common/ont-reg-tablet.jpg`;
const MOBILE_BG = `${CDN_BASE}${CDN_IMAGES_PATH}/common/ont-reg-mobile.jpg`;

const Registration = () => {
  const [activeStep, setActiveStep] = useState(1);
  // showError is for special error handling when we want to get out of the stepper but still show the error
  const [showError, setShowError] = useState<string | null>(null);

  const stepBack = () => {
    setActiveStep(prev => prev - 1);
  };

  const stepForward = () => {
    setActiveStep(prev => prev + 1);
  };

  return (
    <div className="w-full min-h-screen flex items-start sm:items-center justify-center px-3 pb-44 relative pt-8 sm:py-28">
      <div
        className="absolute left-0 right-0 bottom-0 top-0 before:top-0 before:right-0 before:bottom-0
        before:left-0 before:absolute before:bg-black/50"
      >
        <picture>
          <source media="(max-width: 760px)" srcSet={MOBILE_BG} />
          <source media="(max-width: 1100px)" srcSet={TABLET_BG} />
          <source media="(max-width: 1600px)" srcSet={DESKTOP_SMALL_BG} />
          <img
            className="w-full h-full object-cover object-right-bottom"
            src={DESKTOP_BG}
            alt="Lucky the Cat in front of Toronto Ontario Skyline"
          />
        </picture>
      </div>
      <div
        className="bg-white/10 rounded-lg backdrop-blur-sm shadow-md border-white/10 border-solid border-[1px]
        max-w-xl w-full mb-20 sm:mb-0"
      >
        {!showError && (
          <>
            <RegistrationUsp />
            <div className="p-5 pb-8 sm:p-8 sm:pb-12">
              <Stepper activeStep={activeStep}>
                <Step>
                  <BasicInfoForm stepForward={stepForward} />
                </Step>
                <Step>
                  <AddressInfoForm stepBack={stepBack} stepForward={stepForward} />
                </Step>
                <Step>
                  <WorkInfoForm stepBack={stepBack} stepForward={stepForward} />
                </Step>
                <Step stepIcon={GiftIcon}>
                  <ConsentsForm stepBack={stepBack} setShowError={setShowError} />
                </Step>
              </Stepper>
            </div>
          </>
        )}
        {showError && <RegistrationCustomerSupport />}
      </div>
    </div>
  );
};

export default Registration;
