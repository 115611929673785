import { RootState } from '@/models/root-state.model';
import { createSelector } from 'reselect';
import { PNP_DEPOSIT_DEFAULTS } from '@/constants/paynplayConstants';

export const selectLastDeposit = (state: RootState): number | undefined =>
  state.lastDeposit.lastDeposit;

export const selectLastDepositDefault = createSelector(selectLastDeposit, (lastDeposit): number => {
  if (!lastDeposit) {
    return PNP_DEPOSIT_DEFAULTS.low;
  }

  return Math.ceil(lastDeposit / 10) * 10;
});

export const selectLifetimeDepositsAmount = (state: RootState): number | undefined =>
  state.lastDeposit.lifetimeDeposits?.lifetimeDeposits;
