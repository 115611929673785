import { UIActions, UIActionType } from '../actions/uiActions';

export interface UIState {
  isSingleGame: boolean;
}

const initialState = {
  isSingleGame: false,
};

export const uiReducer = (state = initialState, action: UIActions): UIState => {
  switch (action.type) {
    case UIActionType.SetIsSingleGame:
      return {
        ...state,
        isSingleGame: action.isSingleGame,
      };
    default:
      return state;
  }
};
