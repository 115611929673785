import React, { ChangeEvent, InputHTMLAttributes } from 'react';

import { ErrorMessage, useField } from 'formik';
import classNames from 'classnames';

type FormCheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  name: string;
};

const FormCheckbox = ({ ...props }: FormCheckboxProps) => {
  const { name, label, onChange, ...rest } = props;
  const [field] = useField(name);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event);
    field.onChange(event);
  };

  return (
    <div className="w-fit">
      <label
        className={classNames(
          'text-blue-blueMedium font-semibold flex gap-2.5 items-center text-base break-words',
          { '!text-gray-400': props.disabled },
        )}
      >
        <input
          type="checkbox"
          className="w-5 h-5 rounded-sm border-none text-blue-blue focus:outline-none focus:ring-0
          focus:ring-offset-0 cursor-pointer shadow-lg disabled:cursor-not-allowed disabled:text-gray-400"
          {...field}
          {...rest}
          onChange={handleOnChange}
          checked={field.value}
        />
        {label}
      </label>
      <div className="h-6 mt-1.5">
        <ErrorMessage name={name} component="div" className="text-danger" />
      </div>
    </div>
  );
};

export default FormCheckbox;
