import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { setBtag } from '@/store/actions/commonActions';
import { isEmpty } from '@/shared/utility';
import CookiesManager from '../../../shared/cookies-manager';

/*
  This hook is used to handle the btag logic.
  1. If there is a btag already stored in the cookies, it will set it in the store.
  2. If there is a btag in the url, it will set it in the store and store it in the cookies for 30 days.
  3. Throughout the app we use the btag from the store to send it either to the backend or the GTM.
 */
const useBtagLogic = (): void => {
  const { search } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    // check if there are is already a btag stored in the cookies -> set it in the store
    const storedBtag = CookiesManager.getBtagCookie();
    if (storedBtag) {
      dispatch(setBtag(storedBtag));
    }
  }, []);

  useEffect(() => {
    if (isEmpty(search)) {
      return;
    }
    const params = new URLSearchParams(search);
    const btag = params.get('btag');

    if (btag) {
      CookiesManager.setBtagCookie(btag);
      dispatch(setBtag(btag));
    }
  }, [search]);
};

export default useBtagLogic;
