import React from 'react';

import {
  CreditCardIcon,
  HomeIcon,
  ArrowLeftEndOnRectangleIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import { isMobile, useMobileOrientation } from 'react-device-detect';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { AbsoluteLink } from '@/components/AbsoluteLink';
import { useRegistration } from '@/hooks/useRegistration';
import { selectIsAuthenticated } from '@/store/selectors/authSelectors';
import { useCashierShow } from '@/features/cashier/useCashier';
import { isRegulatedLocale } from '@/shared/utility';
import { openModal } from '@/store/actions/modal';

export function GameHeader(): JSX.Element | null {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const dispatch = useDispatch();
  const { isLandscape } = useMobileOrientation();
  const { openRegistration } = useRegistration();
  const intl = useIntl();
  const showCashier = useCashierShow();

  const handleOpenCashier = () => {
    showCashier({ transactionType: 'quick-deposit' });
  };

  if (!isMobile) {
    return null;
  }

  return (
    <div
      className={`relative z-10 flex items-center px-1 ${
        isLandscape ? 'h-full flex-col justify-start space-y-2 pt-2' : 'h-8 justify-between'
      }`}
    >
      <AbsoluteLink to="/games">
        <div className="rounded-full bg-white px-1">
          <HomeIcon className="h-5 w-5 text-black" />
        </div>
      </AbsoluteLink>
      {!isAuthenticated ? (
        <div className={`${isLandscape ? '' : 'space-x-2'}`}>
          {!isRegulatedLocale && (
            <button
              type="button"
              className="rounded-full bg-white px-1 text-sm font-bold text-black"
              onClick={() => dispatch(openModal('login'))}
            >
              {isLandscape ? (
                <ArrowLeftEndOnRectangleIcon className="h-5 w-5" />
              ) : (
                intl.formatMessage({ id: 'header.login' })
              )}
            </button>
          )}
          <button
            type="button"
            className="rounded-full bg-cyan-500 px-1 text-sm font-bold text-white"
            onClick={() => openRegistration()}
          >
            {!isLandscape ? (
              intl.formatMessage({ id: 'header.register' })
            ) : (
              <UserPlusIcon className="h-5 w-5" />
            )}
          </button>
        </div>
      ) : (
        <button type="button" className="rounded-full bg-cyan-500 px-1" onClick={handleOpenCashier}>
          <CreditCardIcon className="h-5 w-5 text-white" />
        </button>
      )}
    </div>
  );
}
