import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import CloseButton from '../UI/Buttons/CloseButton';

const StyledRegistrationUnavailableModal = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  max-width: 520px;
  overflow: hidden;
  border-radius: ${props => props.theme.borderRadius};
  background: white;
  padding: 48px 32px;
  position: relative;

  h1 {
    font-size: 32px;
  }

  p {
    margin-bottom: 0;
  }
`;

function RegistrationUnavailableModal({ closeModal }) {
  const intl = useIntl();
  return (
    <StyledRegistrationUnavailableModal>
      <CloseButton left onClick={closeModal} />
      <h1>{intl.formatMessage({ id: 'registrationUnavailable.title' })}</h1>
      <p>{intl.formatMessage({ id: 'registrationUnavailable.text' })}</p>
    </StyledRegistrationUnavailableModal>
  );
}

RegistrationUnavailableModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default RegistrationUnavailableModal;
