import { createSelector } from 'reselect';
import { GetUserLimitModel } from '@/models/gig/get-user-limit.model';
import { RootState } from '@/models/root-state.model';
import { LimitDetails, LimitDuration, LimitType } from '@lucky7ventures/lucky-components';

export const selectLimits = (state: RootState): GetUserLimitModel | null => state.limits.limits;
export const selectLimitsLoading = (state: RootState): boolean => state.limits.loading;
export const selectLimitsLoaded = (state: RootState): boolean => state.limits.loaded;
export const selectLimitsError = (state: RootState): boolean => state.limits.error;

export const selectActiveLimits = createSelector(selectLimits, limits =>
  limits ? limits.ActiveLimits.sort((a, b) => a.Duration - b.Duration) : [],
);

export const selectFutureLimits = createSelector(selectLimits, limits =>
  limits
    ? limits.FutureLimits.filter(limit => !limit.EndDate).sort((a, b) => a.Duration - b.Duration)
    : [],
);

export const selectActiveDepositLimits = createSelector(
  selectActiveLimits,
  limits => limits?.filter(limit => limit.LimitType === LimitType.Deposit),
);

export const selectFutureDepositLimits = createSelector(
  selectFutureLimits,
  limits => limits?.filter(limit => limit.LimitType === LimitType.Deposit),
);

export const selectActiveSessionLimits = createSelector(
  selectActiveLimits,
  limits => limits?.filter(limit => limit.LimitType === LimitType.Session),
);

export const selectFutureSessionLimits = createSelector(
  selectFutureLimits,
  limits => limits?.filter(limit => limit.LimitType === LimitType.Session),
);

export const selectActiveLossLimits = createSelector(
  selectActiveLimits,
  limits => limits?.filter(limit => limit.LimitType === LimitType.CasinoNetLoss),
);

export const selectFutureLossLimits = createSelector(
  selectFutureLimits,
  limits => limits?.filter(limit => limit.LimitType === LimitType.CasinoNetLoss),
);

export const selectActiveDepositLimit = createSelector(
  selectActiveDepositLimits,
  (state: RootState, duration: LimitDuration) => duration,
  (limits, duration): LimitDetails | undefined => {
    if (!limits) {
      return undefined;
    }

    return limits.find(l => l.Duration === duration);
  },
);

export const selectLowestRemainingDepositLimit = createSelector(
  selectActiveDepositLimits,
  (activeLimits): number | null => {
    return activeLimits.reduce((lowestLimit: number | null, limit) => {
      const remainingAmount = limit.RemainingAmount > 0 ? limit.RemainingAmount : 0;

      if (lowestLimit === null) {
        return remainingAmount;
      }

      if (lowestLimit > remainingAmount) {
        return remainingAmount;
      }

      return lowestLimit;
    }, null);
  },
);
