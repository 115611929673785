import React from 'react';
import Spinner from '../UI/Spinner';

interface ButtonWithLoaderProps {
  loading: boolean;
  disabled?: boolean;
  message: string;
  type?: 'submit' | 'button';
  onClick?: () => void;
}

export function ButtonWithLoader({
  loading,
  disabled = false,
  message,
  type = 'submit',
  onClick,
}: ButtonWithLoaderProps) {
  return (
    <button
      type={type === 'submit' ? 'submit' : 'button'}
      disabled={disabled}
      className="mx-auto my-12 flex h-16 w-full cursor-pointer items-center justify-center rounded-full bg-cyan-500 text-lg font-bold text-white shadow-md transition hover:bg-cyan-600 hover:shadow-lg disabled:cursor-not-allowed disabled:opacity-60 md:px-4"
      onClick={onClick}
    >
      {loading ? (
        <Spinner width={18} height={18} borderwidth={3} color="white" />
      ) : (
        <span>{message}</span>
      )}
    </button>
  );
}
