import React, { useEffect } from 'react';

import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import PageHeader from '../Layout/PageHeader';
import FAQ from '../Pages/Help/FAQ';
import Button from '../UI/Buttons/Button';
import CloseButton from '../UI/Buttons/CloseButton';
import { LineChatIcon } from '../UI/Icons/LineChatIcon';
import { openZendesk } from '../Zendesk';
import { loadZendesk } from '@/store/actions/commonActions';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { openModal } from '@/store/actions/modal';

const StyledHelpDrawer = styled.div`
  .pageHeader {
    .content {
      max-width: 420px;
      display: flex;
      justify-content: center;
      flex-flow: column wrap;
    }
  }

  button {
    padding-top: 0;
    padding-bottom: 0;
    height: 56px;
  }
`;

function HelpDrawer({ closeDrawer }) {
  const isAuthenticated = useSelector(state => state.auth.token !== null);
  const user = useSelector(state => state.user.user);
  const locale = useSelector(state => state.common.locale);
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadZendesk());
  }, []);

  const handleOpenZendesk = () => {
    openZendesk(user, isAuthenticated);
    triggerGTag(GTagEvents.open_support);
  };

  const handleOpenLineChat = () => {
    dispatch(openModal('lineChat'));
  };

  return (
    <StyledHelpDrawer className="drawer" role="presentation" aria-label="Help Drawer">
      <PageHeader>
        <h1>{intl.formatMessage({ id: 'help.header' })}</h1>
        <p>{intl.formatMessage({ id: 'help.subHeader' })}</p>
        <div className="flex items-center justify-center gap-4 [&>button]:!mx-0">
          <Button light iconleft onClick={handleOpenZendesk}>
            <ChatBubbleLeftRightIcon /> {intl.formatMessage({ id: 'help.contactButton' })}
          </Button>
          {locale === 'th' && (
            <Button light iconleft onClick={handleOpenLineChat}>
              <LineChatIcon /> {intl.formatMessage({ id: 'help.lineChatButton' })}
            </Button>
          )}
        </div>
      </PageHeader>
      <FAQ />
      <CloseButton light left onClick={closeDrawer} />
    </StyledHelpDrawer>
  );
}

HelpDrawer.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
};

export default HelpDrawer;
