import { GetUserLimitModel } from '@/models/gig/get-user-limit.model';
import { GetLimitsSuccessAction, LimitsActions, LimitsActionType } from '../actions/limitsActions';

export interface LimitsStateModel {
  limits: GetUserLimitModel | null;
  loading: boolean;
  error: boolean;
  loaded: boolean;
}

const initialState: LimitsStateModel = {
  limits: null,
  loading: false,
  error: false,
  loaded: false,
};

const getLimits = (state: LimitsStateModel): LimitsStateModel => ({
  ...state,
  loading: true,
  error: false,
});

const getLimitsSuccess = (
  state: LimitsStateModel,
  action: GetLimitsSuccessAction,
): LimitsStateModel => {
  return {
    ...state,
    limits: action.limits,
    loading: false,
    error: false,
    loaded: true,
  };
};

const getLimitsError = (state: LimitsStateModel): LimitsStateModel => ({
  ...state,
  loading: false,
  error: true,
});

export const limitsReducer = (state = initialState, action: LimitsActions): LimitsStateModel => {
  switch (action.type) {
    case LimitsActionType.GetLimits:
      return getLimits(state);
    case LimitsActionType.GetLimitsSuccess:
      return getLimitsSuccess(state, action);
    case LimitsActionType.GetLimitsError:
      return getLimitsError(state);
    case LimitsActionType.Reset:
      return initialState;
    default:
      return state;
  }
};
