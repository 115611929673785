import { all, AllEffect, call, ForkEffect, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';
import {
  GetManualBonusesAction,
  getManualBonusesError,
  getManualBonusesSuccess,
  ManualBonusesActionType,
} from '../actions/manualBonusesActions';
import { WpManualBonusModel } from '@/models/wp-manual-bonus.model';
import { WpError } from '@/models/wp-error.model';
import { isSuccessfulWpResponse, wpApiUrl } from '@/utils/utils';

type ManualBonusesAxiosResponse = AxiosResponse<WpManualBonusModel[] | WpError>;

export function* getManualBonusesSaga(
  action: GetManualBonusesAction,
): Generator<StrictEffect, void, ManualBonusesAxiosResponse> {
  const { locale } = action;
  try {
    const response = yield call(axios.get, wpApiUrl(locale, '/manual_bonuses'));

    if (isSuccessfulWpResponse<WpManualBonusModel[]>(response)) {
      yield put(getManualBonusesSuccess(response.data, locale));
    } else {
      yield put(getManualBonusesError());
    }
  } catch (error) {
    yield put(getManualBonusesError());
  }
}

export function* watchManualBonuses(): Generator<AllEffect<ForkEffect<never>>, void> {
  yield all([takeEvery(ManualBonusesActionType.Get, getManualBonusesSaga)]);
}
