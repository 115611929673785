import { all, AllEffect, call, ForkEffect, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { axiosInstance as axios } from '../../shared/axiosInstance';
import {
  GetLastDepositAction,
  getLastDepositError,
  getLastDepositSuccess,
  LastDepositActionType,
} from '../actions/lastDepositActions';

export function* getLastDepositSaga(
  action: GetLastDepositAction,
): Generator<StrictEffect, void, AxiosResponse<{ Amount: number }>> {
  const { token } = action;

  try {
    const response = yield call(axios.post, '/api/user/lastsuccessfuldeposit', { token });
    yield put(getLastDepositSuccess(response.data.Amount));
  } catch (error) {
    yield put(getLastDepositError());
  }
}

export function* watchLastDeposit(): Generator<AllEffect<ForkEffect<never>>, void> {
  yield all([takeEvery(LastDepositActionType.GetLastDeposit, getLastDepositSaga)]);
}
