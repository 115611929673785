import { PublicUserConsentModel } from '@lucky7ventures/lucky-components';
import { ConsentsActions, ConsentsActionTypes } from '../actions/consentsActions';

export interface ConsentsState {
  consents: PublicUserConsentModel[];
  consentsLoading: boolean;
  consentsSuccess: boolean;
  consentsError: boolean;
}

const initialState = {
  consents: [],
  consentsLoading: false,
  consentsSuccess: false,
  consentsError: false,
};

export const consentsReducer = (state = initialState, action: ConsentsActions): ConsentsState => {
  switch (action.type) {
    case ConsentsActionTypes.GetConsents:
      return {
        ...state,
        consentsLoading: true,
        consentsError: false,
      };
    case ConsentsActionTypes.GetConsentsSuccess:
      return {
        ...state,
        consents: action.consents,
        consentsSuccess: true,
        consentsLoading: false,
        consentsError: false,
      };
    case ConsentsActionTypes.GetConsentsError:
      return {
        ...state,
        consentsLoading: false,
        consentsError: true,
      };
    case ConsentsActionTypes.ResetConsents:
      return initialState;
    default:
      return state;
  }
};
