import { IS_SWEDEN } from './constants';

export const PNP_DEPOSIT_DEFAULTS: { low: number; mid: number; high: number } = IS_SWEDEN
  ? {
      low: 250,
      mid: 500,
      high: 1000,
    }
  : {
      low: 25,
      mid: 50,
      high: 100,
    };

export const PNP_DEPOSIT_AMOUNT_RULES: { min: number; max: number } = IS_SWEDEN
  ? {
      min: 100,
      max: 50000,
    }
  : { min: 20, max: 10000 };

export const PNP_CURRENCY: string = IS_SWEDEN ? 'SEK' : 'EUR';
