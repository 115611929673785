import React, { useEffect } from 'react';

import { Field, Form, Formik } from 'formik';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { loadZendesk } from '@/store/actions/commonActions';
import { useBankIdProfile } from '@/hooks/useBankIdProfile';
import { selectLocale } from '@/store/selectors/commonSelectors';
import {
  selectIsEmailConsented,
  selectIsTextMessageConsented,
  selectSmsEmailConsents,
} from '@/store/selectors/consentsSelectors';
import { selectUser } from '@/store/selectors/userSelectors';
import { isEmpty, isRegulatedLocale } from '@/shared/utility';
import Spinner from '@/components/UI/Spinner';
import PasswordChange from '@/components/Pages/Settings/password-change/PasswordChange';
import Tooltip from '@/components/UI/Tooltip';
import { openZendesk } from '@/components/Zendesk';
import PromptMessage from '@/components/UI/PromptMessage';
import { BUILD_MODE, envIsMexico } from '@/constants/constants';
import Settings2FA from './Settings2FA';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { Button } from '@/components/buttons/Button';
import { useApiV2Request } from '@/hooks/useApiV2Request';
import ApiService from '../../shared/apiService';
import { getConsents } from '@/store/actions/consentsActions';
import { openModal } from '@/store/actions/modal';

const StyledDetails = styled.div`
  min-height: calc(100vh - 300px);
  background: ${props => props.theme.colors.offWhite};
  padding: 48px 16px;
  max-width: 720px;
  margin: 0 auto;

  @media (min-width: ${props => props.theme.breakpoints.small}) {
    padding: 64px 32px;
  }

  section {
    margin: 0 auto 16px;
    max-width: 720px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 32px 16px;
    background: ${props => props.theme.colors.white};
    border-radius: 6px;

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      padding: 32px;
      margin: 0 auto 32px;
    }

    h2 {
      width: 100%;
      margin-top: 0;
      font-size: 22px;
    }

    label {
      width: 100%;
      margin-top: 20px;
      font-weight: bold;
      color: ${props => props.theme.colors.blue2};
      font-size: 14px;
      position: relative;
      line-height: 1;
      display: block;

      /* &.margin-top {
				margin-top: 64px;
			} */

      &.quarter {
        width: calc(33.333% - 8px);

        @media (min-width: ${props => props.theme.breakpoints.small}) {
          width: calc(33.333% - 16px);
        }
      }

      &.two-quarters {
        width: calc(66.666% - 8px);

        @media (min-width: ${props => props.theme.breakpoints.small}) {
          width: calc(66.666% - 16px);
        }
      }

      &.half {
        width: calc(50% - 8px);

        @media (min-width: ${props => props.theme.breakpoints.small}) {
          width: calc(50% - 16px);
        }
      }

      input {
        width: 100%;
        border: 0;
        font-size: 18px;
        font-weight: bold;
        color: ${props => props.theme.colors.black};
        border-bottom: 2px solid #eee;
        padding: 16px 26px 16px 0;
        line-height: 1;
        background: none;

        &::placeholder,
        &:disabled {
          color: ${props => props.theme.colors.grey50};
          -webkit-text-fill-color: ${props => props.theme.colors.grey50};
        }

        &::placeholder {
          font-weight: normal;
        }

        &:focus {
          border-color: #eee;
          box-shadow: none;
        }
      }

      .icon {
        position: absolute;
        right: 0;
        bottom: 0;
        cursor: pointer;
        width: 24px;
        text-align: center;

        .lockIcon {
          width: 18px;
          height: 18px;
          margin: 0 0 18px;
          color: ${props => props.theme.colors.grey50};
        }
      }
    }
  }

  .request-a-change {
    text-align: right;
    font-size: 14px;
    margin-bottom: 32px;
    margin-top: 8px;

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      margin-top: -16px;
    }

    button {
      background: none;
      border: none;
      padding: 0;
      margin: 0;
      font-weight: bold;
      cursor: pointer;
      color: ${props => props.theme.colors.blue2};

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .buttons {
    margin: 32px 0 0;
    text-align: right;
    width: 100%;

    button,
    a {
      margin: 0;
      height: 54px;
    }

    .save-button {
      min-width: 180px;
    }

    .prompt-message {
      margin: 0 0 32px;
    }
  }

  .complete-profile {
    display: block;
    width: 100%;
    text-align: center;

    p {
      font-size: 1.1rem;
      opacity: 0.8;
      margin-bottom: 0;
    }
  }
`;

export const StyledSwitch = styled.label`
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: bold;

  > span {
    flex: 1;
    margin-right: 1rem;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 24px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  /* The slider */

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${props => props.theme.colors.grey20};
    -webkit-transition: 0.2s;
    transition: 0.2s;
    border-radius: 24px;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 1rem;
    width: 1rem;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: ${props => props.theme.colors.green40};
  }

  input:checked + .slider:before {
    transform: translateX(24px);
  }
`;

function Details() {
  const locale = useSelector(selectLocale);
  const consents = useSelector(selectSmsEmailConsents);
  const isSmsConsented = useSelector(selectIsTextMessageConsented);
  const isEmailConsented = useSelector(selectIsEmailConsented);
  const user = useSelector(selectUser);
  const { success, loading, error, request: saveConsents } = useApiV2Request();
  const dispatch = useDispatch();
  const intl = useIntl();
  const tags = useSelector(state => state.user.tags);
  const hasFastRegTag = tags.some(tag => tag.Name === 'Fastreg');
  const { isCompletedBankIdProfile } = useBankIdProfile();

  const submitHandler = values => {
    const updatedUserConsents = [...consents].map(consent => {
      return {
        ConsentId: consent.ConsentId,
        Version: consent.Version,
        Consented: consent.Name === 'TEXT_MESSAGE' ? values.allowSms : values.allowEmail,
      };
    });

    saveConsents(ApiService.saveConsents, updatedUserConsents, () => {
      dispatch(getConsents());
    });
  };

  useEffect(() => {
    dispatch(loadZendesk());
  }, []);

  if (isEmpty(user) || isEmpty(consents)) {
    return (
      <StyledDetails>
        <Spinner absolute />
      </StyledDetails>
    );
  }

  const birthDateValues = user?.BirthDate?.split('-');
  const birthYear = birthDateValues[0];
  const birthMonth = birthDateValues[1];
  const birthDay = birthDateValues[2].split('T')[0];

  return (
    <StyledDetails>
      {!isRegulatedLocale(locale) && <PasswordChange />}
      <Formik
        initialValues={{
          allowEmail: isEmailConsented,
          allowSms: isSmsConsented,
        }}
        onSubmit={values => submitHandler(values)}
      >
        <Form>
          <section className="personal-data">
            {!hasFastRegTag ? (
              <>
                <h2 className="h4">
                  {intl.formatMessage({
                    id: 'settings.details.personalData.header',
                  })}
                </h2>
                <label htmlFor="fullName">
                  {intl.formatMessage({ id: 'inputs.fullName' })}
                  <input
                    disabled
                    id="fullName"
                    value={`${user.FirstName} ${user.LastName}`}
                    type="text"
                  />
                  <div className="icon tooltip-container">
                    <LockClosedIcon className="lockIcon" />
                    <Tooltip
                      width="160px"
                      position="top-right"
                      text={intl.formatMessage({
                        id: 'inputs.locked.tooltip',
                      })}
                    />
                  </div>
                </label>
                <label htmlFor="dateOfBirthYear" className="quarter">
                  {intl.formatMessage({ id: 'inputs.year' })}
                  <input disabled id="dateOfBirthYear" value={birthYear} type="text" />
                  <div className="icon tooltip-container">
                    <LockClosedIcon className="lockIcon" />
                    <Tooltip
                      width="160px"
                      position="top-right"
                      text={intl.formatMessage({
                        id: 'inputs.locked.tooltip',
                      })}
                    />
                  </div>
                </label>
                <label htmlFor="dateOfBirthMonth" className="quarter">
                  {intl.formatMessage({ id: 'inputs.month' })}
                  <input disabled id="dateOfBirthMonth" value={birthMonth} type="text" />
                  <div className="icon tooltip-container">
                    <LockClosedIcon className="lockIcon" />
                    <Tooltip
                      width="160px"
                      position="top-right"
                      text={intl.formatMessage({
                        id: 'inputs.locked.tooltip',
                      })}
                    />
                  </div>
                </label>
                <label htmlFor="dateOfBirthDay" className="quarter">
                  {intl.formatMessage({ id: 'inputs.day' })}
                  <input disabled id="dateOfBirthDay" value={birthDay} type="text" />
                  <div className="icon tooltip-container">
                    <LockClosedIcon className="lockIcon" />
                    <Tooltip
                      width="160px"
                      position="top-right"
                      text={intl.formatMessage({
                        id: 'inputs.locked.tooltip',
                      })}
                    />
                  </div>
                </label>
                <label className="margin-top" htmlFor="address">
                  {intl.formatMessage({ id: 'inputs.address' })}
                  <input
                    id="address"
                    disabled
                    value={`${user.Address1}, ${user.City}`}
                    type="text"
                  />
                  <div className="icon tooltip-container">
                    <LockClosedIcon className="lockIcon" />
                    <Tooltip
                      width="160px"
                      position="top-right"
                      text={intl.formatMessage({
                        id: 'inputs.locked.tooltip',
                      })}
                    />
                  </div>
                </label>
                <label className="two-quarters country" htmlFor="country">
                  {intl.formatMessage({ id: 'inputs.country' })}
                  <input disabled id="country" type="text" value={user.CountryName} />
                  <div className="icon tooltip-container">
                    <LockClosedIcon className="lockIcon" />
                    <Tooltip
                      width="160px"
                      position="top-right"
                      text={intl.formatMessage({
                        id: 'inputs.locked.tooltip',
                      })}
                    />
                  </div>
                </label>
                <label className="quarter" htmlFor="postalCode">
                  {intl.formatMessage({ id: 'inputs.postcode' })}
                  <input id="postalCode" disabled type="text" value={user.PostalCode} />
                  <div className="icon tooltip-container">
                    <LockClosedIcon className="lockIcon" />
                    <Tooltip
                      width="160px"
                      position="top-right"
                      text={intl.formatMessage({
                        id: 'inputs.locked.tooltip',
                      })}
                    />
                  </div>
                </label>
                {isCompletedBankIdProfile && (
                  <>
                    <label className="margin-top" htmlFor="mobile">
                      {intl.formatMessage({ id: 'inputs.number' })}
                      <input
                        disabled
                        id="mobile"
                        type="text"
                        value={`${user.MobilePrefix} ${user.Mobile}`}
                      />
                      <div className="icon tooltip-container">
                        <LockClosedIcon className="lockIcon" />
                        <Tooltip
                          width="160px"
                          position="top-right"
                          text={intl.formatMessage({
                            id: 'inputs.locked.tooltip',
                          })}
                        />
                      </div>
                    </label>
                    <label htmlFor="email">
                      {intl.formatMessage({ id: 'inputs.email' })}
                      <input disabled id="email" type="text" value={user.Email} />
                      <div className="icon tooltip-container">
                        <LockClosedIcon className="lockIcon" />
                        <Tooltip
                          width="160px"
                          position="top-right"
                          text={intl.formatMessage({
                            id: 'inputs.locked.tooltip',
                          })}
                        />
                      </div>
                    </label>
                  </>
                )}
              </>
            ) : (
              !envIsMexico() && (
                <div className="complete-profile">
                  <h3>{intl.formatMessage({ id: 'completeProfile.prompt.header' })}</h3>
                  <p>{intl.formatMessage({ id: 'completeProfile.prompt.text' })}</p>
                  <Button
                    text={intl.formatMessage({ id: 'completeProfile.prompt.button' })}
                    onClickHandler={() => {
                      dispatch(openModal('completeProfile'));
                      triggerGTag(GTagEvents.complete_profile_details_click);
                    }}
                  />
                </div>
              )
            )}
          </section>
          {!hasFastRegTag && (
            <div className="request-a-change">
              {intl.formatMessage({ id: 'settings.details.updateDetails' })}{' '}
              <button
                type="button"
                onClick={() => {
                  openZendesk(user, true);
                }}
              >
                {intl.formatMessage({ id: 'settings.details.updateDetails.contact' })}
              </button>
            </div>
          )}
          {isCompletedBankIdProfile && (
            <section>
              <h2 className="h4">
                {intl.formatMessage({
                  id: 'settings.details.receiveOffersAndPromotions.header',
                })}
              </h2>
              <Field name="allowEmail">
                {({ field }) => (
                  <StyledSwitch htmlFor="allowEmail">
                    {intl.formatMessage({ id: 'inputs.toggle.email' })}
                    <div className="switch">
                      <input id="allowEmail" type="checkbox" {...field} checked={field.value} />
                      <span className="slider" />
                    </div>
                  </StyledSwitch>
                )}
              </Field>
              <Field name="allowSms">
                {({ field }) => (
                  <StyledSwitch htmlFor="allowSms">
                    {intl.formatMessage({ id: 'inputs.toggle.sms' })}
                    <div className="switch">
                      <input id="allowSms" type="checkbox" {...field} checked={field.value} />
                      <span className="slider" />
                    </div>
                  </StyledSwitch>
                )}
              </Field>
              <div className="buttons">
                {success && (
                  <PromptMessage
                    type="success"
                    message={intl.formatMessage({
                      id: 'settings.details.successMessage',
                    })}
                  />
                )}
                {error && <p>Consents could not be saved</p>}
                <button
                  type="submit"
                  className="mt-8 rounded-full bg-cyan-500 px-10 py-4 font-bold text-white shadow-md transition hover:bg-cyan-600 hover:shadow-lg md:w-auto"
                >
                  {loading ? (
                    <Spinner color="white" height={22} width={22} borderwidth={3} />
                  ) : (
                    intl.formatMessage({ id: 'settings.details.saveButton' })
                  )}
                </button>
              </div>
            </section>
          )}
        </Form>
      </Formik>
      {BUILD_MODE === 'canada' && <Settings2FA />}
    </StyledDetails>
  );
}

Details.propTypes = {
  closeDrawer: PropTypes.func,
};

Details.defaultProps = {
  closeDrawer: () => {},
};

export default Details;
