import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledValidationMessage = styled.div`
  color: ${props => props.theme.colors.red};
  background: ${props => props.theme.colors.red10};
  padding: 8px 10px;
  border-radius: 6px;
  font-size: 12px;
  margin-bottom: 20px;
  margin-top: 15px;
  position: relative;
  width: auto;
  max-width: 100%;
  display: inline-flex;
  line-height: 1.2;

  &:before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    top: -5px;
    left: 11px;
    border-style: solid;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent ${props => props.theme.colors.red10} transparent;
  }
`;

const ValidationMessage = ({ message }) => (
  <StyledValidationMessage className="validationMessage">{message}</StyledValidationMessage>
);

ValidationMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

export default ValidationMessage;
