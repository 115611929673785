import { DateTime } from 'luxon';
import { createSelector } from 'reselect';
import { RootState } from '@/models/root-state.model';
import { utcToLocalTime } from '@/utils/utils';
import { DBTokenIP } from '@lucky7ventures/lucky-components';

export const selectLoginHistory = (state: RootState): DBTokenIP[] =>
  state.loginHistory.loginHistory;
export const selectLoginHistoryLoading = (state: RootState): boolean => state.loginHistory.loading;

export const selectCurrentLogin = createSelector(selectLoginHistory, loginHistory =>
  loginHistory.length > 0 ? utcToLocalTime(loginHistory[0].DateCreated) : null,
);

export const selectLastLogin = createSelector(selectLoginHistory, loginHistory => {
  if (loginHistory.length < 2) {
    return null;
  }

  const date = utcToLocalTime(loginHistory[1].DateCreated);
  const lastLogin = DateTime.fromJSDate(date)
    .setLocale('sv-se')
    .toLocaleString(DateTime.DATETIME_FULL);

  return lastLogin;
});
