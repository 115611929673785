import * as actionTypes from './actionTypes';

export const openDrawer = (drawerName, drawerProps) => ({
  type: actionTypes.OPEN_DRAWER,
  drawerName,
  drawerProps,
});

export const closeDrawer = () => ({
  type: actionTypes.CLOSE_DRAWER,
});
