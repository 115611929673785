import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import PageHeader from '@/components/Layout/PageHeader';
import ResponsibleGamingIntro from '@/components/shared/responsible-gaming-intro/ResponsibleGamingIntro';
import { useSelector } from 'react-redux';
import { selectLocale } from '@/store/selectors/commonSelectors';

const StyledResponsibleGamingPage = styled.div`
  min-height: 100vh;
  position: relative;
`;

function ResponsibleGamingPage() {
  const intl = useIntl();
  const locale = useSelector(selectLocale);

  return (
    <StyledResponsibleGamingPage>
      <Helmet>
        <title>LuckyDays - {intl.formatMessage({ id: 'responsibleGaming.header' })}</title>
      </Helmet>
      <PageHeader>
        <h1>{intl.formatMessage({ id: 'responsibleGaming.header' })}</h1>
      </PageHeader>
      <section>
        <ResponsibleGamingIntro locale={locale} />
      </section>
    </StyledResponsibleGamingPage>
  );
}

export default ResponsibleGamingPage;
