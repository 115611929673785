import { Transition } from '@headlessui/react';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentLogin } from '@/store/selectors/loginHistorySelectors';

export function LoggedInTimer(): JSX.Element | null {
  const currentLogin = useSelector(selectCurrentLogin);
  const [loggedInTime, setLoggedInTime] = useState<string | null>();

  useEffect(() => {
    if (!currentLogin) {
      setLoggedInTime(null);
      return;
    }

    const interval = setInterval(() => {
      const start = DateTime.fromJSDate(currentLogin);
      const end = DateTime.now();
      setLoggedInTime(end.diff(start, ['hours', 'minutes', 'seconds']).toFormat('hh:mm:ss'));
    }, 1000);

    return () => clearInterval(interval);
  }, [currentLogin]);

  if (!loggedInTime) {
    return null;
  }

  return (
    <Transition
      appear={true}
      show={true}
      enter="transition-opacity duration-100"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className={`flex items-center text-xs font-bold tabular-nums text-white`}>
        <span className="mr-1 inline-flex h-1.5 w-1.5 rounded-full bg-green-600"></span>
        <span className="mr-1 hidden md:inline-flex">Inloggningstid:</span> {loggedInTime}
      </div>
    </Transition>
  );
}
