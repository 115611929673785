import { Action } from 'redux';
import { UserRgStatsResponseDto } from '@/models/gig/user-rg-stats-response-dto.model';

export enum UserPlayStatsAndLimitsActionType {
  GetUserPlayStatsAndLimits = 'GET_USER_PLAY_STATS_AND_LIMITS',
  GetUserPlayStatsAndLimitsSuccess = 'GET_USER_PLAY_STATS_AND_LIMITS_SUCCESS',
  GetUserPlayStatsAndLimitsError = 'GET_USER_PLAY_STATS_AND_LIMITS_ERROR',
  Reset = 'RESET_USER_PLAY_STATS_AND_LIMITS',
}

export type GetUserPlayStatsAndLimitsAction =
  Action<UserPlayStatsAndLimitsActionType.GetUserPlayStatsAndLimits> & {
    token: string;
    months: number;
  };
export type GetUserPlayStatsAndLimitsSuccessAction =
  Action<UserPlayStatsAndLimitsActionType.GetUserPlayStatsAndLimitsSuccess> & {
    stats: UserRgStatsResponseDto;
  };
export type GetUserPlayStatsAndLimitsErrorAction =
  Action<UserPlayStatsAndLimitsActionType.GetUserPlayStatsAndLimitsError>;

export type ResetUserPlayStatsAndLimitsAction = Action<UserPlayStatsAndLimitsActionType.Reset>;

export type UserPlayStatsAndLimitsActions =
  | GetUserPlayStatsAndLimitsAction
  | GetUserPlayStatsAndLimitsSuccessAction
  | GetUserPlayStatsAndLimitsErrorAction
  | ResetUserPlayStatsAndLimitsAction;

export const getUserPlayStatsAndLimits = (
  token: string,
  months: number,
): GetUserPlayStatsAndLimitsAction => ({
  type: UserPlayStatsAndLimitsActionType.GetUserPlayStatsAndLimits,
  token,
  months,
});

export const getUserPlayStatsAndLimitsSuccess = (
  stats: UserRgStatsResponseDto,
): GetUserPlayStatsAndLimitsSuccessAction => ({
  type: UserPlayStatsAndLimitsActionType.GetUserPlayStatsAndLimitsSuccess,
  stats,
});

export const getUserPlayStatsAndLimitsError = (): GetUserPlayStatsAndLimitsErrorAction => ({
  type: UserPlayStatsAndLimitsActionType.GetUserPlayStatsAndLimitsError,
});

export const resetUserPlayStatsAndLimits = (): ResetUserPlayStatsAndLimitsAction => ({
  type: UserPlayStatsAndLimitsActionType.Reset,
});
