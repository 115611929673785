import * as React from 'react';

export default function TrophyIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-7 -2 24 24"
      width={24}
      height={24}
      preserveAspectRatio="xMinYMin"
      className="prefix__icon__icon"
      {...props}
    >
      <path
        d="M2 18h6v-1H2v1zm.294-3c-.6-1.825-1.032-3.825-1.294-6-.362-3-.362-6 0-9h8c.311 3.329.311 6.329 0 9a32.729 32.729 0 01-1.287 6H8a2 2 0 012 2v3H0v-3a2 2 0 012-2h.294zm.692-6.24c.277 2.304.753 4.383 1.423 6.24H5.61a30.946 30.946 0 001.402-6.231c.238-2.037.283-4.294.135-6.769h-4.33a35.456 35.456 0 00.168 6.76z"
        fill="currentColor"
      />
    </svg>
  );
}
