import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isRegulatedLocale } from '@/shared/utility';
import { useAbsoluteNavigate } from './useAbsoluteNavigate';
import { axiosInstance as axios } from '../shared/axiosInstance';
import { logException } from '@/utils/logger';
import { selectMigrationLoading } from '@/store/selectors/commonSelectors';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { logout } from '@/store/actions/auth';
import { closeModal, openModal } from '@/store/actions/modal';
import { closeDrawer } from '@/store/actions/drawer';

export function usePingLogin(): void {
  const token = useSelector((state: any) => state.auth.token);
  const locale = useSelector((state: any) => state.common.locale);
  const migrationLoading = useSelector(selectMigrationLoading);
  const isAuthenticated = useSelector((state: any) => state.auth.token !== null);
  const absoluteNavigate = useAbsoluteNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    let interval: NodeJS.Timer;

    if (token && isAuthenticated) {
      interval = setInterval(() => {
        // If migration is happening, don't ping
        if (migrationLoading) {
          return;
        }
        axios
          .post('/api/auth/isloggedin', { token })
          .then(response => {
            if (response.data.data === false) {
              triggerGTag(GTagEvents.ping_login_failed);
              dispatch(logout());
              absoluteNavigate('/');
              // close modals and drawers if any is open
              dispatch(closeModal());
              dispatch(closeDrawer());
              // open pnp modal if on regulated locale else open the usual logged out modal (use settimeout to wait to close the modals or drawers)
              if (isRegulatedLocale(locale)) {
                dispatch(openModal('paynplay', { loggedOut: true }));
              } else {
                dispatch(openModal('login', { loggedOut: true }));
              }
            }
          })
          .catch(error => logException(error));
      }, 15000);
    }

    return () => clearInterval(interval);
  }, [token, isAuthenticated, migrationLoading]);
}
