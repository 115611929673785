import { call, put, PutEffect, CallEffect } from 'redux-saga/effects';
import { checkDevice } from '@/shared/utility';
import { AxiosResponse } from 'axios';
import ApiService from '@/shared/apiService';
import BffApiService from '@/shared/bffApiService';
import { BffAvailableBonusesResponse, BffResponse, DeviceTypeId } from '@lucky7ventures/bff-types';
import {
  getAvailableBonusesFail,
  getAvailableBonusesSuccess,
  getUserBonusesFail,
  getUserBonusesSuccess,
} from '@/store/actions/bonusesActions';

export function* getUserBonusesSaga() {
  try {
    const response: AxiosResponse = yield ApiService.userBonuses();
    yield put(getUserBonusesSuccess(response.data));
  } catch (error) {
    yield put(getUserBonusesFail());
  }
}

export function* getAvailableBonusesSaga(): Generator<
  CallEffect<AxiosResponse<BffResponse<BffAvailableBonusesResponse>>> | PutEffect,
  void,
  AxiosResponse<BffResponse<BffAvailableBonusesResponse>>
> {
  const device: DeviceTypeId = checkDevice();

  try {
    const response = yield call(BffApiService.getAvailableBonuses, {
      payload: {
        deviceTypeId: device,
      },
    });
    if (response.data.data) {
      yield put(getAvailableBonusesSuccess(response.data.data));
    }
  } catch (error) {
    yield put(getAvailableBonusesFail());
  }
}
