import * as React from 'react';

import { useSelector } from 'react-redux';

import { selectGamesLoading, selectLiveCasinoGames } from '@/store/selectors/gamesSelectors';
import CategoryPageContent from '@/pages/games/categories/CategoryPageContent';
import { useIntl } from 'react-intl';
import { BffGamesCategory } from '@lucky7ventures/bff-types';

function CategoryLiveCasinoGames(): JSX.Element {
  const intl = useIntl();
  const games = useSelector(selectLiveCasinoGames);
  const gamesLoading = useSelector(selectGamesLoading);

  return (
    <CategoryPageContent
      header={intl.formatMessage({ id: `games.categories.liveCasino` })}
      subheader={intl.formatMessage({ id: `games.categories.liveCasino.info` })}
      category={BffGamesCategory.LiveCasino}
      games={games}
      loading={gamesLoading}
    />
  );
}

export default CategoryLiveCasinoGames;
