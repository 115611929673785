import React from 'react';
import { selectGamesLoading, selectHalloweenGames } from '@/store/selectors/gamesSelectors';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import LobbySection from '@/pages/games/lobby/lobby-sections/LobbySection';
import { BffGamesCategory } from '@lucky7ventures/bff-types';

const HalloweenGames = (): JSX.Element => {
  const intl = useIntl();
  const games = useSelector(selectHalloweenGames);
  const loading = useSelector(selectGamesLoading);

  return (
    <LobbySection
      header={intl.formatMessage({ id: `games.categories.halloween` })}
      subheader={intl.formatMessage({ id: `games.categories.halloween.info` })}
      games={games}
      loading={loading}
      category={BffGamesCategory.HalloweenGames}
      maxItems={6}
    />
  );
};

export default HalloweenGames;
