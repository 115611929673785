import { RefObject, useEffect } from 'react';

export const useOnClickOutside = (
  ref: RefObject<any>,
  handler: (event: MouseEvent | TouchEvent | FocusEvent) => void,
  deps?: unknown[],
) => {
  useEffect(() => {
    const clickHandler = (event: MouseEvent) => {
      if (!ref.current) return;
      if (ref.current.contains(event.target)) return;
      handler(event);
    };
    document.addEventListener('click', clickHandler);

    return () => document.removeEventListener('click', clickHandler);
  }, deps);
};
