import React, { useEffect, useState } from 'react';

import LazyLoad from 'react-lazyload';
import classNames from 'classnames';
import GameCardMC from './GameCardMC';
import { envIsMexico } from '@/constants/constants';
import GameCard from './GameCard';
import { GameCardVM } from '@/models/vm/game-card-vm.model';
import { GamesCategories } from '@/models/game-category.type';
import InfiniteScroll from 'react-infinite-scroll-component';
import GamesSkeletonLoader from '@/components/shared/skeleton-loader/GamesSkeletonLoader';

interface GameListProps {
  games: GameCardVM[];
  gamesLoading: boolean;
  category: GamesCategories | string;
}

const CHUNK_SIZE = 18;

function GamesInfiniteGrid({ games, gamesLoading, category }: GameListProps) {
  const [lastIndex, setLastIndex] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [visibleItems, setVisibleItems] = useState<GameCardVM[]>([]);

  const addMoreItems = () => {
    const nextIndex = lastIndex + 1;
    const start = nextIndex * CHUNK_SIZE;
    const end = Math.min(start + CHUNK_SIZE, games.length);
    setVisibleItems([...visibleItems, ...games.slice(start, end)]);
    setLastIndex(nextIndex);
    if (end === games.length) {
      setHasMore(false);
    }
  };

  useEffect(() => {
    setVisibleItems(games.slice(0, Math.min(CHUNK_SIZE, games.length)));
    setHasMore(games.length > CHUNK_SIZE);
  }, [games]);

  if (gamesLoading) {
    return <GamesSkeletonLoader />;
  }

  return (
    <InfiniteScroll
      dataLength={visibleItems.length}
      next={addMoreItems}
      hasMore={hasMore}
      loader={
        <div className="mt-8">
          <GamesSkeletonLoader />
        </div>
      }
    >
      <div
        className={classNames(
          'w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 mx-auto',
          'gap-y-12 gap-x-4 xs:gap-y-8 md:max-w-[1400px] px-[16px] md:px-[32px]',
        )}
      >
        {visibleItems.map(game => {
          return (
            <LazyLoad key={`${game.slug}-${game.gameId}-${category}`} offset={800}>
              {envIsMexico() ? (
                <GameCardMC game={game} onCardClick={() => {}} />
              ) : (
                <GameCard game={game} category={category} />
              )}
            </LazyLoad>
          );
        })}
      </div>
    </InfiniteScroll>
  );
}

export default GamesInfiniteGrid;
