import React, { ElementType, ReactNode } from 'react';

import classNames from 'classnames';

export interface InjectedStepProps {
  isActiveStep: boolean;
}

export interface StepProps {
  inject?: InjectedStepProps;
  stepIcon?: ElementType;
  children: ReactNode;
}

const Step = ({ inject, children }: StepProps) => {
  //
  // Even though [inject] prop is optional, it will always be defined since
  // it is passed via Stepper children prop injection
  //
  const { isActiveStep } = inject as InjectedStepProps;

  return (
    <div
      className={classNames('mt-4 w-full px-[1px] h-0', {
        'active h-auto': isActiveStep,
      })}
    >
      {children}
    </div>
  );
};

export default Step;
