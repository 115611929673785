import { useRef, useEffect } from 'react';
import axios from 'axios';

/**
 * When a component unmounts, we need to cancel any potentially
 * ongoing Axios calls that result in a state update on success / fail.
 * This function sets up the appropriate useEffect to handle the canceling.
 */
export const useCancelToken = () => {
  const axiosSource = useRef(null);

  const newCancelToken = () => {
    const cancelToken = axios.CancelToken;
    axiosSource.current = cancelToken.source();
    return axiosSource.current.token;
  };

  const cancelRequest = () => {
    if (axiosSource.current) {
      axiosSource.current.cancel('Axios request cancelled');
    }
  };

  useEffect(
    () => () => {
      cancelRequest();
    },
    [],
  );

  return { newCancelToken, cancelRequest };
};
