import React from 'react';

import classNames from 'classnames';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

interface RadioProps {
  key: string;
  value: string;
  onClick: () => void;
  checked: boolean;
}

const Radio = ({ value, onClick, checked, ...props }: RadioProps) => {
  return (
    <div
      onClick={onClick}
      className={classNames(
        'flex cursor-pointer rounded-lg py-2 px-3 border-[1px] text-black ml-3 hover:bg-blue-blue/10',
        checked ? 'bg-blue-blue/20 border-blue-blue/50' : 'bg-blue-blue/5 border-blue-blue/10',
      )}
      {...props}
    >
      <div className="flex w-full items-center justify-between">
        <div className="max-w-[90%] text-md">{value}</div>
        <CheckCircleIcon
          className={classNames('w-[20px] h-[20px]', checked ? 'opacity-100' : 'opacity-0')}
        />
      </div>
    </div>
  );
};

export default Radio;
