import { create } from 'zustand';
import { shallow } from 'zustand/shallow';

export type BasicRegistrationInfo = {
  email: string;
  password: string;
  birthDate: {
    day: string;
    month: string;
    year: string;
  };
  mobile: {
    number: string;
    country: string;
    prefix: string;
    wholeNumber: string;
  };
  fitToPlayConsent: boolean;
};

export type AddressRegistrationInfo = {
  firstName: string;
  lastName: string;
  address: string;
  houseNumber: string;
  postCode: string;
  city: string;
  provinceId: string;
};

export type WorkRegistrationInfo = {
  industryId: string;
  occupationId: string;
  citizenshipId: string;
};

export type Consents = {
  tcConsent: boolean;
  marketingConsent: boolean;
  pepHioConsent: boolean;
  proxyConsent: boolean;
};

type State = BasicRegistrationInfo & AddressRegistrationInfo & WorkRegistrationInfo & Consents;

type Actions = {
  updateField: <K extends keyof State>(field: K, value: State[K]) => void;
  updateFields: (fields: Partial<State>) => void;
  reset: () => void;
};

const initialState: State = {
  email: '',
  password: '',
  birthDate: { day: '', month: '', year: '' },
  mobile: {
    number: '',
    country: 'ca',
    prefix: '',
    wholeNumber: '',
  },
  fitToPlayConsent: false,
  firstName: '',
  lastName: '',
  address: '',
  houseNumber: '',
  postCode: '',
  city: '',
  provinceId: '',
  industryId: '',
  occupationId: '',
  citizenshipId: 'ca',
  tcConsent: true,
  marketingConsent: false,
  pepHioConsent: true,
  proxyConsent: true,
};

export const useRegistrationStore = create<{ data: State } & Actions>(set => ({
  data: initialState,
  updateField: (field, value) => {
    set(state => ({ data: { ...state.data, [field]: value } }));
  },
  updateFields: (fields: Partial<State>) => {
    set(state => ({ data: { ...state.data, ...fields } }));
  },
  reset: () => set({ data: initialState }),
}));

export const useRegistrationData = () => {
  return useRegistrationStore(state => state.data, shallow);
};

export const useBasicInfoData = () => {
  return useRegistrationStore(
    state => ({
      email: state.data.email,
      password: state.data.password,
      birthDate: state.data.birthDate,
      mobile: state.data.mobile,
      fitToPlayConsent: state.data.fitToPlayConsent,
    }),
    shallow,
  );
};

export const useAddressInfoData = () => {
  return useRegistrationStore(
    state => ({
      firstName: state.data.firstName,
      lastName: state.data.lastName,
      address: state.data.address,
      houseNumber: state.data.houseNumber,
      postCode: state.data.postCode,
      city: state.data.city,
      provinceId: state.data.provinceId,
    }),
    shallow,
  );
};

export const useWorkInfoData = () => {
  return useRegistrationStore(
    state => ({
      citizenshipId: state.data.citizenshipId,
      industryId: state.data.industryId,
      occupationId: state.data.occupationId,
    }),
    shallow,
  );
};

export const useEmailData = () => {
  return useRegistrationStore(
    state => ({
      email: state.data.email,
    }),
    shallow,
  );
};

export const useRegistrationActions = () => {
  return useRegistrationStore(
    state => ({
      updateField: state.updateField,
      updateFields: state.updateFields,
      reset: state.reset,
    }),
    shallow,
  );
};
