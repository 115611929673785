import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';

const getFormattedCurrency = (currency, amount) => {
  switch (currency) {
    case 'INR':
      return (
        <NumberFormat
          displayType="text"
          value={amount}
          thousandSeparator
          thousandsGroupStyle="lakh"
          prefix="₹"
          decimalScale={2}
        />
      );
    case 'CAD':
    case 'NZD':
    case 'USD':
    case 'MXN':
      return (
        <NumberFormat
          displayType="text"
          value={amount}
          thousandSeparator
          prefix="$"
          decimalScale={2}
        />
      );
    case 'CHF':
      return (
        <NumberFormat
          displayType="text"
          value={amount}
          thousandSeparator
          suffix=" CHf"
          decimalScale={2}
        />
      );
    case 'NOK':
      return (
        <NumberFormat
          displayType="text"
          value={amount}
          thousandSeparator
          suffix=" kr"
          decimalScale={2}
        />
      );
    case 'ZAR':
      return (
        <NumberFormat
          displayType="text"
          value={amount}
          thousandSeparator
          prefix="R"
          decimalScale={2}
        />
      );
    case 'THB':
      return (
        <NumberFormat
          displayType="text"
          value={amount}
          thousandSeparator
          suffix=" บาท"
          decimalScale={2}
        />
      );
    case 'SEK':
      return (
        <NumberFormat
          displayType="text"
          value={amount}
          thousandSeparator=" "
          suffix=" kr"
          decimalScale={2}
        />
      );
    default:
      return (
        <NumberFormat
          displayType="text"
          value={amount}
          thousandSeparator
          prefix="€ "
          decimalScale={2}
        />
      );
  }
};

const Currency = ({ currency, amount }) => getFormattedCurrency(currency, amount);

Currency.propTypes = {
  currency: PropTypes.string.isRequired,
  amount: PropTypes.any.isRequired,
};

export default Currency;
