import styled from 'styled-components';

const TermsAndConditionsStyles = styled.section`
  .content {
    min-height: 700px;
    max-width: 800px;
    margin: 0 auto;
    padding: 64px 16px;
    position: relative;

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      padding: 64px;
    }

    .styled-table-on-desktop {
      display: none;
    }

    .styled-table-on-mobile {
      display: table;
    }

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      .styled-table-on-mobile {
        display: none;
      }

      .styled-table-on-desktop {
        display: table;
      }
    }

    table {
      min-width: 100%;
      overflow: hidden;
      border-spacing: 0;
      box-shadow:
        0 10px 15px -3px rgb(0 0 0 / 10%),
        0 0px 14px 1px rgb(0 0 0 / 17%);
      margin: 3rem -16px;

      @media (min-width: ${props => props.theme.breakpoints.small}) {
        margin: 1em 0 48px;
        border-radius: 8px;
        box-shadow: ${props => props.theme.buttonShadowHover};
        width: 100%;
        max-width: 800px;
      }

      tr {
        border: none;
      }

      th {
        padding: 1rem;
        color: ${props => props.theme.colors.grey100};
        font-weight: 700;
        font-size: 15px;
        text-align: left;
        white-space: normal;
        max-width: 250px;

        @media (min-width: ${props => props.theme.breakpoints.small}) {
          background: ${props => props.theme.colors.blue3};
          color: ${props => props.theme.colors.white};
        }

        .br-on-small {
          @media (min-width: ${props => props.theme.breakpoints.small}) {
            display: none;
          }
        }
      }

      tr {
        &:nth-child(even) {
          background: ${props => props.theme.colors.white};
        }

        td {
          padding: 1rem;
          vertical-align: middle;
          border: none;
          font-size: 15px;

          p {
            margin: 1em 0 0.5em 0;
          }

          span {
            word-break: break-word;
            @media (min-width: ${props => props.theme.breakpoints.small}) {
              word-break: normal;
            }
          }

          span {
            display: block;
          }
        }
      }
    }

    ol > li:before {
      content: counters(item, '.') '. ';
      counter-increment: item;
      font-weight: bold;
      display: inline-block;
    }

    ul > li:before {
      content: '·';
      position: absolute;
      right: calc(100% + 8px);
      margin-right: 6px;
    }

    ul {
      margin: 1rem 0 2rem;
      padding: 0;
    }

    ol {
      counter-reset: item;
      margin: 1rem 0 2rem;
      padding: 0;

      li {
        position: relative;

        h2 {
          display: inline;
        }

        &:before {
          font-size: 24px;
          font-family: 'Avenir Next', sans-serif;
          line-height: 1.2;
          display: inline-block;

          @media (min-width: ${props => props.theme.breakpoints.small}) {
            font-size: 28px;
            position: absolute;
            top: 0;
            right: calc(100% + 8px);
          }
        }

        ol {
          li {
            position: relative;

            &:before {
              font-size: 15px;
              opacity: 0.8;
              line-height: 1.6;
              position: relative;
              top: auto;
              right: auto;
              margin-right: 6px;
              font-family: 'Karla', sans-serif;
              float: left;

              @media (min-width: ${props => props.theme.breakpoints.small}) {
                top: 0;
                font-size: 16px;
                position: absolute;
                right: calc(100% + 8px);
              }
            }

            ol {
              margin-left: 32px;
            }
          }
        }
      }
    }

    p,
    li {
      font-size: 16px;
      display: block;
      position: relative;
      margin: 1em 0 1.3em 0;
    }

    h2 {
      font-size: 24px;
      display: block;
      margin: 2rem 0 1rem;

      @media (min-width: ${props => props.theme.breakpoints.small}) {
        font-size: 28px;
      }
    }

    .cookie {
      overflow-wrap: break-word;
    }

    a {
      color: ${props => props.theme.colors.blue1};
      text-decoration: underline;
    }
  }
`;

export default TermsAndConditionsStyles;
