import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import CloseButton from '../UI/Buttons/CloseButton';
import { useGetProvinces } from '@/hooks/useGetProvinces';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '@/store/selectors/userSelectors';
import { Listbox } from '@headlessui/react';
import { SubdivisionResponseDto } from '@lucky7ventures/lucky-components';
import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import { axiosInstance as axios } from '../../shared/axiosInstance';
import { selectAuthToken } from '@/store/selectors/authSelectors';
import { classNames } from '@/utils/utils';
import { getUser } from '@/store/actions/user';

export function UpdateStateModal({ closeModal }: { closeModal: () => void }): JSX.Element {
  const intl = useIntl();
  const user = useSelector(selectUser);
  const token = useSelector(selectAuthToken);
  const dispatch = useDispatch();
  const { provinces, getProvinces } = useGetProvinces();
  const [province, setProvince] = useState<SubdivisionResponseDto | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const closeHandler = () => {
    closeModal();
  };

  useEffect(() => {
    // when provinces change preselect the first one
    setProvince(provinces[0]);
  }, [provinces]);

  useEffect(() => {
    getProvinces('CA');
  }, []);

  const submit = () => {
    if (!user) {
      return;
    }
    setLoading(true);

    const updateUserPayload = {
      FirstName: user.FirstName,
      LastName: user.LastName,
      BirthDate: user.BirthDate,
      Phone: {
        MobilePrefix: user.MobilePrefix,
        MobileNumber: user.Mobile,
      },
      LanguageId: user.LanguageID,
      CountryId: user.CountryID,
      Address: {
        Address1: user.Address1,
        City: user.City,
        PostalCode: user.PostalCode,
        SubdivisionId: province?.SubdivisionId,
      },
    };

    setError(null);
    axios
      .post('/api/user/v2/update', { token, payload: updateUserPayload })
      .then(data => {
        if (!data.data.success) {
          setError(intl.formatMessage({ id: 'error.9' }));
          setLoading(false);
          return;
        }
        dispatch(getUser(token));
        closeModal();
      })
      .catch(() => {
        setError(intl.formatMessage({ id: 'error.9' }));
        setLoading(false);
      });
  };

  return (
    <div className="prose relative flex h-[580px] w-full max-w-sm flex-col justify-between space-y-4 rounded-2xl bg-gray-50 p-8 pt-0 text-left">
      <CloseButton right onClick={() => closeHandler()} />
      <div>
        <h2 className="mb-0">{intl.formatMessage({ id: 'state.update.modal.title' })}</h2>
        <p className="mt-4">{intl.formatMessage({ id: 'state.update.modal.subtitle' })}</p>
        <div className="mt-8 font-bold">{intl.formatMessage({ id: 'input.province.label' })}</div>
        <Listbox value={province} onChange={setProvince}>
          <div className="relative mt-2">
            <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md">
              <span className="block truncate">{province?.IsoNameSubdivision1}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>
            <Listbox.Options className="text-m absolute z-10 mt-1 max-h-60 w-full space-y-1 overflow-auto rounded-md bg-white p-2 shadow-lg">
              {provinces
                .sort((a, b) => a.IsoNameSubdivision1.localeCompare(b.IsoNameSubdivision1))
                .map(province => (
                  <Listbox.Option
                    key={province.SubdivisionId}
                    value={province}
                    className={({ active, selected }) =>
                      classNames(
                        selected
                          ? 'bg-cyan-500 font-bold text-white'
                          : active
                          ? 'bg-gray-100'
                          : 'bg-white',
                        'm-0 cursor-pointer rounded-md px-2 py-1',
                      )
                    }
                  >
                    {province.IsoNameSubdivision1}
                  </Listbox.Option>
                ))}
            </Listbox.Options>
          </div>
        </Listbox>
        {error && <p className="text-red-600">{error}</p>}
      </div>
      <div className="pt-4">
        <button
          type="button"
          className="flex h-14 w-full items-center justify-center rounded-full bg-cyan-500 px-5 font-bold text-white shadow-md transition-colors hover:bg-cyan-600"
          onClick={submit}
        >
          {loading ? (
            <svg
              className="h-5 w-5 animate-spin text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            intl.formatMessage({ id: 'misc.update' })
          )}
        </button>
      </div>
    </div>
  );
}
