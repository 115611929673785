import { RootState } from '@/models/root-state.model';
import { AutoLoginStep } from '@/models/enum/auto-login-step.enum';
import { createSelector } from 'reselect';
import { selectIsAuthenticated } from './authSelectors';
import { selectUserId } from './userSelectors';

export const selectAutoLoginStep = (state: RootState): AutoLoginStep => state.autoLogin.step;

// combined selectors
export const selectAutoLoginFinished = createSelector(
  selectAutoLoginStep,
  selectIsAuthenticated,
  selectUserId,
  (autoLogin: AutoLoginStep, isAuthenticated, userId): boolean => {
    // auto login is considered finished if it's skipped or produced an error
    if (
      autoLogin === AutoLoginStep.Skipped ||
      autoLogin === AutoLoginStep.Error ||
      autoLogin === AutoLoginStep.LoggedOut
    ) {
      return true;
    }

    if (autoLogin === AutoLoginStep.Success) {
      // for the auto login step success it's a bit more complicated because it takes time to fetch the user
      // so the auto login flow is considered finished if it's success only when it has a token and a userid
      return isAuthenticated && userId !== undefined;
    }

    // in other cases return false
    return false;
  },
);

export const selectAutoLoginFailed = createSelector(
  selectAutoLoginFinished,
  selectIsAuthenticated,
  (autoLoginFinished, isAuthenticated) => autoLoginFinished && !isAuthenticated,
);
