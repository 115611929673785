import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StyledTooltip = styled.div`
  position: absolute;
  background: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  width: ${props => props.width};
  border-radius: 8px;
  display: inline-block;
  line-height: 1.2;
  font-size: 12px;
  font-weight: bold;
  z-index: 10;
  padding: 8px 10px;
  visibility: hidden;
  opacity: 0;
  transition:
    opacity 0.15s ease,
    transform 0.15s ease;
  text-align: center;
  white-space: nowrap;

  p {
    margin-bottom: 0;
  }

  &.tooltip-left {
    top: 50%;
    right: calc(100% + 8px);
    transform: translate(-2px, -50%);

    &:after {
      content: ' ';
      position: absolute;
      top: 50%;
      left: 100%; /* To the right of the tooltip */
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent transparent ${props => props.theme.colors.black};
    }
  }

  &.tooltip-bottom {
    left: 50%;
    top: calc(100% + 8px);
    transform: translate(-50%, 2px);

    &:after {
      content: ' ';
      position: absolute;
      bottom: 100%; /* At the top of the tooltip */
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent ${props => props.theme.colors.black} transparent;
    }
  }

  &.tooltip-bottom-right {
    top: calc(100% + 8px);
    right: 0;
    transform: translate(0, 2px);

    &:after {
      content: ' ';
      position: absolute;
      bottom: 100%; /* At the top of the tooltip */
      right: 16px;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent ${props => props.theme.colors.black} transparent;
    }
  }

  &.tooltip-bottom-left {
    top: calc(100% + 8px);
    left: 4px;
    transform: translate(0, 2px);

    &:after {
      content: ' ';
      position: absolute;
      bottom: 100%; /* At the top of the tooltip */
      left: 16px;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent ${props => props.theme.colors.black} transparent;
    }
  }

  &.tooltip-top-right {
    bottom: calc(100% + 8px);
    right: -4px;
    transform: translate(0, -2px);

    &:after {
      content: ' ';
      position: absolute;
      top: 100%; /* At the top of the tooltip */
      right: 11px;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: ${props => props.theme.colors.black} transparent transparent transparent;
    }
  }

  &:hover {
    display: none;
  }

  ${props =>
    props.width !== 'auto' &&
    css`
      white-space: normal;
      width: ${props.width};
    `};
`;

const Tooltip = ({ text, position, width, ...rest }) => (
  <StyledTooltip width={width} className={`tooltip tooltip-${position}`} {...rest}>
    {text}
  </StyledTooltip>
);

Tooltip.propTypes = {
  text: PropTypes.any.isRequired,
  width: PropTypes.string,
  position: PropTypes.oneOf([
    'top',
    'left',
    'bottom',
    'right',
    'bottom-right',
    'bottom-left',
    'top-right',
    'top-left',
  ]).isRequired,
};

Tooltip.defaultProps = {
  width: 'auto',
};

export default Tooltip;
