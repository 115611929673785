import * as React from 'react';
import { useState } from 'react';
import { Form, Formik } from 'formik';
import { useIntl } from 'react-intl';
import { Alert } from './Alert';
import { CheckBox } from './CheckBox';
import CloseButton from './UI/Buttons/CloseButton';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthToken } from '@/store/selectors/authSelectors';
import {
  selectUser,
  selectUserEmail,
  selectUserHasValidContactInfo,
} from '@/store/selectors/userSelectors';
import { COUNTRY_PHONE_PREFIX } from '@/constants/phonePrefixes';
import * as Yup from 'yup';
import { FormInput } from './FormInput';
import { ContactInfoMobile } from './ContactInfoMobile';
import { getConsents } from '@/store/actions/consentsActions';
import { BtnOneLineBlue } from './buttons/BtnOneLineBlue';
import ApiService from '../shared/apiService';
import { useApiV2Request } from '@/hooks/useApiV2Request';
import { getUser } from '@/store/actions/user';

export function ContactInfoModalSweden({ closeModal }: { closeModal: () => void }): JSX.Element {
  const intl = useIntl();
  const user = useSelector(selectUser);
  const userEmail = useSelector(selectUserEmail);
  const [smsChecked, setSmsChecked] = useState(true);
  const [emailChecked, setEmailChecked] = useState(true);
  const token = useSelector(selectAuthToken);
  const hasValidContactInfo = useSelector(selectUserHasValidContactInfo);
  const dispatch = useDispatch();
  const { request, error, loading } = useApiV2Request();

  const initialValues = {
    email: userEmail,
    mobile: {
      number: hasValidContactInfo && user ? user.Mobile : '',
      country: hasValidContactInfo && user ? user.CountryCode : '',
      prefix: hasValidContactInfo && user ? COUNTRY_PHONE_PREFIX[user.CountryCode] : '',
      wholeNumber:
        hasValidContactInfo && user
          ? `${user.MobilePrefix}${user.Mobile}`
          : user
          ? COUNTRY_PHONE_PREFIX[user.CountryCode]
          : '',
    },
  };

  const validationSchema = Yup.object().shape({
    email: hasValidContactInfo
      ? Yup.string().notRequired()
      : Yup.string()
          .required(intl.formatMessage({ id: 'inputs.email.required' }))
          .email(intl.formatMessage({ id: 'inputs.email.invalid' })),
    mobile: hasValidContactInfo
      ? Yup.object().notRequired()
      : Yup.object().shape({
          wholeNumber: Yup.string().required(intl.formatMessage({ id: 'input.error.required' })),
          number: Yup.string().min(5, intl.formatMessage({ id: 'completeProfile.mobile.error' })),
        }),
  });

  const submitHandler = (values: any): void => {
    const handleOnSuccess = () => {
      dispatch(getUser(token));
      dispatch(getConsents());
      closeModal();
    };

    request(
      ApiService.updateContactInfo,
      {
        email: values.email,
        mobileNumber: values.mobile.number,
        mobilePrefix: values.mobile.prefix,
        smsChecked,
        emailChecked,
      },
      handleOnSuccess,
    );
  };

  return (
    <div className="relative w-full max-w-md rounded-lg bg-slate-50 px-8 pt-12 pb-8">
      <CloseButton left onClick={closeModal} />
      <h1 className="mb-8 text-2xl">
        {intl.formatMessage({ id: 'updateContactInfo.modal.title' })}
      </h1>
      <div className="content">
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={values => submitHandler(values)}
        >
          {({ isValid, handleSubmit }) => (
            <Form>
              <FormInput
                name="email"
                type="email"
                label={intl.formatMessage({
                  id: 'inputs.email',
                })}
                placeholder={intl.formatMessage({
                  id: 'inputs.email.placeholder',
                })}
                completed={!!userEmail}
              />
              <ContactInfoMobile
                label={intl.formatMessage({ id: 'completeProfile.mobile.placeholder' })}
                placeholder="+467212345678"
                name="mobile"
                completed={hasValidContactInfo}
              />
              {!hasValidContactInfo && (
                <div className="consent mt-8 flex flex-col justify-between rounded-lg bg-white px-4 pt-4 pb-2 shadow-md">
                  <p className="mx-auto mb-4 max-w-[260px] text-sm">
                    {intl.formatMessage({ id: 'completeProfile.optin.header' })}
                  </p>
                  <div className="checkboxes flex items-center justify-around last:ml-4">
                    <CheckBox
                      id="smsOptIn"
                      value="checked"
                      label={intl.formatMessage({ id: 'inputs.toggle.sms' })}
                      initialCheck={smsChecked}
                      handleChange={() => setSmsChecked(prevValue => !prevValue)}
                      className="pt-1 pr-2 pl-8 text-xs font-bold text-slate-800 "
                    />
                    <CheckBox
                      id="emailOptIn"
                      value="checked"
                      label={intl.formatMessage({ id: 'inputs.toggle.email' })}
                      initialCheck={emailChecked}
                      handleChange={() => setEmailChecked(prevValue => !prevValue)}
                      className="pt-1 pr-2 pl-8 text-xs font-bold text-slate-800 "
                    />
                  </div>
                </div>
              )}
              <div className="mt-8 mb-4">
                <BtnOneLineBlue
                  onClickHandler={handleSubmit}
                  isLoading={loading}
                  disabled={!isValid}
                  text={intl.formatMessage({ id: 'misc.update' })}
                />
              </div>
            </Form>
          )}
        </Formik>
        {error && <Alert text={error.error} />}
      </div>
    </div>
  );
}
