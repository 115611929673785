import { Transition } from '@headlessui/react';
import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { getAxiosError } from '@/shared/utility';
import { setMigrationLoading } from '@/store/actions/commonActions';
import { selectAuthToken } from '@/store/selectors/authSelectors';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { selectUser, selectWallet } from '@/store/selectors/userSelectors';
import {
  OccupationAndIndustrySelect,
  OccupationAndIndustrySelectItem,
} from './OccupationAndIndustrySelect';
import { OntarioMigrationPassword } from './OntarioMigrationPassword';
import { OntarioMigrationSuccess } from './OntarioMigrationSuccess';
import Spinner from './UI/Spinner';
import { useAbsoluteNavigate } from '@/hooks/useAbsoluteNavigate';
import { useTranslate } from '@/hooks/useTranslate';

export enum MigrationView {
  Migration = 'migration',
  Password = 'password',
  Success = 'success',
}

export function OntarioMigrationModal({
  closeModal,
}: {
  closeModal: () => void;
}): JSX.Element | null {
  const intl = useIntl();
  const user = useSelector(selectUser);
  const wallet = useSelector(selectWallet);
  const locale = useSelector(selectLocale);
  const token = useSelector(selectAuthToken);
  const absoluteNavigate = useAbsoluteNavigate();
  const { translateError } = useTranslate();
  const [view, setView] = useState<MigrationView>(MigrationView.Migration);

  const [occupations, setOccupations] = useState<OccupationAndIndustrySelectItem[]>();
  const [industries, setIndustries] = useState<OccupationAndIndustrySelectItem[]>();
  const [selectedIndustry, setSelectedIndustry] = useState<OccupationAndIndustrySelectItem | null>(
    null,
  );
  const [selectedOccupation, setSelectedOccupation] =
    useState<OccupationAndIndustrySelectItem | null>(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();

  // Get the occupations and industries from the API
  useEffect(() => {
    axios
      .post('/api/migration/occupationsandindustries', { locale: 'ca-on' })
      .then(res => {
        setOccupations(res.data.occupations);
        setIndustries(res.data.industries);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const nextStep = () => {
    if (!selectedOccupation?.OccupationId || !selectedIndustry?.IndustryId) {
      return;
    }

    setView(MigrationView.Password);
  };

  const migratePlayer = (password: string) => {
    if (!user || !wallet || !password) {
      setError(intl.formatMessage({ id: 'error.support' }));
      return;
    }

    if (!selectedOccupation?.OccupationId || !selectedIndustry?.IndustryId) {
      return;
    }

    setLoading(true);
    // Set this to true so the ping login wont happen while the user is migrating
    dispatch(setMigrationLoading(true));

    axios
      .post('/api/migration/register/agco', {
        locale,
        password,
        token,
        occupationId: selectedOccupation.OccupationId,
        industryId: selectedIndustry.IndustryId,
      })
      .then(res => {
        setView(MigrationView.Success);
        const newLocale = locale === 'ca-fr' ? '/fr/' : '/';
        const url = process.env.REACT_APP_MIGRATION_REDIRECT_URL;
        setTimeout(() => {
          window.location.href = `${url}${newLocale}migration/${res.data.token}`;
        }, 5000);
      })
      .catch(error => {
        // Set this to false so the ping login will happen again if an error occurs
        dispatch(setMigrationLoading(false));
        const apiError = getAxiosError(error);
        const err = translateError(apiError);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!user) {
    return null;
  }

  return (
    <div className="relative min-h-[600px] w-full max-w-sm rounded-2xl bg-white text-left">
      <Transition
        show={view === MigrationView.Migration}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        as={Fragment}
      >
        <div className="absolute inset-0 flex flex-col justify-between p-8">
          <div>
            <h1 className="mb-4 text-center text-xl font-bold">
              {intl.formatMessage({ id: 'ontario.migration.header' })}
            </h1>
            <p className="text-center">{intl.formatMessage({ id: 'ontario.migration.text' })}</p>
            <div className="mt-6 space-y-4">
              {occupations && occupations.length > 0 && (
                <OccupationAndIndustrySelect
                  label={intl.formatMessage({ id: 'ontario.occupation.label' })}
                  placeholder={intl.formatMessage({ id: 'ontario.occupation.placeholder' })}
                  items={occupations}
                  onChangeHandler={(item: OccupationAndIndustrySelectItem) =>
                    setSelectedOccupation(item)
                  }
                  selectedItem={selectedOccupation}
                />
              )}
              {industries && industries.length > 0 && (
                <OccupationAndIndustrySelect
                  label={intl.formatMessage({ id: 'ontario.industry.label' })}
                  placeholder={intl.formatMessage({ id: 'ontario.industry.placeholder' })}
                  items={industries}
                  onChangeHandler={(item: OccupationAndIndustrySelectItem) =>
                    setSelectedIndustry(item)
                  }
                  selectedItem={selectedIndustry}
                />
              )}
            </div>
          </div>
          <div className="space-y-4 pt-6">
            <button
              // eslint-disable-next-line prettier/prettier
              className="h-12 w-full rounded-full bg-cyan-500 px-4 text-center font-bold text-white shadow-lg transition hover:shadow-xl hover:enabled:bg-cyan-600 disabled:cursor-not-allowed disabled:opacity-60"
              type="button"
              onClick={() => nextStep()}
              disabled={!selectedOccupation || !selectedIndustry}
            >
              {loading ? (
                <Spinner width={20} height={20} borderwidth={4} color="white" />
              ) : (
                intl.formatMessage({ id: 'ontario.migration.button' })
              )}
            </button>
            <button
              className="h-12 w-full rounded-full bg-gray-200 px-4 text-center font-bold transition hover:bg-gray-300"
              type="button"
              onClick={() => {
                closeModal();
                absoluteNavigate('/logout');
              }}
            >
              {intl.formatMessage({ id: 'header.logout' })}
            </button>
          </div>
        </div>
      </Transition>
      <Transition
        show={view === MigrationView.Password}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="absolute inset-0 flex flex-col justify-between space-y-4 p-8">
          <OntarioMigrationPassword
            callback={password => migratePlayer(password)}
            loading={loading}
            error={error}
            backButtonHandler={() => setView(MigrationView.Migration)}
          />
        </div>
      </Transition>
      <Transition
        show={view === MigrationView.Success}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <OntarioMigrationSuccess />
      </Transition>
    </div>
  );
}
