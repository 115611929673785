import React from 'react';
import { useIntl } from 'react-intl';
import { DocumentMagnifyingGlassIcon } from '@heroicons/react/24/outline';

const VerifyInProgress = (): JSX.Element => {
  const intl = useIntl();
  return (
    <div className="mx-auto flex flex-col min-h-[300px] max-w-[720px] items-center justify-center px-8 py-16">
      <DocumentMagnifyingGlassIcon className="w-8 h-8" />
      <h1 className="mt-8 mb-4 text-lg font-bold">
        {intl.formatMessage({ id: 'account.verification.title' })}
      </h1>
      <p className="text-center">{intl.formatMessage({ id: 'account.verification.desc' })}</p>
    </div>
  );
};

export default VerifyInProgress;
