import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { User } from '@/models/user.model';
import { getLocaleByCountryCode, isEmpty } from '@/shared/utility';
import { BUILD_MODE, IS_SWEDEN } from '@/constants/constants';
import { getLocaleByLanguageId } from '@/utils/utils';
import { logout } from '@/store/actions/auth';

const useUserLocaleRedirect = (): void => {
  const user = useSelector((state: any) => state.user.user);
  const isAuthenticated = useSelector((state: any) => state.auth.token !== null);
  const userLoading = useSelector((state: any) => state.user.userLoading);
  const locale = useSelector((state: any) => state.common.locale);
  const location = useLocation();
  const dispatch = useDispatch();

  function redirectBuildModeNormal(user: User, locale: string): void {
    let userLocale = getLocaleByCountryCode(user.CountryCode);

    if (userLocale === 'ca' && user.LanguageID === 24) {
      userLocale = 'ca-fr';
    }

    if (userLocale === locale) {
      return;
    }

    const path = location.pathname.replace(locale, userLocale);
    window.location.replace(path);
  }

  function redirectBuildModeCanada(user: User, locale: string): void {
    const userLocale = getLocaleByCountryCode(user.CountryCode);

    // If the user is not a canadian user
    // log out the user from this build
    if (userLocale !== 'ca') {
      dispatch(logout());
      return;
    }

    const languageLocale = getLocaleByLanguageId(user.LanguageID);

    // You're already on ca locale with english language
    // or you're on /fr with french language
    if (languageLocale === locale) {
      return;
    }

    if (languageLocale === 'ca') {
      window.location.replace('/');
      return;
    }

    window.location.replace('/fr');
  }

  useEffect(() => {
    if (isAuthenticated && !isEmpty(user) && !userLoading) {
      if (IS_SWEDEN) {
        return;
      }

      if (BUILD_MODE === 'canada') {
        redirectBuildModeCanada(user, locale);
        return;
      }

      redirectBuildModeNormal(user, locale);
    }
  }, [user, isAuthenticated, userLoading]);
};

export default useUserLocaleRedirect;
