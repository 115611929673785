import { updateObject } from '../../shared/utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  token: null,
  error: null,
  loading: false,
  loginAttempts: 0,
  authenticateWithChallengeTokenLoading: false,
  authenticateWithChallengeTokenError: null,
  authenticateWithChallengeTokenSuccess: false,
};

const loginSuccess = (state, action) =>
  updateObject(state, {
    token: action.token,
    error: null,
    loading: false,
  });

const authenticateWithChallengeToken = state =>
  updateObject(state, {
    authenticateWithChallengeTokenLoading: true,
    authenticateWithChallengeTokenSuccess: false,
  });

const authenticateWithChallengeTokenSuccess = (state, action) => ({
  ...state,
  token: action.token,
  authenticateWithChallengeTokenLoading: false,
  authenticateWithChallengeTokenSuccess: true,
});

const authenticateWithChallengeTokenError = (state, action) =>
  updateObject(state, {
    authenticateWithChallengeTokenLoading: false,
    authenticateWithChallengeTokenError: action.error,
    authenticateWithChallengeTokenSuccess: false,
  });

const logoutSuccess = state => updateObject(state, initialState);

const logoutFail = state =>
  updateObject(state, {
    loading: false,
  });

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS:
      return loginSuccess(state, action);
    case actionTypes.LOGOUT_SUCCESS:
      return logoutSuccess(state, action);
    case actionTypes.LOGOUT_FAIL:
      return logoutFail(state, action);
    case actionTypes.AUTHENTICATE_WITH_CHALLENGE_TOKEN:
      return authenticateWithChallengeToken(state, action);
    case actionTypes.AUTHENTICATE_WITH_CHALLENGE_TOKEN_SUCCESS:
      return authenticateWithChallengeTokenSuccess(state, action);
    case actionTypes.AUTHENTICATE_WITH_CHALLENGE_TOKEN_ERROR:
      return authenticateWithChallengeTokenError(state, action);
    default:
      return state;
  }
};

export default authReducer;
