import React from 'react';

import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/solid';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledSearchInput = styled.div`
  width: 100%;
  position: relative;
  max-width: 520px;
  margin: 0 auto;

  input {
    display: block;
    width: 100%;
    background: ${props => props.theme.colors.white};
    color: #666;
    border: none;
    border-radius: 50vh;
    box-shadow: ${props => props.theme.boxShadow};
    padding: 16px 42px 16px 24px;
    margin-top: 0;
    font-size: 16px;
    -webkit-appearance: none;
    outline-offset: 0;
    font-weight: bold;

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      font-size: 20px;
    }

    &::placeholder {
      color: #aaa;
      font-weight: normal;
    }

    &:focus {
      box-shadow: ${props => props.theme.boxShadow};
    }
  }
`;

const StyledSearchButton = styled.button`
  border: none;
  position: absolute;
  background: none;
  top: 0;
  right: 16px;
  bottom: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${props => (props.light ? '#fff' : props.theme.colors.blue3)};
  pointer-events: none;

  svg {
    height: 24px;
    width: 24px;
  }
`;

const StyledCloseButton = styled.button`
  border: none;
  position: absolute;
  background: none;
  top: 0;
  right: 16px;
  bottom: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${props => (props.light ? '#fff' : props.theme.colors.blue3)};

  svg {
    height: 24px;
    width: 24px;
    border-radius: 50vh;
    transition: all 0.2s ease;
  }

  &:hover {
    svg {
      background: rgba(0, 0, 0, 0.15);
    }
  }
`;

const SearchInput = ({ name, id, placeholder, value, onChange, onClose }) => (
  <StyledSearchInput>
    <input
      name={name}
      id={id}
      type="search"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      aria-label={placeholder}
    />
    {value === '' ? (
      <StyledSearchButton type="button" aria-label={name}>
        <MagnifyingGlassIcon />
      </StyledSearchButton>
    ) : (
      <StyledCloseButton type="button" onClick={onClose} aria-label="Close">
        <XMarkIcon />
      </StyledCloseButton>
    )}
  </StyledSearchInput>
);

SearchInput.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SearchInput;
