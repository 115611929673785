import React from 'react';
import useTagManager from '@/features/data-loader/hooks/useTagManager';
import useUserLocaleRedirect from '@/features/data-loader/hooks/useUserLocaleRedirect';
import useManualBonusesLoader from '@/features/data-loader/hooks/useManualBonusesLoader';
import useBonusesContentLogic from '@/features/data-loader/hooks/useBonusesContentLogic';
import useWordpressLoader from '@/features/data-loader/hooks/useWordpressLoader';
import useGamesLoader from '@/features/data-loader/hooks/useGamesLoader';
import useKycLoader from '@/features/data-loader/hooks/useKycLoader';
import useSentry from '@/features/data-loader/hooks/useSentry';
import useAmlVerificationStatusLoader from '@/features/data-loader/hooks/useAmlVerificationStatusLoader';
import useIpDataLoader from '@/features/data-loader/hooks/useIpDataLoader';
import useDetailsReverification from '@/features/data-loader/hooks/useDetailsReverification';
import useBtagLogic from '@/features/data-loader/hooks/useBtagLogic';
import useSearchParams from '@/features/data-loader/hooks/useSearchParams';

export function DataLoader({ children }: { children: React.ReactChildren }): JSX.Element {
  useTagManager();
  useUserLocaleRedirect();
  useBonusesContentLogic();
  useManualBonusesLoader();
  useWordpressLoader();
  useGamesLoader();
  useKycLoader();
  useSentry();
  useAmlVerificationStatusLoader();
  useIpDataLoader();
  useDetailsReverification();
  useBtagLogic();
  useSearchParams();

  return <>{children}</>;
}
