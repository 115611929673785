import {
  DBTokenIP,
  PaginatedTableRowModel,
  UserResponseDto,
} from '@lucky7ventures/lucky-components';
import { User } from '@/models/user.model';
import { BUILD_MODE } from '@/constants/constants';

export const loginsToTableRowData = (logins: DBTokenIP[]): PaginatedTableRowModel[] => {
  if (!logins) {
    return [];
  }

  return logins.map(login => ({
    id: login.Token,
    cells: [
      {
        sortValue: login.DateCreated,
        text: new Date(login.DateCreated).toLocaleDateString(),
        subtext: `${new Date(login.DateCreated).toLocaleTimeString()} UTC`,
      },
      {
        sortValue: login.LastAccess,
        text: login.IsInvalidated ? new Date(login.LastAccess).toLocaleDateString() : '-',
        subtext: login.IsInvalidated
          ? `${new Date(login.LastAccess).toLocaleTimeString()} UTC`
          : undefined,
        align: 'right',
      },
    ],
  }));
};

export const mapUser = (user: UserResponseDto): User => {
  return {
    AlphaCode3: user.Country.AlphaCode3,
    CountryCode: user.Country.AlphaCode2,
    UserID: user.UserId,
    Email: user.Email,
    FirstName: user.FirstName,
    LastName: user.LastName,
    BirthDate: user.BirthDate,
    MobilePrefix: user.Phone.MobilePrefix,
    Mobile: user.Phone.MobileNumber,
    Address1: user.Address.Address1,
    City: user.Address.City,
    PostalCode: user.Address.PostalCode,
    LanguageID: user.Language.LanguageId,
    CountryID: user.Country.CountryId,
    Reference: user.Reference,
    CountryName: user.Country.Name,
    Currency: user.Currency.Code,
    Subdivision: user.Address.Subdivision,
  };
};

export const mapLocaleToPathLocale = (locale: string): string => {
  if (BUILD_MODE === 'canada') {
    if (locale === 'ca-fr') {
      return 'fr';
    }
  }

  return locale;
};

export const mapLocaleToFile = (locale: string): string => {
  switch (locale) {
    case 'row':
      return 'en';
    case 'ca-fr':
      return 'fr';
    case 'at':
      return 'de';
    default:
      return locale;
  }
};

export const localeToCountry = (locale: string) => {
  switch (locale) {
    case 'no':
      return 'Norway';
    case 'fi':
      return 'Finland';
    case 'at':
      return 'Austria';
    case 'nz':
      return 'New Zealand';
    case 'ca':
      return 'Canada';
    case 'ca-fr':
      return 'Canada';
    case 'za':
      return 'South Africa';
    case 'ae':
      return 'United Arab Emirates';
    case 'th':
      return 'Thailand';
    case 'fi-en':
      return 'Finland';
    default:
      return 'Rest of World';
  }
};
