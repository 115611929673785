import React, { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { DateTime } from 'luxon';

export function Clock(): JSX.Element {
  const [date, setDate] = useState<DateTime>(DateTime.now());

  useEffectOnce(() => {
    const updateTime = setInterval(() => {
      setDate(DateTime.now());
    }, 1000);

    return () => {
      clearInterval(updateTime);
    };
  });

  return (
    <div className="mt-4">
      <div>{date.toLocaleString()}</div>
      <div className="tabular-nums">{date.toLocaleString(DateTime.TIME_24_WITH_SECONDS)}</div>
    </div>
  );
}
