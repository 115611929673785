import React, { useState } from 'react';

import { Select, SelectItem } from './Select';

export function DateFilter({
  items,
  selectDateRange,
}: {
  items: SelectItem[];
  selectDateRange: (id: number) => void;
}): JSX.Element | null {
  const [selectedItem, setSelectedItem] = useState<SelectItem>(items[0]);

  const onChangeHandler = (item: SelectItem) => {
    setSelectedItem(item);
    selectDateRange(+item.id);
  };

  if (!selectedItem) {
    return null;
  }

  return (
    <div id="e2e_dateFilter" className="-mt-9 flex justify-end">
      <Select items={items} onChangeHandler={onChangeHandler} selectedItem={selectedItem} />
    </div>
  );
}
