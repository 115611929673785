import { create } from 'zustand';
import { shallow } from 'zustand/shallow';
import { FluidBonusData, FluidUserData } from '@fluidpayments/types';

export type TransactionType = 'deposit' | 'withdrawal' | 'quick-deposit';

interface State {
  show: boolean;
  transactionType: TransactionType;
  bonuses: FluidBonusData;
  activeBonuses: FluidBonusData;
  userData: FluidUserData;
  bonusCode: string | null;
}

interface Actions {
  openFluid: (type?: TransactionType) => void;
  openWithPreselectedBonus: (preselectedBonusCode: string) => void;
  closeFluid: () => void;
  setBonuses: (bonuses: FluidBonusData) => void;
  setUserData: (userData: FluidUserData) => void;
}

export const useFluidPaymentsStore = create<State & Actions>((set, get) => ({
  show: false,
  transactionType: 'deposit',
  bonuses: [], // this is a source of truth from which we build up activeBonuses
  activeBonuses: [], // this is a copy of the array where we align the 'selected' flag; this one is used for sending to Fluid
  bonusCode: null,
  userData: {},
  setUserData: (userData: FluidUserData) => {
    set({
      userData,
    });
  },
  setBonuses: (bonuses: FluidBonusData) => {
    set({
      bonuses: bonuses,
      activeBonuses: bonuses.map(bonus => ({ ...bonus, selected: get().bonusCode === bonus.code })),
    });
  },
  openFluid: (transactionType = 'deposit') => {
    set({
      activeBonuses: get().bonuses.map(bonus => ({ ...bonus, selected: false })),
      transactionType,
      show: true,
      bonusCode: null,
    });
  },
  openWithPreselectedBonus: preselectedBonusCode => {
    set({
      bonusCode: preselectedBonusCode,
      activeBonuses: get().bonuses.map(bonus => ({
        ...bonus,
        selected: preselectedBonusCode === bonus.code,
      })),
      transactionType: 'deposit',
      show: true,
    });
  },
  closeFluid: () => {
    set({
      show: false,
    });
  },
}));

export const useFluidOpen = () => {
  const { openFluid } = useFluidPaymentsStore(
    state => ({
      openFluid: state.openFluid,
    }),
    shallow,
  );

  return openFluid;
};

export const useFluidOpenWithBonus = () => {
  const { openWithPreselectedBonus } = useFluidPaymentsStore(
    state => ({
      openWithPreselectedBonus: state.openWithPreselectedBonus,
    }),
    shallow,
  );

  return openWithPreselectedBonus;
};

export const useFluidClose = () => {
  const { closeFluid } = useFluidPaymentsStore(
    state => ({
      closeFluid: state.closeFluid,
    }),
    shallow,
  );

  return closeFluid;
};
