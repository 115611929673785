import * as React from 'react';

export const TwentyFourIcon = props => (
  <svg width={48} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#a)">
      <path d="M47.547 0H0v20h47.547V0Z" fill="#020203" />
      <path d="M47.17 19.623H.376V.377h46.792v19.246Z" fill="#fff" />
      <path
        d="M28.611 13.333V12.6h-3.57l1.902-2.067c.893-.966 1.335-1.8 1.335-2.5 0-.583-.184-1.041-.576-1.383a2.254 2.254 0 0 0-1.568-.525c-.7 0-1.268.2-1.693.608-.418.4-.634.934-.634 1.584h.909c0-.45.125-.809.375-1.067.25-.25.592-.383 1.043-.383.375 0 .676.116.9.358.226.242.335.542.335.917 0 .275-.067.541-.2.8-.143.258-.393.591-.751 1l-2.47 2.75v.641h4.663Zm4.772 0v-1.65h.992v-.741h-.992V6.225h-.96l-3.186 4.925v.533h3.237v1.65h.917-.008Zm-.91-2.391h-2.21l2.102-3.275.109-.2v3.483-.008Zm6.19 2.391V9.567c.125-.25.3-.459.517-.617.225-.15.484-.233.776-.233.359 0 .626.091.8.275.168.183.251.466.26.841v3.5h.9V9.842c0-1.259-.567-1.892-1.685-1.892-.642 0-1.168.25-1.568.742V5.833h-.9v7.5h.9Z"
        fill="#020203"
      />
      <path
        d="M20.145 10.042a8.13 8.13 0 0 1-2.384 5.742 8.146 8.146 0 0 1-5.749 2.383 8.212 8.212 0 0 1-5.744-2.417A8.196 8.196 0 0 1 3.878 10 8.188 8.188 0 0 1 6.28 4.274a8.204 8.204 0 0 1 5.732-2.399c4.504 0 8.133 3.667 8.133 8.167Z"
        fill="#E20D18"
      />
      <path
        d="M10.719 14.125H8.34a.334.334 0 0 1-.333-.333V6.25c0-.208.167-.333.333-.333h2.378c.208 0 .334.166.334.333v7.542c.041.166-.126.333-.334.333Zm4.921.042h-2.377a.334.334 0 0 1-.334-.334V6.25c0-.208.167-.333.334-.333h2.377c.209 0 .334.166.334.333v7.542c0 .208-.167.375-.334.375Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h47.547v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
