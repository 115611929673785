import * as React from 'react';

import parse, { HTMLReactParserOptions, DOMNode, Element, Node, Text } from 'html-react-parser';
import { PrimitiveType, useIntl } from 'react-intl';

import { AbsoluteLink } from './AbsoluteLink';

const isElement = (domNode: DOMNode): domNode is Element => {
  const isTag = domNode.type === 'tag';
  const hasAttributes = (domNode as Element).attribs !== undefined;

  return isTag && hasAttributes;
};

const isValidAnchor = (element: Element): boolean => {
  const isAnchor = element.name === 'a';
  const hasHref = element.attribs.href !== undefined;

  return isAnchor && hasHref;
};

const isTextNode = (element: Node): element is Text => {
  const isText = element.type === 'text';
  const hasData = (element as Text).data !== undefined;

  return isText && hasData;
};

interface ITranslatedRichTextProps extends React.HTMLAttributes<any> {
  id?: string;
  values?: Record<string, PrimitiveType>;
  string?: string;
  tagName?: React.ElementType;
  children?: (text: string) => React.ReactElement | null;
}

export function TranslatedRichText({
  id,
  values,
  string,
  tagName: TagName,
  children,
  ...rest
}: ITranslatedRichTextProps): JSX.Element | null {
  const intl = useIntl();

  const options: HTMLReactParserOptions = {
    // eslint-disable-next-line consistent-return
    replace: (domNode: DOMNode) => {
      if (isElement(domNode) && isValidAnchor(domNode)) {
        const { attribs, children } = domNode;
        const textNode = children[0];
        if (isTextNode(textNode)) {
          const href = attribs.href;
          if (href.startsWith('/')) {
            return <AbsoluteLink to={`${attribs.href}`}>{textNode.data}</AbsoluteLink>;
          }
          return <a {...attribs}>{textNode.data}</a>;
        }
      }
    },
    trim: true,
  };

  /* Usage of render-prop only with translation string */
  if (children && id) {
    return children(intl.formatMessage({ id }, values));
  }

  if (id) {
    return TagName ? (
      <TagName {...rest}>{parse(intl.formatMessage({ id }, values), options)}</TagName>
    ) : (
      <>{parse(intl.formatMessage({ id }, values), options)}</>
    );
  }

  if (string) {
    return TagName ? (
      <TagName {...rest}>{parse(string.toString(), options)}</TagName>
    ) : (
      <>{parse(string.toString(), options)}</>
    );
  }

  return null;
}
