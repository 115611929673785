import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const StyledPageHeader = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  background: ${props => props.theme.colors.blue2};
  text-align: center;
  min-height: 300px;
  overflow: hidden;
  padding: 64px 32px;
  color: white;

  @media (min-width: ${props => props.theme.breakpoints.small}) {
    padding: 80px 32px;
  }

  .content {
    display: flex;
    justify-content: center;
    flex-flow: column wrap;

    h1 {
      margin: 0;
      align-items: center;
      justify-content: center;
      display: flex;
      flex: 100%;
      font-size: 44px;
      color: inherit;
    }

    p {
      font-size: 18px;
      margin: 8px 0 0;

      @media (min-width: ${props => props.theme.breakpoints.small}) {
        font-size: 20px;
      }
    }

    a,
    button {
      display: inline-flex;
      text-decoration: none;
      color: ${props => props.theme.colors.white};
      margin: 32px auto 0;
      max-width: 200px;
    }
  }
`;

const PageHeader = ({ children, light, padding, backgroundColor, color }) => {
  const isAuthenticated = useSelector(state => state.auth.token !== null);

  return (
    <StyledPageHeader
      className="pageHeader"
      light={light}
      padding={padding}
      backgroundColor={backgroundColor}
      color={color}
      isAuthenticated={isAuthenticated}
    >
      <div className="content">{children}</div>
    </StyledPageHeader>
  );
};

PageHeader.propTypes = {
  backgroundColor: PropTypes.string,
  light: PropTypes.bool,
  children: PropTypes.any.isRequired,
  padding: PropTypes.string,
  color: PropTypes.string,
};

PageHeader.defaultProps = {
  color: 'black',
  backgroundColor: 'offWhite',
  light: false,
  padding: '0',
};

export default PageHeader;
