import React, { ReactNode } from 'react';

interface FieldIconProps {
  icon: ReactNode;
  onClick?: () => void;
}

const FieldIcon = ({ icon, onClick }: FieldIconProps) => (
  <div
    onClick={onClick}
    className="absolute right-2.5 top-1/2 -translate-y-1/2 w-[20px] h-[20px] cursor-pointer peer-autofill:[&>svg]:text-black"
  >
    {icon}
  </div>
);

export default FieldIcon;
