import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { To } from 'react-router-dom';
import { envIsCanada, envIsMexico, envIsSweden } from '@/constants/constants';
import { RootState } from '@/models/root-state.model';
import { mapLocaleToPathLocale } from '@/utils/mappers';

export function useLocalizedTo(to: To): To | null {
  const locale = useSelector((state: RootState) => state.common.locale);
  const [localizedTo, setLocalizedTo] = useState<To | null>(null);

  useEffect(() => {
    if (envIsSweden() || envIsMexico()) {
      setLocalizedTo(to);
      return;
    }

    if (envIsCanada()) {
      if (locale === 'ca') {
        setLocalizedTo(to);
        return;
      }
    }

    // This is to not append trailing slashes to the path
    const path = to === '/' ? '' : to;

    setLocalizedTo(`/${mapLocaleToPathLocale(locale)}${path}`);
  }, [locale, to]);

  return localizedTo;
}
