import React, { useState } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import CloseButton from '../UI/Buttons/CloseButton';
import { CheckBox } from '../CheckBox';
import PromptMessage from '../UI/PromptMessage';
import { BtnOneLineBlue } from '../buttons/BtnOneLineBlue';
import {
  selectIsEmailConsented,
  selectIsTextMessageConsented,
  selectSmsEmailConsents,
} from '@/store/selectors/consentsSelectors';
import ApiService from '../../shared/apiService';
import { useApiV2Request } from '@/hooks/useApiV2Request';
import { getConsents } from '@/store/actions/consentsActions';

interface ISmsMailReminderModalProps {
  closeModal: () => void;
}

export function SmsMailReminderModal({ closeModal }: ISmsMailReminderModalProps): JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();
  const userConsents = useSelector(selectSmsEmailConsents);
  const isSmsConsented = useSelector(selectIsTextMessageConsented);
  const isEmailConsented = useSelector(selectIsEmailConsented);
  const [smsChecked, setSmsChecked] = useState(!!isSmsConsented);
  const [emailChecked, setEmailChecked] = useState(!!isEmailConsented);
  const { error, loading, request: saveConsents } = useApiV2Request();

  const handleSmsConsent = () => {
    setSmsChecked(!smsChecked);
  };

  const handleEmailConsent = () => {
    setEmailChecked(!emailChecked);
  };

  const handleUpdateConsents = () => {
    const updatedUserConsents = [...userConsents].map(consent => {
      return {
        ConsentId: consent.ConsentId,
        Version: consent.Version,
        Consented: consent.Name === 'TEXT_MESSAGE' ? smsChecked : emailChecked,
      };
    });

    saveConsents(ApiService.saveConsents, updatedUserConsents, () => {
      closeModal();
      dispatch(getConsents());
    });
  };

  return (
    <div className="prose relative flex w-full max-w-sm flex-col overflow-hidden rounded-lg bg-slate-50 p-8 text-center">
      <CloseButton left onClick={closeModal} />
      <h1 className="mb-0.5 text-2xl">
        {intl.formatMessage({ id: 'smsMailReminderModal.header' })}
      </h1>
      <p className="text-sm font-medium leading-6">
        {intl.formatMessage({ id: 'smsMailReminderModal.text' })}
      </p>
      <div className="flex flex-col gap-4 rounded-lg bg-white px-4 pt-4 pb-2 shadow-md">
        <span className="text-sm font-semibold">
          {intl.formatMessage({ id: 'smsMailReminderModal.contact' })}
        </span>
        <div className="checkboxes flex items-center justify-around">
          <CheckBox
            className="mx-2"
            id="smsOptIn"
            value="checked"
            label={intl.formatMessage({ id: 'inputs.toggle.sms' })}
            initialCheck={smsChecked}
            handleChange={handleSmsConsent}
          />
          <CheckBox
            className="mx-2"
            id="emailOptIn"
            value="checked"
            label={intl.formatMessage({ id: 'inputs.toggle.email' })}
            initialCheck={emailChecked}
            handleChange={handleEmailConsent}
          />
        </div>
      </div>
      <div className="mt-6">
        <BtnOneLineBlue
          isLoading={loading}
          onClickHandler={handleUpdateConsents}
          text={intl.formatMessage({ id: 'misc.update' })}
        />
      </div>
      {error && (
        <div className="mb-[-32px] mt-4">
          <PromptMessage type="error" message={intl.formatMessage({ id: 'error.support' })} />
        </div>
      )}
    </div>
  );
}
