import React, { useState } from 'react';
import styled from 'styled-components';
import Button from './UI/Buttons/Button';

const StyledLuckyRewardsClubTerms = styled.div`
  margin-top: 2rem;
  position: relative;
  max-height: ${props => (props.isOpen ? '100%' : '300px')};
  overflow: hidden;

  .toggle-terms {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    border: 0;
    padding: 2rem;
    cursor: pointer;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  }
`;

export function LuckyRewardsClubTerms() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => {
    isOpen ? setIsOpen(false) : setIsOpen(true);
  };
  return (
    <StyledLuckyRewardsClubTerms isOpen={isOpen}>
      <div className="section">
        <h2>ข้อตกลงและเงื่อนไข</h2>
        <p>1. โปรแกรม ลัคกี้ รีวอร์ดคลับ ให้บริการเฉพาะสำหรับลูกค้าคนไทยเท่านั้น</p>
        <p>
          2. คุณจะต้องทำการคลิกเข้าร่วมโปรแกรม ลัคกี้ รีวอร์ดคลับ ก่อน เพื่อเป็นส่วนหนึ่งของคลับ และ
          การลงชื่อเข้าร่วมคลับนี้จะเป็นการเข้าร่วมถาวร โดยคุณสามารถจะยกเลิกการเข้าร่วมได้
          โดยการติดต่อพนักงานบริการลูกค้าของเรา
        </p>
        <p>
          3. โปรแกรม ลัคกี้ รีวอร์ดคลับ มอบโปรคืนเงินให้กับคุณที่ทำการเดิมพันโดยเงินสดที่เกมสล็อต
          และ คาสิโนสดที่ร่วมรายการ
        </p>
        <p>
          4. จำนวนเงินคืนที่จะได้รับในแต่ละสัปดาห์นั้น
          ขึ้นอยู่กับระดับเลเวลผู้เล่นของคุณในแต่ละสัปดาห์นั้นๆ
        </p>
        <p>
          5. ระยะเวลาคำนวนเงินคืนแต่ละสัปดาห์คือ ทุกวันจันทร์ เวลา 07:01 ถึง วันจันทร์เวลา 06:59
          ในแต่ละสัปดาห์
        </p>
        <p>
          6. การคำนวนระดับเลเวลผู้เล่นนั้น จะคำนวนจากการเดิมพันโดยเงินสดของผู้เล่นในระยะเวลา 30
          วันย้อนหลัง ณ ขณะที่คุณล็อคอิน โดยแบ่งเป็นระดับเลเวลดังนี้ : <br />
          ระดับ - ชื่อระดับ - ยอดเดิมพันเงินสด 30 วันย้อนหลัง
          <br />
          1 - ผู้เล่นทั่วไป - น้อยกว่า 3,800,000 บาท <br />
          2 - บลู - ระหว่าง 3,800,001บาท - 6,250,000 บาท <br />
          3 - โกล - ระหว่าง 6,250,001 - 13,300,000 บาท <br />4 - แพลตตินั่ม - 3,300,001 บาท
          และมากกว่า
        </p>
        <p>
          7. การคำนวนโปรโมชั่นคืนเงินนั้น จะอ้างอิงจาก
          เปอร์เซ็นที่เรามอบให้ในแต่ละระดับเลเวลผู้เล่นของคุณ
          โดยจะคำนวนจากยอดเดิมพันเงินสดในเกมสล็อต และ คาสิโนสดที่ร่วมรายการเท่านั้นในแต่ละสัปดาห์
          โดย เปอร์เซ็นการจ่ายจะแตกต่างกันระหว่าง สล็อต และ คาสิโนสด ดังนี้
        </p>
        <p>
          7.1. เปอร์เซ็นการจ่ายคืนเงินของ คาสิโนสด : <br />
          ระดับ - ชื่อระดับ - เปอร์เซ็นต์การจ่ายของคาสิโนสด <br />
          1 - ผู้เล่นทั่วไป - 0.3% <br />
          2 - บลู - 0.4% <br />
          3 - โกล - 0.6% <br />4 - แพลตตินั่ม - 0.7%
        </p>
        <p>
          7.2.เปอร์เซ็นการจ่ายคืนเงินของ สล็อต : <br />
          ระดับ - ชื่อระดับ - เปอร์เซ็นต์การจ่ายของสล็อต <br />
          1 - ผู้เล่นทั่วไป - 0.4% <br />
          2 - บลู - 0.7% <br />
          3 - โกล - 0.8% <br />
          4 - แพลตตินั่ม- 1.0% <br />
        </p>
        <p>
          8. คุณจะได้รับเงินคืนในแต่ละสัปดาห์ทันที เมื่อคุณมียอดเงินคืนขั้นต่ำที่ 450 บาท
          โดยจ่ายออกสูงสุดที่ 150,000 บาท ต่อสัปดาห์
        </p>
        <p>
          9. ทางเราขอสงวนการคำนวนเงินคืนให้กับผู้เล่นจากบางเกมส์ที่ไม่เข้าร่วมรายการ
          โดยที่สล็อตเกมทั้งหมด เข้าร่วมรายการนี้ ในส่วนของคาสิโนสดนั้นเกมที่ไม่เข้าร่วมรายการคือ 2
          Hand Casino Holdem, Caribbean Stud Poker, Casino Hold’em, Extreme Texas Hold’em, Extreme
          Texas Holdem, Side Bet City, Texas Hold’em Bonus Poker, Triple Card Poker, Ultimate Texas
          Hold’em **และรวมถึง เทเบิ้ลเกมทั้งหมดอีกด้วย**
        </p>
        <p>
          10. ผู้เล่นที่สามารถเดิมพันได้ถึงเกณฑ์ที่กำหนดในแต่ละสัปดาห์
          จะได้รับการจ่ายออกทุกๆวันอังคาร ก่อนเวลา 23:59 นาฬิกา โดยผู้เล่นจะได้รับทันทีเมื่อล็อคอิน
          โดยจะอยุ่ที่กระเป๋าโบนัส
        </p>
        <p>11. ผู้เล่นจะต้องทำยอดเทิร์น 1 เท่าก่อนการถอนเงิน</p>
        <p>
          12. การคำนวนยอดเทิร์นในแต่ละเกมนั้นจะแตกต่างออกไป โดยไม่ใช่ทุกเกมที่นับยอดเทิร์น 100%
          ดังนี้
        </p>
        <p>
          12.1. การนับยอดเทิร์นในแต่ละประเภทเกม : <br />
          สล็อต: 100% <br />
          เกมแจ๊คพ็อต: 0% <br />
          เกมรูเล็ตสดและบาคาร่าสด: 10% <br />
          เกมคาสิโนสดอื่นๆ: 10% <br />
          เกมโต๊ะ : 5% <br />
        </p>
        <p>
          12.2 เกมสล็อตบางเกมจะไม่ได้นับยอดเทิร์น 100%
          กรุณาเช็คข้อตกลงและเงื่อนไขของเราสำหรับเกมสล็อตที่ไม่นับยอดเทิร์น{' '}
        </p>
        <p>
          13. การเดิมพันเงินสดในแต่ละสัปดาห์จะถูกคำนวนด้วยเงินบาท
          อย่างไรก็ตามจะมีการเปลี่ยนแปลงเป็นสกุลเงินยูโรโดยใช้อัตราการแลกเปลี่ยน ณ เวลานั้นๆ
          ในการคำนวนจำนวนเงินคืนที่ผู้เล่นจะได้รับ
          ดังนั้นการจำนวนการจ่ายออกให้ทางผู้เล่นเป็นสกุลเงินไทยอาจมีความแตกต่างเล็กน้อยกับตัวเลขที่ทางผู้เล่นเห็นบนหน้าเว็บไซต์
        </p>
        <p>
          14.ลัคกี้เดย์ ขอสงวนสิทธิ์ในการยกเลิกโปรโมชั่น การเข้าร่วมคลับของผู้เล่นที่ทำผิดกฏกติกา
          หรือจงใจบิดเบือนข้อกำหนดและเงื่อนไขของโปรโมชั่นนี้
        </p>
        <p>
          15. ลัคกี้เดย์ ขอสงวนสิทธิ์ ในการแก้ไข เปลี่ยนแปลง หรือยกเลิกโปรโมชั่น
          โดยไม่ต้องแจ้งให้ทราบล่วงหน้า
        </p>
        <p>16. ข้อตกลงและเงื่อนไขหลักทั้งหมดมีผลบังคับใช้กับ ลัคกี้ รีวอร์ดคลับ</p>
      </div>
      {!isOpen && (
        <div className="toggle-terms">
          <Button primary small nomargin onClick={toggleIsOpen}>
            ดูรายละเอียดเพิ่มเติม
          </Button>
        </div>
      )}
    </StyledLuckyRewardsClubTerms>
  );
}
