import { updateObject } from '@/shared/utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  drawerName: null,
  drawerProps: {},
  drawerIsOpen: false,
};

const openDrawer = (state, action) =>
  updateObject(state, {
    drawerName: action.drawerName,
    drawerIsOpen: true,
    drawerProps: action.drawerProps,
  });

const closeDrawer = state =>
  updateObject(state, {
    drawerName: null,
    drawerIsOpen: false,
    drawerProps: {},
  });

const drawerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_DRAWER:
      return openDrawer(state, action);
    case actionTypes.CLOSE_DRAWER:
      return closeDrawer(state, action);
    default:
      return state;
  }
};

export default drawerReducer;
