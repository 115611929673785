declare global {
  interface Window {
    regily: any;
  }
}

export const getRegilyLocale = (locale: string): string => {
  switch (locale) {
    case 'row':
    case 'fi-en':
    case 'ca':
    case 'nz':
    case 'za':
    case 'ae':
      return 'en';
    case 'ca-fr':
      return 'fr';
    default:
      return locale;
  }
};
