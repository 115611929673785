import { all, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../actions/actionTypes';
import {
  authenticateWithChallengeTokenSaga,
  loginSuccessSaga,
  logoutSaga,
  logoutSuccessSaga,
} from './auth';
import { getAvailableBonusesSaga, getUserBonusesSaga } from './bonusesSaga';
import { getUserDepositCountSaga, getUserSaga, getUserTagsSaga, getUserWalletSaga } from './user';

export function* watchAuth() {
  yield all([
    takeEvery(actionTypes.LOGIN_SUCCESS, loginSuccessSaga),
    takeEvery(actionTypes.LOGOUT, logoutSaga),
    takeEvery(actionTypes.AUTHENTICATE_WITH_CHALLENGE_TOKEN, authenticateWithChallengeTokenSaga),
    takeEvery(actionTypes.LOGOUT_SUCCESS, logoutSuccessSaga),
  ]);
}

export function* watchUser() {
  yield all([
    takeEvery(actionTypes.GET_USER, getUserSaga),
    takeEvery(actionTypes.GET_USER_WALLET, getUserWalletSaga),
    takeEvery(actionTypes.GET_USER_DEPOSIT_COUNT, getUserDepositCountSaga),
    takeEvery(actionTypes.GET_USER_TAGS, getUserTagsSaga),
  ]);
}

export function* watchBonuses() {
  yield all([
    takeEvery(actionTypes.GET_USER_BONUSES, getUserBonusesSaga),
    takeEvery(actionTypes.GET_AVAILABLE_BONUSES, getAvailableBonusesSaga),
  ]);
}
