import React from 'react';
import { selectAllGames, selectGamesLoading } from '@/store/selectors/gamesSelectors';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import LobbySection from '@/pages/games/lobby/lobby-sections/LobbySection';
import { BffGamesCategory } from '@lucky7ventures/bff-types';

const AllGames = (): JSX.Element => {
  const intl = useIntl();
  const games = useSelector(selectAllGames);
  const loading = useSelector(selectGamesLoading);

  return (
    <LobbySection
      header={intl.formatMessage({ id: `games.categories.allGames` })}
      subheader={intl.formatMessage({ id: `games.categories.allGames.info` })}
      games={games}
      loading={loading}
      category={BffGamesCategory.AllGames}
      maxItems={18}
    />
  );
};

export default AllGames;
