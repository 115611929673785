import PropTypes from 'prop-types';
import React from 'react';
import Currency from './Currency';

const getCurrencyByLocale = locale => {
  if (locale === 'no') {
    return 'NOK';
  }
  if (locale === 'ae') {
    return 'USD';
  }
  if (locale === 'ca' || locale === 'ca-fr') {
    return 'CAD';
  }
  if (locale === 'nz') {
    return 'NZD';
  }
  if (locale === 'za') {
    return 'ZAR';
  }
  if (locale === 'th') {
    return 'THB';
  }

  return 'EUR';
};

const convertCurrency = (locale, amount) => {
  if (locale === 'no') {
    return amount * 10;
  }

  if (locale === 'za') {
    return amount * 16;
  }

  if (locale === 'th') {
    return amount * 40;
  }

  return amount;
};

const JackpotCurrency = ({ locale, amount }) => (
  <Currency currency={getCurrencyByLocale(locale)} amount={convertCurrency(locale, amount)} />
);

JackpotCurrency.propTypes = {
  locale: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
};

export default JackpotCurrency;
