import axios, { AxiosError } from 'axios';
import { BffApiError, FrontendErrorCode } from '@/models/bff-api-error';
import { isBffErrorResponse } from '@/utils/type-guards';
import { logException } from '@/utils/logger';

export interface ApiV2Error {
  errorId: number;
  error: string;
}

const isApiV2Error = (data: unknown): data is ApiV2Error => {
  const r = data as ApiV2Error;
  return !!(r && r.errorId && r.error);
};

export const apiV2ErrorHandler = (error: AxiosError): ApiV2Error => {
  if (axios.isCancel(error)) {
    return {
      errorId: 2000,
      error: 'CANCELLLED_REQUEST',
    };
  }

  if (error.response) {
    if (isApiV2Error(error.response.data)) {
      return error.response.data;
    }
    // check if error is a CloudFlare error from the rate limiter
    if (error.response.status === 429) {
      return {
        errorId: 2001,
        error: 'TOO_MANY_REQUESTS',
      };
    }
  }

  // if the response is something we can't handle we will return a generic error
  // GiG errors usually go from 1-xxx;
  // Our custom errors on the middlelayer go from 7000
  // Only the Generic Error on the frontend will be 2000 - that usually means we can't process the error from the backend
  return {
    errorId: 2000,
    error: 'UNKNOWN_ERROR',
  };
};

export const bffApiErrorHandler = (error: AxiosError): BffApiError => {
  if (axios.isCancel(error)) {
    return {
      code: FrontendErrorCode.CancelledRequest,
    };
  }

  if (error.response) {
    // check if error is a CloudFlare error from the rate limiter
    if (error.response.status === 429) {
      return {
        code: FrontendErrorCode.TooManyRequests,
      };
    }

    if (isBffErrorResponse(error.response.data) && error.response.data.error) {
      return error.response.data.error;
    }
  }

  // let's log all the unknown errors to Sentry to maybe get some context
  logException(new Error('BFF Unknown Error'), { error });

  return {
    code: FrontendErrorCode.UnknownError,
  };
};
