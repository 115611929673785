import { RootState } from '@/models/root-state.model';
import { IpDataModel } from '@/models/ip-data.model';
import { WrapperPlatform } from '@/models/enum/wrapper-platform.enum';
import { createSelector } from 'reselect';

export const selectLocale = (state: RootState): string => state.common.locale;
export const selectLoadZendesk = (state: RootState): boolean => state.common.loadZendesk;
export const selectMigrationLoading = (state: RootState): boolean => state.common.migrationLoading;
export const selectFitToPlay = (state: RootState): boolean => state.common.fitToPlay;
export const selectIpData = (state: RootState): IpDataModel => state.common.ipData;
export const selectBtag = (state: RootState): string => state.common.btag;
export const selectWrapperPlatform = (state: RootState): WrapperPlatform => state.common.platform;

export const selectIsInAppWrapper = createSelector(
  selectWrapperPlatform,
  platform => platform !== WrapperPlatform.None,
);
