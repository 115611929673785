import * as React from 'react';

export function PlugIcon({
  width,
  height,
  className,
}: {
  width: string;
  height: string;
  className: string;
}): JSX.Element {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 1000.000000 1000.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g transform="translate(0.000000,1000.000000) scale(0.100000,-0.100000)" stroke="none">
        <path
          d="M4800 9957 c-3 -2 -59 -7 -125 -11 -480 -25 -992 -138 -1475 -323
-1056 -406 -1967 -1189 -2540 -2183 -154 -267 -307 -611 -410 -921 -108 -325
-201 -774 -225 -1084 -4 -44 -9 -91 -11 -105 -2 -14 -7 -131 -10 -260 -18
-661 104 -1342 351 -1965 459 -1155 1338 -2099 2461 -2641 411 -199 590 -246
914 -240 145 2 208 8 289 26 358 77 650 250 892 530 198 229 342 568 364 855
12 163 12 162 51 169 39 8 98 55 120 97 11 22 14 80 14 287 l0 259 113 37
c574 189 1029 686 1168 1276 43 180 49 273 49 756 l0 461 54 6 c113 11 238
105 289 214 21 47 22 60 25 449 l3 402 -27 21 -27 21 -2102 0 -2102 0 -27 -21
-27 -21 3 -397 3 -396 27 -57 c52 -111 166 -199 278 -214 l48 -6 4 -507 c4
-472 6 -514 27 -621 126 -653 596 -1181 1229 -1381 l72 -23 0 -252 c0 -154 4
-264 11 -281 18 -47 68 -91 120 -104 57 -15 56 -10 34 -149 -34 -227 -128
-415 -288 -575 -197 -196 -439 -296 -717 -296 -206 0 -351 46 -691 215 -448
225 -825 500 -1179 862 -345 352 -586 693 -805 1139 -232 472 -363 925 -432
1490 -25 210 -25 729 0 930 59 466 155 843 313 1222 345 829 910 1513 1659
2008 201 133 286 182 500 287 405 198 853 337 1304 403 415 61 910 57 1328
-11 807 -129 1553 -475 2178 -1009 136 -116 385 -366 494 -495 268 -319 427
-561 601 -915 204 -413 322 -777 399 -1225 76 -448 80 -942 10 -1395 -111
-713 -395 -1392 -828 -1975 -249 -336 -624 -706 -976 -962 -452 -329 -1037
-592 -1596 -719 -149 -33 -170 -42 -227 -99 -76 -76 -102 -208 -61 -306 38
-87 135 -160 228 -171 57 -6 117 4 311 52 770 193 1486 574 2100 1119 101 89
391 389 481 496 530 631 895 1367 1070 2155 148 669 155 1396 18 2070 -198
976 -690 1878 -1405 2575 -861 839 -1934 1324 -3129 1414 -160 12 -556 21
-565 13z m525 -4834 c-54 -136 -133 -340 -177 -451 -44 -112 -76 -207 -73
-210 4 -4 135 -7 291 -7 156 0 284 -2 284 -5 0 -3 -35 -45 -78 -95 -43 -49
-264 -306 -492 -570 -228 -264 -434 -503 -458 -530 -34 -39 -42 -44 -37 -25 7
22 86 227 270 695 85 218 82 202 43 208 -18 2 -149 4 -290 3 -142 -1 -258 1
-258 5 0 6 87 108 220 259 48 55 116 133 234 270 12 14 104 122 206 240 102
118 193 224 203 235 11 11 60 68 110 127 50 60 92 106 94 104 3 -2 -39 -116
-92 -253z"
        />
        <path
          d="M3833 8179 c-113 -22 -208 -107 -245 -221 -11 -34 -21 -1476 -11
-1570 l6 -48 316 0 317 0 -2 793 c-1 772 -2 793 -21 844 -58 147 -209 232
-360 202z"
        />
        <path
          d="M6020 8171 c-77 -25 -154 -92 -191 -168 l-24 -48 -3 -807 -2 -808
320 0 321 0 -3 798 c-3 770 -4 799 -23 847 -23 58 -102 142 -160 170 -43 21
-189 31 -235 16z"
        />
      </g>
    </svg>
  );
}
