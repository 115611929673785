import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeSpecificModal, openModal, updateModalProps } from '@/store/actions/modal';
import { selectIsAuthenticated } from '@/store/selectors/authSelectors';
import {
  selectDisableLocationSmart,
  selectTagsLoaded,
  selectUserId,
} from '@/store/selectors/userSelectors';
import { useAbsoluteNavigate } from './useAbsoluteNavigate';
import { BUILD_MODE } from '@/constants/constants';
import ApiService from '../shared/apiService';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';

declare global {
  interface Window {
    lshtml5: any;
    lsdp: any;
    tmx_profiling_complete: any;
  }
}

interface LocationEvent {
  uid: string;
  requestTime: Date;
  statusCode: number;
  statusMsg: string;
  sessionId: string;
  locationData: LocationData;
}

interface LocationData {
  code: string;
  message: string;
  domain: string;
  functionCalledAt: string;
  receivedTimeStamp: number;
  timeStamp: string;
  submitterIpAddress: string;
}

export function useLocationSmart(): void {
  const dispatch = useDispatch();
  const userId = useSelector(selectUserId);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const absoluteNavigate = useAbsoluteNavigate();
  const [lsSessionId, setLsSessionId] = useState<string | null>(null);
  const [dpSessionId, setDpSessionId] = useState<string | null>(null);
  const [recheckInterval, setRecheckInterval] = useState(0);
  const [isRecheck, setIsRecheck] = useState(false);
  const timeoutRef = useRef<NodeJS.Timer | null>(null);
  const disableLocationSmart = useSelector(selectDisableLocationSmart);
  const tagsLoaded = useSelector(selectTagsLoaded);

  const resetStates = () => {
    setDpSessionId(null);
    setLsSessionId(null);
    setRecheckInterval(0);
  };

  // eslint-disable-next-line
  const logout = () => {
    closeSpecificModal('locationSmart');
    absoluteNavigate('/logout');
    dispatch(
      openModal('login', {
        loggedOut: true,
        loggedOutIntlId: 'ontarioCheck.modal.text',
      }),
    );
  };

  function tmx_profiling_complete(session_id: any) {
    setDpSessionId(session_id);
  }

  useEffect(() => {
    window.tmx_profiling_complete = tmx_profiling_complete;
  }, []);

  const locationCheck = (sessionId: string) => {
    const locationCheckCallback = (e: LocationEvent) => {
      // User has allowed location
      if (e.locationData.code === '0') {
        setLsSessionId(e.sessionId);
      }
      // An error occurred
      if (e.locationData.code === '1') {
        absoluteNavigate('/logout');
        dispatch(updateModalProps({ error: true }));
      }
    };

    window.lshtml5(
      sessionId, // session id
      true, // high accuracy
      0, // maxage (ms)
      10000, // timeout (ms)
      locationCheckCallback, // callback
      'servicesbl.luckydays.ca', // domain
      false, // watch
      30, // watch timeout (seconds)
    );
  };

  const deviceProfiling = (sessionId: string) => {
    window.lsdp.dp(
      'servicesdp.luckydays.ca', // domain
      'dwiy84jk', // org id
      sessionId, // session id
    );
  };

  const locationSmart = (isRecheck: boolean) => {
    triggerGTag(
      isRecheck ? GTagEvents.location_smart_start_recheck : GTagEvents.location_smart_start,
    );
    const date = new Date();
    const timestamp = date.getTime();

    resetStates();
    dispatch(updateModalProps({ loading: true }));
    setIsRecheck(isRecheck);
    locationCheck(`ls-${userId}-ld-${timestamp}`);
    deviceProfiling(`dp-${userId}-ld-${timestamp}`);
  };

  useEffect(() => {
    if (lsSessionId && dpSessionId) {
      ApiService.checkOntarioCompliance({ lsSessionId, dpSessionId, isRecheck })
        .then(res => {
          const { compliant, recheckIn } = res.data;
          if (!compliant) {
            // TODO: Do something with the response if the data is not compliant
            triggerGTag(GTagEvents.location_smart_fail);
            logout();
            return;
          }
          triggerGTag(GTagEvents.location_smart_success);
          // User is compliant
          setLsSessionId(null);
          setDpSessionId(null);
          dispatch(updateModalProps({ success: true }));
          if (recheckIn > 0) {
            setRecheckInterval(recheckIn * 60 * 1000);
            // recheckIn returns in amounts of minutes, so we need to convert it to milliseconds
          }
        })
        .catch(() => {
          triggerGTag(GTagEvents.location_smart_error);
          logout();
        })
        .finally(() => {
          dispatch(updateModalProps({ loading: false }));
        });
    }
  }, [lsSessionId, dpSessionId]);

  useEffect(() => {
    if (recheckInterval > 0) {
      timeoutRef.current = setTimeout(() => {
        dispatch(openModal('locationSmart', { callback: locationSmart, isRecheck: true }, 0));
      }, recheckInterval);
    }
  }, [recheckInterval]);

  useEffect(() => {
    // We don't want location smart stuff happening for Non Ontario builds
    if (BUILD_MODE !== 'canada') {
      return;
    }

    // We need to wait for the tags to load before we can run location smart
    // And then if the disable location smart tag is applied to the user, don't show the modal
    if (!tagsLoaded || disableLocationSmart) {
      return;
    }

    // Reset these states if the user gets logged out
    if (!isAuthenticated) {
      resetStates();

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }

    if (isAuthenticated && userId) {
      setTimeout(() => {
        dispatch(openModal('locationSmart', { callback: locationSmart, isRecheck: false }, 0));
      }, 500);
    }
  }, [isAuthenticated, userId, disableLocationSmart, tagsLoaded]);
}
