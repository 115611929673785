import * as React from 'react';

import { useSelector } from 'react-redux';

import { selectGamesLoading, selectNewGames } from '@/store/selectors/gamesSelectors';
import CategoryPageContent from '@/pages/games/categories/CategoryPageContent';
import { useIntl } from 'react-intl';
import { BffGamesCategory } from '@lucky7ventures/bff-types';

function CategoryNewGames(): JSX.Element {
  const intl = useIntl();

  const games = useSelector(selectNewGames);
  const gamesLoading = useSelector(selectGamesLoading);

  return (
    <CategoryPageContent
      header={intl.formatMessage({ id: `games.categories.newGames` })}
      subheader={intl.formatMessage({ id: `games.categories.newGames.info` })}
      category={BffGamesCategory.NewGames}
      games={games}
      loading={gamesLoading}
    />
  );
}

export default CategoryNewGames;
