import React from 'react';

import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import propTypes from 'prop-types';

export function CountrySelectorDropdown({ header, headerFlag, items }) {
  return (
    <div className="relative">
      <Menu>
        <Menu.Button className="flex justify-between items-center p-4 w-full text-left border-0 rounded-lg bg-black/30 text-white">
          <div className="flex space-x-4">
            {headerFlag && <div className="flex items-center">{headerFlag}</div>}
            <div>{header}</div>
          </div>
          <ChevronDownIcon className="w-4 h-4" />
        </Menu.Button>
        <Menu.Items className="bg-white max-h-[260px] overflow-y-scroll scrollbar-hide px-0 py-2 space-y-1">
          {items.map(item => (
            <Menu.Item key={item.text}>
              <a
                className="flex text-black rounded-lg py-2 px-4 items-center space-x-4 hover:bg-black/10 no-underline"
                href={item.href}
              >
                {item.flag && <div>{item.flag}</div>}
                <div>{item.text}</div>
              </a>
            </Menu.Item>
          ))}
        </Menu.Items>
      </Menu>
    </div>
  );
}

CountrySelectorDropdown.propTypes = {
  header: propTypes.string.isRequired,
  headerFlag: propTypes.any.isRequired,
  items: propTypes.array.isRequired,
};
