import React from 'react';
import { Link } from 'react-router-dom';

interface HeaderIconButtonProps {
  to?: string;
  onClick?: () => void;
  isScrolled: boolean;
  children: React.ReactNode;
  id?: string;
  ariaLabel?: string;
}

export function HeaderIconButton({
  to,
  onClick,
  isScrolled,
  children,
  id,
  ariaLabel,
}: HeaderIconButtonProps): JSX.Element {
  const classNames = `items-center justify-center hidden w-10 h-10 text-slate-900 transition rounded-full md:flex bg-slate-900/10 hover:bg-slate-900/20 hover:shadow-lg ${
    isScrolled
      ? 'md:text-slate-900 md:bg-slate-900/10 md:hover:bg-slate-900/20'
      : 'md:text-white md:bg-white/20 md:hover:bg-white/30'
  }`;

  if (to) {
    return (
      <Link to={to} className={classNames} id={id}>
        {children}
      </Link>
    );
  }

  return (
    <button type="button" aria-label={ariaLabel} className={classNames} onClick={onClick}>
      {children}
    </button>
  );
}
