import { ArrowLeftIcon, LockClosedIcon } from '@heroicons/react/24/solid';
import { Form, Formik } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { useYupSchema } from '@/hooks/useYupSchema';
import { FormPassword } from './FormPassword';
import PromptMessage from './UI/PromptMessage';
import Spinner from './UI/Spinner';

export function OntarioMigrationPassword({
  loading,
  callback,
  error,
  backButtonHandler,
}: {
  callback: (password: string) => void;
  loading: boolean;
  error: string | null;
  backButtonHandler: () => void;
}): JSX.Element | null {
  const intl = useIntl();
  const { password, createSchema } = useYupSchema();
  const passwordSchema = createSchema({ password });

  return (
    <div>
      <button
        type="button"
        onClick={backButtonHandler}
        className="absolute top-3 left-3 rounded-full bg-gray-100 p-2 hover:bg-gray-200"
      >
        <ArrowLeftIcon className="h-4 w-4" />
      </button>
      <h1 className="flex flex-col text-center text-xl font-bold">
        <LockClosedIcon className="mx-auto mb-4 h-6 w-6" />
        {intl.formatMessage({ id: 'ontario.migration.password.header' })}
      </h1>
      <p className="mt-4 text-center">
        {intl.formatMessage({ id: 'ontario.migration.password.text' })}
      </p>
      <Formik
        initialValues={{
          password: '',
        }}
        validationSchema={passwordSchema}
        onSubmit={values => callback(values.password)}
      >
        {({ isValid }) => (
          <Form className="mt-8">
            <FormPassword
              name="password"
              label={intl.formatMessage({ id: 'inputs.newpassword' })}
              placeholder={intl.formatMessage({
                id: 'inputs.newpassword.placeholder',
              })}
            />
            {error && (
              <div>
                <PromptMessage type="error" message={error} />
              </div>
            )}
            <button
              className="h-12 w-full rounded-full bg-cyan-500 px-4 text-center font-bold text-white shadow-lg transition hover:bg-cyan-600 hover:shadow-xl disabled:opacity-50"
              type="submit"
              disabled={!isValid || loading}
            >
              {loading ? (
                <Spinner width={20} height={20} borderwidth={4} color="white" />
              ) : (
                intl.formatMessage({ id: 'ontario.migration.button' })
              )}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
