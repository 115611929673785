import { Action } from 'redux';
import { GetUserLimitModel } from '@/models/gig/get-user-limit.model';

export enum LimitsActionType {
  GetLimits = 'GET_LIMITS',
  GetLimitsSuccess = 'GET_LIMITS_SUCCESS',
  GetLimitsError = 'GET_LIMITS_ERROR',
  Reset = 'RESET_LIMITS',
}

export type GetLimitsAction = Action<LimitsActionType.GetLimits>;
export type GetLimitsSuccessAction = Action<LimitsActionType.GetLimitsSuccess> & {
  limits: GetUserLimitModel;
};
export type GetLimitsErrorAction = Action<LimitsActionType.GetLimitsError>;
export type ResetLimitsAction = Action<LimitsActionType.Reset>;

export type LimitsActions =
  | GetLimitsAction
  | GetLimitsSuccessAction
  | GetLimitsErrorAction
  | ResetLimitsAction;

export const getLimits = (): GetLimitsAction => ({
  type: LimitsActionType.GetLimits,
});

export const getLimitsSuccess = (limits: GetUserLimitModel): GetLimitsSuccessAction => ({
  type: LimitsActionType.GetLimitsSuccess,
  limits,
});

export const getLimitsError = (): GetLimitsErrorAction => ({
  type: LimitsActionType.GetLimitsError,
});

export const resetLimits = (): ResetLimitsAction => ({
  type: LimitsActionType.Reset,
});
