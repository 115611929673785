import React, { useEffect, useRef, useState } from 'react';

function LobbyInViewSection({
  children,
  loader,
}: {
  children: React.ReactChild;
  loader: React.ReactNode;
}): JSX.Element {
  const ref = useRef<HTMLDivElement | null>(null);
  const hasBeenVisible = useRef<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    if (!ref || !ref.current) {
      return;
    }
    const observer = new IntersectionObserver(
      ([entry]) => {
        hasBeenVisible.current = hasBeenVisible.current || entry.isIntersecting;
        if (hasBeenVisible.current) {
          setShow(true);
        }
      },
      { threshold: 0.1, rootMargin: '-5% 0% 0% 0%' },
    );

    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return <div ref={ref}>{show ? children : loader}</div>;
}

export default LobbyInViewSection;
