import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { loaderSpin } from '../../styles/animations';

const StyledSpinner = styled.div`
  border: ${props => `${props.borderwidth}px`} solid rgba(255, 255, 255, 0.2);
  border-left-color: ${props => props.theme.colors[props.color]};
  -webkit-animation: ${loaderSpin} 1.2s infinite linear;
  animation: ${loaderSpin} 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: ${props => `${props.width}px`};
  height: ${props => `${props.height}px`};
  margin: ${props => props.margin};

  ${props =>
    props.dark &&
    css`
      border: ${props => `${props.borderwidth}px`} solid rgba(0, 0, 0, 0.1);
      border-left-color: ${props => props.theme.colors[props.color]};
    `};

  ${props =>
    props.absolute &&
    css`
      position: absolute;
      top: ${props => `calc(50% - ${props.width / 2}px);`};
      left: ${props => `calc(50% - ${props.width / 2}px);`};
      grid-row: none !important;
      grid-column: none !important;
    `};
`;

const Spinner = ({ absolute, width, height, borderwidth, margin, color, dark }) => (
  <StyledSpinner
    className="lucky-spinner"
    absolute={absolute}
    width={width}
    height={height}
    borderwidth={borderwidth}
    margin={margin}
    color={color}
    dark={dark}
  />
);

Spinner.propTypes = {
  absolute: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  borderwidth: PropTypes.number,
  margin: PropTypes.string,
  color: PropTypes.string,
  dark: PropTypes.bool,
};

Spinner.defaultProps = {
  dark: false,
  absolute: false,
  width: 48,
  height: 48,
  borderwidth: 5,
  margin: 'auto',
  color: 'blue2',
};

export default Spinner;
