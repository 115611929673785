import { useIntl } from 'react-intl';

export function useTranslate() {
  const intl = useIntl();

  const translateError = error => {
    const errorMessage = intl.formatMessage({
      id: `error.${error.id}`,
      defaultMessage: error.message,
    });

    return `${errorMessage} [${error.id}]`;
  };

  return { translateError };
}
