import { createSelector } from 'reselect';
import { RootState } from '@/models/root-state.model';
import { WpManualBonusModel } from '@/models/wp-manual-bonus.model';
import { selectUserId } from './userSelectors';

export const selectManualBonuses = (state: RootState): WpManualBonusModel[] =>
  state.manualBonuses.manualBonuses;
export const selectManualBonusesError = (state: RootState): boolean => state.manualBonuses.error;
export const selectManualBonusesLoadedLocale = (state: RootState): string | null =>
  state.manualBonuses.loadedLocale;

export const selectUserManualBonuses = createSelector(
  selectManualBonuses,
  selectUserId,
  (manualBonuses, userId): WpManualBonusModel[] => {
    const now = new Date().toISOString();

    return manualBonuses.filter((bonus: WpManualBonusModel) => {
      const expiryDate = bonus.expiry_date.split(' ').join('T').concat('.000Z');
      if (now > expiryDate) {
        return false;
      }

      if (bonus.available_for_all) {
        return true;
      }

      if (bonus.available_for_list && userId) {
        const availableFor = bonus.available_for_list.split(',');
        return availableFor.includes(userId.toString());
      }

      return false;
    });
  },
);

export const selectFirstManualBonus = createSelector(selectUserManualBonuses, manualBonuses =>
  manualBonuses.length > 0 ? manualBonuses[0] : null,
);
