import {
  CommonActions,
  CommonActionType,
  SetFitToPlayAction,
  SetLocaleAction,
  SetMigrationLoadingAction,
} from '../actions/commonActions';
import { IpDataModel } from '@/models/ip-data.model';
import { WrapperPlatform } from '@/models/enum/wrapper-platform.enum';

export interface CommonState {
  locale: string;
  loadZendesk: boolean;
  migrationLoading: boolean;
  fitToPlay: boolean;
  ipData: IpDataModel;
  btag: string;
  platform: WrapperPlatform;
}

const initialState = {
  locale: '',
  loadZendesk: false,
  migrationLoading: false,
  fitToPlay: true,
  ipData: {
    country: '',
    city: '',
    region: '',
  },
  btag: '',
  platform: WrapperPlatform.None,
};

const setLocale = (state: CommonState, action: SetLocaleAction): CommonState => ({
  ...state,
  locale: action.locale,
});

const loadZendesk = (state: CommonState): CommonState => ({
  ...state,
  loadZendesk: true,
});

const setMigrationLoading = (
  state: CommonState,
  action: SetMigrationLoadingAction,
): CommonState => ({
  ...state,
  migrationLoading: action.migrationLoading,
});

const setFitToPlay = (state: CommonState, action: SetFitToPlayAction): CommonState => ({
  ...state,
  fitToPlay: action.fitToPlay,
});

export const commonReducer = (state = initialState, action: CommonActions) => {
  switch (action.type) {
    case CommonActionType.SetLocale:
      return setLocale(state, action);
    case CommonActionType.LoadZendesk:
      return loadZendesk(state);
    case CommonActionType.SetMigrationLoading:
      return setMigrationLoading(state, action);
    case CommonActionType.SetFitToPlay:
      return setFitToPlay(state, action);
    case CommonActionType.SetIpData:
      return { ...state, ipData: action.data };
    case CommonActionType.SetBtag:
      return { ...state, btag: action.btag };
    case CommonActionType.SetWrapperPlatform:
      return { ...state, platform: action.platform };
    default:
      return state;
  }
};
