import * as React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const bgColor = type => {
  switch (type) {
    case 'error':
      return 'red10';
    default:
      return 'red10';
  }
};

const borderColor = type => {
  switch (type) {
    case 'error':
      return 'red20';
    default:
      return 'red20';
  }
};

const fontColor = type => {
  switch (type) {
    case 'error':
      return 'red40';
    default:
      return 'red40';
  }
};

const StyledAlert = styled.div`
  padding: 1rem;
  background: ${props => props.theme.colors[bgColor(props.type)]};
  border: 1px solid ${props => props.theme.colors[borderColor(props.type)]};
  color: ${props => props.theme.colors[fontColor(props.type)]};
  border-radius: 6px;

  .title {
    font-weight: bold;
    margin-bottom: 8px;
  }
`;

export function Alert({ title, text, type }) {
  return (
    <StyledAlert type={type}>
      {title && <div className="title">{title}</div>}
      {text && <div className="text">{text}</div>}
    </StyledAlert>
  );
}

Alert.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['error']),
};

Alert.defaultProps = {
  title: null,
  type: 'error',
};
