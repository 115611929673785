import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { selectCrucialUserDataErrors } from '@/store/selectors/combined.selectors';
import { openModal } from '@/store/actions/modal';

/*
 This hook is responsible for doing the high level error handling if we find some of the imporant business
 user data is missing.
 Because we use certain pieces of user data to determine flows, and sometimes the data doesn't come in, due to a request error,
 we need a way to ensure that if it happens the user doesn't continue using the app before refreshing the data.
 */
export function useIncompleteDataCheck(): void {
  const crucialUserDataErrors = useSelector(selectCrucialUserDataErrors);
  const dispatch = useDispatch();

  useEffect(() => {
    if (crucialUserDataErrors.length > 0) {
      dispatch(openModal('incompleteUserData', {}, 100));
    }
  }, [crucialUserDataErrors]);
}
