import { envIsCanada, envIsMexico, envIsSweden } from '@/constants/constants';
import { getHtmlLangByLocale } from '@/shared/utility';
import React from 'react';
import { buildLocales } from '@/constants/locales';
import { Helmet } from 'react-helmet-async';

const appendLocaleToOrigin = (origin: string, locale: string): string => {
  if (origin.endsWith('/')) {
    return origin + locale;
  }
  return origin + '/' + locale;
};

const removeLocaleFromPath = (path: string, locale: string): string => {
  if (path.startsWith(`/${locale}`)) {
    return path.substring(locale.length + 1);
  }
  return path;
};

const HelmetAlternateLinks = ({
  locale,
  path,
  origin,
}: {
  locale: string;
  path: string;
  origin: string;
}): JSX.Element | null => {
  if (envIsSweden()) {
    return null;
  }

  if (envIsMexico()) {
    return null;
  }

  if (envIsCanada()) {
    if (locale === 'ca-fr') {
      return (
        <Helmet>
          <link
            rel="alternate"
            href={origin + removeLocaleFromPath(path, 'fr')}
            hrefLang={getHtmlLangByLocale('fr')}
          />
        </Helmet>
      );
    }

    return (
      <Helmet>
        <link
          rel="alternate"
          href={appendLocaleToOrigin(origin, 'fr') + path}
          hrefLang={getHtmlLangByLocale('ca')}
        />
      </Helmet>
    );
  }

  return (
    <Helmet>
      {buildLocales()
        .filter(loc => loc !== locale) // we don't need an alternate for the current locale
        .map(loc => (
          <link
            key={`alternate-${loc}`}
            rel="alternate"
            href={appendLocaleToOrigin(origin, loc) + removeLocaleFromPath(path, locale)}
            hrefLang={getHtmlLangByLocale(loc)}
          />
        ))}
    </Helmet>
  );
};

export default HelmetAlternateLinks;
