// Authentication
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

// User
export const GET_USER = 'GET_USER';
export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';

export const GET_USER_WALLET = 'GET_USER_WALLET';
export const GET_USER_WALLET_START = 'GET_USER_WALLET_START';
export const GET_USER_WALLET_SUCCESS = 'GET_USER_WALLET_SUCCESS';
export const GET_USER_WALLET_FAIL = 'GET_USER_WALLET_FAIL';

export const ADD_KYC_REQUIREMENTS = 'ADD_KYC_REQUIREMENTS';

export const GET_USER_DEPOSIT_COUNT = 'GET_USER_DEPOSIT_COUNT';
export const GET_USER_DEPOSIT_COUNT_SUCCESS = 'GET_USER_DEPOSIT_COUNT_SUCCESS';

export const CLEAR_USER = 'CLEAR_USER';

// Bonuses
export const GET_USER_BONUSES = 'GET_USER_BONUSES';
export const GET_USER_BONUSES_SUCCESS = 'GET_USER_BONUSES_SUCCESS';
export const GET_USER_BONUSES_FAIL = 'GET_USER_BONUSES_FAIL';

export const GET_AVAILABLE_BONUSES = 'GET_AVAILABLE_BONUSES';
export const GET_AVAILABLE_BONUSES_SUCCESS = 'GET_AVAILABLE_BONUSES_SUCCESS';
export const GET_AVAILABLE_BONUSES_FAIL = 'GET_AVAILABLE_BONUSES_FAIL';

// Modals
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const CLOSE_SPECIFIC_MODAL = 'CLOSE_SPECIFIC_MODAL';
export const OPEN_MODAL = 'OPEN_MODAL';
export const UPDATE_MODAL_PROPS = 'UPDATE_MODAL_PROPS';
export const SHOW_BALANCE_UPDATE = 'SHOW_BALANCE_UPDATE';
export const SHOW_USER_IS_PEP_OR_HIO_LOGOUT = 'SHOW_USER_IS_PEP_OR_HIO_LOGOUT';
export const SET_FTD_EXIT_INTENT_SHOWN = 'SET_FTD_EXIT_INTENT_SHOWN';

export const CLOSE_ALL_MODALS = 'CLOSE_ALL_MODALS';

// Drawers
export const OPEN_DRAWER = 'OPEN_DRAWER';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';

// Responsible Gaming
export const GET_USER_TAGS = 'GET_USER_TAGS';
export const GET_USER_TAGS_SUCCESS = 'GET_USER_TAGS_SUCCESS';
export const GET_USER_TAGS_ERROR = 'GET_USER_TAGS_ERROR';
export const SET_USER_EMAIL = 'SET_USER_EMAIL';
export const INCREMENT_USER_DEPOSIT_COUNT = 'INCREMENT_USER_DEPOSIT_COUNT';

export const AUTHENTICATE_WITH_CHALLENGE_TOKEN = 'AUTHENTICATE_WITH_CHALLENGE_TOKEN';
export const AUTHENTICATE_WITH_CHALLENGE_TOKEN_SUCCESS =
  'AUTHENTICATE_WITH_CHALLENGE_TOKEN_SUCCESS';
export const AUTHENTICATE_WITH_CHALLENGE_TOKEN_ERROR = 'AUTHENTICATE_WITH_CHALLENGE_TOKEN_ERROR';
