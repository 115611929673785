import { CheckIcon } from '@heroicons/react/24/solid';
import React, { useCallback, useEffect, useRef } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';
import { useIntl } from 'react-intl';

const canvasStyles: any = {
  position: 'absolute',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: 1,
};

export function OntarioMigrationSuccess(): JSX.Element {
  const intl = useIntl();
  const refAnimationInstance = useRef<any>(null);

  const getInstance = useCallback(instance => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      });
  }, []);

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }, [makeShot]);

  useEffect(() => {
    fire();
  }, []);

  return (
    <div className="absolute inset-0 flex flex-col justify-center overflow-hidden p-8 text-center">
      <div className="z-10 space-y-4">
        <div className="mx-auto -mt-20 h-14 w-14 rounded-full bg-green-500 p-3">
          <CheckIcon className="text-green-100" />
        </div>
        <h1 className="text-xl font-bold">
          {intl.formatMessage({ id: 'ontario.migration.success.header' })}
        </h1>
        <p>{intl.formatMessage({ id: 'ontario.migration.success.text' })}</p>
      </div>
      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
    </div>
  );
}
