import React from 'react';
import { useIntl } from 'react-intl';

export function PayNPlaySubmitButton(): JSX.Element {
  const intl = useIntl();

  return (
    <button type="submit" className="mobile group relative flex w-full select-none">
      <div className="absolute h-16 w-full translate-y-[2px] rounded-full bg-black/30 blur-sm transition-all group-hover:translate-y-[4px] group-active:translate-y-[1px]"></div>
      <div
        className="absolute h-16 w-full rounded-full bg-green-600"
        style={{
          background:
            'linear-gradient(to left, HSL(142, 76%, 24%) 0%, HSL(142, 76%, 36%) 14%, HSL(142, 76%, 36%) 86%, HSL(142, 76%, 24%) 100%)',
        }}
      ></div>
      <div className="relative flex h-16 w-full -translate-y-1 transform items-center justify-center space-x-4 rounded-full bg-green-500 text-white transition-all group-hover:-translate-y-[5px] group-hover:brightness-105 group-active:-translate-y-[2px]">
        <div className="flex flex-col text-center">
          <span className="text-lg font-bold leading-5">
            {intl.formatMessage({ id: 'paynplay.deposit.button.large' })}
          </span>
          <span className="text-xs font-bold text-green-200">
            {intl.formatMessage({ id: 'paynplay.deposit.button.small' })}
          </span>
        </div>
      </div>
    </button>
  );
}
