import { createSelector } from 'reselect';
import { UserRgStatsResponseDto } from '@/models/gig/user-rg-stats-response-dto.model';
import { RootState } from '@/models/root-state.model';

export const selectUserPlayStatsAndLimits = (state: RootState): UserRgStatsResponseDto | null =>
  state.userPlayStatsAndLimits.stats;

export const selectUserPlayStatsAndLimitsLimits = createSelector(
  selectUserPlayStatsAndLimits,
  statsAndLimits =>
    statsAndLimits && statsAndLimits.Limits.length > 0
      ? statsAndLimits?.Limits.sort((a, b) => a.Duration - b.Duration)
      : null,
);

export const selectUserPlayStatsAndLimitsPlayStats = createSelector(
  selectUserPlayStatsAndLimits,
  statsAndLimits => (statsAndLimits && statsAndLimits.PlayStats ? statsAndLimits.PlayStats : null),
);
