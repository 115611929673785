import React from 'react';
import { useIntl } from 'react-intl';
import { AbsoluteLink } from './AbsoluteLink';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';

const links = [
  {
    to: '/games',
    category: 'casino-lobby',
    messageId: 'games.categories.lobby',
  },
  {
    to: '/games/new-games',
    category: 'new-games',
    messageId: 'games.categories.newGames',
  },
  {
    to: '/games/live-casino',
    category: 'live-casino',
    messageId: 'games.categories.liveCasino',
  },
  {
    to: '/help',
    messageId: 'header.help',
  },
  {
    to: '/terms-and-conditions',
    messageId: 'footer.termsLinkText',
  },
  {
    to: '/privacy-policy',
    messageId: 'footer.privacyPolicyLinkText',
  },
  {
    href: 'https://luckydaysaffiliates.com/',
    messageId: 'footer.affiliateLinkText',
  },
  {
    to: '/responsible-gaming',
    messageId: 'footer.responsibleGamingLinkText',
  },
];

export function FooterNav({
  locale,
  isAuthenticated,
}: {
  locale: string;
  isAuthenticated: boolean;
}): JSX.Element {
  const intl = useIntl();

  const onClickHandler = (category: string) => {
    triggerGTag(GTagEvents.open_category, {
      openedFrom: 'footer',
      categoryName: category,
    });
  };

  function getFooterLinks(locale: string, isAuthenticated: boolean) {
    // Remove link to Live-Casino for South Africa and logged out visitors from Germany
    if ((locale === 'za' || locale === 'at') && !isAuthenticated) {
      return links.filter(link => link.category !== 'live-casino');
    } else if (locale === 'mx') {
      return links.concat([
        {
          to: '/about-us',
          messageId: 'footer.aboutUsText',
        },
        {
          to: '/payment-methods',
          messageId: 'footer.paymentProviders',
        },
      ]);
    } else {
      return links;
    }
  }

  return (
    <nav data-testid="navigation">
      <div className="grid grid-cols-2 gap-x-8 gap-y-4">
        {getFooterLinks(locale, isAuthenticated).map(link => (
          <div
            data-testid="footer-nav"
            key={link.messageId}
            className="font-bold text-white/80 transition-colors hover:text-white"
          >
            {link.category ? (
              <AbsoluteLink to={link.to} onClick={() => onClickHandler(link.category)}>
                {intl.formatMessage({ id: link.messageId })}
              </AbsoluteLink>
            ) : link.href ? (
              <a href={link.href}>{intl.formatMessage({ id: link.messageId })}</a>
            ) : link.to ? (
              <AbsoluteLink to={link.to}>{intl.formatMessage({ id: link.messageId })}</AbsoluteLink>
            ) : null}
          </div>
        ))}
      </div>
    </nav>
  );
}
