import { updateObject } from '@/shared/utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  user: {},
  userLoading: false,
  userError: null,
  wallet: {},
  walletLoading: false,
  walletError: null,
  depositCount: null,
  tags: [],
  tagsLoaded: false,
};

// Wallet
const getUserWalletStart = state =>
  updateObject(state, {
    walletLoading: true,
  });

const getUserWalletSuccess = (state, action) =>
  updateObject(state, {
    wallet: action.wallet,
    walletLoading: false,
    walletError: false,
  });

const getUserWalletFail = state =>
  updateObject(state, {
    walletLoading: false,
    walletError: true,
  });

// User
const getUserStart = state =>
  updateObject(state, {
    userLoading: true,
  });

const getUserSuccess = (state, action) => {
  return updateObject(state, {
    user: action.user,
    userLoading: false,
  });
};

const getUserFail = (state, action) =>
  updateObject(state, {
    userLoading: false,
    userError: action.error,
  });

const getUserDepositCountSuccess = (state, action) =>
  updateObject(state, {
    depositCount: action.depositCount,
  });

const getUserTagsSuccess = (state, action) =>
  updateObject(state, {
    tags: action.tags,
    tagsLoaded: true,
  });

const getUserTagsError = state =>
  updateObject(state, {
    tagsLoaded: true,
  });

const clearUser = state => updateObject(state, initialState);

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_WALLET_START:
      return getUserWalletStart(state, action);
    case actionTypes.GET_USER_WALLET_SUCCESS:
      return getUserWalletSuccess(state, action);
    case actionTypes.GET_USER_WALLET_FAIL:
      return getUserWalletFail(state, action);
    case actionTypes.GET_USER_START:
      return getUserStart(state, action);
    case actionTypes.GET_USER_SUCCESS:
      return getUserSuccess(state, action);
    case actionTypes.GET_USER_FAIL:
      return getUserFail(state, action);
    case actionTypes.GET_USER_DEPOSIT_COUNT_SUCCESS:
      return getUserDepositCountSuccess(state, action);
    case actionTypes.GET_USER_TAGS_SUCCESS:
      return getUserTagsSuccess(state, action);
    case actionTypes.GET_USER_TAGS_ERROR:
      return getUserTagsError(state);
    case actionTypes.SET_USER_EMAIL:
      return {
        ...state,
        user: {
          ...state.user,
          Email: action.email,
        },
      };
    case actionTypes.INCREMENT_USER_DEPOSIT_COUNT:
      return {
        ...state,
        depositCount: state.depositCount + 1,
      };
    case actionTypes.CLEAR_USER:
      return clearUser(state, action);
    default:
      return state;
  }
};

export default userReducer;
