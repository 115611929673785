import * as React from 'react';

import { useSelector } from 'react-redux';

import { selectGamesLoading, selectHalloweenGames } from '@/store/selectors/gamesSelectors';
import CategoryPageContent from '@/pages/games/categories/CategoryPageContent';
import { useIntl } from 'react-intl';
import { BffGamesCategory } from '@lucky7ventures/bff-types';

function CategoryHalloweenGames(): JSX.Element {
  const intl = useIntl();

  const games = useSelector(selectHalloweenGames);
  const gamesLoading = useSelector(selectGamesLoading);

  return (
    <CategoryPageContent
      header={intl.formatMessage({ id: `games.categories.halloween` })}
      subheader={intl.formatMessage({ id: `games.categories.halloween.info` })}
      category={BffGamesCategory.HalloweenGames}
      games={games}
      loading={gamesLoading}
    />
  );
}

export default CategoryHalloweenGames;
