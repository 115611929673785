import {
  GetLoginHistorySuccessAction,
  LoginHistoryActions,
  LoginHistoryActionType,
} from '../actions/loginHistoryActions';
import { DBTokenIP } from '@lucky7ventures/lucky-components';

export interface LoginHistoryStateModel {
  loginHistory: DBTokenIP[];
  loading: boolean;
  error: boolean;
}

const initialState: LoginHistoryStateModel = {
  loginHistory: [],
  loading: false,
  error: false,
};

const getLoginHistory = (state: LoginHistoryStateModel): LoginHistoryStateModel => ({
  ...state,
  loading: true,
  error: false,
});

const getLoginHistorySuccess = (
  state: LoginHistoryStateModel,
  action: GetLoginHistorySuccessAction,
): LoginHistoryStateModel => ({
  ...state,
  loginHistory: action.loginHistory,
  loading: false,
});

const getLoginHistoryError = (state: LoginHistoryStateModel): LoginHistoryStateModel => ({
  ...state,
  loading: false,
  error: true,
});

export const loginHistoryReducer = (
  state = initialState,
  action: LoginHistoryActions,
): LoginHistoryStateModel => {
  switch (action.type) {
    case LoginHistoryActionType.GetLoginHistory:
      return getLoginHistory(state);
    case LoginHistoryActionType.GetLoginHistorySuccess:
      return getLoginHistorySuccess(state, action);
    case LoginHistoryActionType.GetLoginHistoryError:
      return getLoginHistoryError(state);
    case LoginHistoryActionType.Reset:
      return initialState;
    default:
      return state;
  }
};
