import * as React from 'react';

const TrustlyLogo = ({
  width = 66,
  height = 16,
  className,
}: {
  width?: number;
  height?: number;
  className?: string;
}): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 66 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M22.0044 2.9643V11.8809H24.3078V2.9643H27.5088V0.845703H18.8034V2.9643H22.0044ZM27.1458 3.87506V11.8809V11.8875H29.3436V8.34986C29.3436 6.59426 30.2346 6.02006 31.2312 6.02006H31.9242V3.87506H31.1058C30.4128 3.87506 29.766 4.06646 29.2644 5.14226V3.87506H27.1458ZM32.9208 8.95046V3.87506H35.112V8.45546C35.112 9.39266 35.5674 10.0857 36.5046 10.0857C37.4088 10.0857 37.983 9.39266 37.983 8.46866V3.87506H40.1742V11.8875H38.0886V10.8777C37.5606 11.6763 36.7752 12.0723 35.7786 12.0723C34.023 12.0657 32.9208 10.8579 32.9208 8.95046ZM43.0188 9.19473L41.1114 9.63033C41.1972 10.5081 42.0288 12.0327 44.5764 12.0393C46.893 12.0393 47.9028 10.7061 47.9028 9.43893C47.9028 8.25753 47.256 7.42593 45.8634 7.10913L44.0352 6.68673C43.6392 6.59433 43.4148 6.40953 43.4148 6.07953C43.4148 5.68353 43.8636 5.41293 44.4708 5.41293C45.4278 5.41293 45.771 5.92773 45.8634 6.39633L47.784 5.97393C47.6916 5.08293 46.7808 3.72333 44.517 3.72333C42.3852 3.72333 41.25 4.83213 41.25 6.20493C41.25 7.55133 41.9298 8.29053 43.5336 8.64033L44.7876 8.91093C45.5466 9.07593 45.7116 9.30033 45.7116 9.60393C45.7116 9.98013 45.3024 10.2837 44.5632 10.2837C43.6062 10.2837 43.1112 9.81513 43.0188 9.19473ZM52.1136 11.8809C50.6946 11.8809 49.7442 11.0955 49.7442 9.53788V5.78908H48.3978V3.86848H49.7442V2.08648L51.9354 1.45288V3.86848H53.5524V5.78908H51.9354V9.14188C51.9354 9.72928 52.239 9.96028 52.8264 9.96028H53.6712V11.8809H52.1136ZM54.8592 0.852307V11.8809V11.8875H57.0504V0.852307H54.8592ZM63.657 3.87506H66L62.6604 14.7585H60.3174L61.2414 11.8875H60.0336L57.8292 3.87506H60.1722L61.8222 10.0725L63.657 3.87506ZM5.1414 5.68351H0V0.852307H14.6982V5.68351H10.1244V11.8809H5.1414V9.78211L9.2466 5.68351L5.1414 1.57831V5.68351Z"
      fill="currentColor"
    />
  </svg>
);

export default TrustlyLogo;
