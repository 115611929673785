import * as React from 'react';

function FlameIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M13 2.932c0-.93-1.277-1.28-1.84-.51C6 9.495 13.333 9.813 13.333 13.25c0 1.392-1.213 2.518-2.702 2.5C9.166 15.732 8 14.587 8 13.213v-3.34c0-.848-1.103-1.26-1.726-.645C5.158 10.327 4 12.208 4 14.5c0 4.136 3.589 7.5 8 7.5s8-3.364 8-7.5c0-6.652-7-7.539-7-11.568z"
        fill="url(#prefix__paint0_linear)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={12}
          y1={2}
          x2={12}
          y2={22}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF0A0A" />
          <stop offset={0.901} stopColor="#FFE600" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default FlameIcon;
