import Cookies from 'universal-cookie';

const cookies = new Cookies();

export enum CookiesNames {
  Btag = 'referrer',
  Token = 'luckydays_uid',
}

const MAX_COOKIE_SIZE = 50; // 50 chars

const CookiesManager = {
  getBtagCookie: (): string => {
    return cookies.get(CookiesNames.Btag);
  },
  setBtagCookie: (value: string) => {
    if (value.length <= MAX_COOKIE_SIZE) {
      const expires = new Date();
      expires.setDate(expires.getDate() + 30);
      cookies.set(CookiesNames.Btag, value, { path: '/', expires });
    }
  },
  setTokenCookie: (value: string) => {
    if (value.length <= MAX_COOKIE_SIZE) {
      cookies.set(CookiesNames.Token, value, { path: '/' });
    }
  },
  getTokenCookie: () => {
    return cookies.get(CookiesNames.Token);
  },
  removeTokenCookie: () => {
    cookies.remove(CookiesNames.Token, { path: '/' });
  },
};

export default CookiesManager;
