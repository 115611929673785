import { WpManualBonusModel } from '@/models/wp-manual-bonus.model';
import {
  ManualBonusesAction,
  ManualBonusesActionType,
  GetManualBonusesSuccessAction,
} from '../actions/manualBonusesActions';

export interface ManualBonusesStateModel {
  manualBonuses: WpManualBonusModel[];
  loadedLocale: string | null;
  success: boolean;
  error: boolean;
}

const initialState: ManualBonusesStateModel = {
  manualBonuses: [],
  loadedLocale: null,
  success: false,
  error: false,
};

const getManualBonuses = (state: ManualBonusesStateModel) => ({
  ...state,
  error: false,
});

const getManualBonusesSuccess = (
  state: ManualBonusesStateModel,
  action: GetManualBonusesSuccessAction,
) => ({
  ...state,
  manualBonuses: action.manualBonuses,
  loadedLocale: action.locale,
});

const getManualBonusesError = (state: ManualBonusesStateModel): ManualBonusesStateModel => ({
  ...state,
  error: true,
});

export const manualBonusesReducer = (
  state = initialState,
  action: ManualBonusesAction,
): ManualBonusesStateModel => {
  switch (action.type) {
    case ManualBonusesActionType.Get:
      return getManualBonuses(state);
    case ManualBonusesActionType.Success:
      return getManualBonusesSuccess(state, action);
    case ManualBonusesActionType.Error:
      return getManualBonusesError(state);
    default:
      return state;
  }
};
