import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/models/root-state.model';
import { isRegulatedLocale } from '@/shared/utility';
import { openModal } from '@/store/actions/modal';

export function useOpenLogin(): () => void {
  const locale = useSelector((state: RootState) => state.common.locale);
  const dispatch = useDispatch();

  return () => {
    if (isRegulatedLocale(locale)) {
      dispatch(openModal('paynplay'));
      return;
    }

    dispatch(openModal('login'));
  };
}
