import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { AbsoluteNavigate } from '../AbsoluteNavigate';
import Spinner from '../UI/Spinner';
import { CDN_BASE, CDN_IMAGES_COMMON_PATH } from '@/constants/constants';
import { selectIsAuthenticated } from '@/store/selectors/authSelectors';
import { closeAllModals } from '@/store/actions/modal';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { logout } from '@/store/actions/auth';

const StyledLogout = styled.section`
  height: 100vh;
  width: 100%;
  position: relative;

  .image-wrapper {
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      object-fit: cover;
      pointer-events: none;
      object-position: 100% 100%;
    }

    &:after {
      content: ' ';
      background-size: cover;
      position: absolute;
      inset: 0;
      object-fit: cover;
      pointer-events: none;
      object-position: 100% 100%;
      background: rgba(0, 0, 0, 0.5);
    }
  }
`;

function Logout() {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const dispatch = useDispatch();

  useEffect(() => {
    // on Logout we want to close all modals except the login modal as we offtenly have a situation where we log out the user and present some information to him in the login modal
    dispatch(closeAllModals(['login']));
    triggerGTag(GTagEvents.logout);
    dispatch(logout());
  }, []);

  if (!isAuthenticated) {
    return <AbsoluteNavigate to="/" replace={true} />;
  }

  return (
    <StyledLogout>
      <div className="image-wrapper">
        <img
          src={`${CDN_BASE}/cdn-cgi/image/fit=cover,width=1440,height=900,gravity=1x0,format=auto${CDN_IMAGES_COMMON_PATH}/luckydays-beach.jpg`}
          alt="Lucky the cat on his way to the beach"
        />
      </div>
      <Spinner absolute color="white" />
    </StyledLogout>
  );
}

export default Logout;
