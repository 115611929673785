export enum GAME_CATEGORY {
  ALL_GAMES = 'allGames',
  HOME_GAMES = 'homeGames',
  JACKPOTS = 'jackpots',
  LAST_PLAYED = 'lastPlayed',
  LIVE_CASINO = ' liveCasino',
  NEW_GAMES = ' newGames',
  RECOMMENDED = ' recommended',
  SEARCH = 'search',
  SLOTS = 'slots',
  TABLE_GAMES = 'tableGames',
}
