import React from 'react';

import { HomeIcon } from '@heroicons/react/24/outline';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { RootState } from '@/models/root-state.model';
import { useXmas } from '@/hooks/useXmas';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { IS_HALLOWEEN, IS_SWEDEN } from '@/constants/constants';
import { AbsoluteNavLink } from '@/components/AbsoluteNavLink';
import { isLiveCasinoEnabled } from '@/utils/logic.utils';

const StyledGameCategoryNav = styled.nav<{ isAuthenticated: boolean; isSweden: boolean }>`
  display: block;
  position: sticky;
  /* width: 100%; */
  height: 64px;
  top: 0;
  z-index: 100;
  overflow: hidden;

  .end-shadow {
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(0%, rgba(255, 255, 255, 0)),
      color-stop(100%, rgba(255, 255, 255, 1))
    );
    border-bottom-right-radius: 8px;
    height: 64px;
    position: absolute;
    pointer-events: none;
    right: 0;
    top: 0;
    width: 24px;
    z-index: 3;
  }

  ${props =>
    !props.isAuthenticated &&
    css`
      top: ${props.isSweden ? '84px' : '56px'};
    `} @media(
  min-width: ${props => props.theme.breakpoints.small}) {
    top: ${props => (props.isSweden ? '92px' : '64px')};

    ${props =>
      !props.isAuthenticated &&
      css`
        top: ${props.isSweden ? '92px' : '64px'};
      `}
  }

  .wrapper {
    margin: 0;
    padding: 0;
    height: 64px;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    background: white;
    text-align: center;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      display: none;
    }
  }

  ul {
    display: inline-flex;
    white-space: nowrap;
    overflow: hidden;
    overflow-x: auto;
    margin: 0;
    padding: 0 8px;
    text-align: center;
    height: 100%;
    align-items: center;

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      padding: 0 24px;
    }

    li {
      display: inline-block;
      list-style: none;
      margin: 0 8px;

      a {
        display: inline-block;
        text-decoration: none;
        background: none;
        border: none;
        position: relative;
        padding: 8px 16px;
        font-size: 14px;
        font-weight: bold;
        transition: all 0.15s ease;
        cursor: pointer;
        background: rgba(0, 0, 0, 0.05);
        color: rgba(0, 0, 0, 0.8);
        border-radius: 50vh;

        &.icon-button {
          position: relative;
          padding-left: 40px;

          svg {
            position: absolute;
            top: 7px;
            left: 14px;
            height: 20px;
            width: 20px;
            opacity: 0.6;
          }
        }

        &:hover {
          background: rgba(0, 0, 0, 0.15);
        }

        &.active {
          background: ${props => props.theme.colors.blue1};
          color: white;
        }
      }
    }
  }
`;

export function GameCategoryNav(): JSX.Element {
  const intl = useIntl();
  const isAuthenticated = useSelector((state: RootState) => state.auth.token !== null);
  const locale = useSelector((state: RootState) => state.common.locale);
  const location = useLocation();
  const openFrom = location.pathname;
  const isXmas = useXmas();

  const tagManagerHandler = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    const target = e.target as HTMLAnchorElement;
    const categoryOpened = target.id;
    triggerGTag(GTagEvents.open_category, {
      openedFrom: openFrom,
      categoryName: categoryOpened,
    });
  };

  return (
    <StyledGameCategoryNav isAuthenticated={isAuthenticated} isSweden={IS_SWEDEN}>
      <div className="end-shadow" />
      <div className="wrapper">
        <ul>
          <li>
            <AbsoluteNavLink
              to="/games"
              className="icon-button"
              id="casino-lobby"
              onClick={e => tagManagerHandler(e)}
              end
            >
              <HomeIcon />
              {intl.formatMessage({ id: 'games.categories.lobby' })}
            </AbsoluteNavLink>
          </li>
          {isAuthenticated && (
            <li>
              <AbsoluteNavLink
                to="/games/last-played"
                id="last-played"
                onClick={e => tagManagerHandler(e)}
              >
                {intl.formatMessage({ id: 'games.categories.lastPlayed' })}
              </AbsoluteNavLink>
            </li>
          )}
          <li>
            <AbsoluteNavLink
              to="/games/recommended"
              id="recommended"
              onClick={e => tagManagerHandler(e)}
            >
              {intl.formatMessage({ id: 'games.categories.recommended' })}
            </AbsoluteNavLink>
          </li>
          {IS_HALLOWEEN && (
            <li>
              <AbsoluteNavLink
                to="/games/halloween"
                id="halloween"
                onClick={e => tagManagerHandler(e)}
              >
                {intl.formatMessage({ id: 'games.categories.halloween' })}
              </AbsoluteNavLink>
            </li>
          )}
          {isXmas && (
            <li>
              <AbsoluteNavLink
                to="/games/winter-games"
                id="winter-games"
                onClick={e => tagManagerHandler(e)}
              >
                {intl.formatMessage({ id: 'games.categories.winterGames' })}
              </AbsoluteNavLink>
            </li>
          )}
          <li>
            <AbsoluteNavLink
              to="/games/new-games"
              id="new-games"
              onClick={e => tagManagerHandler(e)}
            >
              {intl.formatMessage({ id: 'games.categories.newGames' })}
            </AbsoluteNavLink>
          </li>
          {/* Hide Live Casino for South Africa */}
          {/* Hide Live Casino Category for Germany (when logged out) */}
          {!isLiveCasinoEnabled(locale, isAuthenticated) ? null : (
            <li>
              <AbsoluteNavLink
                to="/games/live-casino"
                id="live-casino"
                onClick={e => tagManagerHandler(e)}
              >
                {intl.formatMessage({ id: 'games.categories.liveCasino' })}
              </AbsoluteNavLink>
            </li>
          )}
          <li>
            <AbsoluteNavLink to="/games/slots" id="slots" onClick={e => tagManagerHandler(e)}>
              {intl.formatMessage({ id: 'games.categories.slots' })}
            </AbsoluteNavLink>
          </li>
          <li>
            <AbsoluteNavLink to="/games/jackpots" id="jackpots" onClick={e => tagManagerHandler(e)}>
              {intl.formatMessage({ id: 'games.categories.jackpots' })}
            </AbsoluteNavLink>
          </li>
          <li>
            <AbsoluteNavLink
              to="/games/table-games"
              id="table-games"
              onClick={e => tagManagerHandler(e)}
            >
              {intl.formatMessage({ id: 'games.categories.tableGames' })}
            </AbsoluteNavLink>
          </li>
          <li>
            <AbsoluteNavLink
              to="/games/all-games"
              id="all-games"
              onClick={e => tagManagerHandler(e)}
            >
              {intl.formatMessage({ id: 'games.categories.allGames' })}
            </AbsoluteNavLink>
          </li>
        </ul>
      </div>
    </StyledGameCategoryNav>
  );
}
