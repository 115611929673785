import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { isEmpty, isRegulatedLocale } from '@/shared/utility';
import { isValidEmail } from '@/utils/utils';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { RootState } from '@/models/root-state.model';
import { selectActiveDepositLimit, selectLimitsLoading } from '@/store/selectors/limitsSelectors';
import { IS_SWEDEN } from '@/constants/constants';
import { LimitDuration } from '@lucky7ventures/lucky-components';
import { selectUser } from '@/store/selectors/userSelectors';

export function useBankIdProfile(): { isCompletedBankIdProfile: boolean } {
  const locale = useSelector(selectLocale);
  const limitsLoading = useSelector(selectLimitsLoading);
  const user = useSelector(selectUser);
  const [isCompletedBankIdProfile, setIsCompletedBankIdProfile] = useState(true);
  const is24hLimitSet = useSelector((state: RootState) =>
    selectActiveDepositLimit(state, LimitDuration._24Hours),
  );
  const is1WeekLimitSet = useSelector((state: RootState) =>
    selectActiveDepositLimit(state, LimitDuration._1Week),
  );
  const is1MonthLimitSet = useSelector((state: RootState) =>
    selectActiveDepositLimit(state, LimitDuration._1Month),
  );

  useEffect(() => {
    if (!isRegulatedLocale(locale)) {
      setIsCompletedBankIdProfile(true);
      return;
    }

    if (isEmpty(user)) {
      setIsCompletedBankIdProfile(true);
      return;
    }

    // For Sweden build the user needs to set 3 deposit limits before playing games
    if (IS_SWEDEN && !limitsLoading && !(is24hLimitSet && is1WeekLimitSet && is1MonthLimitSet)) {
      setIsCompletedBankIdProfile(false);
      return;
    }

    if (user?.Mobile && isValidEmail(user.Email)) {
      setIsCompletedBankIdProfile(true);
      return;
    }

    setIsCompletedBankIdProfile(false);
  }, [locale, user, is24hLimitSet, is1WeekLimitSet, is1MonthLimitSet, limitsLoading]);

  return { isCompletedBankIdProfile };
}
