import { createSelector } from 'reselect';

import { RootState } from '@/models/root-state.model';

export const selectModalQueue = (
  state: RootState,
): {
  modalName: string;
  modalProps: any;
  order: number;
}[] => state.modal.queue;

export const selectModalQueueOrdered = createSelector(selectModalQueue, queue => {
  return queue.sort((a, b) => b.order - a.order);
});

export const selectShowBalanceUpdate = (state: RootState) => state.modal.showBalanceUpdate;
export const selectShowUserIsPepOrHioLogout = (state: RootState): boolean =>
  state.modal.showUserIsPepHioLogout;
export const selectIsFtdExitIntentShown = (state: RootState): boolean =>
  state.modal.showFtdExitIntent;
