import * as actionTypes from '@/store/actions/actionTypes';
import { updateObject } from '@/shared/utility';
import { PublicBonusesObject } from '@lucky7ventures/lucky-components';
import { BffAvailableBonusesResponse } from '@lucky7ventures/bff-types';

export interface BonusesState {
  bonuses: PublicBonusesObject[];
  bonusesLoading: boolean;
  availableBonuses: BffAvailableBonusesResponse;
  availableBonusesLoading: boolean;
  availableBonusesFetched: boolean;
}

const initialState: BonusesState = {
  bonuses: [],
  bonusesLoading: false,
  availableBonuses: [],
  availableBonusesLoading: false,
  availableBonusesFetched: false,
};

const getUserBonusesSuccess = (state: any, action: any) =>
  updateObject(state, {
    bonuses: action.bonuses,
    bonusesLoading: false,
  });

const getUserBonusesFail = (state: any) =>
  updateObject(state, {
    bonusesLoading: false,
  });

const getAvailableBonusesSuccess = (state: any, action: any) =>
  updateObject(state, {
    availableBonuses: action.availableBonuses,
    availableBonusesLoading: false,
    availableBonusesFetched: true,
  });

const getAvailableBonusesFail = (state: any) =>
  updateObject(state, {
    availableBonusesLoading: false,
    availableBonusesFetched: true,
  });

const bonusesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.GET_USER_BONUSES_SUCCESS:
      return getUserBonusesSuccess(state, action);
    case actionTypes.GET_USER_BONUSES_FAIL:
      return getUserBonusesFail(state);
    case actionTypes.GET_AVAILABLE_BONUSES_SUCCESS:
      return getAvailableBonusesSuccess(state, action);
    case actionTypes.GET_AVAILABLE_BONUSES_FAIL:
      return getAvailableBonusesFail(state);
    default:
      return state;
  }
};

export default bonusesReducer;
