import React from 'react';

import { useIntl } from 'react-intl';

import { TranslatedRichText } from './TranslatedRichText';

export function OntarioWelcomeModal({ closeModal }: { closeModal: () => void }): JSX.Element {
  const intl = useIntl();

  return (
    <div className="relative w-full max-w-sm rounded-2xl bg-white p-8 text-center">
      <div className="space-y-4">
        <img
          src="https://images.luckydays.com/cdn-cgi/image/fit=contain,width=102,height=118,format=auto/assets/sitting-cat.png"
          alt="Lucky the Cat waving at you"
          className="mx-auto"
        />
        <h1 className="!mb-4 text-xl">{intl.formatMessage({ id: 'ontario.welcome.header' })}</h1>
        <TranslatedRichText id="ontario.welcome.text" />
      </div>
      <div className="mt-8 space-y-4">
        <button
          type="button"
          onClick={() => {
            closeModal();
          }}
          className="w-full rounded-full bg-cyan-500 py-3 px-4 text-center font-bold text-white shadow-lg transition hover:bg-cyan-600 hover:shadow-xl"
        >
          {intl.formatMessage({ id: 'ontario.welcome.button' })}
        </button>
      </div>
    </div>
  );
}
