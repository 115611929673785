import { Action } from 'redux';
import { WpManualBonusModel } from '@/models/wp-manual-bonus.model';

export enum ManualBonusesActionType {
  Get = 'GET_MANUAL_BONUSES',
  Success = 'GET_MANUAL_BONUSES_SUCCESS',
  Error = 'GET_MANUAL_BONUSES_ERROR',
}

export type GetManualBonusesAction = Action<ManualBonusesActionType.Get> & {
  locale: string;
};

export type GetManualBonusesSuccessAction = Action<ManualBonusesActionType.Success> & {
  manualBonuses: WpManualBonusModel[];
  locale: string;
};

export type GetManualBonusesErrorAction = Action<ManualBonusesActionType.Error>;

export type ManualBonusesAction =
  | GetManualBonusesAction
  | GetManualBonusesSuccessAction
  | GetManualBonusesErrorAction;

export const getManualBonuses = (locale: string): GetManualBonusesAction => ({
  type: ManualBonusesActionType.Get,
  locale,
});

export const getManualBonusesSuccess = (
  manualBonuses: WpManualBonusModel[],
  locale: string,
): GetManualBonusesSuccessAction => ({
  type: ManualBonusesActionType.Success,
  manualBonuses,
  locale,
});

export const getManualBonusesError = (): GetManualBonusesErrorAction => ({
  type: ManualBonusesActionType.Error,
});
