import React from 'react';
import styled from 'styled-components';

const StyledSmallLogo = styled.svg`
  padding: 2px;
  fill: ${props => props.theme.colors.blue3};
  transition: all 0.2s ease;
`;

function SmallLogo() {
  return (
    <StyledSmallLogo
      width="46px"
      height="45px"
      viewBox="0 0 46 45"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M0,0.753138075 L0,44.0585774 L19.4734411,44.0585774 L19.4734411,37.4686192 L7.63664357,37.4686192 L7.63664357,0.753138075 L0,0.753138075 Z M32.3779831,38.2217573 L29.8960739,38.2217573 L29.8960739,6.9665272 L32.187067,6.9665272 C36.1963048,6.9665272 37.7236336,8.47280335 37.7236336,13.1799163 L37.7236336,32.0083682 C37.7236336,36.5271967 36.3872209,38.2217573 32.3779831,38.2217573 Z M33.5234796,0.753138075 L22.2594303,0.753138075 L22.2594303,44.0585774 L33.5234796,44.0585774 C41.1601232,44.0585774 45.3602771,39.916318 45.3602771,30.8786611 L45.3602771,13.9330544 C45.3602771,4.89539749 41.3510393,0.753138075 33.5234796,0.753138075 Z"
      />
    </StyledSmallLogo>
  );
}

export default SmallLogo;
