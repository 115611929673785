import {
  all,
  AllEffect,
  ForkEffect,
  put,
  retry,
  StrictEffect,
  takeEvery,
} from 'redux-saga/effects';
import { getLimitsError, getLimitsSuccess, LimitsActionType } from '../actions/limitsActions';
import { AxiosResponse } from 'axios';
import { isAxiosResponse } from '@/utils/type-guards';
import { LimitDuration, LimitType } from '@lucky7ventures/lucky-components';
import { GetUserLimitModel } from '@/models/gig/get-user-limit.model';
import { envIsSweden } from '@/constants/constants';
import ApiService from '../../shared/apiService';

/*
 Due to business logic for the Sweden flow where GiG sets the initial deposit
 limits upon user registration to the maximum values - so it can count the first
 deposit towards the limit we want to filter out those default set limits from the Frontend and
 consider them not-set.
 */
const filterLimits = (limits: GetUserLimitModel): GetUserLimitModel => {
  if (!envIsSweden()) {
    // if environment is not SWEDEN we want to keep the limits as-is
    return limits;
  }

  return {
    ActiveLimits: limits.ActiveLimits.filter(limit => {
      if (limit.LimitType !== LimitType.Deposit) {
        // we want to keep all the limits that are not of type Deposit
        return true;
      }
      if (limit.Duration === LimitDuration._24Hours && limit.Amount === 500001) {
        return false;
      }
      if (limit.Duration === LimitDuration._1Week && limit.Amount === 1000000) {
        return false;
      }
      if (limit.Duration === LimitDuration._1Month && limit.Amount === 1000000) {
        return false;
      }
      return true;
    }),
    FutureLimits: limits.FutureLimits,
  };
};

export function* getLimitsSaga(): Generator<StrictEffect, void, AxiosResponse<any>> {
  try {
    const response = yield retry(3, 3000, ApiService.getLimits);
    if (isAxiosResponse(response)) {
      yield put(getLimitsSuccess(filterLimits(response.data)));
    } else {
      yield put(getLimitsError());
    }
  } catch (error) {
    yield put(getLimitsError());
  }
}

export function* watchLimits(): Generator<AllEffect<ForkEffect<never>>, void> {
  yield all([takeEvery(LimitsActionType.GetLimits, getLimitsSaga)]);
}
