import React from 'react';
import { classNames } from '@/utils/utils';

export function Button({
  onClickHandler,
  isLoading = false,
  text,
  disabled = false,
  type = 'button',
  btnType = 'primary',
}: {
  onClickHandler?: () => void;
  isLoading?: boolean;
  text: string;
  disabled?: boolean;
  type?: 'button' | 'submit';
  btnType?: 'primary' | 'secondary';
}): JSX.Element {
  return (
    <button
      type={type === 'submit' ? 'submit' : 'button'}
      className={classNames(
        'relative w-full rounded-3xl p-3 text-white transition disabled:cursor-not-allowed disabled:opacity-50',
        btnType === 'primary' ? 'bg-cyan-500 hover:bg-cyan-600' : '',
        btnType === 'secondary' ? 'bg-white/20 hover:bg-white/30' : '',
      )}
      onClick={onClickHandler}
      disabled={isLoading || disabled}
    >
      {isLoading && (
        <div className="flex justify-center items-center relative">
          <div className=" absolute w-6 h-6 top-0 rounded-full border-4 border-white/20 border-l-white animate-spin" />
        </div>
      )}
      <span className={`text-md font-bold leading-6 ${isLoading ? 'opacity-0' : ''}`}>{text}</span>
    </button>
  );
}
