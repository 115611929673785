import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { selectIsAuthenticated } from '@/store/selectors/authSelectors';
import { selectShowUserIsPepOrHioLogout } from '@/store/selectors/modalSelectors';
import { openModal, setShowUserIsPepOrHioLogout } from '@/store/actions/modal';
import { openDrawer } from '@/store/actions/drawer';

export function usePepHioHook() {
  const showuserIsPepOrHioLogout = useSelector(selectShowUserIsPepOrHioLogout);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    if (!isAuthenticated && showuserIsPepOrHioLogout) {
      dispatch(
        openModal('basicModal', {
          title: intl.formatMessage({ id: 'detailsReverification.userBlock.title' }),
          body: intl.formatMessage({ id: 'detailsReverification.userBlock.text' }),
          btnText: intl.formatMessage({ id: 'detailsReverification.userBlock.cta' }),
          onClose: () => {
            dispatch(setShowUserIsPepOrHioLogout(false));
          },
          onBtnClick: () => {
            dispatch(setShowUserIsPepOrHioLogout(false));
            dispatch(openDrawer('help'));
          },
        }),
      );
    }
  }, [showuserIsPepOrHioLogout, isAuthenticated]);
}
