/* eslint-disable global-require */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import smoothscroll from 'smoothscroll-polyfill';
import 'url-search-params-polyfill';
import 'scrollyfills';

// Initialize smoothscroll polyfill
smoothscroll.polyfill();

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill-locales');
}
