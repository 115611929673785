import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

export function useFastRegTag() {
  const tags = useSelector(state => state.user.tags);
  const [hasFastRegTag, setHasFastRegTag] = useState(false);

  useEffect(() => {
    setHasFastRegTag(tags.some(tag => tag.Name === 'Fastreg'));
  }, [tags]);

  return { hasFastRegTag };
}
