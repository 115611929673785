import React, { FocusEvent, useCallback, useState } from 'react';

import { Listbox } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import { useField } from 'formik';
import classNames from 'classnames';

import Spinner from './UI/Spinner';

interface Option {
  value: string;
  label: string;
}

interface IFormSelectProps {
  defaultValue?: Option;
  options: Option[];
  isLoading?: boolean;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  name: string;
  label: string;
  onValidationError?: (error: string) => void;
  disabled?: boolean;
}

const FormSelect = ({
  defaultValue,
  options,
  isLoading,
  onChange,
  onFocus,
  name,
  label,
  onValidationError,
  disabled = false,
}: IFormSelectProps): JSX.Element => {
  const [field, meta, helpers] = useField<string>(name);
  const [isEmptyValidationError, setIsEmptyValidationError] = useState(false);

  const getActiveOption = useCallback((): Option => {
    if (options && options.length > 0) {
      const active = options.find(o => o.value === field.value);
      if (active) return active;
    }
    if (defaultValue) {
      return defaultValue;
    }
    return {
      value: '',
      label: '',
    };
  }, [options, field.value]);

  const handleOnBlur = (event: FocusEvent<HTMLButtonElement>) => {
    if (!field.value) {
      onValidationError?.(meta.error!);
      setIsEmptyValidationError(true);
    }
    field.onBlur(event);
  };

  const handleOnChange = (option: Option) => {
    helpers.setValue(option.value);
    onChange?.(option.value);
    setIsEmptyValidationError(false);
  };

  return (
    <Listbox value={getActiveOption()} onChange={handleOnChange} disabled={disabled || isLoading}>
      {({ open }) => (
        <div id={`e2e_${field.name}Select`} className="relative mb-8">
          <span
            className={classNames(
              `absolute left-2 z-[2] top-[1px] px-2 transition-transform text-blue-blueMedium text-base font-semibold
              duration-150 pointer-events-none origin-top-left translate-y-[60%]`,
              { '!translate-y-1/3 scale-75': (field.value || open) && !isLoading },
              { 'text-gray-400': disabled || isLoading },
            )}
          >
            {label}
          </span>
          <Listbox.Button
            onBlur={(event: FocusEvent<HTMLButtonElement>) => !open && handleOnBlur(event)}
            className={classNames(
              `relative w-full cursor-pointer rounded-md h-[56px] pl-3.5 pr-10 text-left bg-white shadow-lg ring-0
              disabled:cursor-not-allowed`,
              { 'ring-red-500 ring-1': isEmptyValidationError || (meta.error && meta.touched) },
            )}
          >
            {field.value && !isLoading && (
              <span
                className={classNames('max-h-fit pt-4 block truncate text-blue-blueMedium', {
                  '!text-gray-400': disabled,
                })}
              >
                {getActiveOption().label}
              </span>
            )}
            {isLoading && (
              <div className="w-full flex justify-end items-center [&_.lucky-spinner]:m-0">
                <Spinner width={30} height={30} />
              </div>
            )}
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon className="h-5 w-5 text-blue-blueMedium" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Listbox.Options
            onFocus={onFocus}
            className="absolute z-10 mt-1 max-h-60 w-full space-y-1 overflow-auto rounded-md bg-white p-2 shadow-lg"
          >
            {options.map(option => (
              <Listbox.Option
                value={option}
                className={({ active, selected }) =>
                  classNames(
                    'm-0 cursor-pointer rounded-md px-2 py-1.5 text-left bg-white',
                    {
                      '!bg-blue-blue font-bold text-white':
                        selected || field.value === option.value,
                    },
                    { '!bg-gray-100': active && !(selected || field.value === option.value) },
                  )
                }
                key={option.value}
              >
                {option.label}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </div>
      )}
    </Listbox>
  );
};

export default FormSelect;
