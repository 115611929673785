import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { isEmpty } from '@/shared/utility';
import BonusCard from '../bonus/BonusCard';
import { selectActiveBonusesWithContent } from '@/store/selectors/bonusesSelectors';

const StyledActiveBonuses = styled.div`
  max-width: 720px;
  margin: 0 auto;
  padding: 48px 16px;

  @media (min-width: ${props => props.theme.breakpoints.small}) {
    padding: 64px 32px;
  }

  .intro {
    margin-bottom: 3rem;

    h1 {
      font-size: 22px;
    }
  }

  .bonuses {
    display: flex;
    justify-content: space-between;
    flex-flow: column;
    min-height: 480px;
    grid-gap: 1rem;
    align-items: center;

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      align-items: stretch;
      flex-flow: row;
      grid-gap: 2rem;
    }

    .bonus-card {
      margin: 0;
    }
  }
`;

export default function ActiveBonuses() {
  const bonuses = useSelector(selectActiveBonusesWithContent);
  const intl = useIntl();
  return (
    <StyledActiveBonuses>
      <div className="intro">
        <h1 className="mb-4">{intl.formatMessage({ id: 'activeBonuses.header' })}</h1>
        <p>{intl.formatMessage({ id: 'activeBonuses.text' })}</p>
      </div>
      <div className="bonuses">
        {!isEmpty(bonuses) ? (
          bonuses.map(bonus => (
            <BonusCard
              key={bonus?.BonusId}
              id={`bonus-card-${bonus?.BonusId}`}
              image={bonus?.Content?.image}
              bonusCode={bonus?.PromoCode}
              userBonusId={bonus?.UserBonusId}
              bonusTerms={bonus?.Content?.bonus_terms}
              bonusType={bonus?.Content?.type}
              desc={bonus?.Content?.description}
              availableUntilText={bonus?.Content?.available_until}
              name={bonus?.Content?.name || bonus?.Name}
              redeemTypeId={1336}
              desktopGameLink={bonus?.Content?.desktop_game_link}
              mobileGameLink={bonus?.Content?.mobile_game_link}
              shortTerms={bonus?.Content?.short_terms}
              realBonusMoney={bonus?.RealBonusMoney}
              bonusMoney={bonus?.BonusMoney}
            />
          ))
        ) : (
          <p>{intl.formatMessage({ id: 'activeBonuses.noActiveBonuses' })}</p>
        )}
      </div>
    </StyledActiveBonuses>
  );
}
