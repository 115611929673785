import { GameCardVM } from '@/models/vm/game-card-vm.model';

export function searchGames(search: string, games: GameCardVM[]): GameCardVM[] {
  if (!games || games.length === 0) {
    return [];
  }

  if (search === '') {
    return [];
  }

  return games.filter(game => {
    const query = search.toLowerCase();

    return (
      game.name.toLowerCase().indexOf(query) !== -1 ||
      game.shortDesc.toLowerCase().indexOf(query) !== -1
    );
  });
}
