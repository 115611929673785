import React, { useEffect, useState } from 'react';

import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from '@/components/UI/Buttons/Button';
import { PAY_AND_PLAY_MESSAGE_ORIGIN } from '@/constants/constants';
import { useTranslate } from '@/hooks/useTranslate';
import { useUpdateUser } from '@/hooks/useUpdateUser';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { selectLastDeposit } from '@/store/selectors/lastDepositSelectors';
import { selectUserBtag } from '@/store/selectors/combined.selectors';
import { PNP_CURRENCY } from '@/constants/paynplayConstants';
import { triggerNetreferFTD, triggerNetreferSignup } from '@/shared/netrefer';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { authenticateWithChallengeToken } from '@/store/actions/auth';

const StyledPayNPlayIframe = styled.div`
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;

  .alert {
    padding: 4rem 2rem;
    text-align: center;

    .icon {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      margin: 0 auto 2rem;

      svg {
        height: 2rem;
        width: 2rem;
      }
    }

    h3 {
      margin-bottom: 1.5rem;
      margin-top: 0;
    }

    p {
      margin-bottom: 2rem;
    }

    &.success {
      .icon {
        background: ${props => props.theme.colors.green};
      }
    }

    &.error {
      .icon {
        background: ${props => props.theme.colors.red};
      }
    }
  }

  > iframe {
    height: 100%;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
  }
`;

/* eslint-disable jsx-a11y/iframe-has-title */
export function PayNPlayIframe({
  url,
  clearIframe,
  closeModal,
  closeDrawer,
  isDeposit,
  depositAmount,
  login,
}) {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const authError = useSelector(state => state.auth.authenticateWithChallengeTokenError);
  const authSuccess = useSelector(state => state.auth.authenticateWithChallengeTokenSuccess);
  const { translateError } = useTranslate();
  const { updateUserWithTimeout } = useUpdateUser();
  const locale = useSelector(selectLocale);
  const btag = useSelector(selectUserBtag);
  const intl = useIntl();
  const [depositSuccess, setDepositSuccess] = useState(false);
  const lastDeposit = useSelector(selectLastDeposit);

  useEffect(() => {
    const handler = event => {
      // filter out the message events that don't come from our origin
      if (event.origin !== PAY_AND_PLAY_MESSAGE_ORIGIN) {
        return;
      }

      const { success, challengeToken, isNewRegistration, errorCode, userId } = event.data;

      if (errorCode) {
        setError(errorCode);
        return;
      }

      // if error is not included and success is false we assume the user has pressed the close button
      if (!success) {
        clearIframe();
        return;
      }

      if (challengeToken && !isDeposit) {
        dispatch(authenticateWithChallengeToken(challengeToken, isNewRegistration));

        if (isNewRegistration) {
          triggerGTag(GTagEvents.pnp_registration, {
            userId,
          });
          triggerNetreferSignup(btag, userId, locale);

          if (depositAmount > 0) {
            triggerNetreferFTD(btag, userId, locale);
            triggerGTag(GTagEvents.pnp_first_deposit, {
              amount: depositAmount,
              currency: PNP_CURRENCY,
            });
          }
        } else {
          triggerGTag(GTagEvents.pnp_deposit, {
            amount: depositAmount,
            currency: PNP_CURRENCY,
          });
          // Add localstorage for opening the player stats and limits modal
          localStorage.setItem('LD_SHOW_STATS_AND_LIMITS', true);
        }
        return;
      }

      if (challengeToken && isDeposit) {
        // when the pnp flow is a deposit flow from the cashier, we just need to update the user after 5 seconds
        setDepositSuccess(true);
        updateUserWithTimeout(5000);

        // this means that the user didn't have prior deposits and it's his first deposit
        if (!lastDeposit) {
          triggerNetreferFTD(btag, userId, locale);
          triggerGTag(GTagEvents.pnp_first_deposit, {
            amount: depositAmount,
            currency: PNP_CURRENCY,
          });
        }
      }
    };

    window.addEventListener('message', handler);

    return () => window.removeEventListener('message', handler);
  }, []);

  useEffect(() => {
    if (authError) {
      setError(translateError(authError));
    }
  }, [authError]);

  useEffect(() => {
    if (authSuccess) {
      closeModal();
    }
  }, [authSuccess]);

  if (error) {
    return (
      <StyledPayNPlayIframe>
        <div className="alert error">
          <div className="icon">
            <XMarkIcon />
          </div>
          <h3>{intl.formatMessage({ id: 'paynplay.error.header' })}</h3>
          {error === 'A013' ? (
            <>
              <p>
                {intl.formatMessage({ id: 'paynplay.error.A013' })} {error}
              </p>
              {!isDeposit && (
                <Button
                  primary
                  nomargin
                  onClick={() => {
                    setError(null);
                    login();
                  }}
                >
                  {intl.formatMessage({ id: 'paynplay.loginwithoutdeposit' })}
                </Button>
              )}
            </>
          ) : error === 'USER_SELF_EXCLUDED' ? (
            <p>
              {intl.formatMessage({ id: 'paynplay.error.USER_SELF_EXCLUDED' })} {error}
            </p>
          ) : (
            <p>
              {intl.formatMessage({ id: 'paynplay.error.general' })} {error}
            </p>
          )}
        </div>
      </StyledPayNPlayIframe>
    );
  }

  if (depositSuccess && isDeposit) {
    return (
      <StyledPayNPlayIframe>
        <div className="alert success">
          <div className="icon">
            <CheckIcon />
          </div>
          <h3>{intl.formatMessage({ id: 'paynplay.deposit.success.header' })}</h3>
          <p>{intl.formatMessage({ id: 'paynplay.deposit.success.text' })}</p>
          <Button primary nomargin to="games" onClick={closeDrawer}>
            {intl.formatMessage({ id: 'paynplay.deposit.success.button' })}
          </Button>
        </div>
      </StyledPayNPlayIframe>
    );
  }

  return (
    <StyledPayNPlayIframe>
      <iframe src={url} frameBorder="0" id="paynplay-iframe" key={url} />
    </StyledPayNPlayIframe>
  );
}

PayNPlayIframe.propTypes = {
  url: PropTypes.string.isRequired,
  clearIframe: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  isDeposit: PropTypes.bool,
  depositAmount: PropTypes.number,
  login: PropTypes.func,
};

PayNPlayIframe.defaultProps = {
  isDeposit: false,
  depositAmount: 0,
  login: () => {},
};
