import { useDispatch, useSelector } from 'react-redux';
import { WpTermsAndConditions } from '@/models/wp-terms-and-conditions.model';
import { selectLocale } from '@/store/selectors/commonSelectors';
import {
  selectTermsAndConditions,
  selectTermsAndConditionsLocale,
} from '@/store/selectors/wordpressSelectors';
import { useEffect } from 'react';
import { getTermsAndConditions } from '@/store/actions/wordpressActions';

export function useTermsAndConditionsLoader(): WpTermsAndConditions | null {
  const locale = useSelector(selectLocale);
  const termsAndConditions = useSelector(selectTermsAndConditions);
  const termsAndConditionsLocale = useSelector(selectTermsAndConditionsLocale);
  const dispatch = useDispatch();

  useEffect(() => {
    if (locale !== termsAndConditionsLocale) {
      dispatch(getTermsAndConditions(locale));
    }
  }, [locale, termsAndConditionsLocale]);

  return termsAndConditions;
}
