import { useDispatch, useSelector } from 'react-redux';
import { selectLocale } from '@/store/selectors/commonSelectors';
import {
  selectPrivacyPolicy,
  selectPrivacyPolicyLocale,
} from '@/store/selectors/wordpressSelectors';
import { useEffect } from 'react';
import { getPrivacyPolicy } from '@/store/actions/wordpressActions';
import { WpPrivacyPolicy } from '@/models/wp-privacy-policy.model';

export function usePrivacyPolicyLoader(): WpPrivacyPolicy | null {
  const locale = useSelector(selectLocale);
  const privacyPolicy = useSelector(selectPrivacyPolicy);
  const privacyPolicyLocale = useSelector(selectPrivacyPolicyLocale);
  const dispatch = useDispatch();

  useEffect(() => {
    if (locale !== privacyPolicyLocale) {
      dispatch(getPrivacyPolicy(locale));
    }
  }, [locale, privacyPolicyLocale]);

  return privacyPolicy;
}
