import React from 'react';

import { CogIcon, CreditCardIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { CDN_BASE, CDN_IMAGES_COMMON_PATH, IS_SWEDEN } from '@/constants/constants';
import { useRebate } from '@/hooks/useRebate';
import { selectUiIsSingleGame } from '@/store/selectors/uiSelectors';
import { useCashierShow } from '@/features/cashier/useCashier';
import { usePageScrolled } from '@/hooks/usePageScrolled';
import { useOntarioRegisterRouteCheck } from '@/hooks/useOntarioRegisterRouteCheck';
import { HeaderMessage } from './HeaderMessage';
import { AbsoluteLink } from '../../AbsoluteLink';
import LuckyDaysLogo from '../../UI/Logos/LuckyDaysLogo';
import { AbsoluteNavLink } from '../../AbsoluteNavLink';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { HeaderIconButton } from './HeaderIconButton';
import TotalBalance from '../../TotalBalance';
import AuthButton from './AuthButton';
import { openSearchPallete } from '@/utils/utils';
import { openDrawer } from '@/store/actions/drawer';

function Header(): JSX.Element | null {
  const isAuthenticated = useSelector((state: any) => state.auth.token !== null);
  const dispatch = useDispatch();
  const { showRebate } = useRebate();
  const intl = useIntl();
  const isSingleGame = useSelector(selectUiIsSingleGame);
  const showCashier = useCashierShow();

  const isScrolled = usePageScrolled();
  const isOntarioRegisterRoute = useOntarioRegisterRouteCheck();

  if (isSingleGame && isMobile) {
    return null;
  }

  return (
    <header
      id="header"
      className={`sticky z-[90] w-full flex-col justify-center bg-white transition-all md:fixed md:bg-transparent
       ${isAuthenticated ? 'hidden md:flex' : 'flex'} ${IS_SWEDEN ? 'top-7' : 'top-0'}`}
    >
      <HeaderMessage />
      <nav
        className={`flex items-center justify-between px-4 py-2 text-slate-900 transition-all md:px-8 ${
          isScrolled
            ? 'shadow-md md:bg-white md:py-3 md:text-slate-900'
            : 'md:bg-transparent md:py-5 md:text-white'
        }`}
      >
        <div className="flex space-x-10">
          <AbsoluteLink to="/">
            <LuckyDaysLogo />
          </AbsoluteLink>
          <div className="hidden space-x-10 md:flex">
            <AbsoluteNavLink
              to="/"
              className={({ isActive }) =>
                `font-bold transition hover:opacity-100 ${isActive ? 'opacity-100' : 'opacity-60'}`
              }
              end
            >
              {intl.formatMessage({ id: 'header.home' })}
            </AbsoluteNavLink>
            <AbsoluteNavLink
              to="/games"
              className={({ isActive }) =>
                `font-bold transition hover:opacity-100 ${isActive ? 'opacity-100' : 'opacity-60'}`
              }
              // TODO: Can we check if we need these still?
              onClick={() => {
                triggerGTag(GTagEvents.open_category, {
                  openedFrom: 'header',
                  categoryName: 'game-lobby',
                });
              }}
            >
              {intl.formatMessage({ id: 'header.games' })}
            </AbsoluteNavLink>
            <button
              type="button"
              onClick={() => {
                triggerGTag(GTagEvents.header_help_click);
                dispatch(openDrawer('help'));
              }}
              className="font-bold opacity-60 transition hover:opacity-100"
            >
              {intl.formatMessage({ id: 'header.help' })}
            </button>
          </div>
        </div>
        <div className="flex gap-2 md:gap-4">
          {isAuthenticated ? (
            <>
              {showRebate && (
                <HeaderIconButton to="lucky-rewards-club" isScrolled={isScrolled}>
                  <img
                    width="20"
                    height="20"
                    src={`${CDN_BASE}/cdn-cgi/image/fit=contain,width=20,height=20,format=auto,dpr=2${CDN_IMAGES_COMMON_PATH}/trophy.png`}
                    alt="Trophy"
                    className="text-transparent"
                  />
                </HeaderIconButton>
              )}
              <button
                type="button"
                className="flex h-10 w-10 items-center justify-center rounded-full bg-cyan-500 font-bold text-white shadow-md transition hover:bg-cyan-600 hover:shadow-lg md:w-auto md:px-4"
                onClick={() => {
                  triggerGTag(GTagEvents.header_open_cashier);
                  showCashier();
                }}
                data-cy="navigation-cashier"
                id="e2e_headerCashier"
              >
                {isSingleGame ? <span>+ / -</span> : <TotalBalance />}
                <CreditCardIcon className="ml-2 h-5 w-5" />
              </button>
              <HeaderIconButton to="settings" isScrolled={isScrolled} id="e2e_headerSettings">
                <CogIcon className="h-5 w-5" />
              </HeaderIconButton>
            </>
          ) : (
            <AuthButton />
          )}
          {!isOntarioRegisterRoute && (
            <HeaderIconButton
              onClick={() => {
                triggerGTag(GTagEvents.header_search_click);
                openSearchPallete();
              }}
              isScrolled={isScrolled}
              ariaLabel="Search Button"
            >
              <MagnifyingGlassIcon id="e2e_search" className="h-5 w-5" />
            </HeaderIconButton>
          )}
        </div>
      </nav>
    </header>
  );
}

export default Header;
