import React, { ChangeEvent, useEffect, useState } from 'react';

import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

import Spinner from '@/components/UI/Spinner';
import useDebounce from '@/hooks/useDebounce';
import { useApiV2Request } from '@/hooks/useApiV2Request';
import { maskCanadaPostCode } from '@/utils/utils';
import ApiService from '../../shared/apiService';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { useRegistrationActions } from '@/store/registrationStore';
import { ONTARIO_REGISTRATION_CANADA_PROVINCES } from '@/constants/locales';
import { ZipCodeGeoResponseDto } from '@/models/apiV2/response/zipcode-geo-response-dto.model';
import { ApiV2Error } from '@/shared/errorHandler';
import TextField from '@/components/form/text/TextField';

const PostalCodeField = () => {
  const [postCode, setPostCode] = useState('');
  const { updateField } = useRegistrationActions();
  const debouncedPostCode = useDebounce(postCode, 800);
  const { request, loading } = useApiV2Request<ZipCodeGeoResponseDto>();
  const { setFieldValue, setFieldError, handleChange } = useFormikContext();
  const intl = useIntl();

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const maskedValue = maskCanadaPostCode(value);

    handleChange(event);
    setFieldValue('postCode', maskedValue);

    if (maskedValue) {
      setPostCode(maskedValue.replace(/\s/g, ''));
    } else {
      setFieldValue('city', '');
    }
  };

  useEffect(() => {
    // Valid postal codes are 6 characters in length,
    // hence we will trigger the request only when user
    // enters 6 characters and after a debounced timeout
    if (debouncedPostCode) {
      if (postCode.length === 6) {
        const handleOnSuccess = ({ city, province, street }: ZipCodeGeoResponseDto) => {
          updateField('postCode', postCode);
          setFieldValue('city', city);
          updateField('city', city);
          updateField('address', street ?? '');
          setFieldValue('address', street ?? '');

          const p = ONTARIO_REGISTRATION_CANADA_PROVINCES.find(p => p.code === `CA-${province}`);
          setFieldValue('provinceId', p?.code);
          updateField('provinceId', p?.code ?? '');
          setFieldError('postCode', undefined);
        };

        const handleOnError = (error: ApiV2Error) => {
          let errorMessage = '';

          switch (error!.error) {
            case 'ZIPCODE_NOT_IN_CANADA':
              errorMessage = intl.formatMessage({ id: 'inputs.postcode.canada.invalid' });
              return;
          }

          setFieldError('postCode', errorMessage);
        };

        request(
          ApiService.getZipCodeGeoInfo,
          { zipCode: postCode },
          handleOnSuccess,
          handleOnError,
        );
      }
    }
  }, [debouncedPostCode]);

  return (
    <div className="flex flex-col items-stretch gap-1">
      <span className="text-white">
        {intl.formatMessage({ id: 'registration.ontario.postCode.hint' })}
      </span>
      <TextField
        data-cy="address_info_zip"
        label="Postal Code"
        name="postCode"
        onChange={handleOnChange}
        onBlur={({ target: { value } }) => updateField('postCode', value)}
        onValidationError={error => triggerGTag(GTagEvents.ont_reg_zipcode_error, { error })}
        onFocus={() => triggerGTag(GTagEvents.ont_reg_zipcode_click)}
        disabled={loading}
        endIcon={loading && <Spinner height={20} width={20} borderwidth={2} color="white" />}
        className="border-[1px] border-white/30 text-white focus-within:border-blue-blue"
      />
    </div>
  );
};

export default PostalCodeField;
