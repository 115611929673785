import React, { ElementType } from 'react';

import StepLabel from './StepLabel';
import StepSeparator from './StepSeparator';

const StepperProgress = ({
  count,
  activeStep,
  stepIcons,
}: {
  count: number;
  activeStep: number;
  stepIcons: (ElementType | null)[];
}) => {
  return (
    <div className="flex items-center w-full">
      {Array(count)
        .fill(null)
        .map((_, index) => {
          const step = index + 1;
          return (
            <React.Fragment key={step}>
              <StepLabel
                step={step}
                isActiveStep={step === activeStep}
                isCompleted={step < activeStep}
                icon={stepIcons[index]}
              />
              {step < count && <StepSeparator isCompleted={step < activeStep} />}
            </React.Fragment>
          );
        })}
    </div>
  );
};

export default StepperProgress;
