import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { selectUser } from '@/store/selectors/userSelectors';
import { BUILD_MODE } from '@/constants/constants';
import { openModal } from '@/store/actions/modal';

export function useOntarioLoginMigration(): void {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  useEffect(() => {
    // Only do this for normal build mode
    if (BUILD_MODE !== 'normal') {
      return;
    }

    // If the user is from Canada with a province of Ontario, show the Ontario migration modal
    if (user && user.CountryCode === 'CA' && user.Subdivision.IsoCodeSubdivision1 === 'CA-ON') {
      dispatch(openModal('ontarioMigration'));
    }
  }, [user]);
}
