import React from 'react';

const Divider = () => {
  return (
    <div
      className="h-[1px] relative before:absolute before:top-0 before:left-[5%] before:right-[5%] before:w-[90%]
      before:h-[1px] before:bg-[linear-gradient(to_right,transparent,rgba(255,255,255,0.5),transparent)]"
    />
  );
};

export default Divider;
