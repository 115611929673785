import { BffErrorCode } from '@lucky7ventures/bff-types';

// because we have BffErrorCode reserving some numbers; to make it work we will put FrontendErrorCodes as negatives
export enum FrontendErrorCode {
  CancelledRequest = -1,
  TooManyRequests = -2,
  UnknownError = -3,
  UnexpectedResponseFormat = -4,
}

export type BffApiErrorCode = BffErrorCode | FrontendErrorCode;

export type BffApiError = {
  code: BffApiErrorCode;
};
