import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import Accordion from '../../UI/Accordion';
import { BUILD_MODE } from '@/constants/constants';
import parse from 'html-react-parser';

const StyledFAQ = styled.section`
  padding: 32px 16px;
  max-width: 800px;
  margin: 0 auto;
  position: relative;

  @media (min-width: ${props => props.theme.breakpoints.small}) {
    padding: 32px;
  }

  h2.h3 {
    font-size: 24px;
    margin-bottom: 24px;

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      font-size: 26px;
    }
  }

  .accordion-group {
    margin-bottom: 64px;
  }
`;

const faqIntlKey = () => {
  switch (BUILD_MODE) {
    case 'canada':
      return 'ontario.help.faq';
    default:
      return 'help.faq';
  }
};

function HelpContent() {
  const intl = useIntl();

  return (
    <StyledFAQ>
      {intl.messages[faqIntlKey()].map((faq, index) => (
        <div key={`faq-${index}`}>
          <h2 className="h3">{faq.header}</h2>
          <div className="accordion-group">
            {faq.questions.map((question, index) => (
              <Accordion key={`question-${index}`} header={question.question}>
                <div className="prose prose-a:underline">{parse(question.answer)}</div>
              </Accordion>
            ))}
          </div>
        </div>
      ))}
    </StyledFAQ>
  );
}

export default HelpContent;
