import { ArrowRightIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useIntl } from 'react-intl';

export function PayNPlayLoginSwitch({
  onClickHandler,
}: {
  onClickHandler: () => void;
}): JSX.Element {
  const intl = useIntl();

  return (
    <div className="bg-gray-200 p-4 text-sm">
      {intl.formatMessage({ id: 'paynplay.modal.login.text' })}
      <br />
      <button
        type="button"
        onClick={onClickHandler}
        className="inline-flex items-center font-bold text-cyan-600"
      >
        {intl.formatMessage({ id: 'paynplay.modal.login.link' })}
        <ArrowRightIcon className="ml-1 inline h-3 w-3" />
      </button>
    </div>
  );
}
