import React from 'react';

import { useIntl } from 'react-intl';

import { TranslatedRichText } from '../TranslatedRichText';

interface IDepositBalanceUpdateModalProps {
  closeModal: () => void;
}

export function DepositBalanceUpdateModal({
  closeModal,
}: IDepositBalanceUpdateModalProps): JSX.Element | null {
  const intl = useIntl();

  return (
    <div className="prose flex w-full max-w-sm flex-col overflow-hidden rounded-lg bg-white p-8 text-left">
      <h1 className="mb-0.5 text-2xl">{intl.formatMessage({ id: 'depositModal.header' })}</h1>
      <TranslatedRichText tagName="p" id="depositModal.text" />
      <button
        type="button"
        onClick={closeModal}
        className="mt-4 flex w-full items-center justify-center rounded-full bg-cyan-500 p-4 font-bold text-white shadow-md transition hover:bg-cyan-600 hover:shadow-lg"
      >
        {intl.formatMessage({ id: 'misc.ok.long' })}
      </button>
    </div>
  );
}
