import React, { useEffect, useRef } from 'react';

import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { selectLocale } from '@/store/selectors/commonSelectors';
import { selectAuthToken } from '@/store/selectors/authSelectors';
import { selectKycDocumentPhotoId } from '@/store/selectors/kycSelectors';
import { selectUserId } from '@/store/selectors/userSelectors';
import { kycGetDocuments } from '@/store/actions/kycActions';
import { getRegilyLocale } from '@/shared/regilyUtils';
import { axiosInstance as axios } from '../../shared/axiosInstance';
import { REGILY_URL } from '@/constants/constants';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';

function Regily() {
  const locale = useSelector(selectLocale);
  const dispatch = useDispatch();
  const intl = useIntl();
  const token = useSelector(selectAuthToken);
  const tokenRef = useRef(null);
  const kycDocumentPhotoId = useSelector(selectKycDocumentPhotoId);
  const photoIdRef = useRef(null);
  const userId = useSelector(selectUserId);
  const userIdRef = useRef(null);

  useEffect(() => {
    document.documentElement.dataset.regilyLanguage = getRegilyLocale(locale);
  }, [locale]);

  useEffect(() => {
    // This token ref is needed because it's used in Regily callbacks that are set only once the component is mounted
    // so this data can come in later
    tokenRef.current = token;
  }, [token]);

  useEffect(() => {
    // The kycId that will be used for the kyc photo id approval
    photoIdRef.current = kycDocumentPhotoId;
  }, [kycDocumentPhotoId]);

  useEffect(() => {
    // The userId that will be used for the kyc photo id approval
    userIdRef.current = userId;
  }, [userId]);

  const regilyErrorFactory = (message, title, action = 'close') => {
    const error = new Error(message);
    error.title = title;
    error.action = action;
    return error;
  };

  // this function is used for the VERIFY flow
  function regilyErrorFromConcerns(concerns) {
    const title = intl.formatMessage({
      id: 'regily.verify.error.concerns',
    });
    const message = concerns.map(concern => concern.message);
    const joinedMessages = message.join('\n');
    return regilyErrorFactory(joinedMessages, title);
  }

  function getRegilyError(errorType, errorId = null) {
    triggerGTag(GTagEvents.regily_error, { error: errorType, errorId });

    if (errorType === 'verifyGeneric') {
      const title = intl.formatMessage({
        id: 'failedSignup.SomethingWentWrongTitle',
      });
      const message = intl.formatMessage({
        id: 'regily.verify.error',
      });
      return regilyErrorFactory(message, title);
    }
    const errorTitle = intl.formatMessage({
      id: 'failedSignup.SomethingWentWrongTitle',
    });
    const errorMessage = `${intl.formatMessage({
      id: 'failedSignup.SomethingWentWrongMessage',
    })} (${errorId})`;
    return regilyErrorFactory(errorMessage, errorTitle);
  }

  // The flow that happens when the Verify variant is done
  // The verify variant is used for the PhotoID upload for Ontario
  function finishVerifyFlow(data) {
    return new Promise((resolve, reject) => {
      const currentToken = tokenRef.current;
      const currentPhotoId = photoIdRef.current;
      if (!currentToken || !currentPhotoId) {
        reject(getRegilyError('verifyGeneric'));
        return;
      }

      // Finish the flow if the documents are not either 'approved' or 'needs-review'
      const ocrData = data.ocrData;
      if (
        ocrData.applicationStatus !== 'approved' &&
        ocrData.applicationStatus !== 'needs-review'
      ) {
        const concerns = ocrData.data.results.concerns;
        if (concerns) {
          reject(regilyErrorFromConcerns(concerns));
          return;
        }
        reject(getRegilyError('verifyGeneric'));
        return;
      }

      const ocrDocuments = ocrData.data.documents;

      if (!ocrDocuments || ocrDocuments.length === 0) {
        reject(getRegilyError('verifyGeneric'));
        return;
      }

      axios
        .post(`/api/user/regily-photo-id`, {
          token: currentToken,
          files: ocrDocuments[0].files.map(file => file.uri),
          status: ocrData.applicationStatus,
          kycId: currentPhotoId.KycId,
          kycStatus: currentPhotoId.Status,
          attributes: ocrData.data.attributes,
        })
        .then(() => {
          // after the KYC ID verification is done trigger the fetch for the kyc documents again
          dispatch(kycGetDocuments());
          resolve();
        })
        .catch(() => reject(getRegilyError('verifyGeneric')));
    });
  }

  useEffect(() => {
    // Capture the regilyDoneCallback
    Object.defineProperty(window, 'regilyDoneCallback', {
      configurable: true,
      value: regilyDoneCallbackData => {
        // We only have one Regily flow - verfiy
        if (regilyDoneCallbackData.variant === 'verify') {
          return finishVerifyFlow(regilyDoneCallbackData);
        }
      },
    });
  }, []);

  return <Helmet>{!window.regily && <script src={REGILY_URL} async />}</Helmet>;
}

export default Regily;
