import { RootState } from '@/models/root-state.model';
import { TimeoutDurationModel } from '@/models/timeoutDuration.model';

export const selectTimeoutLegislationDurations = (state: RootState): TimeoutDurationModel[] =>
  state.userExtras.timeOutLegislationDurations;

export const selectTimeoutLegislationDurationsLoading = (state: RootState): boolean =>
  state.userExtras.timeOutLegislationDurationsLoading;

export const selectTimeoutLegislationDurationsError = (state: RootState): boolean =>
  state.userExtras.timeOutLegislationDurationsError;
