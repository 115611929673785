import * as actionTypes from './actionTypes';

export const openModal = (modalName, modalProps, order = 1) => ({
  type: actionTypes.OPEN_MODAL,
  modalName,
  modalProps,
  order,
});

export const updateModalProps = props => ({
  type: actionTypes.UPDATE_MODAL_PROPS,
  props,
});

export const closeModal = () => ({
  type: actionTypes.CLOSE_MODAL,
});

export const closeSpecificModal = modalName => ({
  type: actionTypes.CLOSE_SPECIFIC_MODAL,
  modalName,
});

export const setShowBalanceUpdate = isShown => ({
  type: actionTypes.SHOW_BALANCE_UPDATE,
  isShown,
});

export const closeAllModals = excludedModals => ({
  type: actionTypes.CLOSE_ALL_MODALS,
  excludedModals,
});

export const setShowUserIsPepOrHioLogout = isShown => ({
  type: actionTypes.SHOW_USER_IS_PEP_OR_HIO_LOGOUT,
  isShown,
});

export const setFtdExitIntentShown = () => ({
  type: actionTypes.SET_FTD_EXIT_INTENT_SHOWN,
});
