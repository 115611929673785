import React from 'react';
import { Link } from 'react-router-dom';
import { useLocalizedTo } from '@/hooks/useLocalizedTo';

export function AbsoluteLink({
  children,
  to,
  onClick,
  className,
  title,
  ...props
}: {
  children: React.ReactNode;
  to: string;
  className?: string;
  onClick?: (e?: React.MouseEvent<HTMLAnchorElement>) => void;
  title?: string;
}): JSX.Element | null {
  const localizedTo = useLocalizedTo(to);

  if (!localizedTo) {
    return null;
  }

  return (
    <Link to={localizedTo} onClick={onClick} title={title} className={className} {...props}>
      {children}
    </Link>
  );
}
