import { DateTime } from 'luxon';
import { mapLocaleToLang } from './utils';

export function getMonday(d: Date): Date {
  const dt = new Date(d);
  const day = dt.getDay();
  const diff = dt.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  return new Date(dt.setDate(diff));
}

export const nextDayUTC = (now: Date): Date => {
  return new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate() + 1));
};

export function nextWeekUTC(now: Date): Date {
  const day = now.getDay();
  const daysToMonday = day === 0 ? 1 : 8 - day;
  return new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate() + daysToMonday));
}

export const nextMonthUTC = (now: Date): Date => {
  return new Date(Date.UTC(now.getFullYear(), now.getMonth() + 1, 1));
};

export const untilOrRemaining = (now: Date, endDate: Date, locale: string): string | null => {
  const start = DateTime.fromJSDate(now);
  const end = DateTime.fromJSDate(endDate);
  const diffInHours = end.diff(start, 'hours');

  if (diffInHours.hours < 24) {
    return end.toRelative({ locale: mapLocaleToLang(locale) });
  }

  return end.toLocaleString(DateTime.DATETIME_SHORT, { locale: mapLocaleToLang(locale) });
};
