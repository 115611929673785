import React, { useEffect, useState } from 'react';
import { GameCardVM } from '@/models/vm/game-card-vm.model';
import GameListHeader from '@/pages/games/GameListHeader';
import GameList from '@/pages/games/GameList';
import { GamesCategories } from '@/models/game-category.type';
import GamesSkeletonLoader from '@/components/shared/skeleton-loader/GamesSkeletonLoader';
import InViewSection from '@/components/shared/in-view-section/InViewSection';

type LobbySectionProps = {
  header: string;
  subheader: string;
  games: GameCardVM[];
  loading: boolean;
  category: GamesCategories;
  maxItems: number;
};

function LobbySection({
  header,
  subheader,
  games,
  loading,
  category,
  maxItems,
}: LobbySectionProps): JSX.Element | null {
  const [visibleGames, setVisibleGames] = useState<GameCardVM[]>([]);

  useEffect(() => {
    setVisibleGames(games.slice(0, Math.min(maxItems, games.length)));
  }, [games, maxItems]);

  if (!loading && games.length === 0) {
    return null;
  }

  return (
    <InViewSection loader={<GamesSkeletonLoader />}>
      <section>
        <GameListHeader
          header={header}
          subheader={subheader}
          games={games}
          gamesLoading={loading}
          count={games.length}
          link={category}
        />
        <GameList games={visibleGames} gamesLoading={loading} category={category} />
      </section>
    </InViewSection>
  );
}

export default LobbySection;
