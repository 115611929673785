import React from 'react';
import { Switch } from '@headlessui/react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';

const ToggleSwitch = ({
  label,
  labelClass,
  checked,
  disabled,
  onChange,
}: {
  label?: string;
  labelClass?: string;
  checked: boolean;
  disabled: boolean;
  onChange: (checked: boolean) => void;
}): JSX.Element => {
  return (
    <Switch.Group>
      <div className="flex items-center justify-between">
        {label && <Switch.Label className={`mr-4 ${labelClass}`}>{label}</Switch.Label>}
        <Switch
          checked={checked}
          onChange={onChange}
          className={`${checked ? 'bg-green-400' : 'bg-gray-300'} ${
            disabled ? 'opacity-60' : 'opacity-100'
          } relative inline-flex h-[28px] w-[56px] items-center rounded-full`}
        >
          <span
            className={`${
              checked ? 'translate-x-[32px]' : 'translate-x-[4px]'
            } inline-block flex h-[20px] w-[20px] transform items-center justify-center rounded-full bg-white transition`}
          >
            {checked ? (
              <CheckIcon className="h-3 w-3 text-green-400" />
            ) : (
              <XMarkIcon className="h-3 w-3 text-gray-300" />
            )}
          </span>
        </Switch>
      </div>
    </Switch.Group>
  );
};

export default ToggleSwitch;
