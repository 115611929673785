import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const StyledSettingsNav = styled.nav`
  background: ${props => props.theme.colors.blue2};
  display: flex;
  width: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  padding-right: 16px;

  &::-webkit-scrollbar {
    display: none;
  }

  ul {
    display: flex;
    justify-content: center;
    align-items: stretch;
    margin: 0 auto;

    li {
      display: inline-flex;
      margin: 0;

      a {
        padding: 0.5rem 1rem;
        border-radius: 8px 8px 0 0;
        text-decoration: none;
        font-weight: bold;
        margin: 0 5px;
        background: rgba(255, 255, 255, 0.1);
        color: white;
        display: flex;
        align-items: center;
        text-align: center;
        white-space: nowrap;

        &.active {
          color: black;
          background: ${props => props.theme.colors.offWhite};
        }
      }
    }
  }
`;

const SETTINGS_LINKS = [
  { to: '', intlId: 'settings.details', end: true },
  { to: 'verify', intlId: 'settings.verify' },
  { to: 'responsible-gaming', intlId: 'settings.responsibleGaming' },
  { to: 'history', intlId: 'history.header' },
  { to: 'active-bonuses', intlId: 'activeBonuses.header' },
];

const SettingsNav = (): JSX.Element => {
  const intl = useIntl();

  return (
    <StyledSettingsNav>
      <ul>
        {SETTINGS_LINKS.map(link => (
          <li key={link.intlId}>
            <NavLink to={link.to} end={link.end} data-cy="NAV_LINK">
              {intl.formatMessage({ id: link.intlId })}
            </NavLink>
          </li>
        ))}
      </ul>
    </StyledSettingsNav>
  );
};

export default SettingsNav;
