import React from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from '@/models/root-state.model';
import { classNames } from '@/utils/utils';
import { LoggedInTimer } from './LoggedInTimer';
import { SjaelvtestIcon } from './UI/Icons/SjaelvtestIcon';
import { SpelgranserIcon } from './UI/Icons/SpelgranserIcon';
import { SpelpausIcon } from './UI/Icons/SpelpausIcon';
import { TwentyFourIcon } from './UI/Icons/TwentyFourIcon';
import { useAbsoluteNavigate } from '@/hooks/useAbsoluteNavigate';
import { openModal } from '@/store/actions/modal';

export function Spelpaus(): JSX.Element {
  const isAuthenticated = useSelector((state: RootState) => state.auth.token !== null);
  const absoluteNavigate = useAbsoluteNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const pathParts = pathname?.split('/');
  const isSingleGame = pathParts.includes('games') && pathParts.length >= 4;

  function spelpausClickHandler() {
    if (isAuthenticated) {
      absoluteNavigate('/settings/responsible-gaming?limit=timeout');
    } else {
      window.open('https://www.spelpaus.se/?scos=true', '_blank', 'noopener noreferrer');
    }
  }

  function spelgranserClickHandler() {
    if (isAuthenticated) {
      absoluteNavigate('/settings/responsible-gaming?limit=deposit');
    } else {
      dispatch(openModal('paynplay'));
    }
  }

  function twentyFourClickHandler() {
    if (isAuthenticated) {
      absoluteNavigate('/settings/responsible-gaming?limit=24h');
    } else {
      dispatch(openModal('paynplay'));
    }
  }

  return (
    <div
      id="e2e_spelpaus"
      className={classNames(
        isSingleGame && isMobile ? 'bg-black' : 'bg-cyan-800',
        isAuthenticated ? 'justify-between' : 'justify-center',
        'sticky top-0 z-[100] flex items-center space-x-2 py-1 px-1 md:px-8',
      )}
    >
      <div className="flex space-x-2">
        <button type="button" onClick={() => spelpausClickHandler()}>
          <SpelpausIcon className="h-5 w-auto cursor-pointer" />
        </button>
        <button type="button" onClick={() => spelgranserClickHandler()}>
          <SpelgranserIcon className="h-5 w-auto cursor-pointer" />
        </button>
        <a
          href="https://www.stodlinjen.se/#!/spelberoende-test-pgsi"
          rel="noopener noreferrer"
          target="_blank"
        >
          <SjaelvtestIcon className="h-5 w-auto cursor-pointer" />
        </a>
        <button type="button" onClick={() => twentyFourClickHandler()}>
          <TwentyFourIcon className="h-5 w-auto cursor-pointer" />
        </button>
      </div>
      {isAuthenticated && <LoggedInTimer />}
    </div>
  );
}
