import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserLocale } from '@/store/selectors/userSelectors';
import {
  selectActiveBonusesIds,
  selectAvailableBonusesIds,
} from '@/store/selectors/bonusesSelectors';
import { getBonusContent } from '@/store/actions/bonusesContentActions';

// this Hook is to be used in the DataLoader only
// it handles all the logic for when the available bonuses content need to be refreshed
const useBonusesContentLogic = (): void => {
  const userLocale = useSelector(selectUserLocale);
  const availableBonusesIds = useSelector(selectAvailableBonusesIds);
  const activeBonusesIds = useSelector(selectActiveBonusesIds);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!userLocale || availableBonusesIds?.length === 0) {
      return;
    }

    availableBonusesIds?.forEach(id => {
      dispatch(getBonusContent(id, userLocale));
    });
  }, [userLocale, availableBonusesIds]);

  useEffect(() => {
    if (!userLocale || activeBonusesIds?.length === 0) {
      return;
    }

    activeBonusesIds?.forEach(id => {
      dispatch(getBonusContent(id, userLocale));
    });
  }, [userLocale, activeBonusesIds]);
};

export default useBonusesContentLogic;
