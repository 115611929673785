import PropTypes from 'prop-types';
import React from 'react';
import { isBrowser } from 'react-device-detect';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import CloseButton from '../UI/Buttons/CloseButton';
import LuckyDaysLogo from '../UI/Logos/LuckyDaysLogo';
import { useAbsoluteNavigate } from '@/hooks/useAbsoluteNavigate';
import { openModal } from '@/store/actions/modal';
import { openDrawer } from '@/store/actions/drawer';

const StyledFailedLoginModal = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  max-width: 380px;
  overflow: hidden;
  border-radius: ${props => props.theme.borderRadius};
  background: ${props => props.theme.colors.offWhite};
  min-height: 600px;
  position: relative;
  padding: 48px 32px 32px;
  justify-content: center;
  align-items: center;

  .logo {
    color: ${props => props.theme.colors.blue3};
    margin: 0 auto;
    justify-content: center;
  }

  .text {
    > p {
      font-size: 14px;
      font-weight: bold;
      padding: 0 16px;
      margin-bottom: 32px;
      margin-top: 16px;
    }

    .button {
      width: 100%;
      border-radius: 50vh;
      padding: 1em 1.5em;
      font-size: 18px;
      margin: 0 0 32px 0;
      background: ${props => props.theme.colors.blue1};
      border: none;
      font-weight: bold;
      transition: all 0.15s ease;
      -webkit-appearance: none;
      cursor: pointer;
      color: #ffffff;
      box-shadow:
        0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);

      &:hover {
        background: ${props => props.theme.colors.blue1Hover};
      }
    }
  }
`;

function FailedLoginModal({ closeModal }) {
  const dispatch = useDispatch();
  const absoluteNavigate = useAbsoluteNavigate();
  const intl = useIntl();

  function helpClickHandler() {
    closeModal();

    if (isBrowser) {
      setTimeout(() => {
        dispatch(openDrawer('help'));
      }, 200);
    } else {
      absoluteNavigate('/help');
    }
  }

  return (
    <StyledFailedLoginModal id="e2e_failedLoginModal">
      <CloseButton left onClick={closeModal} />
      <div className="text">
        <LuckyDaysLogo className="logo" />
        <p className="intro-text">{intl.formatMessage({ id: 'failedLogin.text' })}</p>
        <>
          <button
            className="button"
            type="button"
            onClick={() => {
              dispatch(openModal('forgotPassword'));
            }}
          >
            {intl.formatMessage({ id: 'failedLogin.text.resetPassword' })}
          </button>
          <button
            className="button"
            type="button"
            onClick={() => {
              helpClickHandler();
            }}
          >
            {intl.formatMessage({ id: 'failedLogin.text.contactSupport' })}
          </button>
          <button className="button" type="button" onClick={() => dispatch(openModal('login'))}>
            {intl.formatMessage({ id: 'failedLogin.text.tryAgain' })}
          </button>
        </>
      </div>
    </StyledFailedLoginModal>
  );
}

FailedLoginModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default FailedLoginModal;
