import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledAccordion = styled.div`
  margin-bottom: 8px;

  .accordion {
    border: none;
    color: ${props => props.theme.colors.grey90};
    background: white;
    padding: 20px 48px 20px 16px;
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    height: fit-content;
    text-align: left;
    cursor: pointer;
    position: relative;
    border-radius: 6px;
    line-height: 1.6;

    &:after {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4.5 -4.5 24 24' width='1.2em' height='1.2em' preserveAspectRatio='xMinYMin' %3E%3Cpath d='M8.9 6.9v-5a1 1 0 1 0-2 0v5h-5a1 1 0 1 0 0 2h5v5a1 1 0 1 0 2 0v-5h5a1 1 0 1 0 0-2h-5z' /%3E%3C/svg%3E");
      position: absolute;
      top: 48%;
      right: 16px;
      line-height: 0;
      transform: translateY(-50%);
      color: ${props => props.theme.colors.grey90};
      font-weight: bold;
    }
  }

  .panel {
    display: none;
    padding: 20px 16px;
  }

  &.open {
    .accordion:after {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-5 -11 24 24' width='1.2em' height='1.2em' preserveAspectRatio='xMinYMin' %3E%3Cpath d='M1 0h12a1 1 0 0 1 0 2H1a1 1 0 1 1 0-2z' /%3E%3C/svg%3E");
    }
    .panel {
      display: block;
    }
  }
`;

function Accordion({ header, children }) {
  const [accordionOpen, setAccordionOpen] = useState(false);

  const toggleAccordion = () => {
    accordionOpen ? setAccordionOpen(false) : setAccordionOpen(true);
  };

  return (
    <StyledAccordion className={accordionOpen ? 'open' : null}>
      <button type="button" className="accordion" onClick={toggleAccordion}>
        {header}
      </button>
      <div className="panel">{children}</div>
    </StyledAccordion>
  );
}

Accordion.propTypes = {
  header: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

export default Accordion;
