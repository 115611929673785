import { envIsCanada, envIsMexico, envIsNormal } from '@/constants/constants';
import { useSelector } from 'react-redux';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { selectUserIsFluidTester } from '@/store/selectors/userSelectors';
import { selectFluidConfig } from '@/store/selectors/combined.selectors';

/*
Fluid is enabled on:
  1. Mexico
  2. Canada
  3. Normal build with locale other than fi, fi-en
  Fluid is NOT enabled on:
  1. Sweden
  2. Normal build fi, fi-en
* */
const useIsFluidEnabled = () => {
  const locale = useSelector(selectLocale);
  const isFluidTester = useSelector(selectUserIsFluidTester);
  const fluidConfig = useSelector(selectFluidConfig);
  const isFluidLocale =
    (envIsNormal() && !['fi', 'fi-en'].includes(locale)) || envIsMexico() || envIsCanada();
  // @ts-ignore
  return !!fluidConfig && (isFluidLocale || isFluidTester) && !window.Cypress;
};

export default useIsFluidEnabled;
