import * as actionTypes from './actionTypes';

export const getUser = token => ({
  type: actionTypes.GET_USER,
  token,
});

export const getUserStart = () => ({
  type: actionTypes.GET_USER_START,
});

export const getUserSuccess = user => ({
  type: actionTypes.GET_USER_SUCCESS,
  user,
});

export const getUserFail = userError => ({
  type: actionTypes.GET_USER_FAIL,
  userError,
});

export const getUserWallet = () => ({
  type: actionTypes.GET_USER_WALLET,
});

export const getUserWalletStart = () => ({
  type: actionTypes.GET_USER_WALLET_START,
});

export const getUserWalletSuccess = wallet => ({
  type: actionTypes.GET_USER_WALLET_SUCCESS,
  wallet,
});

export const getUserWalletFail = walletError => ({
  type: actionTypes.GET_USER_WALLET_FAIL,
  walletError,
});

export const clearUser = () => ({
  type: actionTypes.CLEAR_USER,
});

export const getUserDepositCount = () => ({
  type: actionTypes.GET_USER_DEPOSIT_COUNT,
});

export const incrementUserDepositCount = () => ({
  type: actionTypes.INCREMENT_USER_DEPOSIT_COUNT,
});

export const getUserDepositCountSuccess = depositCount => ({
  type: actionTypes.GET_USER_DEPOSIT_COUNT_SUCCESS,
  depositCount,
});

export const getUserTags = token => ({
  type: actionTypes.GET_USER_TAGS,
  token,
});

export const getUserTagsSuccess = tags => ({
  type: actionTypes.GET_USER_TAGS_SUCCESS,
  tags,
});

export const getUserTagsError = () => ({
  type: actionTypes.GET_USER_TAGS_ERROR,
});

export const setUserEmail = email => ({
  type: actionTypes.SET_USER_EMAIL,
  email,
});
