const sha256 = async (message: string): Promise<string> => {
  // Convert the message to an ArrayBuffer
  const buffer = new TextEncoder().encode(message);

  // Generate the SHA-256 hash
  const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);

  // Convert the hash to a hex string
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
};

export const sha256Hash = async (message: string): Promise<string> => {
  if (window.crypto && window.crypto.subtle) {
    // Web Crypto API is supported
    return sha256(message);
  } else {
    return '';
  }
};
