import axios from 'axios';
import { useEffect } from 'react';
import { useAbsoluteNavigate } from './useAbsoluteNavigate';
import { logException } from '@/utils/logger';
import { useCancelToken } from './useCancelToken';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsAuthenticated } from '@/store/selectors/authSelectors';
import { BUILD_MODE, ONTARIO_CHECK_URL } from '@/constants/constants';
import { selectUser } from '@/store/selectors/userSelectors';
import { isEmpty } from '@/shared/utility';
import { openModal } from '@/store/actions/modal';

// After user logs in we want to make sure he is not playing from Ontario
export function useOntarioCheck(): void {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const user = useSelector(selectUser);
  const absoluteNavigate = useAbsoluteNavigate();
  const dispatch = useDispatch();
  const { newCancelToken } = useCancelToken();

  useEffect(() => {
    if (BUILD_MODE !== 'normal') {
      // we don't need this check for Canada or Sweden builds
      return;
    }

    if (!isAuthenticated || isEmpty(user)) {
      // if user is not authenticated we don't want to do the check
      return;
    }

    if (
      user &&
      user.CountryCode === 'CA' &&
      user.Subdivision &&
      user.Subdivision.IsoCodeSubdivision1 === 'CA-ON'
    ) {
      // if the user has registered account in Ontario we don't need to do the IP check as he needs to be migrated
      return;
    }

    axios
      .get<{ canPlay: boolean }>(ONTARIO_CHECK_URL, {
        cancelToken: newCancelToken(),
      })
      .then(res => {
        if (!res.data.canPlay) {
          absoluteNavigate('/logout');
          dispatch(
            openModal('login', {
              loggedOut: true,
              loggedOutIntlId: 'ontario.canplay.logout',
            }),
          );
        }
      })
      .catch(e => {
        if (!axios.isCancel(e)) {
          logException(e);
        }
      });
  }, [isAuthenticated, user]);
}
