import { useIntl } from 'react-intl';
import * as Yup from 'yup';

export function useYupSchema(): {
  password: Yup.StringSchema;
  createSchema: (schemas: Record<string, Yup.AnySchema>) => Yup.AnyObjectSchema;
} {
  const intl = useIntl();

  const password = Yup.string()
    .required(intl.formatMessage({ id: 'inputs.password.required' }))
    .min(6, intl.formatMessage({ id: 'inputs.password.invalid' }));

  const createSchema = (schemas: Record<string, Yup.AnySchema>) => Yup.object().shape(schemas);

  return { password, createSchema };
}
