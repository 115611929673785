import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { isEmpty } from '@/shared/utility';
import { AbsoluteLink } from '../../AbsoluteLink';

const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding: 1em 1.5em;
  font-size: 16px;
  line-height: 22px;
  min-width: ${props => props.width};
  margin: ${props => (props.nomargin ? '0' : '2em 0.5em 1em')};
  background: ${props => props.theme.colors.white20};
  color: ${props => props.theme.colors.white100};
  border-radius: ${props => props.theme.borderRadiusPill};
  transition: all 0.15s ease;
  text-decoration: none;
  border: none;
  cursor: pointer;
  outline: none;
  position: relative;
  -webkit-appearance: none;
  text-align: center;

  svg {
    width: 24px;
    height: 24px;
    margin-left: ${props => (props.iconright ? '10px' : '0')};
    margin-right: ${props => (props.iconleft ? '10px' : '0')};
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &:hover,
  &:active {
    background: ${props => props.theme.colors.white30};
    box-shadow: ${props => props.theme.buttonShadowHover};
    color: ${props => props.theme.colors.white100};
    /* transform: translateY(-1px); */
  }

  ${props =>
    props.primary &&
    css`
      color: ${props => props.theme.colors.white} !important;
      background: ${props => props.theme.colors.blue1} !important;
      box-shadow: ${props => props.theme.buttonShadow} !important;

      &:hover,
      &:active {
        background: ${props => props.theme.colors.blue1Hover} !important;
        box-shadow: ${props => props.theme.buttonShadowHover} !important;
      }
    `};

  ${props =>
    props.dark &&
    css`
      background: ${props => props.theme.colors.blackTransparent};
      color: ${props => props.theme.colors.black};

      &:hover,
      &:active {
        background: ${props => props.theme.colors.blackTransparentHover};
        color: ${props => props.theme.colors.black};
      }
    `};

  ${props =>
    props.small &&
    css`
      padding: 9px 18px;
      font-size: 16px;
      line-height: 22px;

      svg {
        width: 22px;
        height: 22px;
      }
    `};

  ${props =>
    props.big &&
    css`
      padding: 1.1em 1.8em;
      font-size: 18px;
      line-height: 18px;

      svg {
        width: 18px;
        height: 18px;
      }
    `};

  ${props =>
    props.cancel &&
    css`
      background: none;
      color: ${props => props.theme.colors.black};
      opacity: 0.6;

      &:hover,
      &:active {
        background: none;
        color: ${props => props.theme.colors.black};
        box-shadow: none;
        opacity: 1;
      }
    `};

  ${props =>
    props.icononly &&
    css`
      padding: 9px;

      svg {
        height: 22px;
        width: 22px;
      }
    `};
`;

const Button = ({
  children,
  to,
  width,
  primary,
  small,
  ghost,
  iconleft,
  iconright,
  nomargin,
  icononly,
  big,
  cancel,
  ...rest
}) => {
  if (!isEmpty(to)) {
    return (
      <StyledButton
        as={to.substr(0, 1) === '/' ? AbsoluteLink : Link}
        to={to}
        width={width}
        primary={primary ? 1 : 0}
        small={small ? 1 : 0}
        ghost={ghost ? 1 : 0}
        iconright={iconright ? 1 : 0}
        iconleft={iconleft ? 1 : 0}
        nomargin={nomargin ? 1 : 0}
        icononly={icononly ? 1 : 0}
        big={big ? 1 : 0}
        cancel={cancel ? 1 : 0}
        {...rest}
      >
        {children}
      </StyledButton>
    );
  }
  return (
    <StyledButton
      width={width}
      primary={primary ? 1 : 0}
      small={small ? 1 : 0}
      ghost={ghost ? 1 : 0}
      iconright={iconright ? 1 : 0}
      iconleft={iconleft ? 1 : 0}
      nomargin={nomargin ? 1 : 0}
      icononly={icononly ? 1 : 0}
      big={big ? 1 : 0}
      cancel={cancel ? 1 : 0}
      {...rest}
    >
      {children}
    </StyledButton>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
  ghost: PropTypes.bool,
  primary: PropTypes.bool,
  small: PropTypes.bool,
  iconright: PropTypes.bool,
  iconleft: PropTypes.bool,
  nomargin: PropTypes.bool,
  icononly: PropTypes.bool,
  big: PropTypes.bool,
  to: PropTypes.string,
  cancel: PropTypes.bool,
};

Button.defaultProps = {
  width: 'auto',
  ghost: false,
  primary: false,
  small: false,
  iconright: false,
  iconleft: false,
  nomargin: false,
  icononly: false,
  big: false,
  cancel: false,
  to: '',
};

export default Button;
