import { Action } from 'redux';

export enum AmlActionTypes {
  GetVerificationStatus = 'GET_VERIFICATION_STATUS',
  GetVerificationStatusSuccess = 'GET_VERIFICATION_STATUS_SUCCESS',
  GetVerificationStatusError = 'GET_VERIFICATION_STATUS_ERROR',
  ResetAml = 'RESET_AML',
}

export type GetVerificationStatusAction = Action<AmlActionTypes.GetVerificationStatus>;
export type GetVerificationStatusSuccessAction =
  Action<AmlActionTypes.GetVerificationStatusSuccess> & { verificationStatus: number };
export type GetVerificationStatusErrorAction = Action<AmlActionTypes.GetVerificationStatusError>;
export type ResetAmlAction = Action<AmlActionTypes.ResetAml>;

export type AmlAction =
  | GetVerificationStatusAction
  | GetVerificationStatusSuccessAction
  | GetVerificationStatusErrorAction
  | ResetAmlAction;

export const getAmlVerificationStatus = () => ({
  type: AmlActionTypes.GetVerificationStatus,
});

export const getAmlVerificationStatusSuccess = (
  verificationStatus: number,
): GetVerificationStatusSuccessAction => ({
  type: AmlActionTypes.GetVerificationStatusSuccess,
  verificationStatus,
});

export const getAmlVerificationStatusError = (): GetVerificationStatusErrorAction => ({
  type: AmlActionTypes.GetVerificationStatusError,
});

export const resetAml = (): ResetAmlAction => ({
  type: AmlActionTypes.ResetAml,
});
