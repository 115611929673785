import classNames from 'classnames';

type AppendDefaultContainerClassNameArgs = (
  className?: string,
  error?: string,
  touched?: boolean,
) => string;

export const appendDefaultContainerClassNames: AppendDefaultContainerClassNameArgs = (
  className,
  error,
  touched,
) =>
  classNames(
    'relative w-full rounded-md border-0',
    { '!border-red-500 border-1': error && touched },
    className,
  );
