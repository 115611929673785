import React from 'react';

import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import { Button } from '@/components/buttons/Button';
import PostalCodeField from './PostalCodeField';
import Divider from './Divider';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { ONTARIO_REGISTRATION_CANADA_PROVINCES } from '@/constants/locales';
import {
  AddressRegistrationInfo,
  useAddressInfoData,
  useRegistrationActions,
} from '@/store/registrationStore';
import { capitalize } from '@/utils/utils';
import Form from '@/components/form/Form';
import TextField from '@/components/form/text/TextField';
import SearchDropdownField from '@/components/form/search-dropdown/SearchDropdownField';

const provinces = ONTARIO_REGISTRATION_CANADA_PROVINCES.map(province => ({
  value: province.code,
  label: province.name,
}));

const ontarioProvinceId = provinces.find(province => province.label === 'Ontario')?.value;

const AddressInfoForm = ({
  stepBack,
  stepForward,
}: {
  stepBack: () => void;
  stepForward: () => void;
}) => {
  const { firstName, lastName, address, houseNumber, postCode, city, provinceId } =
    useAddressInfoData();
  const { updateField, updateFields } = useRegistrationActions();
  const intl = useIntl();

  const initialValues: AddressRegistrationInfo = {
    firstName,
    lastName,
    address,
    houseNumber,
    postCode,
    city,
    provinceId,
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(intl.formatMessage({ id: 'input.error.required' }))
      .max(25, intl.formatMessage({ id: 'inputs.error.length' }, { length: 25 }))
      .matches(/^[a-zA-Z' -]+$/, intl.formatMessage({ id: 'inputs.firstName.invalid' })),
    lastName: Yup.string()
      .required(intl.formatMessage({ id: 'input.error.required' }))
      .max(25, intl.formatMessage({ id: 'inputs.error.length' }, { length: 25 }))
      .matches(/^[a-zA-Z' -]+$/, intl.formatMessage({ id: 'inputs.lastName.invalid' })),
    postCode: Yup.string()
      .required(intl.formatMessage({ id: 'input.error.required' }))
      .matches(
        /^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/,
        intl.formatMessage({ id: 'inputs.postcode.invalid' }),
      ),
    address: Yup.string().required(intl.formatMessage({ id: 'input.error.required' })),
    houseNumber: Yup.string()
      .matches(
        /^[1-9]\d*(?: ?(?:[a-z]|[/-] ?\d+[a-z]?))?$/,
        intl.formatMessage({ id: 'inputs.houseNumber.invalid' }),
      )
      .required(intl.formatMessage({ id: 'input.error.required' })),
    city: Yup.string()
      .matches(
        /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/,
        intl.formatMessage({ id: 'inputs.city.invalid' }),
      )
      .required(intl.formatMessage({ id: 'input.error.required' })),
    provinceId: Yup.string()
      .required(intl.formatMessage({ id: 'input.error.required' }))
      .test(
        'ontario-only',
        intl.formatMessage({ id: 'registration.ontario.province.invalid' }),
        value => value === ontarioProvinceId,
      ),
  });

  const handleOnSubmit = (values: AddressRegistrationInfo) => {
    triggerGTag(GTagEvents.ont_reg_step2_continue_cta_click);
    updateFields({
      ...values,
      firstName: capitalize(values.firstName),
      lastName: capitalize(values.lastName),
    });
    stepForward();
  };

  return (
    <div className="w-full h-full pt-5">
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
        className="flex flex-col justify-between gap-8 h-full"
      >
        {() => (
          <>
            <div className="flex flex-col gap-7">
              <div
                className="w-full flex flex-col gap-7 items-stretch sm:flex-row sm:items-center
                [&>div:first-of-type]:flex-[50%] [&>div:last-of-type]:flex-[50%]"
              >
                <TextField
                  data-cy="address_info_fname"
                  label={intl.formatMessage({ id: 'inputs.firstName' })}
                  name="firstName"
                  placeholder={intl.formatMessage({ id: 'registration.ontario.name.placeholder' })}
                  onBlur={({ target: { value } }) =>
                    updateField(
                      'firstName',
                      value ? value.replace(/(^\w|\s\w)/g, char => char.toUpperCase()) : '',
                    )
                  }
                  onValidationError={error =>
                    triggerGTag(GTagEvents.ont_reg_first_name_error, { error })
                  }
                  onFocus={() => triggerGTag(GTagEvents.ont_reg_first_name_click)}
                  className="border-[1px] border-white/30 text-white focus-within:border-blue-blue
                  [&>input]:capitalize [&>input]:placeholder:normal-case"
                />
                <TextField
                  label={intl.formatMessage({ id: 'inputs.middleName' })}
                  name="middleName"
                  placeholder={`(${intl.formatMessage({ id: 'misc.optional' })})`}
                  className="border-[1px] border-white/30 text-white focus-within:border-blue-blue
                  [&>input]:capitalize [&>input]:placeholder:normal-case"
                />
              </div>
              <TextField
                data-cy="address_info_lname"
                label={intl.formatMessage({ id: 'inputs.lastName' })}
                name="lastName"
                placeholder={intl.formatMessage({ id: 'registration.ontario.name.placeholder' })}
                onBlur={({ target: { value } }) =>
                  updateField(
                    'lastName',
                    value ? value.replace(/(^\w|\s\w)/g, char => char.toUpperCase()) : '',
                  )
                }
                onValidationError={error =>
                  triggerGTag(GTagEvents.ont_reg_last_name_error, { error })
                }
                onFocus={() => triggerGTag(GTagEvents.ont_reg_last_name_click)}
                className="border-[1px] border-white/30 text-white focus-within:border-blue-blue
                [&>input]:capitalize [&>input]:placeholder:normal-case"
              />
              <PostalCodeField />
              <Divider />
              <div
                className="w-full flex flex-col gap-7 items-stretch sm:flex-row sm:items-center
                [&>div:first-of-type]:flex-[65%] [&>div:last-of-type]:flex-[35%]"
              >
                <TextField
                  label={intl.formatMessage({ id: 'inputs.address' })}
                  name="address"
                  onBlur={({ target: { value } }) => updateField('address', value)}
                  onValidationError={error =>
                    triggerGTag(GTagEvents.ont_reg_address_error, { error })
                  }
                  onFocus={() => triggerGTag(GTagEvents.ont_reg_address_click)}
                  className="border-[1px] border-white/30 text-white focus-within:border-blue-blue"
                />
                <TextField
                  label={intl.formatMessage({ id: 'inputs.houseNumber' })}
                  data-cy="house_number"
                  name="houseNumber"
                  inputMode="numeric"
                  onBlur={({ target: { value } }) => updateField('houseNumber', value)}
                  onValidationError={error =>
                    triggerGTag(GTagEvents.ont_reg_number_error, { error })
                  }
                  onFocus={() => triggerGTag(GTagEvents.ont_reg_number_click)}
                  className="border-[1px] border-white/30 text-white focus-within:border-blue-blue"
                />
              </div>
              <TextField
                label={intl.formatMessage({ id: 'inputs.city' })}
                name="city"
                onBlur={({ target: { value } }) => updateField('city', value)}
                onValidationError={error => triggerGTag(GTagEvents.ont_reg_city_error, { error })}
                onFocus={() => triggerGTag(GTagEvents.ont_reg_city_click)}
                className="border-[1px] border-white/30 text-white focus-within:border-blue-blue"
              />
              <SearchDropdownField
                name="provinceId"
                label={intl.formatMessage({ id: 'inputs.province' })}
                options={provinces}
                onChange={newValue => updateField('provinceId', newValue)}
                onValidationError={error =>
                  triggerGTag(GTagEvents.ont_reg_province_error, { error })
                }
                onFocus={() => triggerGTag(GTagEvents.ont_reg_province_click)}
                labelClassName="text-white"
                inputClassName="!bg-transparent border-[1px] border-white/30 text-white focus-within:border-blue-blue"
              />
            </div>
            <div className="flex flex-col-reverse items-center gap-4 mt-4 sm:flex-row">
              <Button
                text={intl.formatMessage({ id: 'misc.back' })}
                onClickHandler={() => {
                  triggerGTag(GTagEvents.ont_reg_step2_back_cta_click);
                  stepBack();
                }}
                btnType="secondary"
              />
              <Button
                type="submit"
                text={intl.formatMessage({ id: 'misc.continue' })}
                btnType="primary"
              />
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

export default AddressInfoForm;
