import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import Spinner from '../UI/Spinner';

const StyledConfirmModal = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  overflow: hidden;
  min-height: 100%;
  border-radius: ${props => props.theme.borderRadius};
  background: ${props => props.theme.colors.white};
  position: relative;
  padding: 32px 32px 16px;
  max-width: 380px;

  h1 {
    margin-top: 0;
    font-size: 30px;
  }

  p {
    margin-bottom: 32px;
    font-weight: bold;
  }

  .error {
    color: ${props => props.theme.colors.red};
  }

  .buttons {
    display: flex;
    flex-flow: column;

    .cancel,
    .continue {
      flex: 100%;
      border: none;
      padding: 1em 1.5em;
      cursor: pointer;
      font-weight: bold;
      border-radius: 50vh;
      transition: all 0.2s ease;
    }

    .continue {
      box-shadow: ${props => props.theme.buttonShadow};
      color: ${props => props.theme.colors.white};
      background-color: ${props => props.theme.colors.red};

      &:hover {
        background: ${props => props.theme.colors.red2};
        box-shadow: ${props => props.theme.buttonShadowHover};
      }
    }

    .cancel {
      background: none;
      box-shadow: none;
      opacity: 0.6;

      &.hover {
        opacity: 1;
      }
    }
  }
`;

export function ConfirmModal({
  header,
  text,
  buttonText,
  onSubmit,
  closeModal,
  loading,
  error,
}: {
  header: string;
  text: any;
  buttonText: string;
  onSubmit: () => void;
  closeModal: () => void;
  loading: boolean;
  error?: any;
}): JSX.Element {
  const intl = useIntl();

  return (
    <StyledConfirmModal>
      <h1 className="mb-4">{header}</h1>
      <p>{text}</p>
      {error && <p className="error">{error}</p>}
      <div className="buttons">
        <button className="continue" type="button" onClick={onSubmit}>
          {loading ? <Spinner width={18} height={18} borderwidth={3} color="white" /> : buttonText}
        </button>
        <button className="cancel" type="button" onClick={closeModal}>
          {intl.formatMessage({ id: 'buttons.cancel' })}
        </button>
      </div>
    </StyledConfirmModal>
  );
}
