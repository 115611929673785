import { useDispatch, useSelector } from 'react-redux';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { selectAuthToken } from '@/store/selectors/authSelectors';
import {
  selectKycDocuments,
  selectKycDocumentsLoaded,
  selectKycDocumentsRequested,
} from '@/store/selectors/kycSelectors';
import { selectDepositCount } from '@/store/selectors/userSelectors';
import { useEffect } from 'react';
import { addKycRequirements, kycGetDocuments } from '@/store/actions/kycActions';
import { envIsNormal, envIsSweden } from '@/constants/constants';
import { isRegulatedLocale } from '@/shared/utility';

const useKycLoader = (): void => {
  const locale = useSelector(selectLocale);
  const token = useSelector(selectAuthToken);
  const kycDocuments = useSelector(selectKycDocuments);
  const kycDocumentsLoaded = useSelector(selectKycDocumentsLoaded);
  const depositCount = useSelector(selectDepositCount);
  const kycRequested = useSelector(selectKycDocumentsRequested);
  const dispatch = useDispatch();

  // We decided to fetch the kyc documents across all builds as soon as the user logs in
  useEffect(() => {
    if (!token) {
      return;
    }

    dispatch(kycGetDocuments());
  }, [token]);

  /*
  Trigger this effect when the KYC documents are received
  to check if kyc needs to be requested. There is various logic across
  instances and locales on when to request additional kycs
   */
  useEffect(() => {
    // don't try to request additional kycs if the kyc documents are not yet loaded
    if (!kycDocumentsLoaded) {
      return;
    }

    // don't try to request additional kycs if additional kyc documents were already requested, to prevent infinite loops
    if (kycRequested) {
      return;
    }

    // For the Sweden build we never add any KYC requirements automatically
    if (envIsSweden()) {
      return;
    }

    // for the normal build we want to add kyc requirements (PhotoID, POA, POP) when the user has made a deposit
    // and there are no current kycDocuments
    if (envIsNormal()) {
      // if it's a regulated locale don't add kyc requirements (this is because we use PayNPlay there)
      if (isRegulatedLocale(locale)) {
        return;
      }

      if (kycDocuments.length > 0) {
        // if there are some kyc documents already, don't add additional kyc requirements
        return;
      }

      if (!depositCount) {
        // if user has not made a deposit yet, don't add additional kyc requirements
        return;
      }

      dispatch(addKycRequirements());
    }
  }, [locale, kycDocuments, kycDocumentsLoaded, depositCount, kycRequested]);
};

export default useKycLoader;
