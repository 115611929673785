export function getPercentage(vipLevel, gameType) {
  if (vipLevel === 'ผู้เล่นทั่วไป') {
    switch (gameType) {
      case 'Live Casino':
      case 'Other':
        return 0.003;
      case 'Slots':
      case 'Video Slots':
      case '3D Slots':
        return 0.004;
      case 'Excluded':
      default:
        return 0;
    }
  } else if (vipLevel === 'บลู') {
    switch (gameType) {
      case 'Live Casino':
      case 'Other':
        return 0.004;
      case 'Slots':
      case 'Video Slots':
      case '3D Slots':
        return 0.007;
      case 'Excluded':
      default:
        return 0;
    }
  } else if (vipLevel === 'โกล') {
    switch (gameType) {
      case 'Live Casino':
      case 'Other':
        return 0.006;
      case 'Slots':
      case 'Video Slots':
      case '3D Slots':
        return 0.008;
      case 'Excluded':
      default:
        return 0;
    }
  } else if (vipLevel === 'แพลตตินั่ม') {
    switch (gameType) {
      case 'Live Casino':
      case 'Other':
        return 0.007;
      case 'Slots':
      case 'Video Slots':
      case '3D Slots':
        return 0.01;
      case 'Excluded':
      default:
        return 0;
    }
  } else {
    return 0;
  }
}

export function calculateLeftToNextLevel(levelEnd, totalBets) {
  return (levelEnd - totalBets).toFixed(2).toString();
}

export function calculateTurnoverPercentaget(levelStart, levelEnd, totalBets) {
  return parseInt(((totalBets - levelStart) / (levelEnd - levelStart)) * 100);
}

export function getVipLevel(totalBets) {
  // User has betted less than or equal to 4000000
  if (totalBets < 4000000) {
    const levelStart = 0;
    const levelEnd = 3999999;

    return {
      totalBets,
      currentLevel: 'ผู้เล่นทั่วไป',
      previousLevel: '',
      nextLevel: 'บลู',
      leftToNextLevel: calculateLeftToNextLevel(levelEnd, totalBets),
      turnoverPercent: calculateTurnoverPercentaget(levelStart, levelEnd, totalBets),
    };
  }

  // User has betted more than 3999999 and less than 6600000
  if (totalBets > 3999999 && totalBets < 6600000) {
    const levelStart = 4000000;
    const levelEnd = 6599999;

    return {
      totalBets,
      currentLevel: 'บลู',
      previousLevel: 'ผู้เล่นทั่วไป',
      nextLevel: 'โกล',
      leftToNextLevel: calculateLeftToNextLevel(levelEnd, totalBets),
      turnoverPercent: calculateTurnoverPercentaget(levelStart, levelEnd, totalBets),
    };
  }

  // User has betted more than 6600000 and less than 14000000
  if (totalBets > 6599999 && totalBets < 14000000) {
    const levelStart = 6600000;
    const levelEnd = 13999999;

    return {
      totalBets,
      currentLevel: 'โกล',
      previousLevel: 'บลู',
      nextLevel: 'แพลตตินั่ม',
      leftToNextLevel: calculateLeftToNextLevel(levelEnd, totalBets),
      turnoverPercent: calculateTurnoverPercentaget(levelStart, levelEnd, totalBets),
    };
  }

  // User has betted more than 14000000
  return {
    totalBets,
    currentLevel: 'แพลตตินั่ม',
    previousLevel: 'โกล',
    nextLevel: '',
    leftToNextLevel: 0,
    turnoverPercent: 0,
  };
}
