import { Action } from 'redux';
import { WpBonusContentModel } from '@/models/wp-bonus-content.model';

export enum BonusesContentActionTypes {
  Get = 'GET_BONUS_CONTENT',
  Success = 'GET_BONUS_CONTENT_SUCCESS',
  Error = 'GET_BONUS_CONTENT_ERROR',
}

export type GetBonusContentAction = Action<BonusesContentActionTypes.Get> & {
  bonusId: number;
  locale: string;
};

export type GetUserBonusAction = Action<BonusesContentActionTypes.Get> & {
  bonusId: number;
  token: string;
};

export type GetBonusContentSuccessAction = Action<BonusesContentActionTypes.Success> & {
  bonusId: number;
  content: WpBonusContentModel;
  locale: string;
};

export type GetBonusContentErrorAction = Action<BonusesContentActionTypes.Error> & {
  bonusId: number;
  locale: string;
};

export type BonusesContentActions =
  | GetBonusContentAction
  | GetBonusContentSuccessAction
  | GetBonusContentErrorAction;

export const getBonusContent = (bonusId: number, locale: string): GetBonusContentAction => ({
  type: BonusesContentActionTypes.Get,
  bonusId,
  locale,
});

export const getBonusContentSuccess = (
  bonusId: number,
  locale: string,
  content: WpBonusContentModel,
): GetBonusContentSuccessAction => ({
  type: BonusesContentActionTypes.Success,
  bonusId,
  locale,
  content,
});

export const getBonusContentError = (
  bonusId: number,
  locale: string,
): GetBonusContentErrorAction => ({
  type: BonusesContentActionTypes.Error,
  bonusId,
  locale,
});
