const theme = {
  borderRadius: '6px',
  borderRadiusPill: '50vh',
  boxShadow: '0 10px 20px 0 rgba(0,0,0,0.15), 0 3px 6px 0 rgba(0,0,0,0.10);',
  boxShadowHover: '0 15px 25px 0 rgba(0,0,0,0.15), 0 5px 10px 0 rgba(0,0,0,0.05);',
  buttonShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);',
  buttonShadowHover: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);',
};

theme.colors = {
  // Lucky Blue
  blue1: '#00b5ce',
  blue1Hover: '#0097ac',
  blue2: '#007078',
  blue3: '#00262b',

  // Lucky Red
  red: '#e52e12',
  red1: '#e52e12',
  red2: '#851811',

  // Cash Green
  green: '#48B280',
  greenHover: '#3e986e',

  // grey
  grey10: '#EFEFEF',
  grey20: '#DADADA',
  grey30: '#C4C4C4',
  grey40: '#AEAEAE',
  grey50: '#999999',
  grey60: '#838383',
  grey70: '#6D6D6D',
  grey80: '#575757',
  grey90: '#424242',
  grey100: '#2C2C2C',
  black: '#222',

  // yellow
  yellow10: '#F9EED4',
  yellow20: '#F5E3B8',
  yellow30: '#F1D89C',
  yellow40: '#EDCD80',
  yellow50: '#E9C364',
  yellow60: '#BFA052',
  yellow70: '#957D40',
  yellow80: '#6A592E',
  yellow90: '#40361C',
  yellow100: '#16120A',

  // white
  white10: 'rgba(255, 255, 255, 0.1)',
  white20: 'rgba(255, 255, 255, 0.2)',
  white30: 'rgba(255, 255, 255, 0.3)',
  white40: 'rgba(255, 255, 255, 0.4)',
  white50: 'rgba(255, 255, 255, 0.5)',
  white60: 'rgba(255, 255, 255, 0.6)',
  white70: 'rgba(255, 255, 255, 0.7)',
  white80: 'rgba(255, 255, 255, 0.8)',
  white90: 'rgba(255, 255, 255, 0.9)',
  white100: 'rgba(255, 255, 255, 1)',

  // black
  black10: 'rgba(0, 0, 0, 0.1)',
  black20: 'rgba(0, 0, 0, 0.2)',
  black30: 'rgba(0, 0, 0, 0.3)',
  black40: 'rgba(0, 0, 0, 0.4)',
  black50: 'rgba(0, 0, 0, 0.5)',
  black60: 'rgba(0, 0, 0, 0.6)',
  black70: 'rgba(0, 0, 0, 0.7)',
  black80: 'rgba(0, 0, 0, 0.8)',
  black90: 'rgba(0, 0, 0, 0.9)',
  black100: 'rgba(0, 0, 0, 1)',

  // green
  green10: '#eff9f4',
  green20: '#cbeadb',
  green30: '#a7dbc2',
  green40: '#76c8a1',
  green50: '#48b280',
  green60: '#398e66',
  green70: '#2b694c',
  green80: '#1c4532',
  green90: '#0d2118',
  green100: '#08150f',

  // red
  red10: '#FBEAEA',
  red20: '#F3C1C1',
  red30: '#EB9998',
  red40: '#DB4746',
  red50: '#D41F1E',
  red60: '#AE1A19',
  red70: '#871414',
  red80: '#610F0E',
  red90: '#3A0909',
  red100: '#140303',

  yellow: '#E9C364',
  white: '#fff',
  offWhite: '#F4FAFB',
  blackTransparent: 'rgba(0, 0, 0, .1)',
  blackTransparentHover: 'rgba(0, 0, 0, .15)',
};

theme.breakpoints = {
  small: '767px',
  medium: '1080px',
};

theme.shadows = {
  largeBox: '0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);',
};

export default theme;
