import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import Anchor from '../UI/Buttons/Anchor';
import CloseButton from '../UI/Buttons/CloseButton';
import Spinner from '../UI/Spinner';
import { isEmpty } from '@/shared/utility';
import { useTermsAndConditionsLoader } from '@/hooks/useTermsAndConditionsLoader';

const StyledTermsAndConditionsModal = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  max-width: 520px;
  overflow: hidden;
  border-radius: ${props => props.theme.borderRadius};
  background: white;
  padding: 64px 16px 16px;
  text-align: left;
  min-height: 400px;
  position: relative;

  @media (min-width: ${props => props.theme.breakpoints.small}) {
    padding: 64px 32px 32px;
  }

  h1 {
    font-size: 32px;
  }

  ol > li:before {
    content: counters(item, '.') '. ';
    counter-increment: item;
    font-weight: bold;
    display: inline-block;
  }

  ul > li:before {
    content: '·';
  }

  ul {
    margin: 1em 0 48px;
    padding: 0;
  }

  ol {
    counter-reset: item;
    margin: 1em 0 48px;
    padding: 0;

    li {
      position: relative;

      &:before {
        font-size: 24px;
        font-family: 'Avenir Next', sans-serif;
        line-height: 1.2;
        display: inline-block;
      }

      h2 {
        font-size: 24px;
      }

      ol {
        li {
          position: relative;

          &:before {
            font-size: 15px;
            opacity: 0.8;
            line-height: 1.6;
            position: relative;
            top: auto;
            right: auto;
            margin-right: 6px;
            font-family: 'Karla', sans-serif;
            float: left;
          }

          ol {
            margin-left: 32px;
          }
        }
      }
    }
  }

  h2 {
    display: inline;
    margin: 0 0 8px;
  }

  p,
  li {
    font-size: 16px;
    display: block;
    position: relative;
  }

  .close {
    font-weight: bold;
    color: black;
    opacity: 0.6;
    text-decoration: none;
    padding: 16px;
    margin: 0;
    width: 100%;

    &:hover {
      opacity: 1;
    }
  }
`;

function TermsAndConditionsModal({ closeModal }) {
  const termsAndConditions = useTermsAndConditionsLoader();
  const intl = useIntl();

  useEffect(() => {
    const modal = document.querySelector('.ReactModal__Overlay');
    const textbox = document.querySelector('.text-modal .text');

    if (modal !== null && modal.scrollTop !== 0) {
      modal.scrollTo(0, 0);
    }
    if (textbox !== null && textbox.scrollTop !== 0) {
      textbox.scrollTo(0, 0);
    }
  }, []);

  return (
    <StyledTermsAndConditionsModal>
      <CloseButton left onClick={closeModal} />
      <h1>{intl.formatMessage({ id: 'termsAndConditions.header' })}</h1>
      <p className="subheader">{!isEmpty(termsAndConditions) && termsAndConditions.last_updated}</p>
      <div className="content">
        {!isEmpty(termsAndConditions) ? (
          <>
            {parse(termsAndConditions.content.toString())}
            <Anchor className="close" onClick={closeModal}>
              {intl.formatMessage({ id: 'termsAndConditions.closeButton' })}
            </Anchor>
          </>
        ) : (
          <Spinner dark absolute />
        )}
      </div>
    </StyledTermsAndConditionsModal>
  );
}

TermsAndConditionsModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default TermsAndConditionsModal;
