import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Currency from './UI/Currency';
import { CDN_BASE, CDN_IMAGES_GAMES_PATH } from '@/constants/constants';
import { GameCardVM } from '@/models/vm/game-card-vm.model';

export function SearchPalettePreview({
  game,
  openGame,
  showLogin,
}: {
  game: GameCardVM | null;
  openGame: (game: GameCardVM) => void;
  showLogin: boolean;
}): JSX.Element | null {
  const [previewItem, setPreviewItem] = useState<GameCardVM | null>(null);
  const intl = useIntl();

  useEffect(() => {
    if (game) {
      setPreviewItem(game);
    }
  }, [game]);

  if (!previewItem) {
    return null;
  }

  return (
    <div className="hidden h-96 w-72 flex-col justify-between overflow-y-auto p-6 sm:flex">
      <div className="flex h-full flex-col justify-between text-center">
        <div>
          <img
            src={`${CDN_BASE}/cdn-cgi/image/fit=cover,width=96,height=118,format=auto,dpr=2${CDN_IMAGES_GAMES_PATH}/${previewItem.slug}.jpg`}
            alt={previewItem.name}
            height={118}
            width={96}
            className="mx-auto w-24 rounded-md bg-gray-100 text-transparent shadow-md"
          />
          <h2 className="mt-6 mb-0 text-lg font-bold text-gray-900">{previewItem.name}</h2>
          <p className="truncate text-sm leading-6 text-gray-500">{previewItem.shortDesc}</p>
          <div className="mt-4 flex justify-center space-x-1">
            {previewItem.jackpot && (
              <div className="inline-flex rounded-md bg-gray-200 px-2 py-1 text-sm font-bold">
                <Currency currency="EUR" amount={previewItem.jackpot.amount} />
              </div>
            )}
            {previewItem.isLive && (
              <div className="inline-flex rounded-md bg-gray-200 px-2 py-1 text-sm font-bold">
                {intl.formatMessage({ id: 'gameCard.live' })}
              </div>
            )}
            {previewItem.isNew && (
              <div className="inline-flex rounded-md bg-gray-200 px-2 py-1 text-sm font-bold">
                {intl.formatMessage({ id: 'gameCard.new' })}
              </div>
            )}
            {previewItem.isHot && (
              <div className="inline-flex rounded-md bg-gray-200 px-2 py-1 text-sm font-bold">
                {intl.formatMessage({ id: 'gameCard.hot' })}
              </div>
            )}
          </div>
        </div>
        <button
          type="button"
          onClick={() => {
            openGame(previewItem);
          }}
          className="w-full rounded-full bg-cyan-500 p-3 font-bold text-white shadow-md transition-all hover:bg-cyan-600 hover:shadow-lg"
        >
          {showLogin
            ? intl.formatMessage({ id: 'gameCard.loginToPlay' })
            : intl.formatMessage({ id: 'gameCard.play' })}
        </button>
      </div>
    </div>
  );
}
