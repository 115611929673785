import { BUILD_MODE } from '@/constants/constants';
import { isRegulatedLocale } from './utility';

export enum NetreferAction {
  Signup = 'Sign Up',
  FTD = 'First Time Deposit',
}

export function triggerNetrefer(
  action: string,
  btag: string,
  userId: number,
  locale: string,
): void {
  // this function returns the variant for netreferrer depending on the build or locale
  const getVariant = (locale: string) => {
    if (BUILD_MODE === 'canada') {
      return 5;
    }

    if (BUILD_MODE === 'sweden') {
      return 4;
    }

    if (isRegulatedLocale(locale)) {
      return 3;
    }

    return 1;
  };

  const cid = '65c0af48-a01c-4f52-b8ee-309805e26e85';
  const an = action;
  const b = getVariant(locale);
  const s = 'all';
  const to = 'both';
  const tag = btag;
  const customerID = userId;

  const fp = new Image();
  fp.src =
    'https://tracking.netrefer.com/Tracking.svc/Track?' +
    'to=' +
    to +
    '&clientID=' +
    cid +
    '&ActionName=' +
    an +
    '&b=' +
    b +
    '&s=' +
    s +
    '&TrackingTag=' +
    tag +
    '&customerID=' +
    customerID;
}

export function triggerNetreferSignup(btag: string, userId: number, locale: string): void {
  triggerNetrefer(NetreferAction.Signup, btag, userId, locale);
}

export function triggerNetreferFTD(btag: string, userId: number, locale: string): void {
  triggerNetrefer(NetreferAction.FTD, btag, userId, locale);
}
