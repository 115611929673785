import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledNotificationBadge = styled.span`
  position: absolute;
  top: 0;
  left: 100%;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  background: ${props => props.theme.colors.red};
  color: ${props => props.theme.colors.white};
  font-size: 12px;
  text-align: center;
  line-height: 16px;
  pointer-events: none;
`;

const NotificationBadge = ({ count }) => (
  <StyledNotificationBadge className="notification-badge" title="Available bonuses">
    {count}
  </StyledNotificationBadge>
);

NotificationBadge.propTypes = {
  count: PropTypes.number.isRequired,
};

export default NotificationBadge;
