import React from 'react';
import Spinner from '../UI/Spinner';
import classNames from 'classnames';

export function BtnOneLineBlue({
  onClickHandler,
  isLoading = false,
  text,
  disabled = false,
  type = 'button',
  className,
}: {
  onClickHandler?: () => void;
  isLoading?: boolean;
  text: string;
  disabled?: boolean;
  type?: 'button' | 'submit';
  className?: string;
}): JSX.Element {
  return (
    <button
      type={type === 'submit' ? 'submit' : 'button'}
      className={classNames(
        `relative min-h-[64px] w-full rounded-full bg-cyan-500 p-3 text-white shadow-md transition hover:bg-cyan-600
         disabled:cursor-not-allowed disabled:opacity-50`,
        className,
      )}
      onClick={onClickHandler}
      disabled={isLoading || disabled}
    >
      {isLoading && (
        <Spinner absolute={true} width={20} height={20} borderwidth={3} color="white" />
      )}
      <span className={`text-md font-bold leading-6 ${isLoading ? 'opacity-0' : ''}`}>{text}</span>
    </button>
  );
}
