import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { kycGetDocuments } from '@/store/actions/kycActions';
import {
  selectCrucialUserDataErrors,
  selectIsCrucialUserDataLoading,
} from '@/store/selectors/combined.selectors';
import { ButtonWithLoader } from '../Common/ButtonWithLoader';
import { PlugIcon } from '../UI/Icons/PlugIcon';
import { MissingUserData } from '@/models/enum/missing-user-data.enum';
import { getLimits } from '@/store/actions/limitsActions';
import { getUserWallet } from '@/store/actions/user';

export function IncompleteUserDataModal({ closeModal }: { closeModal: () => void }): JSX.Element {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsCrucialUserDataLoading);
  const crucialErrors = useSelector(selectCrucialUserDataErrors);
  const intl = useIntl();

  useEffect(() => {
    if (crucialErrors.length === 0) {
      closeModal();
    }
  }, [crucialErrors]);

  const onClickTryAgain = () => {
    crucialErrors.forEach(crucialError => {
      if (crucialError === MissingUserData.Kyc) {
        dispatch(kycGetDocuments());
      }
      if (crucialError === MissingUserData.DepositLimits) {
        dispatch(getLimits());
      }
      if (crucialError === MissingUserData.Wallet) {
        dispatch(getUserWallet());
      }
    });
  };

  return (
    <div className="w-full max-w-sm rounded-xl bg-white px-8 py-8">
      <div className="flex justify-center">
        <PlugIcon width="64px" height="64px" className="fill-gray-600" />
      </div>
      <h1 className="mt-4 text-lg font-bold">
        {intl.formatMessage({ id: 'modal.incompleteData.header' })}
      </h1>
      <p className="mt-3 mb-0">{intl.formatMessage({ id: 'modal.incompleteData.text' })}</p>
      <ButtonWithLoader
        loading={isLoading}
        message={intl.formatMessage({ id: 'misc.tryAgain' })}
        onClick={onClickTryAgain}
      />
    </div>
  );
}
