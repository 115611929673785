import React, { lazy, Suspense, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { selectIsAuthenticated } from '@/store/selectors/authSelectors';
import { selectAutoLoginFinished } from '@/store/selectors/autoLoginSelectors';
import { useCashierShow } from '@/features/cashier/useCashier';
import { envIsMexico } from '@/constants/constants';
import { openModal } from '@/store/actions/modal';

const HomeMexico = lazy(() => import('./HomeMexico'));
const HomeNormal = lazy(() => import('./HomeNormal'));

function Home({ cashierDrawerOpen }: { cashierDrawerOpen?: boolean }) {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const autoLoginFinished = useSelector(selectAutoLoginFinished);
  const dispatch = useDispatch();
  const showCashier = useCashierShow();

  useEffect(() => {
    // If the user is logged in and "redirected"
    // from cashier page, open the cashier drawer
    if (cashierDrawerOpen && autoLoginFinished && isAuthenticated) {
      showCashier();
    }

    // Open the Login modal when logged-out users
    // are "redirected" to home page from cashier page
    if (cashierDrawerOpen && autoLoginFinished && !isAuthenticated) {
      dispatch(openModal('login'));
    }
  }, [isAuthenticated, autoLoginFinished]);

  return (
    <Suspense fallback={<div className="w-full min-h-[200vh]" />}>
      {envIsMexico() && <HomeMexico />}
      {!envIsMexico() && <HomeNormal />}
    </Suspense>
  );
}

export default Home;
