import axios from 'axios';
import { BUILD_MODE } from '@/constants/constants';
import { logException } from '@/utils/logger';

export const axiosInstance = axios.create();

export function axiosInterceptor(store) {
  axiosInstance.interceptors.request.use(
    request => {
      const state = store.getState();
      const { locale } = state.common;
      const { data } = request;

      if (!data) {
        request.data = {};
      }

      if (BUILD_MODE === 'canada') {
        request.data.locale = `${locale}-on`;
        return request;
      }

      request.data.locale = locale;

      return request;
    },
    error => {
      // Do something with request error
      logException(error, {
        location: 'Axios request interceptor',
      });
      return Promise.reject(error);
    },
  );

  axiosInstance.interceptors.response.use(
    response => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    error => {
      logException(error, {
        location: 'Axios response interceptor',
        url: error.config.url,
      });
      return Promise.reject(error);
    },
  );
}
