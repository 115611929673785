import React from 'react';
import { useIntl } from 'react-intl';

export function PayNPlayModalHeader({ loggedOut }: { loggedOut: boolean }): JSX.Element {
  const intl = useIntl();

  if (loggedOut) {
    return (
      <>
        <div>
          <h3 className="m-0 text-2xl font-bold leading-tight">
            {intl.formatMessage({ id: 'loggedout.header' })}
          </h3>
          <p className="text-md mt-2 mb-0 font-bold text-gray-500">
            {intl.formatMessage({ id: 'loggedout.text' })}
          </p>
        </div>
      </>
    );
  }

  return (
    <div>
      <h3 className="m-0 mx-auto max-w-[260px] text-2xl font-bold leading-tight">
        {intl.formatMessage({ id: 'paynplay.modal.header' })}
      </h3>
      <p className="text-md mt-2 mb-0 font-bold text-gray-500">
        {intl.formatMessage({ id: 'paynplay.modal.subtitle' })}
      </p>
    </div>
  );
}
