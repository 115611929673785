import {
  CDN_BASE,
  CDN_IMAGES_COMMON_PATH,
  CDN_IMAGES_GAMES_PATH,
  CDN_IMAGES_PATH,
  CDN_PAYMENT_PROVIDERS_IMAGES_COMMON_PATH,
  envIsMexico,
  IMAGES_BASE,
} from '@/constants/constants';

interface CDNImageProps {
  width: string;
  height: string;
  fit?: string;
  gravity?: string;
  dpr?: string;
  path?: string;
}

/* the images come from Wordpress in most cases, and there we have some conventions, for example the images are named like this: "/bonus/game-name"
 but as that is prone to human errors, and somebody can also input /bonus/game-name.jpg, we want to sanitize the image name to be sure that it is always in the correct format
*/
const sanitizeImageName = (img: string): string => {
  // cover the case if somebody already included the .
  if (img.endsWith('.jpg') || img.endsWith('.png') || img.endsWith('.jpeg')) {
    return img;
  }
  // if the path is not already included we want to fallback to .png
  return `${img}.png`;
};

export const cdnImage = (img: string, props: CDNImageProps): string => {
  return `${CDN_BASE}/cdn-cgi/image/fit=${props.fit || 'cover'},width=${props.width},height=${
    props.height
  },gravity=${props.gravity || '1x0'},format=auto,dpr=${props.dpr || '1'}${
    props.path || CDN_IMAGES_COMMON_PATH
  }/${img}`;
};

export const gameThumbImg = (slug: string): string => {
  return cdnImage(`${slug}.jpg`, {
    width: '167',
    height: '205',
    dpr: '2',
    path: CDN_IMAGES_GAMES_PATH,
  });
};

export const gameThumbImgMexico = (slug: string, large: boolean): string => {
  return cdnImage(`${slug}.jpg`, {
    width: large ? '260' : '130',
    height: 'auto',
    dpr: '2',
    path: CDN_IMAGES_GAMES_PATH,
  });
};

export const paymentProviderImgMexico = (slug: string): string => {
  return cdnImage(`${slug}.png`, {
    width: '160',
    height: 'auto',
    dpr: '2',
    path: CDN_PAYMENT_PROVIDERS_IMAGES_COMMON_PATH + '/mexico',
    fit: 'contain',
  });
};

export const gspMexicoImage = (name: string, props?: CDNImageProps): string => {
  const width = props?.width ?? '20';
  const height = props?.height ?? '20';

  return cdnImage(`${name}.png`, {
    width: width,
    height: height,
    dpr: '2',
    path: CDN_IMAGES_COMMON_PATH + '/game-providers/mexico',
  });
};

export const gspGridMexicoImage = (name: string): string => {
  return cdnImage(`${name}.png`, {
    width: 'auto',
    height: '50px',
    dpr: '2',
    fit: 'contain',
    path: CDN_IMAGES_COMMON_PATH + '/game-providers/mexico/white',
  });
};

export const bonusCardMexicoImage = (name: string): string => {
  return cdnImage(`${name}`, {
    width: 'auto',
    height: 'auto',
    dpr: '2',
    path: CDN_IMAGES_PATH + '/bonuses',
    gravity: 'auto',
  });
};

export const bonusFluidImage = (name: string): string => {
  if (!envIsMexico()) {
    return `${IMAGES_BASE}/cdn-cgi/image/fit=pad,width=84,height=84,format=auto,dpr=2${sanitizeImageName(
      name,
    )}`;
  }
  return cdnImage(`${sanitizeImageName(name)}`, {
    height: '84',
    width: '84',
    dpr: '2',
    fit: 'pad',
    path: CDN_IMAGES_PATH + '/bonuses',
    gravity: 'auto',
  });
};

export const bannerDesktopImage = (name: string): string => {
  return cdnImage(`${name}`, {
    width: 'auto',
    height: '800px',
    dpr: '2',
    path: CDN_IMAGES_COMMON_PATH + '/banners',
    fit: 'contain',
  });
};

export const bannerMobileImage = (name: string): string => {
  return cdnImage(`${name}`, {
    width: 'auto',
    height: '700px',
    dpr: '2',
    path: CDN_IMAGES_COMMON_PATH + '/banners',
    fit: 'contain',
  });
};
