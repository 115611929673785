enum StorageKeys {
  CookiesConsent = 'LD_COOKIES_CONSENT',
}

const StorageManager = {
  getCookiesConsent: (): { accepted: boolean; performance: boolean } => {
    const current = localStorage.getItem(StorageKeys.CookiesConsent);
    if (!current) {
      return { accepted: false, performance: false };
    }
    const toJson = JSON.parse(current);
    return { accepted: toJson.accepted, performance: toJson.performance };
  },
  saveCookiesConsent: (accepted: boolean, performance: boolean) => {
    const toSave = JSON.stringify({ accepted, performance });
    localStorage.setItem(StorageKeys.CookiesConsent, toSave);
  },
};

export default StorageManager;
