import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { axiosInstance as axios } from '../../shared/axiosInstance';
import { useRebate } from '@/hooks/useRebate';
import { isEmpty } from '@/shared/utility';
import { getPercentage, getVipLevel } from '@/utils/lucky-rewards-club.utils';
import { getMonday } from '@/utils/time.utils';
import { LuckyRewardsClubTerms } from '../LuckyRewardsClubTerms';
import { ProgressBar } from '../ProgressBar';
import Button from '../UI/Buttons/Button';
import Currency from '../UI/Currency';
import Spinner from '../UI/Spinner';
import { getDateRangeBetweenDays } from '@lucky7ventures/lucky-components';
import { CDN_BASE, CDN_IMAGES_COMMON_PATH, CDN_IMAGES_GAMES_PATH } from '@/constants/constants';
import { getUserTags } from '@/store/actions/user';

const StyledLuckyRewardsClub = styled.div`
  .page-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    text-align: center;
    min-height: 300px;
    overflow: hidden;
    padding: 64px 32px;
    color: white;
    position: relative;

    .background-image {
      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        object-fit: cover;
        pointer-events: none;
        object-position: 100% 100%;
      }

      &:after {
        content: ' ';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        inset: 0px;
        pointer-events: none;
        background: rgba(0, 0, 0, 0.5);
      }
    }

    .text {
      position: relative;
      color: white;

      h1 {
        margin: 0;
        align-items: center;
        justify-content: center;
        display: flex;
        flex: 100%;
        font-size: 32px;
        color: inherit;

        @media (min-width: ${props => props.theme.breakpoints.small}) {
          font-size: 44px;
        }
      }

      p {
        font-size: 18px;
        margin: 8px 0 0;

        @media (min-width: ${props => props.theme.breakpoints.small}) {
          font-size: 20px;
        }
      }
    }
  }

  .content {
    max-width: 1400px;
    min-height: 400px;
    margin: 0 auto;
    padding: 2rem 0;
    overflow: hidden;
    position: relative;

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      padding: 4rem 2rem;
    }

    p:not(.big, .progress-level) {
      max-width: 720px;
      opacity: 0.75;
    }

    .current-next-level {
      display: flex;
      justify-content: space-between;
      margin-bottom: -10px;
      margin-top: 2rem;

      p {
        margin-bottom: 0;
        font-size: 0.8rem;
        font-weight: bold;
      }
    }

    .progress {
      display: grid;
      grid-gap: 1rem;
      margin-bottom: 1rem;

      @media (min-width: ${props => props.theme.breakpoints.small}) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 2rem;
        margin-bottom: 2rem;
      }
    }

    .section,
    .level {
      background: white;
      border-radius: 8px;
      padding: 2rem;
      margin-bottom: 1rem;
      border: 1px solid #eee;

      @media (min-width: ${props => props.theme.breakpoints.small}) {
        margin-bottom: 2rem;
      }

      &.opt-in {
        max-width: 600px;
        margin: 0 auto;
        text-align: center;
      }

      &.achieved-balance {
        height: 100%;
        position: relative;
        overflow: hidden;

        .big {
          margin-bottom: 1rem;
        }

        img {
          width: 200px;
          height: auto;
          position: absolute;
          bottom: 0;
          right: 0;
          display: none;

          @media (min-width: ${props => props.theme.breakpoints.small}) {
            display: block;
          }
        }
      }

      h2,
      h3 {
        margin-top: 0;
        padding-bottom: 1rem;
        border-bottom: 1px solid #eee;
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }

      p {
        margin-bottom: 1rem;
      }

      br {
        margin-bottom: 1rem;
      }

      p:last-child {
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .vip-levels {
      h3 {
        margin-top: 4rem;
      }
    }

    .history {
      margin-top: 2rem;
    }

    .table-wrapper {
      width: calc(100% + 4rem);
      margin-left: -2rem;
      padding: 0 2rem 1rem;
      overflow-x: auto;
      display: flex;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      margin-top: 2rem;

      &::-webkit-scrollbar {
        display: none;
      }

      &:after {
        content: '';
        width: 2rem;
      }

      @media (min-width: ${props => props.theme.breakpoints.small}) {
        &:after {
          width: 0;
        }
      }
    }

    table {
      width: 100%;
      border-radius: 8px;
      border-collapse: collapse;
      overflow: hidden;
      box-shadow: ${props => props.theme.buttonShadow};
      font-size: 0.9rem;

      @media (min-width: ${props => props.theme.breakpoints.small}) {
        font-size: 1rem;
      }

      th {
        background: #efefef;
      }

      td,
      th {
        padding: 0.5rem 1rem;
        border-bottom: 1px solid #eee;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &.top5 {
        td,
        th {
          &.game {
            padding-right: 0;
            width: 50px;
          }

          &.gameName {
            max-width: 140px;
          }
        }

        .image-wrapper {
          position: relative;
          background: rgba(0, 0, 0, 0.2);
          border-radius: 3px;
          overflow: hidden;
          display: block;
          width: 32px;
          font-size: 0;

          &:after {
            content: '';
            display: block;
            padding-bottom: 122.75%;
          }

          img {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }

  .big {
    font-size: 2rem;
  }

  .small {
    font-size: 0.9rem;
  }

  .coin {
    width: 1em;
    height: 1em;
    background: #eee;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    top: -0.25em;
    margin-right: 0.5em;
    position: relative;

    &:after {
      font-size: 0.5em;
      margin-top: 1px;
      font-weight: bold;
    }

    &.ผู้เล่นทั่วไป {
      &:after {
        content: '1';
      }
    }

    &.บลู {
      background: ${props => props.theme.colors.blue1};
      color: white;

      &:after {
        content: '2';
      }
    }

    &.โกล {
      background: ${props => props.theme.colors.yellow50};
      color: ${props => props.theme.colors.yellow80};

      &:after {
        content: '3';
      }
    }

    &.แพลตตินั่ม {
      background: #e5e4e2;
      color: ${props => props.theme.colors.green80};

      &:after {
        content: '4';
      }
    }
  }

  .no-margin {
    margin: 0;
  }
`;

async function fetchGames() {
  const { data } = await axios.post('/api/games/lucky-rewards-club-games');

  if (!data) {
    throw new Error('Couldnt fetch games');
  }

  return data;
}

async function fetchTransactions(token) {
  const { from, to } = getDateRangeBetweenDays(new Date(), -30, 0);
  const { data } = await axios.post('/api/games/transactions', {
    token,
    from,
    to,
  });

  if (!data.data) {
    throw new Error('Couldnt fetch transactions');
  }

  return data.data;
}

function createWeekHistoryObject(rounds) {
  let liveCasino = 0;
  let slots = 0;
  let total = 0;

  rounds.forEach(round => {
    if (round.GameType === 'Live Casino') {
      liveCasino += round.Bet + round.BonusAmount;
      total += round.Bet + round.BonusAmount;
    }
    if (round.GameType === 'Slots') {
      slots += round.Bet + round.BonusAmount;
      total += round.Bet + round.BonusAmount;
    }
  });

  return {
    liveCasino: liveCasino.toFixed(2),
    slots: slots.toFixed(2),
    total: total.toFixed(2),
  };
}

const targetedCategories = ['Live Casino', 'Other', 'Slots', 'Video Slots', '3D Slots'];

export default function LuckyRewardsClub() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(state => state.auth.token);
  const { user, tags } = useSelector(state => state.user);
  const [vipLevel, setVipLevel] = useState(null);
  const [balance, setBalance] = useState(null);
  const [gamingHistory, setGamingHistory] = useState({});
  const [top5, setTop5] = useState([]);
  const hasRebateTag = tags.some(tag => tag.Name === 'Rebate');
  const { redirectFromRebate } = useRebate();

  // Redirect users with bonus restricted tag
  // or if the player is not on /th
  useEffect(() => {
    if (redirectFromRebate) {
      navigate('..');
    }
  }, [redirectFromRebate]);

  function assignRebateTag() {
    const tagPayload = {
      tagId: process.env.REACT_APP_REBATE_TAG_ID,
      userId: user.UserID,
    };

    axios
      .post('/api/user/assigntag', tagPayload)
      .then(res => {
        if (res.data.success) {
          dispatch(getUserTags(token));
        }
      })
      .catch(err => console.error(err));
  }

  useEffect(() => {
    if (token) {
      fetchGames()
        .then(jsonGames => {
          fetchTransactions(token).then(gameRounds => {
            let bets = 0;
            const top5 = [];
            const gameRoundsWithoutWinnings = gameRounds.filter(
              round => round.ActivityType === 'Bet',
            );

            // Add game type to the game rounds object
            const gameRoundsWithGameType = gameRoundsWithoutWinnings.map(round => {
              const game = jsonGames.filter(game => game.Name === round.Game);

              if (!game || game.length === 0) {
                return round;
              }

              const newRound = round;
              newRound.GameType = game[0]?.GameType || null;
              newRound.GameID = game[0]?.ID || null;
              newRound.Provider = game[0]?.ShortDescription || null;
              newRound.Slug = game[0]?.Slug || null;

              if (targetedCategories.includes(newRound.GameType)) {
                bets += round.Bet + round.BonusAmount;
              }

              // Add games to top 5
              const existingGame = top5.filter(game => game.Name === round.Game);

              if (existingGame.length) {
                const existingIndex = top5.indexOf(existingGame[0]);
                top5[existingIndex].Amount += round.Bet + round.BonusAmount;
              } else if (targetedCategories.includes(newRound.GameType)) {
                const game = {
                  Name: round.Game,
                  Amount: round.Bet + round.BonusAmount,
                  Provider: round.Provider,
                  Slug: round.Slug,
                  GameID: round.GameID,
                };
                top5.push(game);
              }

              return newRound;
            });
            // sort the top5 array to display the biggest contributed game on top
            top5.sort((gameA, gameB) => gameA.Amount < gameB.Amount);

            // remove games is array is bigger then 5
            if (top5.length > 5) {
              top5.length = 5;
            }

            setTop5(top5);

            // Get this weeks monday date
            const monday = getMonday(new Date());

            const startOfTheWeek = monday.toJSON().split('T')[0].concat('T00:00:00.000');

            monday.setDate(monday.getDate() - 7);
            const startOfLastWeek = monday.toJSON().split('T')[0].concat('T00:000:000.00');

            monday.setDate(monday.getDate() - 7);
            const twoWeeksAgo = monday.toJSON().split('T')[0].concat('T00:000:000.00');

            monday.setDate(monday.getDate() - 7);
            const threeWeeksAgo = monday.toJSON().split('T')[0].concat('T00:000:000.00');

            // Get the rounds starting from monday 00:00
            const thisWeeksRounds = gameRoundsWithGameType.filter(
              round => round.Date >= startOfTheWeek,
            );

            const lastWeeksRounds = gameRoundsWithGameType.filter(
              round => round.Date >= startOfLastWeek && round.Date < startOfTheWeek,
            );

            const twoWeeksAgoRounds = gameRoundsWithGameType.filter(
              round => round.Date >= twoWeeksAgo && round.Date < startOfLastWeek,
            );

            const threeWeeksAgoRounds = gameRoundsWithGameType.filter(
              round => round.Date >= threeWeeksAgo && round.Date < twoWeeksAgo,
            );

            setGamingHistory({
              thisWeek: createWeekHistoryObject(thisWeeksRounds),
              lastWeek: createWeekHistoryObject(lastWeeksRounds),
              twoWeeksAgo: createWeekHistoryObject(twoWeeksAgoRounds),
              threeWeeksAgo: createWeekHistoryObject(threeWeeksAgoRounds),
            });

            const totalBets = bets.toFixed(2);
            const vipLevel = getVipLevel(totalBets);
            setVipLevel(vipLevel);

            // Calculate users current weekly balance
            let balance = 0;

            thisWeeksRounds.forEach(round => {
              const bet = round.Bet + round.BonusAmount;
              const percentage = getPercentage(vipLevel.currentLevel, round.GameType);
              balance += bet * percentage;
            });

            // Maximum payout is 150000 TBH
            if (balance > 150000) {
              balance = 150000;
            }

            setBalance(balance.toFixed(2));
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [token]);

  return (
    <StyledLuckyRewardsClub>
      <div className="page-header">
        <div className="background-image">
          <img
            src={`${CDN_BASE}/cdn-cgi/image/fit=cover,width=1440,height=300,gravity=0.5x0.80,format=auto${CDN_IMAGES_COMMON_PATH}/luckydays-timessquare.jpg`}
            alt="Lucky the cat on his way to the beach"
          />
        </div>
        <div className="text">
          <h1>ลัคกี้ รีวอร์ดคลับ</h1>
          <p>เล่นเกมโปรดของคุณ พร้อมรับรางวัลตอบแทนเพิ่มเติม!</p>
        </div>
      </div>
      {isEmpty(tags) ? (
        <div className="content">
          <Spinner dark absolute />
        </div>
      ) : !hasRebateTag ? (
        <div className="content">
          <div className="section opt-in">
            <h2>ฟรี! เข้าร่วมโปรแกรมรีวอร์ดรับรางวัลมากมาย!</h2>
            <p>
              ที่คาสิโน LuckyDays (ลัคกี้เดย์) เราเน้นความสนุกสนานและความพอใจของคุณเป็นหลัก
              ดังนั้นเราได้สร้าง โปรแกรมลัคกี้รีวอร์ดคลับ ขึ้นมาให้กับคุณ เข้าร่วมฟรีวันนี้
              เพื่อเริ่มเล่นเกมที่คุณชื่นชอบและรับรางวัลตอบแทนมากมายในแต่ละระดับ ยิ่งเล่นมาก
              ยิ่งได้รางวัลมาก!
            </p>
            <Button
              primary
              type="button"
              onClick={() => {
                assignRebateTag();
              }}
            >
              คลิกเข้าร่วมฟรี
            </Button>
          </div>
        </div>
      ) : (
        <div className="content">
          <div className="progress">
            <div>
              <div className="section achieved-balance">
                <h2>ผลตอบแทนล่าสุด</h2>
                <p>
                  นี่คือยอดผลตอบแทนเงินคืนล่าสุดที่คุณได้รับจากการเล่นเกมในสัปดาห์นี้คะ
                  ทำการเล่นเกมสล็อต และคาสิโนสดที่เข้าร่วมรายการ และ
                  กลับมาเช็คว่าคุณจะได้รับเงินคืนเพิ่มอีกนะคะ!
                </p>
                <p>
                  ระยะเวลาที่เราใช้คำนวนเงินคืนนี้ คือ ทุกวันจันทร์เวลา 07.01 นาฬิกา - วันจันทร์เวลา
                  6.59 นาฬิกา ทุกสัปดาห์คะ คุณต้องทำการเดิมพันขั้นต่ำ 450 บาท
                  ในระยะเวลาดังกล่าวเพื่อรับเงินรางวัลคืน โดยเราจ่ายคืนสูงสุดที่ 150,000 บาท
                  ในแต่ละสัปดาห์ค่ะ
                </p>
                <p>
                  เงินคืนของคุณจะทำการเครดิตให้คุณในวันอังคาร เวลา 23:59 นาฬิกา
                  เมื่อคุณล็อคอินโดยจะทำการเครดิตเป็นยอดโบนัสและทำเทิร์นเพียงแค่ 1
                  เท่าก่อนการถอนเงินเท่านั้นค่ะ!
                </p>
                <p className="big">
                  <Currency currency="THB" amount={balance || 0} />
                </p>
                {balance < 450 && (
                  <p className="small">
                    * คุณจะได้รับเงินจำนวนนี้ เมื่อยอดนี้เกิน 450 บาทขึ้นไปค่ะ
                  </p>
                )}
                <img
                  src={`${CDN_BASE}/cdn-cgi/image/width=400,format=auto,dpr=2${CDN_IMAGES_COMMON_PATH}/happy-cat.png`}
                  alt="Happy Cat"
                />
              </div>
            </div>
            <div>
              <div className="section vip-level">
                <h2>ระดับผู้เล่นปัจจุบันของคุณ</h2>
                <p>
                  ระดับผู้เล่นของคุณจะทำแบ่ง โดยคำนวนจากยอดการเดิมพันสะสมด้วยเงินสดในระยะเวลา 30
                  วันล่าสุด และนี่คือระดับผู้เล่นของคุณค่ะ:
                </p>
                <p className="big">
                  <span className={`coin ${vipLevel?.currentLevel.toLowerCase()}`} />
                  {vipLevel?.currentLevel || 'กำลังดาวโหลด...'}
                </p>
              </div>
              <div className="section vip-level">
                <h2>ระดับผู้เล่นขั้นถัดไปของคุณ</h2>
                <p>
                  อยากได้รับเงินคืนเพิ่มใหมคะ?
                  เช็คความคืบหน้าในการอัพเลเวลระดับผู้เล่นของคุณด้านล่างนี้ได้เลยคะ
                  ยิ่งคุณเดิมพันด้วยเงินสดมากเท่าไหร่ คุณจะได้อัพเลเวลสูงขึ้นเท่านั้นค่ะ!
                </p>
                {vipLevel?.currentLevel !== 'แพลตตินั่ม' && (
                  <div className="current-next-level">
                    <p className="progress-level">
                      <span className={`coin ${vipLevel?.currentLevel.toLowerCase()}`} />
                      {vipLevel?.currentLevel}
                    </p>
                    <p className="progress-level">
                      <span className={`coin ${vipLevel?.nextLevel.toLowerCase()}`} />
                      {vipLevel?.nextLevel}
                    </p>
                  </div>
                )}
                {vipLevel?.currentLevel === 'แพลตตินั่ม' ? (
                  <p>
                    <strong>คุณอยู่ในระดับสูงสุดของคลับแล้วค่ะ! ขอให้เล่นให้สนุกนะคะ!</strong>
                  </p>
                ) : (
                  <ProgressBar turnoverPercent={vipLevel?.turnoverPercent || 0} />
                )}
              </div>
            </div>
          </div>
          <div className="section">
            <h2>5 เกมที่คุณเล่นมากที่สุด</h2>
            <p>
              อยากรู้ใช่ใหมคะ ว่าเกมใหนที่คุณทำการเล่นและทำให้คุณได้รับเงินคืนมากที่สุด?
              เช็คด้านล่างได้เลยนะคะ
            </p>
            <div className="table-wrapper">
              <table className="top5">
                <thead>
                  <tr>
                    <th className="game">เกม</th>
                    <th></th>
                    <th>ผู้ให้บริการเกม</th>
                    <th>จำนวนเปอร์เซ็นที่ได้รับ</th>
                  </tr>
                </thead>
                <tbody>
                  {top5.map(game => (
                    <tr key={game.GameID}>
                      <td className="game">
                        <div className="image-wrapper">
                          <LazyLoad once height="40px" width="32px" offset={1000}>
                            <img
                              src={`${CDN_BASE}/cdn-cgi/image/fit=cover,width=32,height=40,format=auto,dpr=2${CDN_IMAGES_GAMES_PATH}/${game.Slug}.jpg`}
                              alt={game.Name}
                            />
                          </LazyLoad>
                        </div>
                      </td>
                      <td className="gameName">{game.Name}</td>
                      <td>{game.Provider}</td>
                      <td>{((game?.Amount / vipLevel?.totalBets) * 100).toFixed(2) || 0} %</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="vip-levels">
            <div className="level">
              <h2>กติกาและเงื่อนไขการเลื่อนระดับชั้น เป็นอย่างไรบ้าง?</h2>
              <p>
                ยิ่งคุณอยู่ในระดับที่สูงขึ้นเท่าไหร่
                คุณก็จะได้รับผลตอบแทนมากขึ้นเท่านั้นในแต่ละสัปดาห์นะคะ
              </p>
              <p>
                เกมคาสิโนสด และ สล็อตที่เข้าร่วมรายงานนั้นจะให้ผลตอบแทนใน % ที่ต่างกันนะคะ
                คุณสามารถเช็ค เปอร์เซ็นการคำนวนเงินคืน ที่คำนวนจากการเดิมพันเงินสดของคุณ
                ในแต่ละสัปดาห์ ได้ตามนี้เลยนะคะ
              </p>
              <h3>
                <span className="coin ผู้เล่นทั่วไป" />
                ผู้เล่นทั่วไป
              </h3>
              <p>
                ระดับเลเวลนี้ สำหรับผู้เล่นทั่วไป ที่มีการเดิมพันเงินสดต่ำกว่า 4,000,000 บาท
                ในระยะเวลา 30 วันที่ผ่านมา
              </p>
              <div className="table-wrapper">
                <table>
                  <thead>
                    <tr>
                      <th>ประเภทเกม</th>
                      <th>คาสิโนสด</th>
                      <th>สล็อต</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>เปอร์เซ็นการคำนวน</td>
                      <td>0.3%</td>
                      <td>0.4%</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h3>
                <span className="coin บลู" />
                บลู
              </h3>
              <p>
                ระดับเลเวลนี้เป็นระดับที่สอง สำหรับผู้เล่นที่มีการเดิมพันด้วยเงินสดที่ 4,000,000 บาท
                ถึง 6,600,000 บาท ในระยะเวลา 30 วันที่ผ่านมา
              </p>
              <div className="table-wrapper">
                <table>
                  <thead>
                    <tr>
                      <th>ประเภทเกม</th>
                      <th>คาสิโนสด</th>
                      <th>สล็อต</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>เปอร์เซ็นการคำนวน</td>
                      <td>0.4%</td>
                      <td>0.7%</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h3>
                <span className="coin โกล" />
                โกล
              </h3>
              <p>
                ระดับเลเวลนี้เป็นระดับที่สาม สำหรับผู้เล่นที่ทำการเดิมพันด้วยเงินสดที่ 6,600,000 บาท
                ถึง 14,000,000 บาท ในระยะเวลา 30 วันที่ผ่านมา
              </p>
              <div className="table-wrapper">
                <table>
                  <thead>
                    <tr>
                      <th>ประเภทเกม</th>
                      <th>คาสิโนสด</th>
                      <th>สล็อต</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>เปอร์เซ็นการคำนวน</td>
                      <td>0.6%</td>
                      <td>0.8%</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h3>
                <span className="coin แพลตตินั่ม" />
                แพลตตินั่ม
              </h3>
              <p>
                ระดับเลเวลนี้เป็นคาสิโนระดับสูงสุดของคลับ
                โดยสำหรับผู้เล่นที่มีการเดิมพันเงินสดมากกว่า 14,000,000 บาท ในระยะเวลา 30
                วันที่ผ่านมา
              </p>
              <div className="table-wrapper">
                <table>
                  <thead>
                    <tr>
                      <th>ประเภทเกม</th>
                      <th>คาสิโนสด</th>
                      <th>สล็อต</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>เปอร์เซ็นการคำนวน</td>
                      <td>0.7%</td>
                      <td>1%</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="history">
            <div className="section">
              <h3>ประวัติการเดิมพันสะสมในโปรแกรมรีวอร์ด</h3>
              <p>
                คุณสามารถทำการตรวจสอบเกมที่คุณทำการเดิมพันและทำให้คุณได้รับเงินคืนจากโปรแกรมรีวอร์ดของเราคะ
              </p>
              <p>
                หากคุณมีคำถามเกี่ยวกับประวัติการเดิมพัน และ/หรือ
                ระดับเลเวลของคุณที่โปรแกรมรีวอร์ดของเรา กรุณาติดต่อพนักงานบริการลูกค้าของเรานะค่ะ
              </p>
              <div className="table-wrapper">
                <table>
                  <thead>
                    <tr>
                      <th>ช่วงเวลา</th>
                      <th>คาสิโนสด</th>
                      <th>สล็อต</th>
                      <th>ยอดรวม</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>สัปดาห์นี้</td>
                      <td>
                        <Currency
                          currency="THB"
                          amount={gamingHistory?.thisWeek?.liveCasino || 0}
                        />
                      </td>
                      <td>
                        <Currency currency="THB" amount={gamingHistory?.thisWeek?.slots || 0} />
                      </td>
                      <td>
                        <Currency currency="THB" amount={gamingHistory?.thisWeek?.total || 0} />
                      </td>
                    </tr>
                    <tr>
                      <td>สัปดาห์ที่แล้ว</td>
                      <td>
                        <Currency
                          currency="THB"
                          amount={gamingHistory?.lastWeek?.liveCasino || 0}
                        />
                      </td>
                      <td>
                        <Currency currency="THB" amount={gamingHistory?.lastWeek?.slots || 0} />
                      </td>
                      <td>
                        <Currency currency="THB" amount={gamingHistory?.lastWeek?.total || 0} />
                      </td>
                    </tr>
                    <tr>
                      <td>2 สัปดาห์ที่แล้ว</td>
                      <td>
                        <Currency
                          currency="THB"
                          amount={gamingHistory?.twoWeeksAgo?.liveCasino || 0}
                        />
                      </td>
                      <td>
                        <Currency currency="THB" amount={gamingHistory?.twoWeeksAgo?.slots || 0} />
                      </td>
                      <td>
                        <Currency currency="THB" amount={gamingHistory?.twoWeeksAgo?.total || 0} />
                      </td>
                    </tr>
                    <tr>
                      <td>3 สัปดาห์ที่แล้ว</td>
                      <td>
                        <Currency
                          currency="THB"
                          amount={gamingHistory?.threeWeeksAgo?.liveCasino || 0}
                        />
                      </td>
                      <td>
                        <Currency
                          currency="THB"
                          amount={gamingHistory?.threeWeeksAgo?.slots || 0}
                        />
                      </td>
                      <td>
                        <Currency
                          currency="THB"
                          amount={gamingHistory?.threeWeeksAgo?.total || 0}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <LuckyRewardsClubTerms />
        </div>
      )}
    </StyledLuckyRewardsClub>
  );
}
