import {
  BonusesContentActions,
  BonusesContentActionTypes,
  GetBonusContentSuccessAction,
  GetBonusContentErrorAction,
} from '@/store/actions/bonusesContentActions';
import { WpBonusContentModel } from '@/models/wp-bonus-content.model';

// string key is locale
// number key is bonus ID
export type BonusesContentStateModel = Record<string, Record<number, WpBonusContentModel | null>>;

const initialState: BonusesContentStateModel = {};

const getBonusContentSuccess = (
  state: BonusesContentStateModel,
  action: GetBonusContentSuccessAction,
): BonusesContentStateModel => {
  const content = state[action.locale];
  return {
    ...state,
    [action.locale]: { ...content, [action.content.id]: action.content },
  };
};

const getBonusContentError = (
  state: BonusesContentStateModel,
  { bonusId, locale }: GetBonusContentErrorAction,
): BonusesContentStateModel => ({
  ...state,
  [locale]: { ...state[locale], [bonusId]: null },
});

export const bonusesContentReducer = (
  state = initialState,
  action: BonusesContentActions,
): BonusesContentStateModel => {
  switch (action.type) {
    case BonusesContentActionTypes.Success:
      return getBonusContentSuccess(state, action);
    case BonusesContentActionTypes.Error:
      return getBonusContentError(state, action);
    default:
      return state;
  }
};
