import { Action } from 'redux';
import { KycDocumentResponseDto } from '@lucky7ventures/lucky-components';

export enum KycActionType {
  GetDocuments = 'KYC_GET',
  GetDocumentsSuccess = 'KYC_GET_SUCCESS',
  GetDocumentsError = 'KYC_GET_ERROR',
  AddKycRequirements = 'ADD_KYC_REQUIREMENTS',
  RunVerify = 'RUN_VERIFY',
  RunVerifyFinished = 'RUN_VERIFY_FINISHED',
  Reset = 'KYC_RESET',
}

export type KycGetDocumentsAction = Action<KycActionType.GetDocuments>;
export type KycGetDocumentsSuccessAction = Action<KycActionType.GetDocumentsSuccess> & {
  documents: KycDocumentResponseDto[];
};
export type KycGetDocumentsErrorAction = Action<KycActionType.GetDocumentsError>;

export type AddKycRequirementsAction = Action<KycActionType.AddKycRequirements>;

export type RunVerifyAction = Action<KycActionType.RunVerify>;
export type RunVerifyFinishedAction = Action<KycActionType.RunVerifyFinished>;

export type KycResetAction = Action<KycActionType.Reset>;

export type KycActions =
  | KycGetDocumentsAction
  | KycGetDocumentsSuccessAction
  | KycGetDocumentsErrorAction
  | AddKycRequirementsAction
  | KycResetAction
  | RunVerifyAction
  | RunVerifyFinishedAction;

export const kycGetDocuments = (): KycGetDocumentsAction => ({
  type: KycActionType.GetDocuments,
});

export const kycGetDocumentsSuccess = (
  documents: KycDocumentResponseDto[],
): KycGetDocumentsSuccessAction => ({
  type: KycActionType.GetDocumentsSuccess,
  documents,
});

export const kycGetDocumentsError = (): KycGetDocumentsErrorAction => ({
  type: KycActionType.GetDocumentsError,
});

export const addKycRequirements = (): AddKycRequirementsAction => ({
  type: KycActionType.AddKycRequirements,
});

export const runVerify = (): RunVerifyAction => ({
  type: KycActionType.RunVerify,
});

export const runVerifyFinished = (): RunVerifyFinishedAction => ({
  type: KycActionType.RunVerifyFinished,
});

export const kycReset = (): KycResetAction => ({
  type: KycActionType.Reset,
});
