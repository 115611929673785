import { Action } from 'redux';
import { BffLifetimeDepositsResponse } from '@lucky7ventures/bff-types';

export enum LastDepositActionType {
  GetLastDeposit = 'GET_LAST_DEPOSIT',
  GetLastDepositSuccess = 'GET_LAST_DEPOSIT_SUCCESS',
  GetLastDepositError = 'GET_LAST_DEPOSIT_ERROR',
  Reset = 'LAST_DEPOSIT_RESET',
  SetLifetimeDeposits = 'SET_LIFETIME_DEPOSITS',
}

export type GetLastDepositAction = Action<LastDepositActionType.GetLastDeposit> & {
  token: string;
};
export type GetLastDepositSuccessAction = Action<LastDepositActionType.GetLastDepositSuccess> & {
  lastDeposit: number;
};
export type GetLastDepositErrorAction = Action<LastDepositActionType.GetLastDepositError>;

export type ResetLastDeposit = Action<LastDepositActionType.Reset>;

export type SetLifetimeDepositsAction = Action<LastDepositActionType.SetLifetimeDeposits> & {
  deposits: BffLifetimeDepositsResponse;
};

export type LastDepositActions =
  | GetLastDepositAction
  | GetLastDepositSuccessAction
  | GetLastDepositErrorAction
  | ResetLastDeposit
  | SetLifetimeDepositsAction;

export const getLastDeposit = (token: string): GetLastDepositAction => ({
  type: LastDepositActionType.GetLastDeposit,
  token,
});

export const getLastDepositSuccess = (lastDeposit: number): GetLastDepositSuccessAction => ({
  type: LastDepositActionType.GetLastDepositSuccess,
  lastDeposit,
});

export const getLastDepositError = (): GetLastDepositErrorAction => ({
  type: LastDepositActionType.GetLastDepositError,
});

export const resetLastDeposit = (): ResetLastDeposit => ({ type: LastDepositActionType.Reset });

export const setLifetimeDeposits = (
  deposits: BffLifetimeDepositsResponse,
): SetLifetimeDepositsAction => ({
  type: LastDepositActionType.SetLifetimeDeposits,
  deposits,
});
