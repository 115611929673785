import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useAbsoluteNavigate } from '@/hooks/useAbsoluteNavigate';
import { setFitToPlay } from '@/store/actions/commonActions';
import CloseButton from '../UI/Buttons/CloseButton';

export function FitToPlayModal({ closeModal }: { closeModal: () => void }): JSX.Element {
  const dispatch = useDispatch();
  const absoluteNavigate = useAbsoluteNavigate();
  const intl = useIntl();

  const onResponsibleGamingClickHandler = () => {
    absoluteNavigate('/settings/responsible-gaming');
    dispatch(setFitToPlay(false));
    closeModal();
  };

  const onFitToPlayConfirm = () => {
    dispatch(setFitToPlay(true));
    closeModal();
  };

  const onFitToPlayCancel = () => {
    dispatch(setFitToPlay(false));
    absoluteNavigate('/games');
    closeModal();
  };

  return (
    <div className="w-full max-w-sm rounded-xl bg-white px-8 py-8 relative">
      <CloseButton right onClick={onFitToPlayCancel} />
      <h1 className="mt-4 text-lg font-bold">
        {intl.formatMessage({ id: 'ontario.fittoplay.header' })}
      </h1>
      <p className="mt-3 mb-0">
        {intl.formatMessage({ id: 'ontario.fittoplay.text' })}{' '}
        <button
          type="button"
          onClick={onResponsibleGamingClickHandler}
          className="text-cyan-600 underline"
        >
          {intl.formatMessage({ id: 'ontario.fittoplay.click' })}
        </button>{' '}
        {intl.formatMessage({ id: 'ontario.fittoplay.text2' })}
      </p>
      <button
        type="button"
        onClick={onFitToPlayConfirm}
        className="mt-8 w-full rounded-full bg-cyan-500 py-3 px-8 font-bold text-white shadow-md transition-all hover:bg-cyan-600 hover:shadow-lg"
      >
        {intl.formatMessage({ id: 'ontario.fittoplay.button' })}
      </button>
    </div>
  );
}
