import * as actionTypes from '../actions/actionTypes';

const initialState = {
  queue: [],
  showBalanceUpdate: false,
  showUserIsPepHioLogout: false,
  showFtdExitIntent: false,
};

const openModal = (state, action) => {
  return {
    ...state,
    queue: [
      {
        modalName: action.modalName,
        modalProps: action.modalProps,
        order: action.order,
      },
      ...state.queue,
    ],
  };
};

const closeModal = state => ({
  ...state,
  queue: [],
});

const closeSpecificModal = (state, action) => ({
  ...state,
  queue: state.queue.filter(modal => modal.modalName !== action.modalName),
});

const updateModalProps = (state, action) => {
  const currentModal = state.queue[0];

  if (!currentModal) {
    return state;
  }

  // update the props for the first modal in the queue
  const updatedModal = {
    ...currentModal,
    modalProps: { ...currentModal.modalProps, ...action.props },
  };

  return {
    ...state,
    queue: [updatedModal, ...state.queue.slice(1, state.queue.length)],
  };
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_MODAL:
      return openModal(state, action);
    case actionTypes.CLOSE_MODAL:
      return closeModal(state, action);
    case actionTypes.CLOSE_SPECIFIC_MODAL:
      return closeSpecificModal(state, action);
    case actionTypes.UPDATE_MODAL_PROPS:
      return updateModalProps(state, action);
    case actionTypes.SHOW_BALANCE_UPDATE:
      return {
        ...state,
        showBalanceUpdate: action.isShown,
      };
    case actionTypes.CLOSE_ALL_MODALS:
      return {
        ...state,
        queue: state.queue.filter(modal => action.excludedModals.includes(modal.modalName)),
      };
    case actionTypes.SHOW_USER_IS_PEP_OR_HIO_LOGOUT:
      return {
        ...state,
        showUserIsPepHioLogout: action.isShown,
      };
    case actionTypes.SET_FTD_EXIT_INTENT_SHOWN:
      return {
        ...state,
        showFtdExitIntent: true,
      };
    default:
      return state;
  }
};

export default modalReducer;
