import React, { lazy, Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';

import { Footer } from '@/components/Layout/Footer';

import { Outlet } from 'react-router-dom';
import { usePingLogin } from '@/hooks/usePingLogin';
import {
  getHomeMetaDescByLocale,
  getHomeTitleByLocale,
  getHtmlLangByLocale,
} from '@/shared/utility';
import { Zendesk } from '@/components/Zendesk';
import { Spelpaus } from '@/components/Spelpaus';
import { APP_VERSION, envIsCanada, envIsSweden } from '@/constants/constants';
import { useStatsAndLimits } from '@/hooks/useStatsAndLimits';
import { SearchPalette } from '@/components/SearchPalette';
import { selectIsAuthenticated } from '@/store/selectors/authSelectors';
import { useConsents } from '@/hooks/useConsents';
import { useOntarioLoginMigration } from '@/hooks/useOntarioLoginMigration';
import { useLocationSmart } from '@/hooks/useLocationSmart';
import { useOntarioCheck } from '@/hooks/useOntarioCheck';
import { useIncompleteDataCheck } from '@/hooks/useIncompleteDataCheck';
import { useRequiredDepositLimitsCheck } from '@/hooks/useRequiredDepositLimitsCheck';
import { usePepHioHook } from '@/hooks/usePepHioHook';
import { mapLocaleToLang } from '@/utils/utils';
import { Settings } from 'luxon';

import { selectUserId } from '@/store/selectors/userSelectors';

import { autoLogin } from '@/store/actions/autoLoginActions';
import { useLifetimeDeposits } from '@/hooks/useLifetimeDeposits';
import { selectLocale } from '@/store/selectors/commonSelectors';
import HelmetAlternateLinks from '@/features/helmet/HelmetAlternateLinks';
import useIsFluidEnabled from '@/hooks/useIsFluidEnabled';
import CookieConsentManager from '@/features/cookie-consent/CookieConsentManager';
import Header from '@/components/shared/header/Header';
import ModalContainer from '@/components/Modals/ModalContainer';
import DrawerContainer from '@/components/Drawers/DrawerContainer2';
import TabBar from '@/components/Layout/TabBar';
import GlobalStyles from '@/components/Common/GlobalStyles';

const CashierContainer = lazy(() => import('@/features/cashier/CashierContainer'));
const FluidPaymentsWrapper = lazy(() => import('@/features/cashier/fluid/FluidPaymentsWrapper'));

function App(): JSX.Element {
  const locale = useSelector(selectLocale);
  const userId = useSelector(selectUserId);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const windowOrigin = window.location.origin;
  const windowPathname = window.location.pathname;
  const isFluidEnabled = useIsFluidEnabled();
  const dispatch = useDispatch();

  useEffect(() => {
    Settings.defaultLocale = mapLocaleToLang(locale);
  }, [locale]);

  useStatsAndLimits();
  usePingLogin();
  useConsents();
  useLocationSmart();
  useOntarioLoginMigration();
  useOntarioCheck();
  useIncompleteDataCheck();
  useRequiredDepositLimitsCheck();
  usePepHioHook();
  useLifetimeDeposits();

  useEffect(() => {
    // Initiate auto login flow
    dispatch(autoLogin());
  }, []);

  useEffect(() => {
    // Scroll to top of page when the user is logging in
    if (isAuthenticated) {
      window.scrollTo(0, 0);
    }
  }, [isAuthenticated]);

  return (
    <>
      <Helmet>
        <html lang={getHtmlLangByLocale(locale)} />
        <title>{getHomeTitleByLocale(locale)}</title>
        <meta name="description" content={getHomeMetaDescByLocale(locale)} />
        {APP_VERSION && <meta name="luckydays_version" content={APP_VERSION} />}
        {envIsCanada() && (
          <>
            <script
              type="text/javascript"
              src="https://servicesbl.luckydays.ca/v4/html5/?version=v2"
            ></script>
            <script type="text/javascript" src="/toolkit.js"></script>
          </>
        )}
        <link rel="canonical" href={windowOrigin + windowPathname} />
      </Helmet>
      <HelmetAlternateLinks locale={locale} path={windowPathname} origin={windowOrigin} />
      <GlobalStyles />
      {envIsSweden() && <Spelpaus />}
      <Header />
      <main className="min-h-screen">
        <Outlet />
      </main>
      <Footer />
      <CookieConsentManager />
      <TabBar />
      <DrawerContainer />
      <ModalContainer />
      <SearchPalette />
      <Suspense fallback={<div></div>}>
        {!isFluidEnabled && isAuthenticated && userId && <CashierContainer />}
        {isFluidEnabled && <FluidPaymentsWrapper />}
      </Suspense>
      <Zendesk locale={locale} />
    </>
  );
}

export default App;
