import * as React from 'react';

import { useSelector } from 'react-redux';

import { selectAllGames, selectGamesLoading } from '@/store/selectors/gamesSelectors';
import CategoryPageContent from '@/pages/games/categories/CategoryPageContent';
import { useIntl } from 'react-intl';
import { BffGamesCategory } from '@lucky7ventures/bff-types';

function CategoryAllGames(): JSX.Element {
  const intl = useIntl();

  const games = useSelector(selectAllGames);
  const gamesLoading = useSelector(selectGamesLoading);

  return (
    <CategoryPageContent
      header={intl.formatMessage({ id: `games.categories.allGames` })}
      subheader={intl.formatMessage({ id: `games.categories.allGames.info` })}
      category={BffGamesCategory.AllGames}
      games={games}
      loading={gamesLoading}
    />
  );
}

export default CategoryAllGames;
