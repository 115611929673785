import React from 'react';

export function BtnBasicLink({
  onClickHandler,
  text,
}: {
  onClickHandler: () => void;
  text: string;
}): JSX.Element {
  return (
    <button
      type="button"
      className=" mx-auto text-gray-500 underline transition hover:text-gray-700"
      onClick={onClickHandler}
    >
      {text}
    </button>
  );
}
