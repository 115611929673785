import React, { useEffect, useState } from 'react';

import { Field, FieldProps, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import classNames from 'classnames';
import { AgcoUserDetails, IndustryResponse, Occupation } from '@lucky7ventures/lucky-components';

import { BtnOneLineBlue } from './buttons/BtnOneLineBlue';
import { FormInput } from './FormInput';
import { MobileInput } from './MobileInput';
import FormCheckbox from './FormCheckbox';
import FormSelect from './FormSelect';
import PromptMessage from './UI/PromptMessage';
import { selectAuthToken } from '@/store/selectors/authSelectors';
import { getConsents } from '@/store/actions/consentsActions';
import { closeSpecificModal, setShowUserIsPepOrHioLogout } from '@/store/actions/modal';
import ApiService from '../shared/apiService';
import { logException } from '@/utils/logger';
import { deepCompare, stringValueByKey } from '@/utils/utils';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { ApiV2Error } from '@/shared/errorHandler';
import { User } from '@/models/user.model';
import { UpdateVerifyAgcoUserDetailsRequestDto } from '@/models/apiV2/update-verify-agco-user-details-request-dto.model';
import { useAbsoluteNavigate } from '@/hooks/useAbsoluteNavigate';
import { useApiV2Request } from '@/hooks/useApiV2Request';
import { getUser } from '@/store/actions/user';

interface FormValues {
  fullName: string;
  address: string;
  city: string;
  postCode: string;
  occupationId: string;
  industryId: string;
  mobile: {
    number: string;
    country: string;
    prefix: string;
    wholeNumber: string;
  };
  isNotPepOrHio: boolean;
}

const DetailsReverificationForm = ({
  user,
  additionalUserDetails,
  closeModal,
}: {
  user: User;
  additionalUserDetails: AgcoUserDetails;
  closeModal: () => void;
}) => {
  const intl = useIntl();
  const absoluteNavigate = useAbsoluteNavigate();
  const dispatch = useDispatch();
  const token = useSelector(selectAuthToken);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [occupations, setOccupations] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const [industries, setIndustries] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const { request: occupationsRequest, loading: occupationsLoading } =
    useApiV2Request<Occupation[]>();
  const { request: industriesRequest, loading: industriesLoading } =
    useApiV2Request<IndustryResponse[]>();
  const { request: verifyRequest, loading: verifyLoading, error: verifyError } = useApiV2Request();
  const {
    request: updateVerifyRequest,
    loading: updateVerifyLoading,
    error: updateVerifyError,
  } = useApiV2Request();

  const initialValues: FormValues = {
    fullName: `${user.FirstName} ${user.LastName}`,
    address: user.Address1,
    city: user.City,
    postCode: user.PostalCode,
    occupationId: stringValueByKey(additionalUserDetails, 'OccupationId'),
    industryId: stringValueByKey(additionalUserDetails, 'IndustryId'),
    mobile: {
      number: user.Mobile,
      wholeNumber: user.MobilePrefix + user.Mobile,
      prefix: user.MobilePrefix,
      country: user.CountryCode,
    },
    isNotPepOrHio: !additionalUserDetails.IsPep && !additionalUserDetails.IsHio,
  };

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required(intl.formatMessage({ id: 'input.error.required' })),
    address: Yup.string().required(intl.formatMessage({ id: 'input.error.required' })),
    city: Yup.string().required(intl.formatMessage({ id: 'input.error.required' })),
    postCode: Yup.string().required(intl.formatMessage({ id: 'input.error.required' })),
    occupationId: Yup.string().required(intl.formatMessage({ id: 'input.error.required' })),
    industryId: Yup.string().required(intl.formatMessage({ id: 'input.error.required' })),
    mobile: Yup.object().shape({
      wholeNumber: Yup.string().required(intl.formatMessage({ id: 'input.error.required' })),
      number: Yup.string().min(5, intl.formatMessage({ id: 'inputs.mobile.invalid' })),
    }),
    isNotPepOrHio: Yup.boolean().notRequired(),
  });

  const handleOnLogout = () => {
    dispatch(closeSpecificModal('detailsReverification'));
    triggerGTag(GTagEvents.reverification_logout);
    absoluteNavigate('/logout');
  };

  const handleOnSubmit = (values: FormValues) => {
    const payload: UpdateVerifyAgcoUserDetailsRequestDto = {
      firstName: values.fullName.split(' ')[0],
      lastName: values.fullName.split(' ').slice(1).join(' '),
      mobile: {
        mobilePrefix: values.mobile.prefix,
        mobileNumber: values.mobile.number,
      },
      address: {
        address1: values.address,
        city: values.city,
        postalCode: values.postCode,
      },
      occupationId: Number(values.occupationId),
      industryId: Number(values.industryId),
      isPepOrIsHio: !values.isNotPepOrHio,
    };
    const handleOnSuccess = () => {
      dispatch(getUser(token));
      dispatch(getConsents());
      closeModal();
    };
    const handleOnError = (error: ApiV2Error) => {
      if (error.error === 'VERIFICATION_ACCOUNT_BLOCK') {
        dispatch(setShowUserIsPepOrHioLogout(true));
        handleOnLogout();
      } else {
        logException(error);
        triggerGTag(GTagEvents.reverification_error, {
          error: error.error,
          errorId: error.errorId,
        });
      }
    };

    const isDataEqual = deepCompare(values, initialValues);
    if (isDataEqual) {
      updateVerifyRequest(
        ApiService.verifyAgcoUserDetails,
        null,
        () => {
          handleOnSuccess();
          triggerGTag(GTagEvents.reverification_accepted);
        },
        handleOnError,
      );
    } else {
      verifyRequest(
        ApiService.updateVerifyAgcoUserDetails,
        payload,
        () => {
          handleOnSuccess();
          triggerGTag(GTagEvents.reverification_modified);
        },
        handleOnError,
      );
    }
  };

  useEffect(() => {
    if (isEditEnabled) {
      const handleOnOccupationsSuccess = (data: Occupation[]) => {
        const mappedOccupations = data.map(occupation => ({
          value: stringValueByKey(occupation, 'OccupationId'),
          label: occupation.Name,
        }));
        setOccupations(mappedOccupations);
      };

      const handleOnIndustriesSuccess = (data: IndustryResponse[]) => {
        const mappedIndustries = data.map(industry => ({
          value: stringValueByKey(industry, 'IndustryId'),
          label: industry.Name,
        }));
        setIndustries(mappedIndustries);
      };

      occupationsRequest(
        ApiService.getOccupations,
        { regulator: 'agco' },
        handleOnOccupationsSuccess,
      );
      industriesRequest(ApiService.getIndustries, {}, handleOnIndustriesSuccess);
    }
  }, [isEditEnabled]);

  return (
    <>
      <span
        onClick={() => setIsEditEnabled(true)}
        className="text-blue-blue font-semibold cursor-pointer block w-fit ml-auto underline"
      >
        {intl.formatMessage({ id: 'detailsReverification.edit' })}
      </span>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
        enableReinitialize
      >
        {() => (
          <Form className="[&_input]:bg-white [&_.special-label]:!text-sm">
            <FormInput
              name="fullName"
              disabled={!isEditEnabled}
              label={intl.formatMessage({ id: 'inputs.fullName' })}
              placeholder={intl.formatMessage({ id: 'inputs.fullName' })}
            />
            <FormInput
              name="address"
              disabled={!isEditEnabled}
              label={intl.formatMessage({ id: 'inputs.address' })}
              type="text"
              autoComplete="street-address"
              placeholder={intl.formatMessage({ id: 'inputs.address' })}
            />
            <FormInput
              name="city"
              disabled={!isEditEnabled}
              label={intl.formatMessage({ id: 'inputs.city' })}
              type="text"
              autoComplete="address-level2"
              placeholder={intl.formatMessage({ id: 'inputs.city' })}
            />
            <FormInput
              name="postCode"
              disabled={!isEditEnabled}
              label={intl.formatMessage({ id: 'inputs.postcode' })}
              type="text"
              autoComplete="postal-code"
              placeholder={intl.formatMessage({ id: 'inputs.postcode' })}
            />
            <Field name="mobile">
              {({ field, form }: FieldProps) => (
                <MobileInput disabled={!isEditEnabled} field={field} form={form} {...field} />
              )}
            </Field>
            <FormSelect
              name="occupationId"
              label={intl.formatMessage({ id: 'inputs.occupation' })}
              isLoading={occupationsLoading}
              options={occupations}
              defaultValue={{
                value: stringValueByKey(additionalUserDetails, 'OccupationId'),
                label: additionalUserDetails.Occupation || '',
              }}
              disabled={!isEditEnabled}
            />
            <FormSelect
              name="industryId"
              label={intl.formatMessage({ id: 'inputs.industry' })}
              isLoading={industriesLoading}
              options={industries}
              defaultValue={{
                value: stringValueByKey(additionalUserDetails, 'IndustryId'),
                label: additionalUserDetails.Industry || '',
              }}
              disabled={!isEditEnabled}
            />
            <FormCheckbox
              name="isNotPepOrHio"
              label={intl.formatMessage({ id: 'inputs.isNotPepOrHio' })}
              disabled={!isEditEnabled}
            />
            <BtnOneLineBlue
              type="submit"
              text={intl.formatMessage({ id: 'detailsReverification.confirm' })}
              isLoading={verifyLoading || updateVerifyLoading}
              className="py-0 h-12 min-h-fit mb-3 mt-6"
            />
          </Form>
        )}
      </Formik>
      <button
        className={classNames(
          'h-12 w-full rounded-full bg-gray-200 px-4 text-center font-bold transition hover:bg-gray-300',
          { disabled: verifyLoading || updateVerifyLoading },
        )}
        type="button"
        onClick={handleOnLogout}
      >
        {intl.formatMessage({ id: 'detailsReverification.logout' })}
      </button>
      {(verifyError || updateVerifyError) && (
        <div className="[&_p]:!text-base mt-4">
          <PromptMessage
            type="error"
            message={`${intl.formatMessage({ id: 'error.44' })} (${
              verifyError?.errorId || updateVerifyError?.errorId
            })`}
          />
        </div>
      )}
    </>
  );
};

export default DetailsReverificationForm;
