import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { BUILD_MODE, IS_SWEDEN } from '@/constants/constants';
import { getPathWithoutLocale } from '@/utils/utils';
import { Select, SelectItem } from '@/components/Select';
import { useAbsoluteNavigate } from '@/hooks/useAbsoluteNavigate';

const StyledHistory = styled.div`
  max-width: 720px;
  margin: 0 auto;
  padding: 48px 16px;

  @media (min-width: ${props => props.theme.breakpoints.small}) {
    padding: 64px 32px;
  }

  header {
    h1 {
      font-size: 22px;
      margin-bottom: 1rem;
    }

    p {
      max-width: 600px;
    }

    nav {
      display: flex;
      margin-bottom: 2rem;

      a {
        padding: 0.5rem 1rem;
        background: rgba(0, 0, 0, 0.05);
        color: rgba(0, 0, 0, 0.8);
        border-radius: 50vh;
        margin-right: 1rem;
        text-decoration: none;
        font-weight: bold;
        transition: all 0.15s ease;

        &:hover {
          background: rgba(0, 0, 0, 0.15);
        }

        &.active {
          background: ${props => props.theme.colors.blue1};
          color: white;
        }
      }
    }
  }
`;

const historyTabs = [
  ...[
    {
      id: '1',
      translationId: 'history.gaming',
      props: '/settings/history',
    },
    {
      id: '2',
      translationId: 'history.payments',
      props: '/settings/history/payments',
    },
  ],
  ...(IS_SWEDEN || BUILD_MODE === 'canada'
    ? [
        {
          id: '3',
          translationId: 'history.login',
          props: '/settings/history/login',
        },
      ]
    : []),
];

const History = (): JSX.Element => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const initialItem =
    historyTabs.find(type => type.props === getPathWithoutLocale(pathname)) || historyTabs[0];
  const [selectedItem, setSelectedItem] = useState<SelectItem>(initialItem);
  const absoluteNavigate = useAbsoluteNavigate();

  function onChangeHandler(item: SelectItem) {
    setSelectedItem(item);
    absoluteNavigate(item.props as string);
  }

  return (
    <StyledHistory>
      <header>
        <h1>{intl.formatMessage({ id: 'history.header' })}</h1>
        <p>{intl.formatMessage({ id: 'history.text' })}</p>
        <div className="mt-8 w-40">
          <div id="e2e_historySelect">
            <Select
              items={historyTabs}
              selectedItem={selectedItem}
              onChangeHandler={onChangeHandler}
            />
          </div>
        </div>
      </header>
      <Outlet />
    </StyledHistory>
  );
};

export default History;
