import axios, { AxiosResponse, CancelToken } from 'axios';
import { envIsCanada } from '@/constants/constants';
import { RootState } from '@/models/root-state.model';
import { Store } from 'redux';
import { ShortRegisterPayload } from '@/models/apiV2/short-register-user-data.model';
import { API_URLS } from '@/constants/api-urls';
import { UserProfileCompletionRequestDto } from '@/models/apiV2/user-profile-completion.model';
import { UpdateVerifyAgcoUserDetailsRequestDto } from '@/models/apiV2/update-verify-agco-user-details-request-dto.model';
import { SetLimitRequestPayload } from '@/models/apiV2/limits-model';
import { ValidateEmailRequestDto } from '@/models/apiV2/request/validate-email-request-dto.model';
import { ValidateMobileRequestDto } from '@/models/apiV2/request/validate-mobile-request-dto.model';
import { ValidateEmailMobileRequestDto } from '@/models/apiV2/request/validate-email-mobile-request-dto.model';
import { ZipCodeGeoRequestDto } from '@/models/apiV2/request/zipcode-geo-request-dto.model';
import { RegisterAgcoRequestDto } from '@/models/apiV2/request/register-agco-request-dto.model';
import { UserEmailExistenceRequestDto } from '@/models/apiV2/request/user-email-existence-request-dto.model';
import { SOWQuestionnaireRequestDto } from '@/models/apiV2/request/kyc-dto.model';
import { SaveConsentsRequestDto } from '@/models/apiV2/request/save-consents-request-dto.model';
import { UpdateContactInfoRequestDto } from '@/models/apiV2/request/update-contact-info-request-dto.model';
import { AuthenticateWithChallengeRequestDto } from '@/models/apiV2/request/authenticate-with-challenge-request-dto.model';
import { RegisterMexicoPayload } from '@/models/apiV2/register-mexico-data.model';

const axiosInstance = axios.create();

export function apiServiceInterceptor(store: Store) {
  // In API V2 we expect each request to have x-locale header
  // we can attach it here in the singular place so that we don't need to do it across the whole app code
  axiosInstance.interceptors.request.use(request => {
    const state: RootState = store.getState();
    const locale = state.common.locale;
    const token = state.auth.token;
    let headers = request.headers;

    // A singular place in the app where we attach the token to the request for API V2
    if (token) {
      headers = { ...headers, 'x-token': `${token}` };
    }
    headers = { ...headers, 'x-locale': envIsCanada() ? `${locale}-on` : `${locale}` };
    request.headers = headers;
    return request;
  });
}

interface ApiServiceMethodConfig {
  cancelToken?: CancelToken;
}

export type ApiServiceMethod<P> = (
  payload: P,
  config?: ApiServiceMethodConfig,
) => Promise<AxiosResponse>;

export type ApiServiceGetMethod = (config?: ApiServiceMethodConfig) => Promise<AxiosResponse>;

export interface ApiServiceI {
  changeEmail: ApiServiceMethod<{ email: string }>;
  changePassword: ApiServiceMethod<{ current: string; new: string }>;
  checkOntarioCompliance: ApiServiceMethod<{
    lsSessionId: string;
    dpSessionId: string;
    isRecheck: boolean;
  }>;
  checkUserEmailExistence: ApiServiceMethod<UserEmailExistenceRequestDto>;
  completeUserProfile: ApiServiceMethod<UserProfileCompletionRequestDto>;
  creditBonus: ApiServiceMethod<{ bonusCode: string }>;
  deleteBonus: ApiServiceMethod<{ userBonusId: string }>;
  forgotPassword: ApiServiceMethod<{ email: string }>;
  getAgcoUserDetails: ApiServiceGetMethod;
  getCountries: ApiServiceGetMethod;
  getIndustries: ApiServiceGetMethod;
  getJackpots: ApiServiceMethod<{ currency: string }>;
  getLimits: ApiServiceGetMethod;
  getOccupations: ApiServiceMethod<{ regulator: 'agco' }>;
  getSowQuestionnaire: ApiServiceMethod<{ locale: string }>;
  getSubdivisions: ApiServiceMethod<{ countryCode: string; locale: string }>;
  getZipCodeGeoInfo: ApiServiceMethod<ZipCodeGeoRequestDto>;
  postSowQuestionnaire: ApiServiceMethod<SOWQuestionnaireRequestDto>;
  registerAGCO: ApiServiceMethod<RegisterAgcoRequestDto>;
  registerShort: ApiServiceMethod<ShortRegisterPayload>;
  registerMexico: ApiServiceMethod<RegisterMexicoPayload>;
  setLimits: ApiServiceMethod<SetLimitRequestPayload>;
  updateVerifyAgcoUserDetails: ApiServiceMethod<UpdateVerifyAgcoUserDetailsRequestDto>;
  userBonuses: ApiServiceGetMethod;
  validateEmail: ApiServiceMethod<ValidateEmailRequestDto>;
  validateEmailMobile: ApiServiceMethod<ValidateEmailMobileRequestDto>;
  validateMobile: ApiServiceMethod<ValidateMobileRequestDto>;
  verifyAgcoUserDetails: ApiServiceMethod<null>;
  getConsents: ApiServiceGetMethod;
  saveConsents: ApiServiceMethod<SaveConsentsRequestDto>;
  updateContactInfo: ApiServiceMethod<UpdateContactInfoRequestDto>;
  authenticateWithChallenge: ApiServiceMethod<AuthenticateWithChallengeRequestDto>;
}

const apiPostCall = <P>(url: string, payload: P, config?: ApiServiceMethodConfig) => {
  return axiosInstance.post(url, payload, config);
};

const ApiService: ApiServiceI = {
  changeEmail: (payload, config) => apiPostCall(API_URLS.USER_CHANGE_EMAIL, payload, config),
  changePassword: (payload, config) => apiPostCall(API_URLS.CHANGE_PASSWORD, payload, config),
  checkOntarioCompliance: (payload, config) =>
    apiPostCall(API_URLS.COMPLIANCE_ONTARIO, payload, config),
  checkUserEmailExistence: (payload, config) =>
    apiPostCall(API_URLS.CHECK_USER_EMAIL_EXISTENCE, payload, config),
  completeUserProfile: (payload, config) => apiPostCall(API_URLS.COMPLETE_USER, payload, config),
  creditBonus: (payload, config) => apiPostCall(API_URLS.CREDIT_BONUS, payload, config),
  deleteBonus: (payload, config) => apiPostCall(API_URLS.DELETE_BONUS, payload, config),
  forgotPassword: (payload, config) => apiPostCall(API_URLS.FORGOT_PASSWORD, payload, config),
  getAgcoUserDetails: config => apiPostCall(API_URLS.GET_AGCO_USER_DETAILS, null, config),
  getCountries: config => apiPostCall(API_URLS.GET_COUNTRIES, config),
  getIndustries: config => apiPostCall(API_URLS.GET_INDUSTRIES, null, config),
  getJackpots: (payload, config) => apiPostCall(API_URLS.GET_JACKPOTS, payload, config),
  getLimits: config => apiPostCall(API_URLS.GET_LIMITS, null, config),
  getOccupations: (payload, config) => apiPostCall(API_URLS.GET_OCCUPATIONS, payload, config),
  getSowQuestionnaire: (payload, config) =>
    apiPostCall(API_URLS.GET_SOW_QUESTIONNAIRE, payload, config),
  getSubdivisions: (payload, config) => apiPostCall(API_URLS.GET_SUBDIVISIONS, payload, config),
  getZipCodeGeoInfo: (payload, config) => apiPostCall(API_URLS.GET_ZIP_CODE_GEO, payload, config),
  postSowQuestionnaire: (payload, config) =>
    apiPostCall(API_URLS.POST_SOW_QUESTIONNAIRE, payload, config),
  registerAGCO: (payload, config) => apiPostCall(API_URLS.REGISTER_AGCO, payload, config),
  registerShort: (payload, config) => apiPostCall(API_URLS.REGISTER_SHORT, payload, config),
  registerMexico: (payload, config) => apiPostCall(API_URLS.REGISTER_MEXICO, payload, config),
  setLimits: (payload, config) => apiPostCall(API_URLS.SET_LIMITS, payload, config),
  updateVerifyAgcoUserDetails: (payload, config) =>
    apiPostCall(API_URLS.UPDATE_VERIFY_AGCO_USER_DETAILS, payload, config),
  userBonuses: config => apiPostCall(API_URLS.USER_BONUSES, null, config),
  validateEmail: (payload, config) => apiPostCall(API_URLS.VALIDATE_EMAIL, payload, config),
  validateEmailMobile: (payload, config) =>
    apiPostCall(API_URLS.VALIDATE_EMAIL_MOBILE, payload, config),
  validateMobile: (payload, config) => apiPostCall(API_URLS.VALIDATE_MOBILE, payload, config),
  verifyAgcoUserDetails: (_payload, config) =>
    apiPostCall(API_URLS.VERIFY_AGCO_USER_DETAILS, null, config),
  getConsents: config => apiPostCall(API_URLS.GET_CONSENTS, null, config),
  saveConsents: (payload, config) => apiPostCall(API_URLS.SAVE_CONSENTS, payload, config),
  updateContactInfo: (payload, config) =>
    apiPostCall(API_URLS.UPDATE_CONTACT_INFO, payload, config),
  authenticateWithChallenge: (payload, config) =>
    apiPostCall(API_URLS.AUTHENTICATE_WITH_CHALLENGE, payload, config),
};

export default ApiService;
