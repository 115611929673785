import React from 'react';
import styled from 'styled-components';
import { AbsoluteLink } from '../../AbsoluteLink';
import LuckyDaysLogo from '../../UI/Logos/LuckyDaysLogo';

type LogoColors = 'white' | 'blue3';

const StyledLogo = styled(AbsoluteLink)<{ color: LogoColors; className: string }>`
  display: flex;
  transition: all 0.2s ease;
  line-height: 0;

  svg {
    color: ${props => props.theme.colors[props.color]};
  }
`;

function Logo({ color = 'blue3' }: { color?: LogoColors }): JSX.Element {
  return (
    <StyledLogo color={color} to="/" className="logo inline-flex" aria-label="LuckyDays">
      <LuckyDaysLogo />
    </StyledLogo>
  );
}

export default Logo;
