import {
  GetLastDepositSuccessAction,
  LastDepositActions,
  LastDepositActionType,
  SetLifetimeDepositsAction,
} from '@/store/actions/lastDepositActions';
import { BffLifetimeDepositsResponse } from '@lucky7ventures/bff-types';

export interface LastDepositStateModel {
  lastDeposit: number | undefined;
  loading: boolean;
  error: boolean;
  lifetimeDeposits: BffLifetimeDepositsResponse | null;
}

const initialState: LastDepositStateModel = {
  lastDeposit: undefined,
  loading: false,
  error: false,
  lifetimeDeposits: null,
};

const getLastDeposit = (state: LastDepositStateModel): LastDepositStateModel => ({
  ...state,
  loading: true,
  error: false,
});

const getLastDepositSuccess = (
  state: LastDepositStateModel,
  action: GetLastDepositSuccessAction,
): LastDepositStateModel => ({
  ...state,
  lastDeposit: action.lastDeposit,
  loading: false,
});

const getLastDepositError = (state: LastDepositStateModel): LastDepositStateModel => ({
  ...state,
  loading: false,
  error: true,
});

const setLifetimeDeposits = (
  state: LastDepositStateModel,
  action: SetLifetimeDepositsAction,
): LastDepositStateModel => ({
  ...state,
  lifetimeDeposits: action.deposits,
});

export const lastDepositReducer = (
  state = initialState,
  action: LastDepositActions,
): LastDepositStateModel => {
  switch (action.type) {
    case LastDepositActionType.GetLastDeposit:
      return getLastDeposit(state);
    case LastDepositActionType.GetLastDepositSuccess:
      return getLastDepositSuccess(state, action);
    case LastDepositActionType.GetLastDepositError:
      return getLastDepositError(state);
    case LastDepositActionType.SetLifetimeDeposits:
      return setLifetimeDeposits(state, action);
    case LastDepositActionType.Reset:
      return initialState;
    default:
      return state;
  }
};
