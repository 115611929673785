import { RootState } from '@/models/root-state.model';
import {
  DocumentType,
  KycDocumentResponseDto,
  VerificationStatus,
} from '@lucky7ventures/lucky-components';
import { createSelector } from 'reselect';
import { FluidKycStatus } from '@fluidpayments/types';
import { envIsMexico } from '@/constants/constants';
import { DateTime } from 'luxon';

export const selectKycDocuments = (state: RootState): KycDocumentResponseDto[] =>
  state.kyc.documents;
export const selectKycDocumentsLoaded = (state: RootState): boolean => state.kyc.documentsLoaded;
export const selectKycDocumentsLoading = (state: RootState): boolean => state.kyc.documentsLoading;
export const selectKycDocumentsRequested = (state: RootState): boolean =>
  state.kyc.documentsRequested;
export const selectKycDocumentsError = (state: RootState): boolean => state.kyc.documentsError;
export const selectKycVerifyInProgress = (state: RootState): boolean => state.kyc.verifyInProgress;

const selectKycDocumentsSortedByKycIdDesc = createSelector(
  selectKycDocuments,
  (documents): KycDocumentResponseDto[] => documents.sort((a, b) => b.KycId - a.KycId),
);

const selectKycDocumentsSortedByDateLastModified = createSelector(
  selectKycDocuments,
  (documents): KycDocumentResponseDto[] =>
    documents.sort(
      (a, b) =>
        DateTime.fromISO(b.DateLastModified as unknown as string).valueOf() -
        DateTime.fromISO(a.DateLastModified as unknown as string).valueOf(),
    ),
);

// this is an important selector, as the original state holds all the kyc requests,
// we only want to get the latest kyc requests and ignore the rest
export const selectKycDocumentsLatest = createSelector(
  selectKycDocumentsSortedByKycIdDesc,
  (documents): KycDocumentResponseDto[] => {
    const latestDocuments = documents.reduce((acc: Record<number, KycDocumentResponseDto>, i) => {
      // add the kyc only if there is no documents of that type in the map
      if (!acc[i.TypeId]) {
        return { ...acc, [i.TypeId]: i };
      }
      return acc;
    }, {});
    return Object.values(latestDocuments);
  },
);

export const selectKycDocumentPhotoIds = (selectKycDocumentsSelector = selectKycDocumentsLatest) =>
  createSelector(selectKycDocumentsSelector, (documents): KycDocumentResponseDto[] =>
    documents.filter(document => document.TypeId === DocumentType.Id),
  );

export const selectKycDocumentPhotoId = createSelector(
  selectKycDocumentPhotoIds(),
  (documents): KycDocumentResponseDto | undefined => documents[0],
);

export const selectKycDocumentPhotoIdStatusMexico = createSelector(
  selectKycDocumentPhotoIds(selectKycDocumentsSortedByDateLastModified),
  (photoIdDocuments): VerificationStatus | null => {
    if (photoIdDocuments.length === 0) return null;

    // For Mexico we need a different mechanism to define PhotoID status
    // because Mexico requires three documents (ID front, ID back, selfie)
    // approved in order for the user to be PhotoID KYC verified
    const latestDocument = photoIdDocuments[0];

    // Important: It seems that GiG's documents in the response are sorted as follows:
    // 1. Requested,
    // 2. Rejected,
    // 3. Approved

    if (latestDocument.Status === VerificationStatus.Approved && photoIdDocuments.length >= 3) {
      // If the latest document is approved, and for the user to be fully approved,
      // other two documents must also be approved. Since documents are sorted
      // by KycId, this should mean that the first three documents should then
      // all be Approved
      // Method `slice` is zero based, but does not include end element index,
      // hence we need to slice it to index 3
      const firstThreeDocuments = photoIdDocuments.slice(0, 3);
      for (const document of firstThreeDocuments) {
        if (document.Status !== VerificationStatus.Approved) {
          return document.Status;
        }
      }
      return VerificationStatus.Approved;
    }

    // For all other statuses:
    // 1. Requested/UserRequested
    // 2. Rejected
    // 3. Processing
    return latestDocument.Status;
  },
);

export const selectKycDocumentPOA = createSelector(
  selectKycDocumentsLatest,
  (documents): KycDocumentResponseDto | undefined =>
    documents.find(document => document.TypeId === DocumentType.ProofOfAddress),
);

export const selectKycDocumentPOP = createSelector(
  selectKycDocumentsLatest,
  (documents): KycDocumentResponseDto | undefined =>
    documents.find(document => document.TypeId === DocumentType.PaymentMethod),
);

export const selectKycDocumentSOWQ = createSelector(
  selectKycDocumentsLatest,
  (documents): KycDocumentResponseDto | undefined =>
    documents.find(document => document.TypeId === DocumentType.SourceOfWealthQuestionnaire),
);

export const selectKycSupportingDocuments = createSelector(
  selectKycDocuments,
  (documents): KycDocumentResponseDto[] =>
    documents.filter(document => document.TypeId === DocumentType.SourceOfWealthSupportingDocument),
);

export const selectKycSourceOfFunds = createSelector(
  selectKycDocumentsLatest,
  (documents): KycDocumentResponseDto | undefined =>
    documents.find(document => document.TypeId === DocumentType.SourceOfFunds),
);

export const selectKycPhotoIdApproved = createSelector(
  selectKycDocumentPhotoId,
  selectKycDocumentsLoaded,
  (photoId, documentsLoaded): boolean => {
    return documentsLoaded && !!photoId && photoId.Status === VerificationStatus.Approved;
  },
);

export const selectKycPOARequested = createSelector(
  selectKycDocumentPOA,
  selectKycDocumentsLoaded,
  (kycPOA, documentsLoaded): boolean => {
    return documentsLoaded && !!kycPOA && kycPOA.Status === VerificationStatus.Requested;
  },
);

export const selectKycPOPRequested = createSelector(
  selectKycDocumentPOP,
  selectKycDocumentsLoaded,
  (kycPOP, documentsLoaded): boolean => {
    return documentsLoaded && !!kycPOP && kycPOP.Status === VerificationStatus.Requested;
  },
);

export const selectKycSOFRequested = createSelector(
  selectKycSourceOfFunds,
  selectKycDocumentsLoaded,
  (kycSOF, documentsLoaded): boolean => {
    return documentsLoaded && !!kycSOF && kycSOF.Status === VerificationStatus.Requested;
  },
);

// we consider the account not verified if there is at least one kyc request that is not approved
export const selectKycAccountVerified = createSelector(
  selectKycDocumentsLatest,
  (documents): boolean => !documents.find(kyc => kyc.Status !== VerificationStatus.Approved),
);

export const selectKycAccountVerifiedMexico = createSelector(
  selectKycAccountVerified,
  selectKycDocumentPhotoIdStatusMexico,
  (accountVerified, photoIdStatus): boolean => {
    // Mexico user is fully verified when:
    // 1. All other KYC documents + latest PhotoID are Approved
    // 1. All other PhotoIDs are approved
    return accountVerified && photoIdStatus === VerificationStatus.Approved;
  },
);

export const selectFluidKycStatus = createSelector(
  selectKycDocumentPhotoId,
  selectKycDocumentPhotoIdStatusMexico,
  (photoId, photoIdStatusMexico): FluidKycStatus => {
    const status = envIsMexico() ? photoIdStatusMexico : photoId?.Status;

    switch (status) {
      case VerificationStatus.Approved:
        return 'verified';
      case VerificationStatus.Processing:
        return 'processing';
      default:
        return 'unverified';
    }
  },
);
