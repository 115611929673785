import React, { InputHTMLAttributes, ReactNode } from 'react';
import { appendDefaultContainerClassNames } from './helpers';
import FieldLabel from './FieldLabel';
import FieldInput from './FieldInput';
import FieldIcon from './FieldIcon';

export interface FieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  labelClassName?: string;
  endIcon?: ReactNode;
  endIconAction?: () => void;
}

const Field = ({
  className,
  endIcon,
  endIconAction,
  label,
  labelClassName,
  ...props
}: FieldProps) => {
  return (
    <div className={appendDefaultContainerClassNames(className, undefined, true)}>
      <FieldInput {...props} />
      <FieldLabel className={labelClassName} value={true}>
        {label}
      </FieldLabel>
      <FieldIcon icon={endIcon} onClick={endIconAction} />
    </div>
  );
};

export default Field;
