import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/high-res.css';
import { useEffectOnce } from 'react-use';
import styled from 'styled-components';
import { IS_SWEDEN } from '@/constants/constants';
import { mobileInputExcludedCountries } from '@/constants/mobileInputConstants';
import { FieldInputProps, FormikProps } from 'formik';

const StyledPhoneInput = styled.div`
  position: relative;
  text-align: left;

  .react-tel-input {
    font-family: 'Karla', sans-serif;
    border-bottom: 2px solid #eee;
    margin-bottom: 32px;
    height: 70px;

    .form-control,
    .flag-dropdown {
      margin-top: 16px !important;
      height: 56px;
      border: none;
      letter-spacing: 0;
      border-radius: 0;
      background: transparent;
      color: ${props => props.theme.colors.black};
      font-weight: bold;
      font-size: 16px;

      &:focus {
        border-color: #eee;
        box-shadow: none;
      }

      &:disabled ~ .flag-dropdown {
        opacity: 40%;
      }
    }

    li {
      margin-bottom: 0;
    }

    .form-control {
      padding: 10px 0 8px;
      z-index: 2;
      width: calc(100% - 60px);
      margin-left: 60px;

      &.invalid-number {
        background: none;
        border: none;
      }
    }

    .flag-dropdown {
      width: 100%;
      margin-top: 28px !important;
      height: 32px;
      z-index: 1;

      .selected-flag {
        border-radius: 8px;
        background: #fff;
      }

      &:hover,
      &.open {
        background: transparent;

        .selected-flag {
          border-radius: 8px;
          background: #fefefe;
        }
      }

      .country-list {
        border-radius: 8px;
        font-size: 14px;
        box-shadow:
          0 10px 15px -3px rgba(0, 0, 0, 0.1),
          0 4px 6px -2px rgba(0, 0, 0, 0.05);

        .flag {
          top: 9px;
        }

        .country {
          padding: 10px 9px 10px 44px;
          margin-top: 0;
        }
      }
    }

    .special-label {
      display: block;
      background: none;
      color: ${props => props.theme.colors.blue2};
      font-weight: bold;
      font-size: 15px;
      top: 0;
      left: 0;
      padding: 0;
    }

    .country-list {
      width: 100%;
    }
  }

  .approved {
    position: absolute;
    top: 33px;
    right: 0;
    width: 18px;
    height: 18px;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;

    &-password {
      right: 40px;
      top: 33px;
    }
  }

  .validationMessage {
    font-weight: bold;
    margin-bottom: 0;
  }
`;

export interface MobileValue {
  number: string;
  prefix: string;
  country: string;
  wholeNumber: string;
}

export interface CountryValue {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
}

export function MobileInput({
  field,
  form,
  disabled = false,
}: {
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  disabled?: boolean;
}) {
  const intl = useIntl();
  const [phone, setPhone] = useState<MobileValue>(field.value ? field.value : null);
  const [, setValid] = useState(false);
  const [error, setError] = useState(false);
  const [isBlurred, setIsBlurred] = useState(false);
  const excludedCountries = mobileInputExcludedCountries(IS_SWEDEN);

  useEffectOnce(() => {
    if (field.value && field.value.wholeNumber && field.value.wholeNumber.length > 5) {
      setValid(true);
    }
  });

  function checkIfValid(value: MobileValue, blurred: boolean) {
    if (value.wholeNumber && value.wholeNumber.length <= 5 && blurred) {
      setValid(false);
      setError(true);
    } else if (value.wholeNumber && value.wholeNumber.length > 5) {
      setValid(true);
      setError(false);
    } else {
      setValid(false);
      setError(false);
    }
  }

  function onChangeHandler(value: string, country: CountryValue) {
    const updatedPhone = {
      number: value.slice(country.dialCode.length),
      prefix: `+${country.dialCode}`,
      country: country.countryCode,
      wholeNumber: value,
    };

    setPhone(updatedPhone);
    checkIfValid(updatedPhone, isBlurred);

    if (country && value && country.dialCode && country.countryCode) {
      form.setFieldValue(field.name, updatedPhone);
    }
  }

  return (
    <StyledPhoneInput>
      <PhoneInput
        country={phone.country.toLowerCase()}
        value={phone.wholeNumber}
        onChange={(value, country) => {
          onChangeHandler(value, country as CountryValue);
        }}
        onBlur={() => {
          setIsBlurred(true);
          checkIfValid(phone, true);
        }}
        inputProps={{
          name: 'mobile',
        }}
        specialLabel={intl.formatMessage({ id: 'completeProfile.mobile.placeholder' })}
        excludeCountries={excludedCountries}
        masks={{ se: '..........' }}
        inputClass="disabled:!text-gray-400"
        disabled={disabled}
      />
      {error && <div className="text-left text-xs text-red-500">{error}</div>}
    </StyledPhoneInput>
  );
}
