import { all, AllEffect, ForkEffect, put, takeEvery, call, StrictEffect } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  GamesActionsTypes,
  getGamesError,
  getGamesJackpotsSuccess,
  getGamesSuccess,
  getLastPlayedGamesSuccess,
} from '../actions/gamesActions';
import { ApiResponse } from '@/models/api-response.model';
import { checkDevice } from '@/shared/utility';
import { doNothing } from '@/utils/utils';
import bffApiService from '@/shared/bffApiService';
import {
  BffGamesJackpotsResponse,
  BffGamesResponse,
  BffGamesLastPlayedResponse,
  BffResponse,
} from '@lucky7ventures/bff-types';

export function* getGamesSaga(): Generator<
  StrictEffect,
  void,
  AxiosResponse<ApiResponse<BffGamesResponse>>
> {
  try {
    const gamesResponse = yield call(bffApiService.getAllGames, {
      payload: { device: checkDevice() },
    });

    if (gamesResponse.data.data) {
      yield put(getGamesSuccess(gamesResponse.data.data));
    }
  } catch (error) {
    yield put(getGamesError());
  }
}

export function* getLastPlayedGamesSaga(): Generator<
  StrictEffect,
  void,
  AxiosResponse<BffResponse<BffGamesLastPlayedResponse>>
> {
  try {
    const lastPlayedResponse = yield call(bffApiService.getGamesLastPlayed);
    if (lastPlayedResponse.data.data) {
      yield put(getLastPlayedGamesSuccess(lastPlayedResponse.data.data));
    }
  } catch {
    doNothing();
  }
}

export function* getGamesJackpotsSaga(): Generator<
  StrictEffect,
  void,
  AxiosResponse<ApiResponse<BffGamesJackpotsResponse>>
> {
  try {
    const response = yield call(bffApiService.getGamesJackpots, {
      payload: {
        lang: 'en',
        currencyCode: 'EUR',
        device: checkDevice(),
      },
    });

    if (response.data.data) {
      yield put(getGamesJackpotsSuccess(response.data.data));
    }
  } catch (error) {
    yield put(getGamesError());
  }
}

export function* watchGames(): Generator<AllEffect<ForkEffect<never>>, void> {
  yield all([
    takeEvery(GamesActionsTypes.Get, getGamesSaga),
    takeEvery(GamesActionsTypes.GetLastPlayedGames, getLastPlayedGamesSaga),
    takeEvery(GamesActionsTypes.GetGamesJackpots, getGamesJackpotsSaga),
  ]);
}
