import React from 'react';

function Backdrop({ onBackdropClick }: { onBackdropClick: () => void }): JSX.Element {
  return (
    <div
      className="fixed top-0 left-0 right-0 bottom-0 z-[1] bg-black opacity-75"
      onClick={onBackdropClick}
    ></div>
  );
}

export default Backdrop;
