import * as actionTypes from './actionTypes';
import { FrontEndUserBonusObject } from '@lucky7ventures/lucky-components';
import { BffAvailableBonusesResponse } from '@lucky7ventures/bff-types';

export const getUserBonuses = () => ({
  type: actionTypes.GET_USER_BONUSES,
});

export const getUserBonusesSuccess = (bonuses: FrontEndUserBonusObject[]) => ({
  type: actionTypes.GET_USER_BONUSES_SUCCESS,
  bonuses,
});

export const getUserBonusesFail = () => ({
  type: actionTypes.GET_USER_BONUSES_FAIL,
});

export const getAvailableBonuses = () => ({
  type: actionTypes.GET_AVAILABLE_BONUSES,
});

export const getAvailableBonusesSuccess = (availableBonuses: BffAvailableBonusesResponse) => ({
  type: actionTypes.GET_AVAILABLE_BONUSES_SUCCESS,
  availableBonuses,
});

export const getAvailableBonusesFail = () => ({
  type: actionTypes.GET_AVAILABLE_BONUSES_FAIL,
});
