import { Action } from 'redux';

export enum UIActionType {
  SetIsSingleGame = 'UI_SET_IS_SINGLE_GAME',
}

export type SetIsSingleGameAction = Action<UIActionType.SetIsSingleGame> & {
  isSingleGame: boolean;
};

export type UIActions = SetIsSingleGameAction;

export const setIsSingleGame = (isSingleGame: boolean): SetIsSingleGameAction => ({
  type: UIActionType.SetIsSingleGame,
  isSingleGame,
});
