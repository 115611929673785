import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { AuthResponseDto } from '@lucky7ventures/lucky-components';

import CloseButton from '../UI/Buttons/CloseButton';
import LuckyDaysLogo from '../UI/Logos/LuckyDaysLogo';
import { LoginOtpStep } from '../LoginOtpStep';
import PromptMessage from '../UI/PromptMessage';
import { TranslatedRichText } from '../TranslatedRichText';
import { LoginFirstStep } from '../LoginFirstStep';
import { useAbsoluteNavigate } from '@/hooks/useAbsoluteNavigate';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { loginSuccess } from '@/store/actions/auth';

enum LoginStep {
  FirstStep,
  OtpStep,
}

interface Credentials {
  username: string;
  password: string;
}

export function LoginModal({
  closeModal,
  loggedOut = false,
  loggedOutIntlId = undefined,
  email,
}: {
  closeModal: () => void;
  loggedOut?: boolean;
  loggedOutIntlId?: string;
  email?: string;
}): JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [loginStep, setLoginStep] = useState(LoginStep.FirstStep);
  const [credentials, setCredentials] = useState<Credentials | null>(null);
  const [error, setError] = useState<string | null>(null);
  const navigate = useAbsoluteNavigate();
  const location = useLocation();

  const genericError = () => {
    setError(intl.formatMessage({ id: 'error.support' }));
  };

  const onLoginFirstStep = (data: AuthResponseDto, credentials: Credentials) => {
    setError(null);
    // there can be different results of the login
    // 1. SUCCESSFUL - that means that the user is logged in and the flow can be redirected forward
    // 2. TWO_FACTOR_BY_MESSAGE_FIRST_STEP_SUCCESSFUL - that means that the user has entered username/password ok, but still needs an OTP
    if (data.Result === 'SUCCESSFUL') {
      dispatch(loginSuccess(data.Token));
      triggerGTag(GTagEvents.login_success);
      closeModal();

      if (location.pathname.includes('register')) {
        navigate('/');
      }

      return;
    }

    if (data.Result === 'TWO_FACTOR_FIRST_STEP_SUCCESSFUL') {
      setCredentials(credentials);
      setLoginStep(LoginStep.OtpStep);
      triggerGTag(GTagEvents.login_2fa);
      return;
    }
    genericError();
  };

  const onLoginOtpStep = (data: AuthResponseDto) => {
    setError(null);
    // there can be different results of the login
    // 1. SUCCESSFUL - that means that the user is logged in and the flow can be redirected forward
    // 2. TWO_FACTOR_FIRST_STEP_SUCCESSFUL - that means that the user has entered username/password ok, but still needs an OTP
    if (data.Result === 'SUCCESSFUL') {
      dispatch(loginSuccess(data.Token));
      triggerGTag(GTagEvents.login_2fa_success);
      closeModal();
      return;
    }

    triggerGTag(GTagEvents.login_2fa_error);
    genericError();
  };

  return (
    <div
      className="relative flex min-h-[600px] w-full max-w-[380px] flex-col justify-center rounded-lg bg-slate-50 p-8"
      id="e2e_loginModal"
    >
      <CloseButton left onClick={closeModal} />
      <div className="text">
        <div className="mb-10 flex flex-col items-center justify-center">
          <LuckyDaysLogo />
        </div>
        {loggedOut && loginStep === LoginStep.FirstStep && (
          <>
            {loggedOutIntlId && (
              <p className="prose-sm mb-6 rounded-md bg-red-200 p-4 text-left leading-snug text-red-600 prose-a:underline">
                <TranslatedRichText id={loggedOutIntlId} />
              </p>
            )}
            {!loggedOutIntlId && (
              <p className="intro-text mb-6 text-sm font-semibold">
                {intl.formatMessage({ id: 'loggedout.text' })}
              </p>
            )}
          </>
        )}
        {error && (
          <div className="-mt-4">
            <PromptMessage type="error" message={error} />
          </div>
        )}
        {loginStep === LoginStep.FirstStep && (
          <LoginFirstStep onSuccessfulLogin={onLoginFirstStep} email={email} />
        )}
        {loginStep === LoginStep.OtpStep && (
          <LoginOtpStep
            username={credentials!.username}
            password={credentials!.password}
            onSuccessfulOtp={onLoginOtpStep}
          />
        )}
      </div>
    </div>
  );
}
