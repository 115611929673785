import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AbsoluteNavigate } from '../AbsoluteNavigate';
import { useCookies } from 'react-cookie';
import { selectIsAuthenticated } from '@/store/selectors/authSelectors';

export function Impersonate(): JSX.Element | null {
  const { token } = useParams<{ token: string }>();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [, setCookie] = useCookies(['luckydays_uid']);

  useEffect(() => {
    setCookie('luckydays_uid', token, { path: '/' });
  }, []);

  return isAuthenticated ? <AbsoluteNavigate to="/" /> : null;
}
