import React, { ReactNode } from 'react';

import classNames from 'classnames';

interface ITooltipProps {
  position: 'top' | 'right' | 'bottom' | 'left';
  text: string;
  className?: string;
  children: ReactNode;
}

const Tooltip = ({ position, text, className, children }: ITooltipProps) => {
  return (
    <div className="relative inline-block z-99">
      <div className="peer">{children}</div>
      <div
        className={classNames(
          `absolute z-20 w-max rounded bg-black py-1.5 px-2.5 text-xs
          font-medium text-white transition ease-in opacity-0 peer-hover:opacity-100 pointer-events-none`,
          { 'bottom-full left-1/2 mb-3 -translate-x-1/2': position === 'top' },
          { 'left-full top-1/2 ml-3 -translate-y-1/2': position === 'right' },
          { 'top-full left-1/2 mt-3 -translate-x-1/2': position === 'bottom' },
          { 'right-full top-1/2 mr-3 -translate-y-1/2': position === 'left' },
          className,
        )}
      >
        <span
          className={classNames(
            'absolute z-10 h-2 w-2 translate rotate-45 rounded-sm bg-black',
            { 'bottom-[-3px] left-1/2 -translate-x-1/2': position === 'top' },
            { 'left-[-3px] top-1/2 -translate-y-1/2': position === 'right' },
            { 'top-[-3px] left-1/2 -translate-x-1/2': position === 'bottom' },
            { 'right-[-3px] top-1/2 -translate-y-1/2': position === 'left' },
          )}
        />
        {text}
      </div>
    </div>
  );
};

export default Tooltip;
