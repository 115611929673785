import * as actionTypes from './actionTypes';

// Login
export const loginSuccess = token => ({
  type: actionTypes.LOGIN_SUCCESS,
  token,
});

// Logout
export const logout = () => ({
  type: actionTypes.LOGOUT,
});

export const logoutSuccess = () => ({
  type: actionTypes.LOGOUT_SUCCESS,
});

export const logoutFail = () => ({
  type: actionTypes.LOGOUT_FAIL,
});

export const authenticateWithChallengeToken = (challengeToken, isNewRegistration) => ({
  type: actionTypes.AUTHENTICATE_WITH_CHALLENGE_TOKEN,
  challengeToken,
  isNewRegistration,
});

export const authenticateWithChallengeTokenSuccess = token => ({
  type: actionTypes.AUTHENTICATE_WITH_CHALLENGE_TOKEN_SUCCESS,
  token,
});

export const authenticateWithChallengeTokenError = error => ({
  type: actionTypes.AUTHENTICATE_WITH_CHALLENGE_TOKEN_ERROR,
  error,
});
