import React from 'react';

export function BtnBlueLink({
  onClickHandler,
  text,
}: {
  onClickHandler: () => void;
  text: string;
}): JSX.Element {
  return (
    <button
      type="button"
      className=" mx-auto text-luckyBlue1 underline transition hover:text-luckyBlue2"
      onClick={onClickHandler}
    >
      {text}
    </button>
  );
}
