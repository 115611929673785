import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useSelector } from 'react-redux';

import Loader from './Loader';
import { selectIsAuthenticated } from '@/store/selectors/authSelectors';
import { selectAutoLoginStep } from '@/store/selectors/autoLoginSelectors';
import { AutoLoginStep } from '@/models/enum/auto-login-step.enum';

export function PrivateRoutes(): JSX.Element | null {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const step = useSelector(selectAutoLoginStep);

  if (step === AutoLoginStep.Idle || step === AutoLoginStep.Checking) {
    return <Loader />;
  }

  if (isAuthenticated) {
    return <Outlet />;
  }

  return <Navigate to=".." />;
}
