import React, { lazy, Suspense } from 'react';

import { useSelector } from 'react-redux';
import { envIsMexico, IS_HALLOWEEN } from '@/constants/constants';
import { selectIsAuthenticated } from '@/store/selectors/authSelectors';
import { useXmas } from '@/hooks/useXmas';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { isLiveCasinoEnabled } from '@/utils/logic.utils';
import LastPlayedGames from '@/pages/games/lobby/lobby-sections/LastPlayedGames';
import RecommendedGames from '@/pages/games/lobby/lobby-sections/RecommendedGames';
import HalloweenGames from '@/pages/games/lobby/lobby-sections/HalloweenGames';
import WinterGames from '@/pages/games/lobby/lobby-sections/WinterGames';
import NewGames from '@/pages/games/lobby/lobby-sections/NewGames';
import LiveCasinoGames from '@/pages/games/lobby/lobby-sections/LiveCasinoGames';
import SlotsGames from '@/pages/games/lobby/lobby-sections/SlotsGames';
import JackpotsGames from '@/pages/games/lobby/lobby-sections/JackpotsGames';
import TableGames from '@/pages/games/lobby/lobby-sections/TableGames';
import AllGames from '@/pages/games/lobby/lobby-sections/AllGames';

const GamesLobbyMexico = lazy(() => import('./GamesLobbyMexico'));

function GamesLobby(): JSX.Element {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isXmas = useXmas();
  const locale = useSelector(selectLocale);

  return (
    <Suspense fallback={<div className="w-full min-h-[100vh]" />}>
      {envIsMexico() && <GamesLobbyMexico />}
      {!envIsMexico() && (
        <div
          id="e2e_sections_container"
          className="py-16 flex flex-col gap-16 max-w-[1400px] mx-auto"
        >
          {isAuthenticated && <LastPlayedGames />}
          <RecommendedGames />
          {IS_HALLOWEEN && <HalloweenGames />}
          {isXmas && <WinterGames />}
          <NewGames />
          {isLiveCasinoEnabled(locale, isAuthenticated) && <LiveCasinoGames />}
          <SlotsGames />
          <JackpotsGames />
          <TableGames />
          <AllGames />
        </div>
      )}
    </Suspense>
  );
}

export default GamesLobby;
