import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_SWEDEN, LD_SHOW_STATS_AND_LIMITS } from '@/constants/constants';
import { selectUserPlayStatsAndLimits } from '@/store/selectors/userPlayStatsAndLimitsSelectors';
import { openModal } from '@/store/actions/modal';

export function useStatsAndLimits(): void {
  const statsAndLimits = useSelector(selectUserPlayStatsAndLimits);
  const showStatsAndLimits = localStorage.getItem(LD_SHOW_STATS_AND_LIMITS);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!IS_SWEDEN) {
      return;
    }

    if (statsAndLimits && showStatsAndLimits) {
      setTimeout(() => {
        dispatch(openModal('statsAndLimits'));
      }, 1500);
    }
  }, [statsAndLimits, showStatsAndLimits]);
}
