import { PublicUserConsentModel } from '@lucky7ventures/lucky-components';
import { Action } from 'redux';

export enum ConsentsActionTypes {
  GetConsents = 'GET_CONSENTS',
  GetConsentsSuccess = 'GET_CONSENTS_SUCCESS',
  GetConsentsError = 'GET_CONSENTS_ERROR',
  ResetConsents = 'RESET_CONSENTS',
}

export type GetConsentsAction = Action<ConsentsActionTypes.GetConsents>;
export type GetConsentsSuccessAction = Action<ConsentsActionTypes.GetConsentsSuccess> & {
  consents: PublicUserConsentModel[];
};
export type GetConsentsErrorAction = Action<ConsentsActionTypes.GetConsentsError>;
export type ResetConsentsAction = Action<ConsentsActionTypes.ResetConsents>;

export type ConsentsActions =
  | GetConsentsAction
  | GetConsentsSuccessAction
  | GetConsentsErrorAction
  | ResetConsentsAction;

export const getConsents = (): GetConsentsAction => ({
  type: ConsentsActionTypes.GetConsents,
});

export const getConsentsSuccess = (
  consents: PublicUserConsentModel[],
): GetConsentsSuccessAction => ({
  type: ConsentsActionTypes.GetConsentsSuccess,
  consents,
});

export const getConsentsError = (): GetConsentsErrorAction => ({
  type: ConsentsActionTypes.GetConsentsError,
});

export const resetConsents = (): ResetConsentsAction => ({
  type: ConsentsActionTypes.ResetConsents,
});
