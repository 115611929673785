import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { selectActiveLimits, selectLimitsLoaded } from '@/store/selectors/limitsSelectors';
import { envIsSweden } from '@/constants/constants';
import { openModal } from '@/store/actions/modal';

export function useRequiredDepositLimitsCheck(): void {
  const limitsLoaded = useSelector(selectLimitsLoaded);
  const activeLimits = useSelector(selectActiveLimits);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!envIsSweden()) {
      return;
    }

    if (limitsLoaded) {
      // For the Sweden build we require the user to have Daily, Weekly and Monthly Deposit limit
      // 1 - daily, 2 - weekly, 3 - monthly
      const requiredLimitDurations = [1, 2, 3];
      const missingLimitDurations = requiredLimitDurations.filter(
        i => !activeLimits.find(limit => limit.Duration === i),
      );
      if (missingLimitDurations.length > 0) {
        dispatch(openModal('requiredDepositLimits'));
      }
    }
  }, [limitsLoaded, activeLimits]);
}
