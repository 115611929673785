import React, { lazy, Suspense } from 'react';

const ResponsibleGaming = lazy(() => import('./ResponsibleGaming'));

function ResponsibleGamingTab() {
  return (
    <Suspense fallback={<div />}>
      <ResponsibleGaming />
    </Suspense>
  );
}

export default ResponsibleGamingTab;
