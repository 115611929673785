import React, { Suspense } from 'react';
import { envIsMexico } from '@/constants/constants';

const AboutUsContentMx = React.lazy(() => import('./AboutUsContentMx'));

const AboutUsContent = (): JSX.Element => {
  if (envIsMexico()) {
    return <AboutUsContentMx />;
  }

  // LAZY LOAD
  // switch (locale) {
  //   case 'ae':
  //     return <AboutUsContentAe />;
  //   case 'fi':
  //     return <AboutUsContentFi />;
  //   case 'fi-en':
  //     return <AboutUsContentFiEn />;
  //   case 'za':
  //     return <AboutUsContentZa />;
  //   case 'nz':
  //     return <AboutUsContentNz />;
  //   case 'ca':
  //     return <AboutUsContentCa />;
  //   case 'ca-fr':
  //     return <AboutUsContentFr />;
  //   case 'no':
  //     return <AboutUsContentNo />;
  //   case 'at':
  //     return <AboutUsContentAt />;
  //   case 'th':
  //     return <AboutUsContentTh />;
  //   default:
  //     return <AboutUsContentEn />;
  // }

  // After implementing about-us page for all locales, it will default to EN version
  return <div />;
};

const AboutUs = (): JSX.Element => {
  return (
    <div>
      <Suspense fallback={<div />}>
        <AboutUsContent />
      </Suspense>
    </div>
  );
};

export default AboutUs;
