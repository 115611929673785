import * as React from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectGamesByProvider, selectGamesLoading } from '@/store/selectors/gamesSelectors';
import CategoryPageContent from '@/pages/games/categories/CategoryPageContent';
import EmptyGamesPlaceholder from '@/pages/games/EmptyGamesPlaceholder';
import { BffGamesCategory } from '@lucky7ventures/bff-types';

function GamesByProvider(): JSX.Element {
  const { provider } = useParams<{ provider: string }>();
  const selectedProvider = useSelector(selectGamesByProvider(provider || ''));
  const gamesLoading = useSelector(selectGamesLoading);

  if (!selectedProvider) {
    return <EmptyGamesPlaceholder category={BffGamesCategory.AllGames} />;
  }

  return (
    <CategoryPageContent
      header={selectedProvider.name}
      category={BffGamesCategory.AllGames}
      games={selectedProvider.games}
      loading={gamesLoading}
    />
  );
}

export default GamesByProvider;
