import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';

const ModalLayout = ({
  children,
  closeModal,
  onClose,
  className,
  id,
}: {
  children: React.ReactNode;
  closeModal?: () => void;
  onClose?: () => void;
  className?: string;
  id?: string;
}): JSX.Element => {
  const handleOnClose = () => {
    closeModal?.();
    onClose?.();
  };

  return (
    <div
      id={id}
      className={classNames(
        'relative w-full max-w-sm overflow-hidden lg:max-w-2xl rounded-xl bg-white text-left',
        className,
      )}
    >
      {closeModal && (
        <button
          type="button"
          onClick={handleOnClose}
          className="absolute top-0 left-0 rounded-br-xl p-2 text-gray-700 hover:bg-gray-200"
        >
          <XMarkIcon className="h-6 w-6" />
        </button>
      )}
      <div className={`p-6 ${closeModal ? 'mt-6' : 'mt-0'}`}>{children}</div>
    </div>
  );
};

export default ModalLayout;
