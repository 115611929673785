import { RootState } from '@/models/root-state.model';
import { WpReferrerContent } from '@/models/wp-referrer-content.model';
import { WpPrivacyPolicy } from '@/models/wp-privacy-policy.model';
import { WpTermsAndConditions } from '@/models/wp-terms-and-conditions.model';
import { WpBonusIntro } from '@/models/wp-bonus-intro.model';

export const selectHeaderMessage = (state: RootState): string | null =>
  state.wordpress.headerMessage;
export const selectReferrerContent = (state: RootState): WpReferrerContent | null =>
  state.wordpress.referrerContent;
export const selectBonusIntro = (state: RootState): WpBonusIntro | null =>
  state.wordpress.bonusIntro;
export const selectTermsAndConditions = (state: RootState): WpTermsAndConditions | null =>
  state.wordpress.termsAndConditions;
export const selectTermsAndConditionsLocale = (state: RootState): string | null =>
  state.wordpress.termsAndConditionsLocale;
export const selectPrivacyPolicy = (state: RootState): WpPrivacyPolicy | null =>
  state.wordpress.privacyPolicy;
export const selectPrivacyPolicyLocale = (state: RootState): string | null =>
  state.wordpress.privacyPolicyLocale;
