import React from 'react';

import Spinner from './UI/Spinner';

const Loader = (): JSX.Element => {
  return (
    <div className="relative z-[999] flex h-screen w-full items-center justify-center bg-[#F4FAFB]">
      <Spinner width={50} height={50} borderwidth={5} dark />
    </div>
  );
};

export default Loader;
