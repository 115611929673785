import React from 'react';

import LazyLoad from 'react-lazyload';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { isBrowser } from 'react-device-detect';

import GameTag from './GameTag';
import PlayIcon from '@/components/UI/Icons/PlayIcon';
import { AbsoluteLink } from '@/components/AbsoluteLink';
import { useOpenLogin } from '@/hooks/useOpenLogin';
import { BUILD_MODE } from '@/constants/constants';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { gameThumbImg } from '@/utils/image-utils';
import { GameCardVM } from '@/models/vm/game-card-vm.model';
import { selectIsFastReg } from '@/store/selectors/userSelectors';
import { selectIsAuthenticated } from '@/store/selectors/authSelectors';
import { openModal } from '@/store/actions/modal';

const StyledGameCard = styled.div<{ isbrowser: number }>`
  display: block;
  text-decoration: none;

  .image-wrapper {
    position: relative;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    overflow: hidden;
    display: block;

    .tag {
      position: absolute;

      &.new {
        top: 8px;
        right: 8px;
      }

      &.live {
        top: 8px;
        left: 8px;
      }

      &.hot {
        top: 8px;
        left: 8px;
      }

      &.jackpot {
        bottom: 8px;
        left: 8px;
        right: 8px;
      }
    }

    &:after {
      content: '';
      display: block;
      padding-bottom: 122.75%;
    }

    img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      margin: 0;
      padding: 0;
    }

    .play-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      padding: 16px;

      .play {
        background: rgba(0, 0, 0, 0.6);
        border-radius: 50vh;
        width: 46px;
        height: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid white;

        svg {
          font-size: 20px;
          color: white;
          margin-left: 2px;
        }
      }
    }

    .overlay {
      background: rgba(0, 0, 0, 0.6);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      padding: 16px;

      .button {
        width: 100%;
        border-radius: 50vh;
        padding: 14px;
        font-size: 16px;
        text-decoration: none;
        text-align: center;
        color: #fff;
        background: ${props => props.theme.colors.blue1};
        border: none;
        font-weight: bold;
        transition: all 0.15s ease;
        -webkit-appearance: none;
        cursor: pointer;
        box-shadow:
          0 10px 15px -3px rgba(0, 0, 0, 0.1),
          0 4px 6px -2px rgba(0, 0, 0, 0.05);

        &:hover {
          background: ${props => props.theme.colors.blue1Hover};
        }
      }
    }
  }

  .info {
    h3,
    a {
      display: block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 16px 0 0;
      padding: 0;
      font-size: 16px;
      text-decoration: none;
      color: ${props => props.theme.colors.blue3};

      a {
        margin-top: 0;
      }

      @media (min-width: ${props => props.theme.breakpoints.small}) {
        font-size: 18px;
      }
    }

    h4 {
      width: 100%;
      display: block;
      font-size: 13px;
      font-weight: bold;
      margin: 6px 0 0 0;
      font-family: 'Karla', sans-serif;
      opacity: 0.5;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (min-width: ${props => props.theme.breakpoints.small}) {
        font-size: 14px;
      }
    }
  }

  ${props =>
    props.isbrowser &&
    css`
      &:hover {
        .overlay,
        .play-overlay {
          opacity: 1;
        }
      }
    `}
`;

function GameCard({ game, category }: { game: GameCardVM; category: string }) {
  const location = useLocation();
  const openFrom = location.pathname;

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const dispatch = useDispatch();
  const intl = useIntl();
  const hasFastRegTag = useSelector(selectIsFastReg);
  const openLogin = useOpenLogin();

  function tagManagerHandler() {
    triggerGTag(GTagEvents.open_game, {
      openedFrom: openFrom,
      categoryName: category,
      gameName: game.name,
    });
  }

  const gameClickHandler = () => {
    if (canOpenGame()) {
      // if the user can open the game that means the game is an AbsoluteLink
      // just call the google tag manager event
      tagManagerHandler();
      return;
    }

    // if the user can't open the game, we have several scenarios
    if (hasFastRegTag) {
      dispatch(openModal('completeProfile'));
      triggerGTag(GTagEvents.complete_profile_game_click);
      return;
    }

    openLogin();
  };

  const canOpenGame = () => {
    if (BUILD_MODE === 'canada') {
      // for build mode canada, we don't want to allow demo play at all if the user is not authenticated
      return isAuthenticated;
    }

    // other build modes
    // this is for locales that support fast registration
    // if the user has a fast reg tag, we don't allow him to open the game
    if (hasFastRegTag) {
      return false;
    }

    // user can open the game if the game has an AllowDemoPlay or if he is authenticated
    return game.isDemoAvailable || isAuthenticated;
  };

  const shouldPresentLoginOverlay = () => {
    if (BUILD_MODE === 'canada') {
      return !isAuthenticated;
    }

    return !game.isDemoAvailable && !isAuthenticated;
  };

  return (
    <StyledGameCard
      className="e2e_gameCard w-full flex justify-center items-center"
      isbrowser={isBrowser ? 1 : 0}
      as={canOpenGame() ? AbsoluteLink : 'div'}
      to={`/games/${game.slug}/${game.gameId}`}
      onClick={gameClickHandler}
    >
      <div className="image-wrapper">
        <LazyLoad once height="100%" offset={1000}>
          <img src={gameThumbImg(game.slug)} alt={game.name} />
        </LazyLoad>

        {game.jackpot && <GameTag type="jackpot" amount={game.jackpot.amount} />}
        {game.isLive && <GameTag type="live" />}
        {game.isNew && <GameTag type="new" />}
        {game.isHot && <GameTag type="hot" />}
        {isBrowser && (
          <div className="overlay">
            {shouldPresentLoginOverlay() ? (
              <button type="button" className="button" onClick={openLogin}>
                {intl.formatMessage({ id: 'gameCard.loginToPlay' })}
              </button>
            ) : (
              <div className="play-overlay">
                <div className="play">
                  <PlayIcon />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="info">
        <h3 className="font-bold">{game.name}</h3>
        <h4>{game.shortDesc}</h4>
      </div>
    </StyledGameCard>
  );
}

export default GameCard;
