import React from 'react';
import classNames from 'classnames';

type BtnIconProps = {
  className?: string;
  onClickHandler: () => void;
  children: React.ReactNode;
};

function BtnIcon({ onClickHandler, children, className }: BtnIconProps): JSX.Element {
  return (
    <button
      type="button"
      className={classNames(
        'block rounded-[50%] bg-white/20 p-2 hover:bg-white/30 relative',
        className,
      )}
      onClick={onClickHandler}
    >
      {children}
    </button>
  );
}

export default BtnIcon;
