import { all, AllEffect, call, ForkEffect, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';
import { doNothing, isSuccessfulWpResponse, wpApiUrl } from '@/utils/utils';
import {
  GetBonusIntroAction,
  getBonusIntroSuccess,
  GetHeaderMessageAction,
  getHeaderMessageSuccess,
  GetPrivacyPolicyAction,
  getPrivacyPolicySuccess,
  GetReferrerContentAction,
  getReferrerContentSuccess,
  GetTermsAndConditionsAction,
  getTermsAndConditionsSuccess,
  WordpressActionType,
} from '../actions/wordpressActions';
import { WpReferrerContent } from '@/models/wp-referrer-content.model';
import { WpHeaderMessage } from '@/models/wp-header-message.model';
import { WpBonusIntro } from '@/models/wp-bonus-intro.model';
import { WpTermsAndConditions } from '@/models/wp-terms-and-conditions.model';
import { WpPrivacyPolicy } from '@/models/wp-privacy-policy.model';

export function* getHeaderMessageSaga(
  action: GetHeaderMessageAction,
): Generator<StrictEffect, void, AxiosResponse<WpHeaderMessage>> {
  try {
    const response = yield call(axios.get, wpApiUrl(action.locale, '/header_message'));
    if (isSuccessfulWpResponse(response)) {
      yield put(getHeaderMessageSuccess(response.data.text));
    }
  } catch (error) {
    doNothing();
  }
}

export function* getBonusIntroSaga(
  action: GetBonusIntroAction,
): Generator<StrictEffect, void, AxiosResponse<WpBonusIntro>> {
  try {
    const response = yield call(axios.get, wpApiUrl(action.locale, '/bonus_intro'));
    if (isSuccessfulWpResponse(response)) {
      yield put(getBonusIntroSuccess(response.data));
    }
  } catch (error) {
    doNothing();
  }
}

export function* getReferrerContentSaga(
  action: GetReferrerContentAction,
): Generator<StrictEffect, void, AxiosResponse<WpReferrerContent>> {
  try {
    const response = yield call(axios.get, wpApiUrl(action.locale, `/referrers/${action.id}`));

    if (isSuccessfulWpResponse(response)) {
      yield put(getReferrerContentSuccess(response.data));
    }
  } catch (error) {
    doNothing();
  }
}

export function* getTermsAndConditionsSaga({
  locale,
}: GetTermsAndConditionsAction): Generator<
  StrictEffect,
  void,
  AxiosResponse<WpTermsAndConditions>
> {
  try {
    const response = yield call(axios.get, wpApiUrl(locale, `/terms_and_conditions`));
    if (isSuccessfulWpResponse(response)) {
      yield put(getTermsAndConditionsSuccess(response.data, locale));
    }
  } catch (error) {
    doNothing();
  }
}

export function* getPrivacyPolicySaga({
  locale,
}: GetPrivacyPolicyAction): Generator<StrictEffect, void, AxiosResponse<WpPrivacyPolicy>> {
  try {
    const response = yield call(axios.get, wpApiUrl(locale, `/privacy_policy`));
    if (isSuccessfulWpResponse(response)) {
      yield put(getPrivacyPolicySuccess(response.data, locale));
    }
  } catch (error) {
    doNothing();
  }
}

export function* watchWordpress(): Generator<AllEffect<ForkEffect<never>>, void> {
  yield all([
    takeEvery(WordpressActionType.GetHeaderMessage, getHeaderMessageSaga),
    takeEvery(WordpressActionType.GetBonusIntro, getBonusIntroSaga),
    takeEvery(WordpressActionType.GetTermsAndConditions, getTermsAndConditionsSaga),
    takeEvery(WordpressActionType.GetPrivacyPolicy, getPrivacyPolicySaga),
    takeEvery(WordpressActionType.GetReferrerContent, getReferrerContentSaga),
  ]);
}
