import { PublicUserConsentModel } from '@lucky7ventures/lucky-components';
import { createSelector } from 'reselect';
import { RootState } from '@/models/root-state.model';

export enum CONSENT_TYPES {
  DETAILS_REVERIFICATION = 'DETAILS_REVERIFICATION',
  'E-MAIL' = 'E-MAIL',
  PERSONAL_DATA_PROCESSING = 'PERSONAL_DATA_PROCESSING',
  PRIVACY_AND_COOKIE_NOTICE = 'PRIVACY_AND_COOKIE_NOTICE',
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
  TEXT_MESSAGE = 'TEXT_MESSAGE',
}

export const mandatoryConsents = [
  CONSENT_TYPES.PERSONAL_DATA_PROCESSING,
  CONSENT_TYPES.PRIVACY_AND_COOKIE_NOTICE,
  CONSENT_TYPES.TERMS_AND_CONDITIONS,
];

export const smsEmailConsents = [CONSENT_TYPES.TEXT_MESSAGE, CONSENT_TYPES['E-MAIL']];

// This is one step into the response that we get back from GiG (PublicUserConsentsListModel)
export const selectConsents = (state: RootState): PublicUserConsentModel[] =>
  state.consents.consents;

export const selectMandatoryConsents = createSelector(selectConsents, consents =>
  consents.filter(consent => mandatoryConsents.includes(consent.Name as CONSENT_TYPES)),
);

export const selectSmsEmailConsents = createSelector(selectConsents, consents =>
  consents.filter(consent => smsEmailConsents.includes(consent.Name as CONSENT_TYPES)),
);

export const selectIsEmailConsented = createSelector(selectSmsEmailConsents, consents => {
  const consent = consents.find(consent => consent.Name === 'E-MAIL');
  return consent?.Consented;
});

export const selectIsTextMessageConsented = createSelector(selectSmsEmailConsents, consents => {
  const consent = consents.find(consent => consent.Name === 'TEXT_MESSAGE');
  return consent?.Consented;
});

export const selectIsTCConsented = createSelector(selectMandatoryConsents, consents => {
  const consent = consents.find(consent => consent.Name === 'TERMS_AND_CONDITIONS');
  return consent?.Consented;
});

export const selectIsPPConsented = createSelector(selectMandatoryConsents, consents => {
  const consent = consents.find(consent => consent.Name === 'PRIVACY_AND_COOKIE_NOTICE');
  return consent?.Consented;
});

export const selectIsDRConsented = createSelector(selectConsents, consents => {
  const consent = consents.find(consent => consent.Name === 'DETAILS_REVERIFICATION');
  return consent?.Consented;
});
