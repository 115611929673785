import React from 'react';

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import GameListHeader from './GameListHeader';
import GameList from './GameList';
import {
  selectAllGames,
  selectGamesLoading,
  selectSearchedGames,
} from '@/store/selectors/gamesSelectors';
import { isEmpty } from '@/shared/utility';
import { RootState } from '@/models/root-state.model';
import { GAME_CATEGORY } from './types';
import GamesSkeletonLoader from '@/components/shared/skeleton-loader/GamesSkeletonLoader';

export function GamesSearch({ searchValue }: { searchValue: string }): JSX.Element {
  const intl = useIntl();
  const filteredGames = useSelector((state: RootState) => selectSearchedGames(state, searchValue));
  const allGames = useSelector(selectAllGames);
  const gamesLoading = useSelector(selectGamesLoading);

  return (
    <div className="pb-28">
      <section className="pt-12 sm:pt-20">
        <GameListHeader
          header={`${intl.formatMessage({ id: `games.search` })}: ${searchValue}`}
          games={allGames}
          gamesLoading={gamesLoading}
          count={!isEmpty(filteredGames) ? filteredGames.length : 0}
        />
        {!isEmpty(filteredGames) ? (
          <GameList
            games={filteredGames}
            gamesLoading={gamesLoading}
            category={GAME_CATEGORY.SEARCH}
          />
        ) : (
          <GamesSkeletonLoader />
        )}
      </section>
    </div>
  );
}
