import React from 'react';
import classNames from 'classnames';

interface FieldErrorProps {
  error?: string;
  show: boolean;
}

const FieldError = ({ error, show }: FieldErrorProps) => (
  <div
    className={classNames(
      `absolute bottom-0 transition-opacity duration-1500 translate-y-full text-[12px]
          text-red-300 rounded-b-md py-0.5 text-left sm:max-w-[92%]`,
      show ? 'opacity-100' : 'opacity-0',
    )}
  >
    {error}
  </div>
);

export default FieldError;
