import React from 'react';

import { XMarkIcon } from '@heroicons/react/24/solid';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledCloseButton = styled.button`
  position: absolute;
  top: 0;
  padding: 8px;
  line-height: 0;
  left: ${props => (props.left ? 0 : 'auto')};
  right: ${props => (props.right ? 0 : 'auto')};
  border-radius: ${props =>
    props.left ? `0 0 ${props.theme.borderRadius} 0` : `0 0 0 ${props.theme.borderRadius}`};
  color: ${props => (props.light ? props.theme.colors.white : props.theme.colors.black)};
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 3;
  transition: all 0.15s ease;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 22px;
    width: 22px;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

const CloseButton = ({ onClick, right, left, light }) => (
  <StyledCloseButton
    type="button"
    aria-label="Close"
    onClick={onClick}
    right={right ? 1 : 0}
    left={left ? 1 : 0}
    light={light ? 1 : 0}
    className="close-button"
    data-cy="close-button"
  >
    <XMarkIcon />
  </StyledCloseButton>
);

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  right: PropTypes.bool,
  left: PropTypes.bool,
  light: PropTypes.bool,
};

CloseButton.defaultProps = {
  right: false,
  left: false,
  light: false,
};

export default CloseButton;
