import { AxiosResponse } from 'axios';
import { WpError } from '@/models/wp-error.model';
import { BffResponse } from '@lucky7ventures/bff-types';

export const isAxiosResponse = (response: unknown): response is AxiosResponse => {
  return !!(response as AxiosResponse).data;
};

export const isWpError = (response: unknown): response is WpError => {
  const r = response as WpError;
  return !!(r.code && r.message && r.data && r.data.status);
};

export const isBffResponse = (data: unknown): data is BffResponse<any> => {
  const r = data as BffResponse<any>;
  return r && r.hasOwnProperty('data');
};

export const isBffErrorResponse = (data: unknown): data is BffResponse<any> => {
  const r = data as BffResponse<any>;
  return !!(r && r.error && r.error.code);
};
