// Design doc: https://www.notion.so/superbet/Client-metrics-mechanics-for-duration-type-38c895f2314145edada08550a62b4aa8
import {
  ClientMetricsConfig,
  Metric,
  MetricHistogram,
  NetworkRequest,
  WhitelistHosts,
} from './network-types';

export class NetworkClientMetrics {
  private metricId: number;
  private bucketsMs: number[];
  private whitelistHosts: WhitelistHosts;

  private clientResponseMetrics: Metric[] = [];

  public constructor() {
    this.metricId = 0;
    this.bucketsMs = [0];
    this.whitelistHosts = {};
  }

  static getMetricsPayload(clientResponseMetrics: Metric[]): string {
    return clientResponseMetrics
      .map(metric => {
        return `[${metric.metricId},"${metric.method}",${metric.hostId},"${metric.path}","${
          metric.httpStatus
        }",${JSON.stringify(metric.histogram)}]`;
      })
      .join('\n');
  }

  public configure(config: ClientMetricsConfig): void {
    this.metricId = config.metricId;
    this.bucketsMs = config.bucketsMs;
    this.whitelistHosts = config.whitelistHosts;
  }

  public recordNetworkResponse(networkRequest: NetworkRequest): void {
    const host = networkRequest.host;
    if (!(host in this.whitelistHosts)) {
      return;
    }
    const indexOfMetric = this.clientResponseMetrics.findIndex(metric => {
      return (
        metric.metricId === this.metricId &&
        metric.hostId === this.whitelistHosts[host] &&
        metric.method === networkRequest.method &&
        metric.path === networkRequest.path &&
        metric.httpStatus === networkRequest.status
      );
    });

    if (indexOfMetric !== -1) {
      this.addMetricToExistingEntry(indexOfMetric, networkRequest);
    } else {
      const histogram = this.createInitialHistogram(networkRequest);
      this.clientResponseMetrics.push({
        metricId: this.metricId,
        hostId: this.whitelistHosts[host],
        method: networkRequest.method,
        path: networkRequest.path,
        httpStatus: networkRequest.status,
        histogram,
      });
    }
  }

  // for test purposes
  public getClientResponseMetrics(): Metric[] {
    return this.clientResponseMetrics;
  }

  public clearMetrics(): void {
    this.clientResponseMetrics = [];
  }

  private addMetricToExistingEntry(indexOfMetric: number, networkRequest: NetworkRequest): void {
    const countOfAllValuesIndex = 0;
    const sumOfAllValuesIndex = 1;

    const metric = this.clientResponseMetrics[indexOfMetric];

    let incrementFromIndex = 2;
    metric.histogram.forEach((bucket, index) => {
      if (index === countOfAllValuesIndex) {
        metric.histogram[index][1] += 1;
      } else if (index === sumOfAllValuesIndex) {
        metric.histogram[index][1] += networkRequest.duration;
      } else {
        if (networkRequest.duration > metric.histogram[index][0]) {
          incrementFromIndex += 1;
        }
        if (index >= incrementFromIndex) {
          metric.histogram[index][1] += 1;
        }
      }
    });
  }

  private createInitialHistogram(networkRequest: NetworkRequest): [number, number][] {
    const countOfAllValuesKey = -1;
    const sumOfAllValuesKey = -2;
    const histogram: MetricHistogram = [];

    /**
     * First two values in histogram specifically reserved
     * for count of all values and sum of all durations
     */
    histogram.push([countOfAllValuesKey, 1]);
    histogram.push([sumOfAllValuesKey, networkRequest.duration]);
    /**
     * We start incrementing bucket values from bucket that has value less
     * than duration of the request, and increment all buckets after that
     * e.g. if request duration is 120ms and buckets ([duration, count]) are
     * [[50, 0], [100, 1], [200,2]], then histogram will be [[50, 0], [100, 2], [200, 3]]
     */
    let incrementFromIndex = 0;
    this.bucketsMs.forEach((bucket, index) => {
      if (networkRequest.duration > bucket) {
        incrementFromIndex += 1;
      }
      const valueToAdd = index >= incrementFromIndex ? 1 : 0;
      histogram.push([bucket, valueToAdd]);
    });
    return histogram;
  }
}
