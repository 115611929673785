import * as React from 'react';

export function RestOfWorld(props) {
  return (
    <svg width={22} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M0 0v16h22V0H0z" fill="#3D58DB" />
      <path
        d="M11.25 13.25a5 5 0 100-10 5 5 0 000 10zm0 1.25a6.25 6.25 0 110-12.5 6.25 6.25 0 010 12.5z"
        fill="#fff"
      />
      <path
        d="M11.25 13.25c.28 0 .7-.355 1.092-1.14.49-.98.783-2.365.783-3.86 0-1.495-.293-2.88-.783-3.86-.393-.785-.813-1.14-1.092-1.14-.28 0-.7.355-1.092 1.14-.49.98-.783 2.365-.783 3.86 0 1.495.293 2.88.783 3.86.393.785.813 1.14 1.092 1.14zm0 1.25c-1.726 0-3.125-2.798-3.125-6.25S9.525 2 11.25 2c1.726 0 3.125 2.798 3.125 6.25s-1.4 6.25-3.125 6.25z"
        fill="#fff"
      />
      <path d="M6.25 9.5h10v1.25h-10V9.5zm0-3.75h10V7h-10V5.75z" fill="#fff" />
    </svg>
  );
}
