import { WpReferrerContent } from '@/models/wp-referrer-content.model';
import { WpBonusIntro } from '@/models/wp-bonus-intro.model';
import { WordpressActions, WordpressActionType } from '../actions/wordpressActions';
import { WpTermsAndConditions } from '@/models/wp-terms-and-conditions.model';
import { WpPrivacyPolicy } from '@/models/wp-privacy-policy.model';

export interface WordpressStateModel {
  headerMessage: string | null;
  referrerContent: WpReferrerContent | null;
  bonusIntro: WpBonusIntro | null;
  termsAndConditions: WpTermsAndConditions | null;
  termsAndConditionsLocale: string | null;
  privacyPolicy: WpPrivacyPolicy | null;
  privacyPolicyLocale: string | null;
}

const initialState: WordpressStateModel = {
  referrerContent: null,
  headerMessage: null,
  bonusIntro: null,
  termsAndConditions: null,
  termsAndConditionsLocale: null,
  privacyPolicy: null,
  privacyPolicyLocale: null,
};

export const wordpressReducer = (
  state = initialState,
  action: WordpressActions,
): WordpressStateModel => {
  switch (action.type) {
    case WordpressActionType.GetReferrerContentSuccess:
      return { ...state, referrerContent: action.content };
    case WordpressActionType.GetHeaderMessageSuccess:
      return { ...state, headerMessage: action.message };
    case WordpressActionType.GetBonusIntroSuccess:
      return { ...state, bonusIntro: action.content };
    case WordpressActionType.GetTermsAndConditionsSuccess:
      return {
        ...state,
        termsAndConditions: action.terms,
        termsAndConditionsLocale: action.locale,
      };
    case WordpressActionType.GetPrivacyPolicySuccess:
      return { ...state, privacyPolicy: action.privacyPolicy, privacyPolicyLocale: action.locale };
    default:
      return state;
  }
};
