import React, { useState } from 'react';

import { XMarkIcon } from '@heroicons/react/24/solid';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { PnPAmountSelect } from './PnPAmountSelect';
import { PayNPlaySubmitButton } from './PayNPlaySubmitButton';
import { PayNPlayLoginSwitch } from './PayNPlayLoginSwitch';
import { PayNPlayInput } from './PayNPlayInput';
import { PayNPlayModalHeader } from './PayNPlayModalHeader';
import { PayNPlayWelcomeOptIn } from './PayNPlayWelcomeOptIn';
import { PayNPlayTCs } from './PayNPlayTCs';
import { PNP_CURRENCY, PNP_DEPOSIT_DEFAULTS } from '@/constants/paynplayConstants';
import { validatePnPAmount } from '@/utils/paynplayValidators';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { mapLocaleToLang } from '@/utils/utils';
import { envIsSweden } from '@/constants/constants';
import { openModal } from '@/store/actions/modal';

export function PayNPlayModal({
  closeModal,
  loggedOut = false,
}: {
  closeModal: () => void;
  loggedOut?: boolean;
}) {
  const [depositAmount, setDepositAmount] = useState<number | string>('');
  const [inputError, setInputError] = useState<string | null>(null);
  const [welcomeBonus, setWelcomeBonus] = useState(true);
  const intl = useIntl();
  const locale = useSelector(selectLocale);
  const dispatch = useDispatch();
  const alreadyOptedIn = localStorage.getItem('PNP_WCB_OPTIN') === 'true';

  const registerWithBankId = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const validation = validatePnPAmount(
      depositAmount,
      PNP_CURRENCY,
      mapLocaleToLang(locale),
      true,
    );

    if (!validation.isValid) {
      setInputError(
        intl.formatMessage({ id: validation?.message?.id }, validation?.message?.values),
      );
      return;
    }

    dispatch(openModal('paynplayIframe', { amount: depositAmount, welcomeBonus: welcomeBonus }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value, 10) || '';

    if (newValue !== '') {
      setInputError(null);
    }

    setDepositAmount(newValue);
  };

  const handleAmountSelect = (value: string | number) => {
    setInputError(null);
    setDepositAmount(value);
  };

  return (
    <div
      id="e2e_paynplayModal"
      className="relative w-full max-w-[420px] overflow-hidden rounded-xl bg-white shadow-xl"
    >
      <div className="flex h-full flex-col justify-between">
        <form
          className="relative flex w-full flex-col space-y-6 px-6 py-8"
          onSubmit={registerWithBankId}
        >
          <PayNPlayModalHeader loggedOut={loggedOut} />
          <PayNPlayInput onChangeHandler={handleChange} error={inputError} value={depositAmount} />
          <PnPAmountSelect
            depositAmount={depositAmount}
            handleAmountSelect={handleAmountSelect}
            low={PNP_DEPOSIT_DEFAULTS.low}
            mid={PNP_DEPOSIT_DEFAULTS.mid}
            high={PNP_DEPOSIT_DEFAULTS.high}
          />
          <PayNPlaySubmitButton />
          {envIsSweden() && !alreadyOptedIn && (
            <div className="text-gray-600">
              <PayNPlayWelcomeOptIn
                id="wcbOptInModal"
                optIn={welcomeBonus}
                toggleOptIn={() => setWelcomeBonus(prevValue => !prevValue)}
              />
            </div>
          )}
          <p className="prose prose-sm italic">
            <PayNPlayTCs />
          </p>
        </form>
        <PayNPlayLoginSwitch
          onClickHandler={() => dispatch(openModal('paynplayIframe', { amount: 0, login: true }))}
        />
        <button
          type="button"
          className="absolute top-0 right-0 flex h-12 w-12 items-center justify-center rounded-bl-xl bg-white text-black hover:bg-gray-100"
          onClick={() => closeModal()}
        >
          <XMarkIcon className="h-4 w-4" />
        </button>
      </div>
    </div>
  );
}
