import { XMarkIcon } from '@heroicons/react/24/solid';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { usePayNPlayIframe } from '@/hooks/usePayNPlayIframe';
import { PayNPlayIframe } from './PayNPlayIframe';
import Spinner from '@/components/UI/Spinner';

export function PayNPlayIframeModal({
  amount,
  closeModal,
  login = false,
  welcomeBonus = false,
}: {
  amount: number;
  closeModal: () => void;
  login?: boolean;
  welcomeBonus?: boolean;
}): JSX.Element {
  const intl = useIntl();
  const [closePromptOpen, setClosePromptOpen] = useState(false);
  const { getIframeUrl, iframeUrl, resetIframe } = usePayNPlayIframe(welcomeBonus);

  useEffect(() => {
    getIframeUrl(login ? null : amount);
  }, [amount, login]);

  return (
    <div
      id="e2e_paynplayIframeModal"
      className="relative h-[600px] w-full max-w-[420px] overflow-hidden rounded-xl bg-white shadow-xl"
    >
      {!iframeUrl ? (
        <Spinner absolute dark />
      ) : (
        <PayNPlayIframe
          url={iframeUrl}
          clearIframe={resetIframe}
          closeDrawer={() => {}}
          closeModal={closeModal}
          login={() => getIframeUrl(null)}
          depositAmount={amount || 0}
        />
      )}
      <button
        type="button"
        className="absolute top-0 right-0 flex h-12 w-12 items-center justify-center rounded-bl-xl rounded-tr-xl bg-white text-black hover:bg-gray-100"
        onClick={() => setClosePromptOpen(true)}
      >
        <XMarkIcon className="h-4 w-4" />
      </button>
      {closePromptOpen && (
        <div className="absolute inset-0 flex h-full w-full items-center justify-center bg-black/60 p-8">
          <div className="rounded-xl bg-white px-8 pt-8 pb-6">
            <h3 className="font-display m-0 text-2xl font-bold">
              {intl.formatMessage({ id: 'paynplay.cancel.header' })}
            </h3>
            <button
              onClick={() => {
                closeModal();
                setClosePromptOpen(false);
              }}
              type="button"
              className="mt-6 w-full rounded-full bg-cyan-500 py-3 px-4 font-bold text-white shadow-md"
            >
              {intl.formatMessage({ id: 'paynplay.cancel.close' })}
            </button>
            <button
              onClick={() => setClosePromptOpen(false)}
              type="button"
              className="mt-2 w-full p-2 font-bold"
            >
              {intl.formatMessage({ id: 'paynplay.cancel.stay' })}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
