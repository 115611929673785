import { Action } from 'redux';
import { DBTokenIP } from '@lucky7ventures/lucky-components';

export enum LoginHistoryActionType {
  GetLoginHistory = 'GET_LOGIN_HISTORY',
  GetLoginHistorySuccess = 'GET_LOGIN_HISTORY_SUCCESS',
  GetLoginHistoryError = 'GET_LOGIN_HISTORY_ERROR',
  Reset = 'RESET_LOGIN_HISTORY',
}

export type GetLoginHistoryAction = Action<LoginHistoryActionType.GetLoginHistory> & {
  token: string;
};
export type GetLoginHistorySuccessAction = Action<LoginHistoryActionType.GetLoginHistorySuccess> & {
  loginHistory: DBTokenIP[];
};
export type GetLoginHistoryErrorAction = Action<LoginHistoryActionType.GetLoginHistoryError>;
export type ResetLoginHistoryAction = Action<LoginHistoryActionType.Reset>;

export type LoginHistoryActions =
  | GetLoginHistoryAction
  | GetLoginHistorySuccessAction
  | GetLoginHistoryErrorAction
  | ResetLoginHistoryAction;

export const getLoginHistory = (token: string): GetLoginHistoryAction => ({
  type: LoginHistoryActionType.GetLoginHistory,
  token,
});

export const getLoginHistorySuccess = (
  loginHistory: DBTokenIP[],
): GetLoginHistorySuccessAction => ({
  type: LoginHistoryActionType.GetLoginHistorySuccess,
  loginHistory,
});

export const getLoginHistoryError = (): GetLoginHistoryErrorAction => ({
  type: LoginHistoryActionType.GetLoginHistoryError,
});

export const resetLoginHistory = (): ResetLoginHistoryAction => ({
  type: LoginHistoryActionType.Reset,
});
