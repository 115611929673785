import { axiosInstance as axios } from '../shared/axiosInstance';
import { useState } from 'react';
import { getAxiosError, getGigError } from '@/shared/utility';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';

interface Return {
  success: boolean;
  loading: boolean;
  error: { id: number; message: string } | null;
  forgotPasswordChange: (recoveryCode: string, password: string) => void;
}

export function useForgotPasswordChange(): Return {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<{ id: number; message: string } | null>(null);

  const forgotPasswordChange = (recoveryCode: string, password: string) => {
    setLoading(true);
    axios
      .post(`/api/auth/forgotpassword/change`, { recoveryCode, password })
      .then(response => {
        setLoading(false);
        if (response.data.success) {
          triggerGTag(GTagEvents.user_changed_forgotten_password);
          setSuccess(true);
        } else {
          setError(getGigError(response.data.error));
        }
      })
      .catch(err => {
        setLoading(false);
        setError(getAxiosError(err));
      });
  };

  return { loading, success, error, forgotPasswordChange };
}
