import React from 'react';

import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useEffectOnce } from 'react-use';
import styled from 'styled-components';

import Anchor from '../UI/Buttons/Anchor';
import Button from '../UI/Buttons/Button';
import CloseButton from '../UI/Buttons/CloseButton';
import { openModal } from '@/store/actions/modal';

const StyledBonusTermsModal = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  max-width: 520px;
  overflow: hidden;
  border-radius: ${props => props.theme.borderRadius};
  background: white;
  padding: 64px 16px 16px;
  text-align: left;

  @media (min-width: ${props => props.theme.breakpoints.small}) {
    padding: 64px 32px 16px;
  }

  h1 {
    font-size: 32px;
  }

  button {
    margin: 32px 0 0;
  }

  .close {
    font-weight: bold;
    color: black;
    opacity: 0.6;
    text-decoration: none;
    padding: 16px;
    margin: 0;

    &:hover {
      opacity: 1;
    }
  }
`;

function ChristmasBonusTermsModal({ closeModal, text, header }) {
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffectOnce(() => {
    const modalContainer = document.querySelector('.ReactModal__Overlay');
    const modal = document.querySelector('.hejsan');

    if (modalContainer) {
      modalContainer.scrollTop = 0;
    }

    if (modal) {
      modal.scrollTop = 0;
    }
  });

  return (
    <StyledBonusTermsModal className="hejsan">
      <CloseButton left onClick={closeModal} />
      <h1>{header}</h1>
      {text}
      <Button primary onClick={() => dispatch(openModal('termsAndConditions'))}>
        {intl.formatMessage({
          id: 'bonusTerms.linkToTermsText',
        })}
      </Button>
      <Anchor className="close" onClick={closeModal}>
        Close
      </Anchor>
    </StyledBonusTermsModal>
  );
}

ChristmasBonusTermsModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  text: PropTypes.any.isRequired,
  header: PropTypes.any.isRequired,
};

export default ChristmasBonusTermsModal;
