import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { LockClosedIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import PasswordChangeForm from './PasswordChangeForm';
import DetachedField from '@/components/form/field/DetachedField';

const PasswordChange = () => {
  const intl = useIntl();
  const [showPasswordBox, setShowPasswordBox] = useState(false);

  return (
    <div className="bg-white p-[32px] rounded-t-md">
      <div className={classNames('flex justify-between mt-0 items-center mb-[32px]')}>
        <h2 className="text-[22px] m-0">
          {intl.formatMessage({ id: 'settings.details.password.header' })}
        </h2>
        <div className="w-auto">
          <button
            type="button"
            className="text-lg text-teal-700 font-bold"
            onClick={() => setShowPasswordBox(!showPasswordBox)}
          >
            {!showPasswordBox
              ? intl.formatMessage({ id: 'inputs.password.change' })
              : intl.formatMessage({ id: 'buttons.cancel' })}
          </button>
        </div>
      </div>
      {showPasswordBox ? (
        <PasswordChangeForm
          className="border-[1px] border-gray-200"
          labelClassName="text-teal-700"
        />
      ) : (
        <DetachedField
          className="border-[1px] border-gray-200"
          labelClassName="text-teal-700"
          value="passwordplaceholder"
          type="password"
          endIcon={<LockClosedIcon />}
          readOnly
          label={intl.formatMessage({ id: 'inputs.password' })}
        />
      )}
    </div>
  );
};

export default PasswordChange;
