import React from 'react';
import { useIntl } from 'react-intl';
import { envIsSweden } from '@/constants/constants';
import { classNames } from '@/utils/utils';
import TrustlyLogo from '@/components/shared/logos/Trustly';

export function PayNPlayInput({
  onChangeHandler,
  value,
  error,
}: {
  onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | number;
  error: string | null;
}): JSX.Element {
  const intl = useIntl();

  return (
    <label htmlFor="amount" className="relative flex flex-col">
      <input
        id="amount"
        className={classNames(
          `w-full rounded-lg border-0 border-b-2 py-3 px-2 text-center text-xl font-bold placeholder:font-normal focus:ring-0 sm:rounded-none`,
          error ? '!border-red-500' : '!border-gray-200',
        )}
        type="tel"
        placeholder={intl.formatMessage({ id: 'paynplay.input.placeholder' })}
        value={value}
        onChange={onChangeHandler}
      />
      <span className="absolute left-2 top-3 rounded-lg bg-gray-200 px-2 py-1 text-sm font-bold md:left-0">
        {envIsSweden() ? 'SEK' : 'EUR'}
      </span>
      <TrustlyLogo className="absolute right-3 top-4 h-5 w-16 text-black sm:right-0" />
      {error && (
        <span className="mx-1 rounded-b-md bg-red-200 px-2 py-1 text-left text-xs font-bold text-red-800 sm:m-0">
          {error}
        </span>
      )}
    </label>
  );
}
