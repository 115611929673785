import React, { InputHTMLAttributes } from 'react';

import { CheckIcon } from '@heroicons/react/20/solid';
import { useField } from 'formik';

import { classNames } from '@/utils/utils';

interface IFormInputProps extends InputHTMLAttributes<HTMLInputElement> {
  type?: 'text' | 'email' | 'number' | 'tel';
  label: string;
  completed?: boolean;
  formatOnChange?: (value: any) => any;
  hint?: string;
}

export function FormInput({
  type = 'text',
  label,
  completed = false,
  formatOnChange,
  hint,
  ...rest
}: IFormInputProps): JSX.Element {
  const { name, className, disabled } = rest;

  const [field, meta, helpers] = useField(name!);

  const { setValue } = helpers;

  return (
    <div className="relative mb-8 pb-2">
      <label
        htmlFor={name}
        className="align-start flex flex-col text-left text-sm font-bold text-[#007078]"
      >
        {label}
        <input
          {...field}
          {...rest}
          type={type}
          className={classNames(
            meta.touched && meta.error ? 'border-b-red-500' : 'border-b-slate-200',
            'border-0 border-b-2 bg-slate-50 px-0 pr-6 text-xl font-bold text-black placeholder:font-normal placeholder:text-gray-400 focus:border-b-slate-200 focus:ring-0 disabled:cursor-not-allowed disabled:text-gray-400',
            className,
          )}
          id={name}
          disabled={completed || disabled}
          onChange={e => {
            if (formatOnChange) {
              setValue(formatOnChange(e.target.value));
              return;
            }
            setValue(e.target.value);
          }}
        />
      </label>
      {meta.touched && meta.error && (
        <div className="absolute bottom-0 translate-y-full text-xs text-left text-red-500">
          {meta.error}
        </div>
      )}
      {hint && <div className="mt-2 text-left text-xs italic">{hint}</div>}
      {completed && (
        <span className="absolute right-0 top-8">
          <CheckIcon className="h-6 w-6 text-green-600" />
        </span>
      )}
    </div>
  );
}
