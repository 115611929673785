import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@/components/buttons/Button';
import { useAbsoluteNavigate } from '@/hooks/useAbsoluteNavigate';
import CloseButton from '@/components/UI/Buttons/CloseButton';
import { useCashierHide, useDepositTabEffect } from '../cashier/useCashier';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { useFluidClose } from '../cashier/fluid/fluidPaymentsStore';
import { useDispatch } from 'react-redux';
import { setFtdExitIntentShown } from '@/store/actions/modal';
import Logo from '@/components/shared/logos/Logo';

const ExitIntent = ({ closeModal }: { closeModal: () => void }) => {
  const intl = useIntl();
  const navigate = useAbsoluteNavigate();
  const hideCashier = useCashierHide();
  const { toggleDepositTabEffectTrigger } = useDepositTabEffect();
  const closeFluid = useFluidClose();
  const dispatch = useDispatch();

  const handleOnClose = () => {
    closeModal();
    hideCashier();
    closeFluid();
    triggerGTag(GTagEvents.ftd_exit_intent_closed);
  };

  const handleOnDeposit = () => {
    toggleDepositTabEffectTrigger();
    closeModal();
    triggerGTag(GTagEvents.ftd_exit_intent_deposit);
  };

  const handleOnGamesRedirect = () => {
    closeModal();
    hideCashier();
    closeFluid();
    triggerGTag(GTagEvents.ftd_exit_intent_closed);
    navigate('/games');
  };

  useEffect(() => {
    dispatch(setFtdExitIntentShown());
  }, []);

  return (
    <div
      className="transition-all ease-in-out duration-1000 w-[350px] rounded-lg bg-slate-50"
      data-cy="exit-intent"
    >
      <div className="flex flex-col items-center space-y-[48px] justify-center relative p-8">
        <CloseButton left onClick={handleOnClose} />
        <Logo color="blue3" />
        <div className="text-2xl font-bold">{intl.formatMessage({ id: 'exitIntent.header' })}</div>
        <div className="text-lg font-extralight text-center">
          <FormattedMessage
            id="exitIntent.reminder"
            values={{
              a: (chunks: string[]) => <div className="font-bold text-blue-blue">{chunks[0]}</div>,
            }}
          />
        </div>
        <div className="flex flex-col items-center space-y-[16px] w-[200px]">
          <Button
            onClickHandler={handleOnDeposit}
            btnType="primary"
            text={intl.formatMessage({ id: 'notifications.lowBalanceCta' })}
          />
          <button
            type="button"
            className="rounded-full w-full trensition ring-[3px] ring-blue-blue ring-inset p-3 text-blue-blue hover:border-cyan-600 hover:text-cyan-600 font-bold"
            onClick={handleOnGamesRedirect}
          >
            {intl.formatMessage({ id: 'home.games.seeAllGames' })}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExitIntent;
