import React from 'react';
import { useSelector } from 'react-redux';
import { selectLocale } from '@/store/selectors/commonSelectors';
import CookieBanner from './CookieBanner';

const CookieConsentManager = (): JSX.Element | null => {
  const locale = useSelector(selectLocale);

  if (['th', 'mx'].includes(locale)) {
    return null;
  }

  return <CookieBanner />;
};

export default CookieConsentManager;
