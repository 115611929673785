import React from 'react';
import classNames from 'classnames';
import SkeletonLoader from '@/components/shared/skeleton-loader/SkeletonLoader';

const GameCardSkeleton = (): JSX.Element => {
  return (
    <div>
      <SkeletonLoader className="pb-[122.75%] w-full" />
      <SkeletonLoader className="h-5 mt-3 w-[80%] rounded-md" />
      <SkeletonLoader className="h-5 mt-3 w-[60%] rounded-md" />
    </div>
  );
};

const GamesSkeletonLoader = () => {
  return (
    <div
      className={classNames(
        'w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 mx-auto',
        'gap-y-12 gap-x-4 xs:gap-y-8 md:max-w-[1400px] px-[16px] md:px-[32px]',
      )}
    >
      <GameCardSkeleton />
      <GameCardSkeleton />
      <div className="hidden sm:block">
        <GameCardSkeleton />
      </div>
      <div className="hidden md:block">
        <GameCardSkeleton />
      </div>
      <div className="hidden lg:block">
        <GameCardSkeleton />
      </div>
      <div className="hidden xl:block">
        <GameCardSkeleton />
      </div>
    </div>
  );
};

export default GamesSkeletonLoader;
