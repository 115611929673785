import React from 'react';

import { useIntl } from 'react-intl';

import { IMAGES } from '@/constants/constants';
import { GamesCategories } from '@/models/game-category.type';

interface IEmptyGamesPlaceholderProps {
  category: GamesCategories;
}

function EmptyGamesPlaceholder({ category }: IEmptyGamesPlaceholderProps) {
  const intl = useIntl();

  return (
    <div
      id="e2e_empty_games"
      className="col-span-full mt-12 flex flex-col items-center justify-center gap-4"
    >
      <img
        src={IMAGES.gamesPlaceholder}
        alt="Sitting cat placeholder"
        className="h-[140px] w-auto object-contain"
      />
      <span className="max-w-[350px] text-center text-sm text-darkGray">
        {category === 'last-played'
          ? intl.formatMessage({ id: 'games.lastPlayed.empty.placeholder' })
          : intl.formatMessage({ id: 'games.empty.placeholder' })}
      </span>
    </div>
  );
}

export default EmptyGamesPlaceholder;
