import { envIsCanada, envIsMexico } from '@/constants/constants';
import { User } from '@/models/user.model';
import { IpDataModel } from '@/models/ip-data.model';
import { BffGameItem } from '@lucky7ventures/bff-types';

export const MEXICO_FILTERED_SHORTDESCRIPTIONS: string[] = [
  'Playson',
  'Inspired',
  'Wazdan',
  'Hacksaw Gaming',
];

export const shouldExcludeGame = (
  game: BffGameItem,
  user: User | null,
  locale: string,
  gameTester: boolean,
  ipData: IpDataModel,
): boolean => {
  // If user is a gameTester, we don't exclude any game
  if (gameTester) {
    return false;
  }

  // All of the games that's on .ca will NOT be filtered at all
  if (envIsCanada()) {
    // on Canada build for now we don't have any more filters on the frontend
    return false;
  }

  const CANADA_FILTERED_SHORTDESCRIPTIONS = ['High 5 Games'];
  const SWEDEN_FILTERED_SHORTDESCRIPTIONS: string[] = [];
  const CANADA_QUEBEC_FILTERED_VENDORS = ['Play N Go'];
  const CANADA_QUEBEC_FILTERED_SHORTDESCRIPTIONS = ['Wazdan'];

  // Filtering Rules for Logged in state
  if (user) {
    if (user.CountryCode === 'CA') {
      if (user.Subdivision.IsoCodeSubdivision1 === 'CA-QC') {
        return (
          CANADA_FILTERED_SHORTDESCRIPTIONS.includes(game.shortDesc) ||
          CANADA_QUEBEC_FILTERED_SHORTDESCRIPTIONS.includes(game.shortDesc) ||
          CANADA_QUEBEC_FILTERED_VENDORS.includes(game.vendor)
        );
      }
      return CANADA_FILTERED_SHORTDESCRIPTIONS.includes(game.shortDesc);
    } else if (user?.CountryCode?.toLocaleLowerCase() === 'mx') {
      return MEXICO_FILTERED_SHORTDESCRIPTIONS.includes(game.shortDesc);
    }
  }

  // Filtering Rules for Logged out state
  if (locale === 'ca' || locale === 'ca-fr') {
    // for canada we locale we have several special filtering rules
    if (ipData.region === 'Quebec')
      return (
        CANADA_FILTERED_SHORTDESCRIPTIONS.includes(game.shortDesc) ||
        CANADA_QUEBEC_FILTERED_SHORTDESCRIPTIONS.includes(game.shortDesc) ||
        CANADA_QUEBEC_FILTERED_VENDORS.includes(game.vendor)
      );
    return CANADA_FILTERED_SHORTDESCRIPTIONS.includes(game.shortDesc);
  } else if (locale === 'sv') {
    return SWEDEN_FILTERED_SHORTDESCRIPTIONS.includes(game.shortDesc);
  } else if (envIsMexico() || locale === 'mx') {
    return MEXICO_FILTERED_SHORTDESCRIPTIONS.includes(game.shortDesc);
  }

  return false;
};
