import { Action } from 'redux';
import {
  BffGamesJackpotsResponse,
  BffGamesResponse,
  BffGamesLastPlayedResponse,
} from '@lucky7ventures/bff-types';

export enum GamesActionsTypes {
  Get = 'GET_GAMES',
  GetSuccess = 'GET_GAMES_SUCCESS',
  GetError = 'GET_GAMES_ERROR',
  GetLastPlayedGames = 'GET_LAST_PLAYED_GAMES',
  GetLastPlayedGamesSuccess = 'GET_LAST_PLAYED_GAMES_SUCCESS',
  ClearLastPlayedGames = 'CLEAR_LAST_PLAYED_GAMES',
  GetGamesJackpots = 'GET_GAMES_JACKPOTS',
  GetGamesJackpotsSuccess = 'GET_GAMES_JACKPOTS_SUCCESS',
}

export type GetGamesAction = Action<GamesActionsTypes.Get>;
export type GetGamesSuccessAction = Action<GamesActionsTypes.GetSuccess> & BffGamesResponse;
export type GetGamesErrorAction = Action<GamesActionsTypes.GetError>;
export type GetLastPlayedGamesAction = Action<GamesActionsTypes.GetLastPlayedGames>;
export type GetLastPlayedGamesSuccessAction =
  Action<GamesActionsTypes.GetLastPlayedGamesSuccess> & {
    lastPlayedIds: BffGamesLastPlayedResponse;
  };
export type ClearLastPlayedGamesAction = Action<GamesActionsTypes.ClearLastPlayedGames>;

export type GetGamesJackpotsAction = Action<GamesActionsTypes.GetGamesJackpots>;
export type GetGamesJackpotsSuccessAction = Action<GamesActionsTypes.GetGamesJackpotsSuccess> & {
  data: BffGamesJackpotsResponse;
};

export type GamesActions =
  | GetGamesAction
  | GetGamesSuccessAction
  | GetGamesErrorAction
  | GetLastPlayedGamesAction
  | GetLastPlayedGamesSuccessAction
  | ClearLastPlayedGamesAction
  | GetGamesJackpotsAction
  | GetGamesJackpotsSuccessAction;

export const getGames = (): GetGamesAction => ({
  type: GamesActionsTypes.Get,
});

export const getGamesSuccess = ({
  games,
  providers,
  categories,
}: BffGamesResponse): GetGamesSuccessAction => ({
  type: GamesActionsTypes.GetSuccess,
  games,
  providers,
  categories,
});

export const getGamesError = (): GetGamesErrorAction => ({
  type: GamesActionsTypes.GetError,
});

export const getLastPlayedGames = (): GetLastPlayedGamesAction => ({
  type: GamesActionsTypes.GetLastPlayedGames,
});

export const getLastPlayedGamesSuccess = (
  lastPlayedIds: BffGamesLastPlayedResponse,
): GetLastPlayedGamesSuccessAction => ({
  type: GamesActionsTypes.GetLastPlayedGamesSuccess,
  lastPlayedIds,
});

export const clearLastPlayedGames = (): ClearLastPlayedGamesAction => ({
  type: GamesActionsTypes.ClearLastPlayedGames,
});

export const getGamesJackpots = (): GetGamesJackpotsAction => ({
  type: GamesActionsTypes.GetGamesJackpots,
});

export const getGamesJackpotsSuccess = (
  data: BffGamesJackpotsResponse,
): GetGamesJackpotsSuccessAction => ({
  type: GamesActionsTypes.GetGamesJackpotsSuccess,
  data,
});
