import React from 'react';

import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/24/solid';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ExclamationIcon from './Icons/ExclamationIcon';

const StyledSuccessMessage = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 24px 16px 16px;
  background: ${props => props.theme.colors.green20};
  color: ${props => props.theme.colors.green70};
  border-radius: ${props => props.theme.borderRadius};
  font-size: 14px;
  margin: 0 auto 32px;
  font-weight: bold;
  max-width: 100%;

  .icon {
    display: flex;
    background: ${props => props.theme.colors.green};
    color: ${props => props.theme.colors.white};
    padding: 5px;
    border-radius: 50vh;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  .prompt-text {
    text-align: left;
    padding-left: 20px;

    p {
      margin: 0;
    }
  }
`;

const StyledErrorMessage = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 24px 16px 16px;
  color: ${props => props.theme.colors.red};
  background: ${props => props.theme.colors.red10};
  border-radius: ${props => props.theme.borderRadius};
  font-size: 14px;
  font-weight: bold;
  margin: 0 auto 32px;
  max-width: 100%;

  .icon {
    display: flex;
    background: ${props => props.theme.colors.red};
    color: ${props => props.theme.colors.white};
    padding: 5px;
    border-radius: 50vh;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  .prompt-text {
    text-align: left;
    padding-left: 20px;

    p {
      margin: 0;
    }
  }
`;

const StyledInfoMessage = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 24px 16px 16px;
  color: ${props => props.theme.colors.yellow70};
  background: ${props => props.theme.colors.yellow10};
  border-radius: ${props => props.theme.borderRadius};
  font-size: 14px;
  font-weight: bold;
  margin: 0 auto 20px;
  max-width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.small}) {
    margin: 0 auto 32px;
  }

  .icon {
    display: flex;
    background: ${props => props.theme.colors.yellow};
    color: ${props => props.theme.colors.white};
    padding: 5px;
    border-radius: 50vh;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  .prompt-text {
    text-align: left;
    padding-left: 20px;

    p {
      margin: 0;
    }
  }
`;

const PromptMessage = ({ message, type, ...props }) => {
  let promptMessage = null;

  if (type === 'success') {
    promptMessage = (
      <StyledSuccessMessage {...props} className="prompt-message">
        <span className="icon">
          <CheckIcon />
        </span>
        <div className="prompt-text">
          <p>{message}</p>
        </div>
      </StyledSuccessMessage>
    );
  }
  if (type === 'error') {
    promptMessage = (
      <StyledErrorMessage {...props} className="prompt-message">
        <span className="icon">
          <ExclamationIcon />
        </span>
        <div className="prompt-text">
          <p>{message}</p>
        </div>
      </StyledErrorMessage>
    );
  }
  if (type === 'info') {
    promptMessage = (
      <StyledInfoMessage {...props} className="prompt-message">
        <span className="icon">
          <ExclamationCircleIcon className="rotate-180" />
        </span>
        <div className="prompt-text">
          <p>{message}</p>
        </div>
      </StyledInfoMessage>
    );
  }
  return promptMessage;
};

PromptMessage.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default PromptMessage;
