import React from 'react';

const ExclamationIcon = props => (
  <svg
    viewBox="-10 -5 24 24"
    width={24}
    height={24}
    preserveAspectRatio="xMinYMin"
    className="alert"
    {...props}
  >
    <path
      fill="currentColor"
      d="M2,0.75 C2.69035594,0.75 3.25,1.30964406 3.25,2 L3.25,8.25 C3.25,8.94035594 2.69035594,9.5 2,9.5 C1.30964406,9.5 0.75,8.94035594 0.75,8.25 L0.75,2 C0.75,1.30964406 1.30964406,0.75 2,0.75 Z M2,13.25 C1.30964406,13.25 0.75,12.6903559 0.75,12 C0.75,11.3096441 1.30964406,10.75 2,10.75 C2.69035594,10.75 3.25,11.3096441 3.25,12 C3.25,12.6903559 2.69035594,13.25 2,13.25 Z"
    />
  </svg>
);

export default ExclamationIcon;
