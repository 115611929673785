import { AmlVerificationStatus } from '@lucky7ventures/lucky-components';

import {
  AmlAction,
  AmlActionTypes,
  GetVerificationStatusSuccessAction,
} from '../actions/amlActions';

export interface AmlStateModel {
  verificationStatus: AmlVerificationStatus | null;
  verificationStatusError: boolean;
}

const initialState = {
  verificationStatus: null,
  verificationStatusError: false,
};

const getAmlVerificationStatusSuccess = (
  state: AmlStateModel,
  action: GetVerificationStatusSuccessAction,
) => ({
  ...state,
  verificationStatus: action.verificationStatus,
  verificationStatusError: false,
});

const getAmlVerificationStatusError = (state: AmlStateModel) => ({
  ...state,
  amlVerificationStatusError: true,
});

const amlReducer = (state = initialState, action: AmlAction) => {
  switch (action.type) {
    case AmlActionTypes.GetVerificationStatusSuccess:
      return getAmlVerificationStatusSuccess(state, action);
    case AmlActionTypes.GetVerificationStatusError:
      return getAmlVerificationStatusError(state);
    case AmlActionTypes.ResetAml:
      return initialState;
    default:
      return state;
  }
};

export default amlReducer;
