import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BUILD_MODE } from '@/constants/constants';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { selectUserIsXmasTester } from '@/store/selectors/userSelectors';

const xmasAllowedLocale = (locale: string): boolean => {
  if (BUILD_MODE === 'canada') {
    return locale !== 'ca-fr';
  }

  if (BUILD_MODE === 'sweden') {
    return false;
  }

  const allowedLocales = ['nz', 'row', 'ca', 'ca-fr', 'no', 'at', 'fi'];

  return allowedLocales.includes(locale);
};

const isDecember = () => {
  return new Date().getMonth() === 11;
};

const defaultIsXmasEnabled = (locale: string): boolean => {
  return isDecember() && xmasAllowedLocale(locale);
};

export function useXmas(): boolean {
  const locale = useSelector(selectLocale);
  const isXmasTester = useSelector(selectUserIsXmasTester);
  const [isXmasEnabled, setIsXmasEnabled] = useState(defaultIsXmasEnabled(locale));

  useEffect(() => {
    // Special case when XMAS is true, some locale shouldn't see it
    if (!xmasAllowedLocale(locale)) {
      setIsXmasEnabled(false);
      return;
    }

    // if user is logged in as an Xmas tester we want to show the Xmas stuff even before December
    if (isXmasTester) {
      setIsXmasEnabled(true);
      return;
    }

    // show Xmas only if it's December
    if (isDecember()) {
      setIsXmasEnabled(true);
      return;
    }

    setIsXmasEnabled(false);
  }, [isXmasTester, locale]);

  return isXmasEnabled;
}
