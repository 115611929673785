import { useState } from 'react';
import axios from 'axios';
import { SubdivisionResponseDto } from '@lucky7ventures/lucky-components';

interface Return {
  provinces: SubdivisionResponseDto[];
  getProvinces: (countryCode: string) => void;
}

export function useGetProvinces(): Return {
  const [provinces, setProvinces] = useState<SubdivisionResponseDto[]>([]);

  const getProvinces = (countryCode: string) => {
    axios
      .get(`/api/countries/subdivisions?countryCode=${countryCode}`)
      .then(({ data }) => {
        setProvinces(data);
      })
      .catch(() => setProvinces([]));
  };

  return { provinces, getProvinces };
}
