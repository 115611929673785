import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Tooltip from './UI/Tooltip';

const StyledProgressbar = styled.div`
  text-align: center;
  display: block;
  width: 100%;
  margin: 16px 0 0;

  p {
    margin: 10px 0 0;

    span {
      font-weight: bold;

      &.opacity {
        opacity: 0.6;
        font-weight: normal;
      }
    }
  }

  .progress-bar-container {
    height: 40px;
    background: ${props => props.theme.colors.white};
    box-shadow: ${props => props.theme.boxShadow};
    border-radius: 8px;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;

    .progress {
      width: 100%;
      max-width: ${props => props.turnoverPercent}%;
      background: ${props => props.theme.colors.green};
      background: linear-gradient(90deg, rgba(62, 152, 110, 1) 0%, rgba(72, 178, 128, 1) 100%);
      height: 100%;
      position: relative;
      border-radius: 7px;
      background-clip: padding-box;
      transition: all 0.2s ease-in-out;

      .percent {
        position: absolute;
        font-weight: bold;
        font-size: 14px;
        top: 50%;
        left: ${props => (props.turnoverPercent <= 50 ? '100%' : 'auto')};
        right: ${props => (props.turnoverPercent > 50 ? '0' : 'auto')};
        transform: translateY(-50%);
        padding: 2px 8px;
        color: ${props =>
          props.turnoverPercent > 50 ? props.theme.colors.white : props.theme.colors.blue3};
      }
    }
  }
`;

export function ProgressBar({ turnoverPercent, tooltip }) {
  return (
    <StyledProgressbar turnoverPercent={turnoverPercent}>
      <div className="progress-bar-container tooltip-container">
        <div className="progress">
          <span className="percent">{turnoverPercent}%</span>
        </div>
        {tooltip !== '' && <Tooltip width="160px" text={tooltip} position="bottom" />}
      </div>
    </StyledProgressbar>
  );
}

ProgressBar.propTypes = {
  turnoverPercent: PropTypes.number.isRequired,
  tooltip: PropTypes.string,
};

ProgressBar.defaultProps = {
  tooltip: '',
};
