import React from 'react';
import classNames from 'classnames';
const LoadingSpinner = ({ mode = 'light' }: { mode?: 'light' | 'dark' }) => {
  return (
    <div
      className={classNames(
        'h-14 w-14 rounded-full border-4  animate-spin',
        mode === 'light' ? 'border-white/20 border-l-white' : 'border-black/20 border-l-black',
      )}
    ></div>
  );
};

export default LoadingSpinner;
