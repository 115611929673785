import * as React from 'react';

import { useSelector } from 'react-redux';

import { selectGamesLoading, selectLastPlayedGames } from '@/store/selectors/gamesSelectors';
import CategoryPageContent from '@/pages/games/categories/CategoryPageContent';
import { useIntl } from 'react-intl';
import { LastPlayedCategory } from '@/models/game-category.type';

function CategoryLastPlayedGames(): JSX.Element {
  const intl = useIntl();
  const games = useSelector(selectLastPlayedGames);
  const gamesLoading = useSelector(selectGamesLoading);

  return (
    <CategoryPageContent
      header={intl.formatMessage({ id: `games.categories.lastPlayed` })}
      subheader={intl.formatMessage({ id: `games.categories.lastPlayed.info` })}
      category={LastPlayedCategory.LastPlayed}
      games={games}
      loading={gamesLoading}
    />
  );
}

export default CategoryLastPlayedGames;
