import React, { Fragment, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { useIntl } from 'react-intl';

export interface OccupationAndIndustrySelectItem {
  OccupationId?: number;
  IndustryId?: number;
  Code?: string;
  Name: string;
}

export function OccupationAndIndustrySelect({
  label,
  items,
  placeholder,
  selectedItem,
  onChangeHandler,
}: {
  label: string;
  placeholder: string;
  items: OccupationAndIndustrySelectItem[];
  selectedItem: OccupationAndIndustrySelectItem | null;
  onChangeHandler: (item: OccupationAndIndustrySelectItem) => void;
}): JSX.Element {
  const [query, setQuery] = useState('');
  const intl = useIntl();

  const filteredItems =
    query === ''
      ? items
      : items.filter(item => item.Name.toLowerCase().includes(query.toLowerCase()));

  return (
    <div>
      <span className="text-sm font-bold">{label}</span>
      <Combobox value={selectedItem} onChange={onChangeHandler}>
        {() => (
          <div className="relative mt-1">
            <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left text-sm shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-cyan-300">
              <Combobox.Input
                className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                onChange={event => setQuery(event.target.value)}
                placeholder={placeholder}
                displayValue={(item: OccupationAndIndustrySelectItem) => (item ? item.Name : '')}
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery('')}
            >
              <Combobox.Options className="absolute z-10 mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 px-0 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {filteredItems.length === 0 && query !== '' ? (
                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                    {intl.formatMessage({ id: 'ontario.occupationAndIndustrySelect.noResults' })}
                  </div>
                ) : (
                  filteredItems.map(item => (
                    <Combobox.Option
                      key={item.Name}
                      className={({ active }) =>
                        `relative mb-0 cursor-default select-none py-2 pl-4 pr-10 ${
                          active ? 'bg-cyan-600 text-white' : 'text-gray-900'
                        }`
                      }
                      value={item}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                          >
                            {item.Name}
                          </span>
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 right-0 flex items-center pr-3 ${
                                active ? 'text-white' : 'text-cyan-600'
                              }`}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        )}
      </Combobox>
    </div>
  );
}
